import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ContentApi, CourseApi, SoloDivesApi } from "../../store/api";
// import { CustomTable, TitleView } from "../";

const { Title } = Typography;
const { Search } = Input;

const SoloDivesDetails = () => {
  // const { state } = useLocation();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  //modal add course
  const [
    visibleAddOrEditSoloDivesContentModal,
    setvisibleAddOrEditSoloDivesContentModal,
  ] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [modalLoading, setmodalLoading] = useState(false);
  const [searchedText, setsearchedText] = useState(null);
  const [page, setpage] = useState(null);
  const [totalCount, settotalCount] = useState(0);
  //content modal control
  const [contentAddModal, setContentAddModal] = useState(false);
  const [contentEditModal, setContentEditModal] = useState(false);
  const [contentRecord, setContentRecord] = useState({});
  const [contentList, setcontentList] = useState([]);
  const [showLoadMore, setshowLoadMore] = useState(false);
  const [loadMoreLoading, setloadMoreLoading] = useState(false);
  const me = useSelector((state) => state.MeReducer?.response);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex((item) => item?.type === "dives");
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  useEffect(() => {
    fetchData();
  }, [id]);

  async function fetchData() {
    setLoader(true);
    let payload = {
      solo_dive_id: id,
      page: 1,
      limit: 20,
    };
    const res = await SoloDivesApi.getSoloDivesDetails(id, payload);
    if (res.status === 200) {
      setData(res.data);
      setLoader(false);
    } else {
      console.log("Error while fetch solodives content list data!");
      setLoader(false);
    }
  }

  //chapter edit button click
  const editButtonClick = useCallback(async (param) => {
    setUpdateModal(true);
    setEditData(param);
  }, []);

  //onsubmit add chapter
  const onSubmitAddOrEditSoloDivesContentModal = async (callBackValue) => {
    setmodalLoading(true);
    const res = updateModal
      ? await CourseApi.updateChapter(data.id, editData.id, callBackValue)
      : await CourseApi.addChapter(data.id, callBackValue);
    if (res.status === 200) {
      fetchMyAPI();
      setmodalLoading(false);
      setvisibleAddOrEditSoloDivesContentModal(false);
      setUpdateModal(false);
    } else {
      console.log("Error in chapter submit api", res);
      setmodalLoading(false);
      setvisibleAddOrEditSoloDivesContentModal(false);
      setUpdateModal(false);
    }
  };

  //comtent add click
  const contentAddClick = async (param) => {
    let chapterId = {
      chapter: param.id,
    };
    setContentRecord(chapterId);
    setContentAddModal(true);
  };

  //content button delete
  const contentDeletePress = async (param) => {
    // console.log("USAMA",param);
    setLoader(true);
    let payload = {
      solo_dive_id: id,
      solo_dive_content_id: param.id,
    };
    const res = await SoloDivesApi.deleteSoloDiveContent(payload);
    if (res.status === 200) {
      fetchData();
    } else {
      console.log("Error in content delete api", res);
      setLoader(false);
    }
  };

  //content edit press
  const contentEditPress = (param) => {
    setContentRecord(param);
    setContentEditModal(true);
  };

  //submit content form
  const contentFormSubmit = async (values) => {
    setmodalLoading(true);

    if (values.order) values["order"] = parseInt(values?.order);
    else delete values.order;

    if (contentRecord?.content?.id) {
      values["content"] = contentRecord?.content?.id ?? null;
    }
    const res = contentEditModal
      ? await CourseApi.editContent(
          data.id,
          contentRecord.chapter,
          contentRecord.id,
          values
        )
      : await CourseApi.addContent(data.id, contentRecord.chapter, values);

    if (res.status === 200) {
      fetchMyAPI();
      setmodalLoading(false);
      setContentEditModal(false);
      setContentAddModal(false);
      setContentRecord({});
    } else {
      console.log("Error in chapter submit api", res);
      setmodalLoading(false);
      setContentEditModal(false);
      setContentAddModal(false);
      setContentRecord({});
    }
  };

  //load total page data
  async function fetchMyAPI() {
    setLoader(true);
    const res = await CourseApi.getCourseDetails(id);
    if (res.status === 200) {
      setData(res.data);
      setLoader(false);
    } else {
      console.log("Error while fetch course details data!");
      setLoader(false);
    }
  }

  //chapter => content table (nested view/table)
  const expandedRowRender = (record) => {
    // console.log("ecord.course_contents", record.course_contents);
    return (
      <Table
        className="nestedTable"
        columns={contentColumns}
        dataSource={record.course_contents}
        pagination={false}
        rowKey={(data) => data.id}
      />
    );
  };

  //content column
  const contentColumns = [
    {
      title: "TITLE",
      dataIndex: "title",
    },

    {
      title: "TYPE",
      dataIndex: "type",
      align: "left",
    },

    {
      title: "SCALINGS",
      dataIndex: "scalings",
      align: "center",
      render: (text, record) => {
        // return <Image width={100} height={80} src={text} />;
        return (
          <space style={{ display: "flex" }}>
            {text?.map((item, index) => (
              // <Image width={100} height={80} src={item.bg_path} />
              <>
                {item.name}
                {index + 1 < text.length && ", "}
              </>
            ))}
          </space>
        );
      },
    },
    {
      title: "IS PREMIUM",
      dataIndex: "is_premium",
      align: "center",
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },
    {
      title: "IS FEATURE",
      dataIndex: "is_feature",
      align: "center",
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },
    {
      title: "IS PUBLISHED",
      dataIndex: "is_published",
      align: "center",
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      // width: "20%",
      render: (text, record) => (
        <Space size="middle">
          {/* <Button type="primary" onClick={() => contentEditPress(record)}>
            Edit
          </Button> */}
          {showDelete && (
            <Popconfirm
              title="Are you sure to delete this Content?"
              onConfirm={() => contentDeletePress(record)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" shape="round" size="large">
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const addContent = async (param) => {
    let payload = {
      content: param.id,
      type: param.type,
    };
    const res = await SoloDivesApi.addSoloDiveContent(id, payload);
    if (res.status === 200) {
      fetchData();
    }
  };

  const getContentList = async () => {
    setloadMoreLoading(true);
    const res = await ContentApi.getAllContentList({
      page: page == null ? 1 : page,
      limit: 15,
      extended_type: "DAILY_DIVE",
      unused_course_content: true,
      q: searchedText,
    });

    if (res.status === 200) {
      if (res.data.results.length !== 0) {
        settotalCount(res.data.count);
        if (page === 1 || page == null) {
          setcontentList(res.data.results);
        } else {
          let array = contentList;
          array = [...array, ...res.data.results];
          setcontentList(array);
        }
        if (res.data.results?.length < 15) {
          setshowLoadMore(false);
        } else {
          setshowLoadMore(true);
        }
      }
    }

    setloadMoreLoading(false);
  };

  useEffect(() => {
    if (searchedText != null) {
      setpage(1);
      getContentList();
    }
  }, [searchedText]);

  useEffect(() => {
    if (page != null) {
      getContentList();
    }
  }, [page]);

  return (
    <React.Fragment>
      {loader && (
        <Spin
          style={{
            position: "absolute",
            top: "50%",
            left: "60%",
            zIndex: 1,
          }}
          size="large"
        />
      )}

      {/* <TitleView data={data} />
      <CustomTable title="Benefits: " data={data?.benefits} />
      <CustomTable title="Categories: " data={data?.categories} /> */}

      {/* <Row style={{ marginTop: 10 }}>
        <Title level={5}>TOTAL CONTENTS:</Title>
      </Row> */}
      <Row style={{ marginBottom: 10 }}>
        <Col>
          {showCreateUpdate && (
            <Button
              // icon={<PlusCircleOutlined />}
              size={"large"}
              type="primary"
              shape="round"
              onClick={() => getContentList()}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              ADD CONTENT
            </Button>
          )}
          {contentList.length > 0 && (
            <Search
              placeholder="Search here..."
              enterButton
              size="large"
              allowClear
              clear
              // onChange={text=>setsearchedText(text)}
              onSearch={setsearchedText}
            />
          )}
        </Col>
      </Row>
      <Row>
        {contentList.map((item, index) => (
          <Col span={8} key={index}>
            <Popconfirm
              title="Are you sure to add this Content?"
              onConfirm={() => addContent(item)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<PlusCircleOutlined />}
                size={"large"}
                shape="round"
                type="dashed"
                onClick={() => {}}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                {item.title}
              </Button>
            </Popconfirm>
          </Col>
        ))}
        {/* {contentList.length > 0 && totalCount > 15 && ( */}
        {showLoadMore && (
          <Button
            type="primary"
            shape="round"
            style={{ marginBottom: 5 }}
            loading={loadMoreLoading}
            size={"large"}
            onClick={() => {
              if (page != null) {
                setpage((page) => page + 1);
              } else {
                setpage(2);
              }
            }}
          >
            Load More
          </Button>
        )}
      </Row>

      <Table
        columns={contentColumns}
        dataSource={data?.contents}
        scroll={{ x: 1, y: window.innerHeight - 300 }}
        size="middle"
        loading={data.loading}
        rowKey={(data) => data.id}
        pagination={{
          total: data.count,
          pageSize: 20,
          showSizeChanger: false,
          onChange: (page) => {
            // handlePageChange(page);
          },
        }}
      />
    </React.Fragment>
  );
};

export default SoloDivesDetails;
