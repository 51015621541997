import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  // Popconfirm,
  message,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddEditLocation from "../../components/modals/AddEditLocation";
import { ActionCreators } from "../../store";
import { QuestionariesApi } from "../../store/api";

const { Title } = Typography;
const { Search } = Input;

const Location = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.QuestionariesReducer);
  const [loader, setLoader] = useState(false);
  const [modalLoading, setmodalLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [editData, setEditData] = useState({});
  const [updateLocationModal, setUpdateLocationModal] = useState(false);
  const [addLocationModal, setAddLocationModal] = useState(false);
  const me = useSelector((state) => state.MeReducer?.response);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex(
        (item) => item?.type === "location"
      );
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  // console.log("data.allLocations", data.allLocations);

  useEffect(() => {
    dispatch(ActionCreators.getLocations(1, 20));
  }, [dispatch]);

  const addLocationClick = useCallback(() => {
    setUpdateLocationModal(false);
    setAddLocationModal(true);
    setEditData({});
  }, []);

  const editButtonClick = useCallback((param) => {
    setEditData(param);
    setAddLocationModal(false);
    setUpdateLocationModal(true);
  }, []);

  // const deletePress = async (id) => {
  //   setLoader(true);
  //   try {
  //     const res = await QuestionariesApi.deleteSingleLocation(id);
  //     if (res.status === 200) {
  //       dispatch(ActionCreators.getLocations(1, 20));
  //     }
  //     setLoader(false);
  //   } catch (error) {
  //     setLoader(false);
  //   }
  // };

  const setsearchedText = (text) => {
    setCurrentPageNumber(1);
    dispatch(ActionCreators.getLocations(1, 20, text));
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    dispatch(ActionCreators.getLocations(page, 20));
  };

  const onSubmitModal = async (res) => {
    setmodalLoading(true);
    setLoader(true);
    if (!res.errorFields) {
      const bgLink = res?.image_path ? await res?.image_path : null;
      res["image_path"] = bgLink ?? null;
      // console.log(res);
      const response = updateLocationModal
        ? await QuestionariesApi.updateLocation(res, editData.id)
        : await QuestionariesApi.addLocation(res);
      // console.log("=-=-=-=-=-", response);
      if (response.status === 200) {
        dispatch(ActionCreators.getLocations(currentPageNumber, 20));
      } else {
        message.error("Failed to add/update location data!");
      }
      setmodalLoading(false);
      setAddLocationModal(false);
      setUpdateLocationModal(false);
      setLoader(false);
    } else {
      setmodalLoading(false);
      setAddLocationModal(false);
      setUpdateLocationModal(false);
      setLoader(false);
    }
  };

  const columns = [
    {
      title: "LOCATION NAME",
      dataIndex: "location_name",
      render: (text, record) => <Space size="middle">{text}</Space>,
    },
    {
      title: "IMAGE",
      dataIndex: "image_path",
      align: "center",
      // width: 130,
      render: (text, record) => (
        <Space size="middle">
          <img
            src={record.image_path}
            alt="N/A"
            style={{ height: 50, width: 50 }}
          />
        </Space>
      ),
    },
    // {
    //   title: "ABOUT",
    //   dataIndex: "about",
    //   align: "center",
    //   width: 400,
    //   render: (text, record) => (
    //     <Space size="middle" style={{ textAlign: "justify" }}>
    //       {text}
    //     </Space>
    //   ),
    // },
    {
      title: "STUDIO LOCATION TYPE",
      dataIndex: "studio_location_types",
      align: "center",
      // width: 120,
      render: (text) => (
        // <Space size="middle">
        <div>
          <p>
            {text?.[0]}
            {<span>{text?.[1] ? "," : null}</span>}
          </p>
          <p>{text?.[1] ? text?.[1] : null}</p>
        </div>
        // </Space>
      ),
    },
    {
      title: "AVAILABILITY",
      dataIndex: "availability",
      align: "center",
      // width: 100,
      render: (text, record) => <Space size="middle">{text}</Space>,
    },
    {
      title: "CITY",
      dataIndex: "city",
      align: "center",
      // width: 100,
      render: (text, record) => <Space size="middle">{text}</Space>,
    },
    {
      title: "COUNTRY",
      dataIndex: "country",
      align: "center",
      // width: 100,
      render: (text, record) => <Space size="middle">{text}</Space>,
    },
    // {
    //   title: "CONTACT NO",
    //   dataIndex: "phone",
    //   align: "center",
    //   // width: 100,
    //   render: (text, record) => <Space size="middle">{text}</Space>,
    // },

    {
      title: "IS PUBLISHED",
      dataIndex: "is_published",
      align: "center",
      // width: 120,
      render: (text, record) =>
        text ? (
          <CheckCircleFilled style={{ color: "#22c55e" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#ef4444" }} />
        ),
    },
    {
      title: "ORDER",
      dataIndex: "order",
      align: "center",
      width: 70,
      render: (text, record) => <Space size="middle">{text}</Space>,
    },

    {
      title: "ACTION",
      dataIndex: "role",
      key: "name",
      // width: 200,
      align: "center",
      render: (text, record) => (
        <Space size="small">
          {showCreateUpdate && (
            <Button
              type="primary"
              shape="round"
              onClick={() => editButtonClick(record)}
            >
              EDIT
            </Button>
          )}
          {/* <Popconfirm
            title="Are you sure to delete this Studio Location?"
            onConfirm={() => deletePress(record.id)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Button shape="round" size="large" type="danger">
              Delete
            </Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            Studio Locations
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL STUDIO LOCATIONS : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          {showCreateUpdate && (
            <Button
              icon={<PlusCircleOutlined />}
              size={"large"}
              shape="round"
              type="primary"
              onClick={() => addLocationClick()}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              ADD STUDIO LOCATION
            </Button>
          )}
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-between" }}>
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search by city"
            enterButton
            size="large"
            onSearch={(text) => setsearchedText(text)}
          />
        </Col>
      </Row>
      <Table
        style={{ marginTop: 10 }}
        columns={columns}
        dataSource={data.allLocations}
        scroll={{ x: 1, y: window.innerHeight - 350 }}
        size="middle"
        loading={loader}
        rowKey={(data) => data.id}
        pagination={{
          total: data.count,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
      <Modal
        title={
          updateLocationModal ? "UPDATE STUDIO LOCATION" : "ADD STUDIO LOCATION"
        }
        open={updateLocationModal ? updateLocationModal : addLocationModal}
        footer={null}
        closable={false}
        width={1000}
      >
        <AddEditLocation
          setVisibleModal={
            updateLocationModal ? setUpdateLocationModal : setAddLocationModal
          }
          onSubmitModal={onSubmitModal}
          data={editData}
          modalLoading={modalLoading}
          setmodalLoading={setmodalLoading}
        />
      </Modal>
    </React.Fragment>
  );
};

export default Location;
