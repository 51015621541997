import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "../../store";
import styles from "./styles.module.css";
import { Table, Col, Row, Typography, Space, Input, Select } from "antd";
import moment from "moment";
const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const Subscriber = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.SubscriberReducer);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchedText, setsearchedText] = useState(null);
  const [subscriptionType, setsubscriptionType] = useState(null);

  useEffect(() => {
    dispatch(ActionCreators.getSubscribersAction(1, 20));
  }, [dispatch]);

  const handlePageChange = (page) => {
    let action = ActionCreators.getSubscribersAction(page, 20,subscriptionType,searchedText);
    dispatch(action);
    setCurrentPageNumber(page);
  };

  const onChangeSubscriptionType = useCallback(
    (value) => {
      value = value === "ALL" ? null : value;
      setsubscriptionType(value);
      let action = ActionCreators.getSubscribersAction(
        1,
        20,
        value ?? null,
        searchedText ?? null
      );
      dispatch(action);
      setCurrentPageNumber(1);
    },
    [ searchedText,dispatch]
  );

  const onSearchSubscriber = useCallback(() => {
    let action = ActionCreators.getSubscribersAction(
      1,
      20,
      subscriptionType ?? null,
      searchedText ?? null
    );
    dispatch(action);
    setCurrentPageNumber(1);
  }, [subscriptionType, searchedText,dispatch]);

  const columns = [
    {
      title: "USER",
      dataIndex: "user",
      ellipsis: true,
      textWrap: "word-break",
      width: 130,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      width: 200,
    },
    {
      title: "SUBCRIPTION TYPE",
      dataIndex: "subscription_type",
      width: 130,
      render: (text) => <div style={{ textAlign: "center" }}>{text}</div>,
    },
    {
      title: "STORE",
      dataIndex: "store",
      width: 130,
      align: "center",
      render: (text) => <div>{text === null ? "N/A" : text}</div>,
    },
    {
      title: "START DATE",
      dataIndex: "start_date",
      width: 130,
      align: "center",
      render: (text) => (
        <Space size="middle">{moment(text).format("MM/DD/YYYY")}</Space>
      ),
    },
    {
      title: "END DATE",
      dataIndex: "end_date",
      width: 130,
      align: "center",
      render: (text) => (
        <Space size="middle">{moment(text).format("MM/DD/YYYY")}</Space>
      ),
    },
  ];

  return (
    <div>
      {data.errMsg ? <p className={styles.err}>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ marginBottom: 0, textAlign: "left" }}>
            SUBSCRIBER
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL SUBSCRIBERS : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row
        style={{
          justifyContent: "space-between",
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Select
            placeholder="Subscription type..."
            size="large"
            onChange={onChangeSubscriptionType}
            style={{ minWidth: 200 }}
          >
            <Option value="FREE">FREE</Option>
            <Option value="TRIAL">TRIAL</Option>
            <Option value="UNLOCKED">UNLOCKED</Option>
            <Option value="MONTHLY">MONTHLY</Option>
            <Option value="YEARLY">YEARLY</Option>
            <Option value="ALL">ALL</Option>
          </Select>
        </Col>
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search by name or email..."
            enterButton
            size="large"
            onChange={(e) => setsearchedText(e.target.value)}
            onSearch={onSearchSubscriber}
          />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={data.allSubscribers}
        scroll={{ x: 1, y: window.innerHeight - 310 }}
      
        size="middle"
        loading={data.loading}
        rowKey={(data) => data.id}
        pagination={{
          total: data.count,
          pageSize: 20,
          current:currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
    </div>
  );
};

export default Subscriber;
