/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class FeelingApi {
  static async getAllFeeling(payload) {
    const response = await http.get("/api/admin/feeling", payload);
    return response;
  }
  static async getFeelingDetails(payload) {
    const response = await http.get(`/api/admin/feeling/${payload.id}`);
    return response;
  }
  static async deleteSingleFeeling(id) {
    const response = await http.del(`/api/admin/feeling/${id}`);
    return response;
  }

  static async editFeeling(payload, id) {
    if (!payload.priority) delete payload.priority;

    const response = await http.put(`/api/admin/feeling/${id}`, payload);
    return response;
  }

  static async addFeeling(payload) {
    if (!payload.priority) delete payload.priority;

    const response = await http.post("/api/admin/feeling", payload);
    return response;
  }
}
