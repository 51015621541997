import { put } from "redux-saga/effects";
import { constant } from "../Constant";
import { UserContentApi } from "../api";
// workers
function* getAllUserContentAction(param) {
  try {
    const res = yield UserContentApi.getAllUserContent({
      page: param.page,
      limit: param.limit,
      content_type:param.content_type,
      q:param.q,
      sort:param.sort,
      order:param.order
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_USER_CONTENT_SUCCESS,
        allUserContent: res.data.results,
        count:res.data.count
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_USER_CONTENT_FAILED,
        errMsg: "Invalid number",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_USER_CONTENT_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}

export { getAllUserContentAction };
