import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "../../store";
import NetworkClient from "../../store/api/utils/NetworkClient";
import { TIME_CHOICES } from "../../store/enums";
import CustomDropdownSelect from "../utils/CustomDropdownSelect";
import CustomSelect from "../utils/CustomSelect";
import FileUpload from "../utils/FileUpload";

const { Option } = Select;
const { Text } = Typography;
let index = 0;

const AddEditContentModal = memo(
  ({
    setvisibleAddContentModal,
    onSubmitAddContentModal,
    data,
    loader,
    submitPress,
    selectedContentType,
    setselectedContentType,
  }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [modalLoading, setLoading] = useState(loader);

    //store data
    const insrtuctorOptions = useSelector((state) => state.InstructorReducer);
    const categoryOptions = useSelector((state) => state.CategoryReducer);
    const feelingsOptions = useSelector((state) => state.FeelingReducer);
    const scallingsOptions = useSelector((state) => state.ScalingReducer);
    const tagsOptions = useSelector((state) => state.TagReducer);
    const moodsOptions = useSelector((state) => state.MoodReducer);
    const [disableSubmit, setdisableSubmit] = useState(false);

    //loader
    useEffect(() => {
      submitPress ? setLoading(true) : setLoading(false);
    }, [submitPress]);

    //reset fields
    useEffect(() => {
      form.resetFields();
    }, [form, data]);

    //call api for form data
    useEffect(() => {
      if (selectedContentType !== "") {
        dispatch(ActionCreators.getInstructorsAction(1, 20));
        dispatch(ActionCreators.getCategoriesAction(1, 20));
        dispatch(ActionCreators.getFeelingsAction(1, 20));
        dispatch(ActionCreators.getScalingsAction(1, 20));
        dispatch(ActionCreators.getTagsAction(1, 20));
        dispatch(ActionCreators.getMoodsAction(1, 20));
      }
    }, [selectedContentType, dispatch]);

    //custom add intros dropdown
    const [items, setItems] = useState([]);
    const [name, setName] = useState("");
    const onNameChange = (event) => {
      setName(event.target.value);
    };
    const addItem = (e) => {
      e.preventDefault();
      if (name) {
        setItems([...items, name || `New item ${index++}`]);
        setName("");
      }
    };

    //custom add prompts dropdown
    const [prompts, setPrompts] = useState([]);
    const [val, setVal] = useState("");
    const onValChange = (event) => {
      setVal(event.target.value);
    };
    const addPromptsItem = (e) => {
      e.preventDefault();
      if (val) {
        setPrompts([...prompts, val || `New item ${index++}`]);
        setVal("");
      }
    };

    //background image upload
    const imageUpload = async (e) => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("image", e.file);
        const res = await NetworkClient.filePost(
          "/api/admin/upload-image",
          formData
        );
        console.log(res.status);
        if (res.status === 200) {
          const { data } = res;
          console.log("uploaded image link", data);
          setdisableSubmit(false);
          setLoading(false);
          return data;
        } else {
          console.log("image upload failed");
          message.error("Unable to upload");
          setLoading(false);
          setdisableSubmit(true);
        }

        setLoading(false);
      } catch (error) {
        message.error("Unable to upload image");
        setLoading(false);
        setdisableSubmit(true);
      }
    };

    //file upload
    const fileUpload = async (e) => {
      setLoading(true);
      // console.log(e.file);
      try {
        const formData = new FormData();
        formData.append("file", e.file);
        const res = await NetworkClient.filePost(
          "/api/admin/upload-file",
          formData
        );
        const { data } = res;
        console.log("uploaded file link", data);
        setLoading(false);
        return data;
      } catch (e) {
        console.log(e);
        setLoading(false);
        return false;
      }
    };

    const onSearchInstructor = async (value) => {
      await dispatch(ActionCreators.getInstructorsAction(1, 20, value));
    };

    const onSearchTag = async (value) => {
      await dispatch(ActionCreators.getTagsAction(1, 20, value));
    };

    const onSearchCategory = async (value) => {
      await dispatch(ActionCreators.getCategoriesAction(1, 20, value));
    };

    const onSearchMood = async (value) => {
      await dispatch(ActionCreators.getMoodsAction(1, 20, value));
    };

    const onSearchFelling = async (value) => {
      await dispatch(ActionCreators.getFeelingsAction(1, 20, value));
    };

    const onSearchScaling = async (value) => {
      await dispatch(ActionCreators.getScalingsAction(1, 20, value));
    };

    return (
      <>
        {modalLoading && (
          <Spin
            style={{
              position: "absolute",
              top: "48%",
              left: "48%",
              zIndex: 1,
            }}
            size="large"
          />
        )}
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onSubmitAddContentModal}
          onFinishFailed={onSubmitAddContentModal}
          autoComplete="off"
          initialValues={{
            title: data?.title ?? null,
            modified_title: data?.modified_title ?? null,
            instructor: data?.instructor ?? null,
            categories: data?.categories ?? [],
            feelings: data?.feelings ?? [],
            scalings: data?.scalings ?? [],
            tags: data?.tags ?? [],
            moods: data?.moods ?? [],
            interval: data?.interval ?? null,
            extended_type: data?.extended_type ?? null,
            priority: data?.priority ?? null,
            post_id: data?.post_id ?? null,
            is_premium: data?.is_premium ?? false,
            is_feature: data?.is_feature ?? false,
            is_published: data?.is_published ?? false,
            bg_path: data?.bg_path ?? null,
            file_path: data?.file_path ?? null,
            file_two_path: data?.file_two_path ?? null,
            file_three_path: data?.file_three_path ?? null,
            audio_path: data?.audio_path ?? null,
            intros: data?.intros ?? [],
            outro: data?.outro ?? "",
            prompts: data?.prompts ?? [],
            file_time_duration: data?.file_time_duration,
            file_two_time_duration: data?.file_two_time_duration,
            file_three_time_duration: data?.file_three_time_duration,
          }}
        >
          {!(data && data?.id) && (
            <Form.Item
              name="content_type"
              rules={[
                { required: true, message: "Please input the Content name!" },
              ]}
            >
              <Select
                placeholder="Select content type"
                onChange={(val) => {
                  setselectedContentType(val);
                }}
              >
                <Option value="JOURNAL">JOURNAL</Option>
                <Option value="AUDIO">AUDIO</Option>
                <Option value="VIDEO">VIDEO</Option>
                <Option value="ARTICLE">ARTICLE</Option>
              </Select>
            </Form.Item>
          )}

          {selectedContentType !== "" && (
            <>
              <Form.Item
                label="TITLE"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please input title!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {selectedContentType === "AUDIO" && (
                <Form.Item label="MODIFIED TITLE" name="modified_title">
                  <Input />
                </Form.Item>
              )}
              <Row gutter={16}>
                <CustomSelect
                  item={insrtuctorOptions?.instructors}
                  label="INSTRUCTOR"
                  name="instructor"
                  onSearch={onSearchInstructor}
                  defaultValue={data?.instructor}
                />
                <CustomSelect
                  item={categoryOptions?.categories}
                  label="CATEGORIES"
                  name="categories"
                  onSearch={onSearchCategory}
                  defaultValue={data?.categories}
                />
              </Row>
              <Row gutter={16}>
                <CustomSelect
                  item={feelingsOptions?.feelings}
                  label="FEELINGS"
                  name="feelings"
                  onSearch={onSearchFelling}
                  defaultValue={data?.feelings}
                />
                <CustomSelect
                  item={scallingsOptions?.scalings}
                  label="SCALINGS"
                  name="scalings"
                  onSearch={onSearchScaling}
                  defaultValue={data?.scalings}
                />
              </Row>
              <Row gutter={16}>
                <CustomSelect
                  item={tagsOptions?.tags}
                  label="TAGS"
                  name="tags"
                  onSearch={onSearchTag}
                  defaultValue={data?.tags}
                />
                <CustomSelect
                  item={moodsOptions?.moods}
                  label="MOODS"
                  name="moods"
                  onSearch={onSearchMood}
                  defaultValue={data?.moods}
                />
              </Row>

              <Row gutter={16}>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    label="INTERVAL"
                    name="interval"
                    rules={[
                      {
                        required: true,
                        message: "Interval is required!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Interval"
                      optionFilterProp="children"
                      onChange={() => {}}
                      onSearch={() => {}}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {Object.keys(TIME_CHOICES).map((key) => {
                        return (
                          <Option key={key} value={key}>
                            {TIME_CHOICES[key]}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    label="EXTENDED TYPE"
                    name="extended_type"
                    rules={[
                      {
                        required: true,
                        message: "Extended type is required!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select extended type"
                      onChange={() => {}}
                    >
                      <Option value="REGULAR">REGULAR</Option>
                      <Option value="DAILY_DIVE">DAILY_DIVE</Option>
                      <Option value="COURSE">COURSE</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={24}>
                  <CustomDropdownSelect
                    label="INTROS"
                    name="intros"
                    items={items}
                    value={name}
                    onNameChange={onNameChange}
                    addItem={addItem}
                    defaultValue={data?.intros}
                  />
                </Col>
                {selectedContentType === "JOURNAL" && (
                  <Col span={24}>
                    <CustomDropdownSelect
                      label="PROMPTS"
                      name="prompts"
                      items={prompts}
                      value={val}
                      onNameChange={onValChange}
                      addItem={addPromptsItem}
                      defaultValue={data?.prompts}
                    />
                  </Col>
                )}
              </Row>

              {selectedContentType === "JOURNAL" && (
                <Form.Item label="OUTRO" name="outro">
                  <Input />
                </Form.Item>
              )}

              <Row gutter={16}>
                <Col lg={6} sm={6} xs={12}>
                  <Form.Item name="is_premium" valuePropName="checked">
                    <Checkbox>IS PREMIUM</Checkbox>
                  </Form.Item>
                </Col>
                <Col lg={6} sm={6} xs={12}>
                  <Form.Item name="is_feature" valuePropName="checked">
                    <Checkbox>IS FEATURE</Checkbox>
                  </Form.Item>
                </Col>
                <Col lg={6} sm={6} xs={12}>
                  <Form.Item name="is_published" valuePropName="checked">
                    <Checkbox>IS PUBLISHED</Checkbox>
                  </Form.Item>
                </Col>
                <Col lg={6} sm={6} xs={12} style={{ display: "flex" }}>
                  <span style={{ paddingTop: 5, paddingRight: 5 }}>
                    PRIORITY
                  </span>
                  <Form.Item name="priority">
                    <Input type={"number"} placeholder="Priority" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                {selectedContentType === "ARTICLE" ? (
                  <Col lg={12} sm={24} xs={24}>
                    <div>
                      <Form.Item
                        name="post_id"
                        label="POST ID"
                        rules={[
                          {
                            required: true,
                            message: "Post ID is required!",
                          },
                        ]}
                      >
                        <Input placeholder="Input 3 digit Post ID" />
                      </Form.Item>
                    </div>
                  </Col>
                ) : (
                  <Col lg={12} sm={24} xs={24}>
                    <div style={{ width: window.innerWidth * 1 }}>
                      <FileUpload
                        label={
                          selectedContentType === "VIDEO"
                            ? "UPLOAD VIDEO"
                            : "UPLOAD AUDIO"
                        }
                        name={
                          selectedContentType === "AUDIO" ||
                          selectedContentType === "VIDEO"
                            ? "file_path"
                            : "audio_path"
                        }
                        upload={fileUpload}
                        accept={
                          selectedContentType === "VIDEO"
                            ? ".MP4, .MOV, .WMV, .FLV, .WebM, .MKV "
                            : ".MP3, .WAV, .AAC"
                        }
                        modalLoading={modalLoading}
                        required={
                          selectedContentType === "JOURNAL" ? false : true
                        }
                      />
                      {data?.file_path !== "" &&
                        data?.file_path !== undefined && (
                          <Text>
                            {data?.file_path.substr(
                              data?.file_path.lastIndexOf("/") + 1
                            )}
                          </Text>
                        )}
                    </div>
                  </Col>
                )}
                {selectedContentType === "AUDIO" && (
                  <Col lg={6} sm={6} xs={12}>
                    <Form.Item
                      label="AUDIO1 DURATION"
                      name="file_time_duration"
                      rules={[
                        {
                          required:
                            form.getFieldValue("file_path") === null
                              ? false
                              : true,
                          message: "This field is Required",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: "80%" }}
                        placeholder={"00:00:00"}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Divider />
              {selectedContentType === "AUDIO" && (
                <Row>
                  <Col lg={12} sm={24} xs={24}>
                    <div style={{ width: window.innerWidth * 1 }}>
                      <FileUpload
                        label="AUDIO UPLOAD 2"
                        name="file_two_path"
                        upload={fileUpload}
                        accept=".MP3, .WAV, .AAC"
                        modalLoading={modalLoading}
                      />
                      {data?.file_two_path !== "" &&
                        data?.file_two_path !== undefined &&
                        data?.file_two_path !== null && (
                          <Text>
                            {data?.file_two_path.substr(
                              data?.file_two_path.lastIndexOf("/") + 1
                            )}
                          </Text>
                        )}
                    </div>
                  </Col>
                  <Col lg={6} sm={6} xs={12}>
                    <Form.Item
                      label="AUDIO2 DURATION"
                      name="file_two_time_duration"
                      rules={[
                        {
                          required:
                            form.getFieldValue("file_two_path") === null
                              ? false
                              : true,
                          message: "This field is Required",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: "80%" }}
                        placeholder={"00:00:00"}
                      />
                    </Form.Item>
                  </Col>
                  <Divider />
                </Row>
              )}

              {selectedContentType === "AUDIO" && (
                <Row>
                  <Col lg={12} sm={24} xs={24}>
                    <div style={{ width: window.innerWidth * 1 }}>
                      <FileUpload
                        label="AUDIO UPLOAD 3"
                        name="file_three_path"
                        upload={fileUpload}
                        accept=".MP3, .WAV, .AAC"
                        modalLoading={modalLoading}
                      />
                      {data?.file_three_path !== "" &&
                        data?.file_three_path !== undefined &&
                        data?.file_three_path !== null && (
                          <Text>
                            {data?.file_three_path.substr(
                              data?.file_three_path.lastIndexOf("/") + 1
                            )}
                          </Text>
                        )}
                    </div>
                  </Col>
                  <Col lg={6} sm={6} xs={12}>
                    <Form.Item
                      label="AUDIO3 DURATION"
                      name="file_three_time_duration"
                      rules={[
                        {
                          required:
                            form.getFieldValue("file_three_path") === null
                              ? false
                              : true,
                          message: "This field is Required",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: "80%" }}
                        placeholder={"00:00:00"}
                      />
                    </Form.Item>
                  </Col>
                  <Divider />
                </Row>
              )}
              <Row gutter={16}>
                <Col lg={12} sm={24} xs={24}>
                  <div style={{ width: window.innerWidth * 1 }}>
                    <FileUpload
                      label="BG IMAGE"
                      name="bg_path"
                      upload={imageUpload}
                      accept=".png, .jpg, .jpeg"
                      modalLoading={modalLoading}
                      required={true}
                    />
                    {data?.bg_path !== "" &&
                      data?.bg_path !== undefined &&
                      data?.bg_path !== null && (
                        <>
                          <Text>
                            {data?.bg_path.substr(
                              data?.bg_path.lastIndexOf("/") + 1
                            )}
                          </Text>
                        </>
                      )}
                  </div>
                </Col>
              </Row>
            </>
          )}

          <Form.Item>
            <Row gutter={10} style={{ justifyContent: "flex-end" }}>
              <Col>
                <Button
                  type="danger"
                  shape="round"
                  onClick={() => {
                    form.resetFields();
                    setvisibleAddContentModal(false);
                  }}
                  size="large"
                >
                  Close
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  shape="round"
                  loading={loader}
                  disabled={modalLoading || disableSubmit}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </>
    );
  }
);

export default AddEditContentModal;
