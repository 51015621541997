import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  message,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import AddEditMoodModal from "../../components/modals/AddEditMoodModal";
import { ActionCreators } from "../../store";
import { MoodApi } from "../../store/api";

const Mood = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const { getMoodsAction } = bindActionCreators(ActionCreators, dispatch); //call action
  const data = useSelector((state) => state.MoodReducer);
  const me = useSelector((state) => state.MeReducer?.response);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  //modal
  const [visibleAddMoodModal, setvisibleAddMoodModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [modalLoading, setmodalLoading] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex(
        (item) => item?.type === "content_configuration"
      );
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  useEffect(() => {
    dispatch(ActionCreators.getMoodsAction(1, 20));
  }, [dispatch]);

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    dispatch(ActionCreators.getMoodsAction(page, 20));
  };

  const addMoodButtonClick = useCallback(() => {
    setvisibleAddMoodModal(true);
    setUpdateModal(false);
    setEditData({});
  }, []);

  //edit button click
  const editButtonClick = useCallback((param) => {
    setEditData(param);
    setUpdateModal(true);
  }, []);

  //Add Mood
  const onSubmitAddMoodModal = useCallback(
    async (res) => {
      if (!res.errorFields) {
        setmodalLoading(true);
        const values = {
          name: res.name,
          bg_path: res.bg_path ? res.bg_path : null,
          icon_path: res.icon_path,
          priority: res.priority ? res.priority : null,
        };
        const response = updateModal
          ? await MoodApi.editMood(values, editData.id)
          : await MoodApi.addMood(values);
        if (response.status === 201 || response.status === 200) {
          getMoodsAction(currentPageNumber, 20);
          setvisibleAddMoodModal(false);
          setUpdateModal(false);
          message.success(
            `Mood successfully ${updateModal ? "Updated" : "Added"}`
          );
          setmodalLoading(false);
        } else {
          message.error(response?.data?.detail);
          setmodalLoading(false);
        }
      }
    },
    [getMoodsAction, editData, updateModal, currentPageNumber]
  );

  const columns = [
    // {
    //   title: "Serial No",
    //   key: "index",
    //   render: (value, item, index) => <Space size="middle">{index + 1}</Space>,
    // },
    {
      title: "Id",
      dataIndex: "id",
      ellipsis: true,
      textWrap: "word-break",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Icon",
      dataIndex: "icon_path",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <img
            src={record.icon_path}
            alt="N/A"
            style={{ height: 50, width: 50 }}
          />
        </Space>
      ),
    },
    {
      title: "Background Image",
      dataIndex: "bg_path",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <img
            src={record.bg_path}
            alt="N/A"
            style={{ height: 50, width: 50 }}
          />
        </Space>
      ),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      align: "center",
      render: (text) => (
        <>
          {text !== null && text.toString() !== "9999999999" ? (
            <>{text}</>
          ) : (
            "N/A"
          )}
        </>
      ),
    },
    {
      title: "Created Time",
      dataIndex: "created_at",
      render: (text, record) => (
        <Space size="middle">
          {moment(record.created_at).utc().format("MM/DD/YYYY hh:mm:A")}
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "role",
      key: "name",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          {showCreateUpdate && (
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={() => editButtonClick(record)}
            >
              Edit
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            MOOD
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL MOODS : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          {showCreateUpdate && (
            <Button
              icon={<PlusCircleOutlined />}
              size={"large"}
              shape="round"
              type="primary"
              onClick={() => addMoodButtonClick()}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              ADD MOOD
            </Button>
          )}
        </Col>
      </Row>
      <Table
        rowKey={(data) => data.id}
        columns={columns}
        dataSource={data.moods}
        scroll={{ x: 1, y: window.innerHeight - 280 }}
        size="middle"
        loading={data.loading}
        pagination={{
          total: data.count,
          pageSize: 20,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
      <Modal
        title={updateModal ? "UPDATE Mood" : "ADD MOOD"}
        open={updateModal ? updateModal : visibleAddMoodModal}
        footer={null}
        closable={false}
      >
        <AddEditMoodModal
          setvisibleAddMoodModal={
            updateModal ? setUpdateModal : setvisibleAddMoodModal
          }
          onSubmitAddMoodModal={onSubmitAddMoodModal}
          data={editData}
          modalLoading={modalLoading}
        />
      </Modal>
    </React.Fragment>
  );
};

export default Mood;
