import { constant } from "../Constant";

const initialState = {
  loading: true,
  allUserCourseContent: [],
  count:0,
  errMsg: "",
};

const UserCourseContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_USER_COURSE_CONTENT:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_USER_COURSE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        allUserCourseContent: action.allUserCourseContent,
        count: action.count,
        errMsg: "",
      };
    case constant.GET_USER_COURSE_CONTENT_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    case constant.CLEAN_USER_COURSE_CONTENT:
      return {
        ...state,
        loading: false,
        allUserCourseContent: [],
        count:0,
        errMsg: "",
      };
    default:
      return state;
  }
};

export default UserCourseContentReducer;
