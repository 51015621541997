import { Col, Form, Select } from "antd";
import React, { memo } from "react";

const { Option } = Select;

const CustomSelect = memo(
  ({
    item,
    label,
    name,
    defaultValue,
    required = false,
    onSearch,
    onFocus,
    fullWidth = false,
  }) => {
    return (
      <Col lg={fullWidth ? 24 : 12} sm={24} xs={24}>
        <Form.Item
          label={label}
          name={name}
          // initialValue={{[`${name}`]:defaultValue}}
          rules={[
            {
              required: required,
              message: label + " is Required!",
            },
          ]}
        >
          <Select
            showSearch
            labelInValue
            onFocus={onFocus}
            mode={
              name === "instructor" || name === "form_next" ? null : "multiple"
            }
            placeholder={"Select/Search " + label}
            optionFilterProp="children"
            // maxTagCount={2}
            onChange={(value) => {
              if (
                value &&
                value?.length > 10 &&
                name !== "instructor" &&
                name === "form_next"
              ) {
                let tem = value.slice(-1)[0];
                value.splice(9, value.length - 9, tem);
              }
            }}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            defaultValue={defaultValue}
          >
            {item &&
              item.map((data, index) => {
                return (
                  <Option key={index} value={data.id}>
                    {/* {data.name === undefined ? data.modified_title : data.name} */}
                    {data?.name === undefined ? data?.title : data?.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
      </Col>
    );
  }
);

export default CustomSelect;
