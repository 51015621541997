import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";

const Dashboard = () => {
  return (
    <div>
      <Header />
      <h1>Welcome to DiveThru Admin system</h1>
      <Footer/>
    </div>
  );
};

export default Dashboard;
