import {
  Avatar,
  Button,
  Col, List,
  Radio,
  Row, Typography
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "../../store";

export default function SelectInstructorModal({
  onSelectInstructor,
  onSelectLocation,
  locationList,
}) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [hasMore, sethasMore] = useState(true);
  const instrctorReducer = useSelector((state) => state.InstructorReducer);
  const [selectedId, setselectedId] = useState("");

  const [page, setpage] = useState(1);
  // const loadMoreData = () => {
  //   if (loading) {
  //     return;
  //   }
  //   setLoading(true);
  //   dispatch(ActionCreators.getInstructorsAction(page, 20));
  // };
  // useEffect(() => {
  //   loadMoreData();
  // }, [page]);

  useEffect(() => {
    dispatch(ActionCreators.getInstructorsAction(page, 100));
  }, [])
  

  useEffect(() => {
    // console.log(instrctorReducer?.instructors?.length);
    if (instrctorReducer?.instructors?.length < 20) {
      sethasMore(false);
    }
    setData([...data, ...instrctorReducer?.instructors]);
    setLoading(false);
  }, [instrctorReducer]);


  return (
    <div
      id="scrollableDiv"
      style={{
        height: 400,
        overflow: "auto",
        padding: "0 16px",
        // border: "1px solid rgba(140, 140, 140, 0.35)",
      }}
    >
      <List
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item key={item?.id + "_" + index} style={{ display: "block" }}>
            <Row>
              <Col span={6}>
                <Avatar size={80} src={item?.instructor?.image_path} />
              </Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <p style={{ marginBottom: 0 }}>{item?.name}</p>
                <p>{item?.instructor?.designation}</p>
              </Col>
              <Col
                span={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => {
                    setselectedId(item?.id);
                    onSelectInstructor(item?.id);
                  }}
                >
                  SELECT
                </Button>
              </Col>
            </Row>

            {item?.id === selectedId && locationList?.length > 0 && (
              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text underline style={{ textAlign: "center" }}>
                    Select Location from below
                  </Typography.Text>
                </Col>

                <Col
                  span={24}
                  style={{
                    marginTop: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Radio.Group
                    buttonStyle="solid"
                    onChange={(e) => {
                      onSelectLocation(e.target.value, item?.id);
                    }}
                  >
                    {locationList?.map((loc) => (
                      <Radio.Button key={loc?.id} value={loc?.id}>
                        {loc?.location_name}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Col>
              </Row>
            )}
          </List.Item>
        )}
      />
    </div>
  );
}
