import { Button, Card, Col, Form, Input, message, Row, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SettingApi } from "../../store/api";

// {
//   "title": "string",
//   "description": "string",
//   "phone_number": "string",
//   "email": "user@example.com"
// }
export default function SupportInfo() {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(true);
  const [data, setdata] = useState({});
  const me = useSelector((state) => state.MeReducer?.response);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex(
        (item) => item?.type === "site_configuration"
      );
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  useEffect(() => {
    getSupportInfo();
  }, []);

  const getSupportInfo = async () => {
    const response = await SettingApi.getSupportInfo();
    if (response.status === 200) {
      setdata(response?.data);
      setloading(false);
    } else {
      setloading(false);
      message.error(response?.data?.detail);
    }
  };

  const onSubmit = async (value) => {
    try {
      setloading(true);
      let values = {
        title: value?.title ?? null,
        description: value?.description ?? null,
        phone_number: value?.phone_number ?? null,
        email: value?.email ?? null,
      };
      const response = await SettingApi.updateSupportInfo(values);
      if (response.status === 201 || response.status === 200) {
        message.success("Successfully updated.");
        getSupportInfo();
        setloading(false);
      } else {
        setloading(false);
        message.error("Update unsuccessful");
      }
    } catch (error) {
      setloading(false);
      message.error("Update unsuccessful");
    }
  };

  return (
    <div className="settingFormContainer">
      <Row gutter={20}>
        <Col span={24}>
          <Card
            title="SUPPORT INFO UPDATE SETTINGS"
            style={{
              width: 500,
            }}
          >
            {loading ? (
              <Spin size="large" />
            ) : (
              <Form
                form={form}
                layout="vertical"
                name="basic"
                initialValues={{
                  title: data?.title,
                  description: data?.description,
                  phone_number: data?.phone_number,
                  email: data?.email,
                }}
                onFinish={onSubmit}
                onFinishFailed={onSubmit}
                autoComplete="off"
              >
                <Form.Item label="Title" name="title">
                  <Input />
                </Form.Item>
                <Form.Item label="Description" name="description">
                  <TextArea rows={3} placeholder={"Write from here..."} />
                </Form.Item>
                <Form.Item label="Phone Number" name="phone_number">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    htmlType="submit"
                    block
                    disabled={!showCreateUpdate}
                  >
                    UPDATE SUPPORT INFO
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}
