import { put } from "redux-saga/effects";
import { AppointmentApi } from "../api";
import { constant } from "../Constant";
// workers
function* getAppointmentAction(param) {
  try {
    const res = yield AppointmentApi.getAppointments({
      page: param.page,
      limit: param.limit,
      q: param.q,
      appointment_type: param.appointment_type,
      appointment_age_category: param.appointment_age_category,
      appointment_location: param.appointment_location,
      is_canceled: param.is_canceled,
      is_completed: param.is_completed,
      is_payment: param.is_payment,
      start_date: param.start_date,
      end_date: param.end_date,
      cancel_start_date: param.cancel_start_date,
      cancel_end_date: param.cancel_end_date,
      instructor_id: param.instructor_id,
      sort: param.sort,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_APPOINTMENT_SUCCESS,
        appointment: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_APPOINTMENT_FAILED,
        errMsg: "Invalid appointment",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_APPOINTMENT_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}

export { getAppointmentAction };
