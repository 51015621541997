import { put } from "redux-saga/effects";
import { CategoryApi } from "../api";
import { constant } from "../Constant";
// workers
function* getAllCategoryAction(param) {
  try {
    const res = yield CategoryApi.getAllCategory({
      page: param.page,
      limit: param.limit,
      q: param.q,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_CATEGORIES_SUCCESS,
        categories: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_CATEGORIES_FAILED,
        errMsg: "Invalid category",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_CATEGORIES_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
function* getCategoryDetailsAction(param) {
  try {
    const res = yield CategoryApi.getCategoryDetails(param);
    if (res.status === 200) {
      const payload = {
        type: constant.GET_CATEGORY_DETAILS_SUCCESS,
        categoryDetails: res.data,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_CATEGORIES_FAILED,
        errMsg: "Invalid category",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_CATEGORIES_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
export { getAllCategoryAction, getCategoryDetailsAction };
