import { Button, Col, Form, Input, message, Row, Spin, Checkbox } from "antd";
import React, { memo, useEffect, useState } from "react";

const { TextArea } = Input;

const AddEditOptions = memo(
  ({ setVisibleModal, onSubmitModal, data, modalLoading }) => {
    const [form] = Form.useForm();
    const [showData, setShowData] = useState(false);

    useEffect(() => {
      if (data?.additional_data) {
        setShowData(true);
      }
    }, [data]);

    useEffect(() => {
      form.resetFields();
    }, [form, data]);

    const onSubmit = (value) => {
      if (value.errorFields) {
        message.error("Please fill up required fields");
      } else {
        setShowData(false);
        onSubmitModal(value);
        form.resetFields();
      }
    };

    const closeButtonClick = () => {
      form.resetFields();
      setShowData(false);
      setVisibleModal(false);
    };

    return (
      <React.Fragment>
        {modalLoading ? (
          <Spin
            size="large"
            style={{ display: "flex", justifyContent: "center" }}
          />
        ) : (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{
              name: data?.name ?? null,
              amount: data?.additional_data?.amount ?? null,
              description: data?.additional_data?.description ?? null,
              label: data?.additional_data?.label ?? null,
              ignore_matching: data?.additional_data?.ignore_matching ?? false,
            }}
            onFinish={onSubmit}
            onFinishFailed={onSubmit}
            autoComplete="off"
          >
            <Row gutter={8}>
              <Col lg={17} sm={17} xs={17}>
                <Form.Item
                  label="NAME"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input title!",
                    },
                  ]}
                >
                  <Input placeholder="Enter the option name" />
                </Form.Item>
              </Col>
              <Col
                lg={7}
                sm={7}
                xs={7}
                style={{
                  alignSelf: "center",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Button
                  type="danger"
                  shape="round"
                  onClick={() => {
                    setShowData(!showData);
                  }}
                  size="large"
                  style={{
                    borderRadius: 12,
                    backgroundColor: "white",
                    color: "black",
                    borderColor: "gray",
                    fontSize: 12,
                    height: 35,
                  }}
                >
                  Additional data
                </Button>
              </Col>
            </Row>

            {showData && (
              <>
                <Row gutter={16}>
                  <Col lg={12} sm={12} xs={12}>
                    <Form.Item label="AMOUNT" name="amount">
                      <Input placeholder="Write amount" type="number" />
                    </Form.Item>
                  </Col>
                  <Col lg={12} sm={12} xs={12}>
                    <Form.Item label="DESCRIPTION" name="description">
                      <TextArea placeholder="Write description" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col lg={12} sm={12} xs={12}>
                    <Form.Item name="ignore_matching" valuePropName="checked">
                      <Checkbox>IGNORE MATCHING</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col lg={12} sm={12} xs={12}>
                    <Form.Item label="LABEL" name="label">
                      <Input placeholder="Create label" />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Form.Item>
              <Row gutter={5} style={{ justifyContent: "flex-end" }}>
                <Col>
                  <Button
                    type="danger"
                    shape="round"
                    onClick={closeButtonClick}
                    size="large"
                  >
                    Close
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    shape="round"
                    htmlType="submit"
                    size="large"
                    loading={modalLoading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}
      </React.Fragment>
    );
  }
);

export default AddEditOptions;
