import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import AddEditIntervalModal from "../../components/modals/AddEditIntervalModal";
import { ActionCreators } from "../../store";
import { IntervalApi } from "../../store/api";

const Interval = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const { getIntervalsAction } = bindActionCreators(ActionCreators, dispatch); //call action
  const data = useSelector((state) => state.IntervalReducer);
  const me = useSelector((state) => state.MeReducer?.response);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  //modal
  const [visibleAddIntervalModal, setvisibleAddIntervalModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [modalLoading, setmodalLoading] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex(
        (item) => item?.type === "content_configuration"
      );
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  useEffect(() => {
    dispatch(ActionCreators.getIntervalsAction(1, 20));
  }, [dispatch]);

  const deletePress = async (id) => {
    const res = await IntervalApi.deleteSingleInterval(id);
    if (res.status === 200) {
      getIntervalsAction(currentPageNumber, 20);
      message.success(res?.data?.message);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    dispatch(ActionCreators.getIntervalsAction(page, 20));
  };

  const addIntervalButtonClick = useCallback(() => {
    setvisibleAddIntervalModal(true);
    setUpdateModal(false);
    setEditData({});
  }, []);

  //edit button click
  const editButtonClick = useCallback((param) => {
    setEditData(param);
    setUpdateModal(true);
  }, []);

  //Add Interval
  const onSubmitAddIntervalModal = useCallback(
    async (res) => {
      if (!res.errorFields) {
        setmodalLoading(true);
        const values = {
          interval: res.interval,
        };
        if (res.step_one !== "") {
          values.step_one = res.step_one;
        }
        if (res.step_two !== "") {
          values.step_two = res.step_two;
        }
        if (res.step_three !== "") {
          values.step_three = res.step_three;
        }
        if (res.step_four !== "") {
          values.step_four = res.step_four;
        }

        const response = updateModal
          ? await IntervalApi.editInterval(values, editData.id)
          : await IntervalApi.addInterval(values);
        if (response.status === 201 || response.status === 200) {
          getIntervalsAction(currentPageNumber, 20);
          setvisibleAddIntervalModal(false);
          setUpdateModal(false);
          message.success(
            `Interval successfully ${updateModal ? "Updated" : "Added"}`
          );
          setmodalLoading(false);
        } else {
          message.error(response?.data?.detail);
          setmodalLoading(false);
        }
      }
    },
    [getIntervalsAction, editData, updateModal, currentPageNumber]
  );

  const columns = [
    {
      title: "INTERVAL",
      dataIndex: "interval",
    },
    {
      title: "STEP ONE",
      dataIndex: "step_one",
    },
    {
      title: "STEP TWO",
      dataIndex: "step_two",
    },
    {
      title: "STEP THREE",
      dataIndex: "step_three",
    },
    {
      title: "STEP FOUR",
      dataIndex: "step_four",
    },
    {
      title: "Action",
      dataIndex: "role",
      key: "id",
      align: "center",
      render: (text, record) => (
        <Space size="small">
          {showCreateUpdate && (
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={() => editButtonClick(record)}
            >
              Edit
            </Button>
          )}
          {showDelete && (
            <Popconfirm
              title="Are you sure to delete this interval?"
              onConfirm={() => deletePress(record.id)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button shape="round" size="large" type="danger">
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            INTERVAL
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL INTERVALS : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          {showCreateUpdate && (
            <Button
              icon={<PlusCircleOutlined />}
              size={"large"}
              shape="round"
              type="primary"
              onClick={() => addIntervalButtonClick()}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              ADD INTERVAL
            </Button>
          )}
        </Col>
      </Row>
      <Table
        rowKey={(data) => data.id}
        columns={columns}
        dataSource={data.intervals}
        scroll={{ x: 1, y: window.innerHeight - 280 }}
        size="middle"
        loading={data.loading}
        pagination={{
          total: data.count,
          pageSize: 20,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
      <Modal
        title={updateModal ? "UPDATE INTERVAL" : "ADD INTERVAL"}
        open={updateModal ? updateModal : visibleAddIntervalModal}
        footer={null}
        closable={false}
      >
        <AddEditIntervalModal
          setvisibleAddIntervalModal={
            updateModal ? setUpdateModal : setvisibleAddIntervalModal
          }
          onSubmitAddIntervalModal={onSubmitAddIntervalModal}
          data={editData}
          modalLoading={modalLoading}
        />
      </Modal>
    </React.Fragment>
  );
};

export default Interval;
