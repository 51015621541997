import { put } from "redux-saga/effects";
import { ReminderApi } from "../api";
import { constant } from "../Constant";
// workers
function* getRemindersAction(param) {
  try {
    const res = yield ReminderApi.getReminders({
      page: param.page,
      limit: param.limit,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_REMINDERS_SUCCESS,
        reminders: res.data.results,
        count:res.data.count
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_REMINDERS_FAILED,
        errMsg: "Invalid reminder",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_REMINDERS_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
function* getReminderDetailsAction(param) {
  try {
    const res = yield ReminderApi.getReminderDetails(param);
    if (res.status === 200) {
      const payload = {
        type: constant.GET_REMINDER_DETAILS_SUCCESS,
        details: res.data,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_REMINDERS_FAILED,
        errMsg: "Invalid reminder",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_REMINDERS_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
export { getRemindersAction, getReminderDetailsAction };
