import axios from "axios";
import { logOutAction } from "../../action/user";
import { store } from "../../store";
import { API_BASE_URL } from "../Config";
import { AuthApi } from "../index";
var moment = require("moment-timezone");

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.headers.common["X-API-TOKEN"] = "nomoreapikey";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["LOCAL-TIMEZONE"] = moment.tz.guess();

const setToken = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const request = async (config) => {
  const callBack = axios(config);
  return callBack
    .then(() => {
      return callBack;
    })
    .catch(async (error) => {
      console.log(
        "🚀 ~ file: AxiosConfig.js ~ request ~ error",
        error.response
      );
      if (error.response.status === 422) {
        return error;
      } else if (
        error.response.status === 400 ||
        error.response.status === 403
      ) {
        return error.response;
      } else if (error.response == null) {
        return error;
      } else if (error.response.status === 401) {
        const result = await refreshToken();
        if (result !== null) {
          try {
            config.headers = {
              common: {
                "X-JWT-TOKEN": `Bearer ${result}`,
              },
            };
          } catch (error) {
            console.log(error);
          }
          return axios(config);
        } else {
          return axios(config);
        }
      } else if (error.response.status === 503) {
        return error;
      } else if (error.code === "ECONNABORTED") {
        return axios(config);
      } else {
        if (error.response.data.message) {
          return error.response.data.message;
        }
        return error;
      }
    });
};

const refreshToken = async () => {
  let refresh_Token = localStorage.getItem("refreshToken");
  try {
    const res = await AuthApi.refreshToken({
      refresh_token: refresh_Token,
    });
    if (res.status === 200) {
      setToken(res.data.access_token);
      localStorage.setItem("token", res.data.access_token);
      return res.data.access_token;
    } else {
      console.log("Token has been expired");
      store.dispatch(logOutAction());
      return null;
    }
  } catch (error) {
    console.log("Error in logout from refreshtoken", error);
    store.dispatch(logOutAction());
  }
};

export { request, setToken, refreshToken };
