/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class UserCourseContentApi {
  static async getAllUserCourseContent(payload) {
    const response = await http.get("/api/admin/user_course_content", payload);
    return response;
  }

  static async deleteSingleUserCourseContent(payload) {
    const response = await http.del(`/api/admin/user_course_content/${payload}`);
    return response;
  }

  static async editUserCourseContent(payload, id) {
    const response = await http.put(`/api/admin/user_course_content/${id}`, payload);
    return response;
  }

  static async addUserCourseContent(payload) {
    const response = await http.post("/api/admin/user_course_content", payload);
    return response;
  }
}
