import { put } from "redux-saga/effects";
import { MoodApi } from "../api";
import { constant } from "../Constant";
// workers
function* getMoodsAction(param) {
  try {
    const res = yield MoodApi.getAllMood({
      page: param.page,
      limit: param.limit,
      q: param.q,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_MOODS_SUCCESS,
        moods: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_MOODS_FAILED,
        errMsg: "Invalid mood",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_MOODS_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
function* getMoodDetailsAction(param) {
  try {
    const res = yield MoodApi.getMoodDetails(param);
    if (res.status === 200) {
      const payload = {
        type: constant.GET_MOOD_DETAILS_SUCCESS,
        details: res.data,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_MOODS_FAILED,
        errMsg: "Invalid mood",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_MOODS_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
export { getMoodsAction, getMoodDetailsAction };
