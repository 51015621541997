/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class BenefitApi {
  static async getBenefits(payload) {
    const response = await http.get("/api/admin/benefit", payload);
    return response;
  }
  static async getBenefitDetails(payload) {
    const response = await http.get(`/api/admin/benefit/${payload.id}`);
    return response;
  }
  static async deleteSingleBenefit(id) {
    const response = await http.del(`/api/admin/benefit/${id}`);
    return response;
  }

  static async editBenefit(payload, id) {
    const response = await http.put(`/api/admin/benefit/${id}`, payload);
    return response;
  }

  static async addBenefit(payload) {
    const response = await http.post("/api/admin/benefit", payload);
    return response;
  }
}
