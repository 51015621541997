/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class SubscriberApi {
  static async getAllSubscriber(payload) {
    const response = await http.get("/api/admin/subscriber", payload);
    return response;
  }

  static async deleteSingleSubscriber(payload) {
    const response = await http.del(`/api/admin/subscriber/${payload}`);
    return response;
  }

  static async editSubscriber(payload, id) {
    const response = await http.put(`/api/admin/subscriber/${id}`, payload);
    return response;
  }

  static async addSubscriber(payload) {
    const response = await http.post("/api/admin/subscriber", payload);
    return response;
  }
}
