import { constant } from "../Constant";

const initialState = {
  loading: true,
  reminders: [],
  count:0,
  errMsg: "",
  details: {},
};

const ReminderReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_REMINDERS:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_REMINDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        reminders: action.reminders,
        count:action.count,
        errMsg: "",
      };
    case constant.GET_REMINDER_DETAILS:
      return {
        ...state,
        id: action.id,
        loading: true,
      };
    case constant.GET_REMINDER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: action.details,
        errMsg: "",
      };
    case constant.GET_REMINDERS_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    case constant.CLEAN_REMINDER:
      return {
        ...state,
        loading: false,
        reminders: [],
        count:0,
        errMsg: "",
      };
    default:
      return state;
  }
};

export default ReminderReducer;
