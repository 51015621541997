import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
// import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import AddEditContentModal from "../../components/modals/AddEditContentModal";
import { ActionCreators } from "../../store";
import { ContentApi } from "../../store/api";
import ContentFilter from "./ContentFilter";
import styles from "./styles.module.css";

const { Title, Text } = Typography;

const Content = () => {
  const dispatch = useDispatch();
  // const { getContentsAction } = bindActionCreators(ActionCreators, dispatch); //call action
  const data = useSelector((state) => state.ContentReducer);
  const [visibleAddContentModal, setvisibleAddContentModal] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [updateModal, setUpdateModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [modalLoading, setmodalLoading] = useState(false);
  const [selectedContentType, setselectedContentType] = useState("");
  const [filterData, setFilterData] = useState({});
  //duration
  const [filePathOne, setFilePathOne] = useState(null);
  const [filePathTwo, setFilePathTwo] = useState(null);
  const [filePathThree, setFilePathThree] = useState(null);
  const [deletePopupData, setdeletePopupData] = useState({});
  const [showDeleteConfirmModal, setshowDeleteConfirmModal] = useState(false);
  const [deleteId, setdeleteId] = useState("");
  const [deleteName, setdeleteName] = useState("");
  const [submitPress, setSubmitPress] = useState(false);
  const me = useSelector((state) => state.MeReducer?.response);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex(
        (item) => item?.type === "content"
      );
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  useEffect(() => {
    dispatch(ActionCreators.getContentsAction({ page: 1, limit: 20 }));
  }, [dispatch]);

  const deletePress = async (id, name) => {
    setdeleteName(name);
    setmodalLoading(true);
    // let type = params.type.toLowerCase();
    const res = await ContentApi.deleteSingleContent(id);
    if (res.status === 200) {
      setdeletePopupData(res.data);
      setdeleteId(id);
      setshowDeleteConfirmModal(true);
    }
    setmodalLoading(false);
  };

  const deleteConfirm = async () => {
    // console.log(1);
    setmodalLoading(true);
    // let type = params.type.toLowerCase();
    const res = await ContentApi.deleteSingleContentConfirm(deleteId);
    if (res.status === 200) {
      filterData["page"] = currentPageNumber ?? 1;
      filterData["limit"] = 20;
      let action = ActionCreators.getContentsAction(filterData);
      dispatch(action);
      setshowDeleteConfirmModal(false);
      console.log(res);
      message.success(res.data.message);
    } else {
      message.error(res.message);
    }
    setmodalLoading(false);
  };

  const handlePageChange = useCallback(
    (page) => {
      filterData["page"] = page;
      filterData["limit"] = 20;
      let action = ActionCreators.getContentsAction(filterData);
      dispatch(action);
      setCurrentPageNumber(page);
    },
    [dispatch, filterData]
  );

  const addContentButtonClick = useCallback(() => {
    setSubmitPress(false);
    setvisibleAddContentModal(true);
    setUpdateModal(false);
    setEditData({});
  }, []);

  //edit button click
  const editButtonClick = useCallback(async (param) => {
    setSubmitPress(false);
    setmodalLoading(true);
    setselectedContentType(param.type);

    let type = param.type.toLowerCase();
    const res = await ContentApi.getContentDetails(type, param.id);
    if (res.status === 200) {
      let data = res.data;
      data["categories"] = generateDefaultValues(data?.categories);
      data["feelings"] = generateDefaultValues(data?.feelings);
      data["scalings"] = generateDefaultValues(data?.scalings);
      data["moods"] = generateDefaultValues(data?.moods);
      data["tags"] = generateDefaultValues(data?.tags);
      data["instructor"] = generateDefaultValues(data?.instructor);
      setmodalLoading(false);
      setEditData(res.data);
    } else {
      setEditData(param);
    }
    setmodalLoading(false);
    setUpdateModal(true);
  }, []);

  const generateDefaultValues = (param) => {
    let array = [];
    if (param?.length > 0) {
      array = [];
      for (let a of param) {
        let obj = {
          value: a.id,
          label: a.name,
        };
        array.push(obj);
      }
    } else if (!Array.isArray(param) && param?.id !== undefined) {
      let obj = {
        value: param?.id,
        label: param?.name,
      };
      array.push(obj);
    }
    return array;
  };

  //get file duration
  const fileDuration = async (audioLink, label) => {
    setmodalLoading(true);
    let media = new Audio(audioLink);
    media.onloadedmetadata = () => {
      const duration = media.duration;
      const minutes = Math.floor(duration / 60);
      const seconds = Math.floor(duration % 60);
      const totalTime = `00:${minutes ?? "00"}:${seconds ?? "00"}`;
      if (label === 1) {
        console.log("uploaded file duration", totalTime);
        setFilePathOne(totalTime);
      } else if (label === 2) {
        console.log("uploaded file2 duration", totalTime);
        setFilePathTwo(totalTime);
      } else {
        console.log("uploaded file3 duration", totalTime);
        setFilePathThree(totalTime);
      }
      setmodalLoading(false);
    };
  };

  //Add Content
  const onSubmitAddContentModal = useCallback(
    async (values) => {
      setmodalLoading(true);
      if (!values.errorFields && !submitPress) {
        setSubmitPress(true);
        const payload = { ...values };
        payload.instructor = values.instructor?.value;
        payload.modified_title =
          values.modified_title === "" ? null : values.modified_title;
        payload.categories = values.categories?.map((item) => item.value);
        payload.moods = values.moods?.map((item) => item.value);
        payload.feelings = values.feelings?.map((item) => item.value);
        payload.scalings = values.scalings?.map((item) => item.value);
        payload.tags = values.tags?.map((item) => item.value);

        if (payload.priority) payload["priority"] = parseInt(payload.priority);
        else delete payload.priority;

        delete payload.content_type;
        const bgLink = await payload.bg_path;
        const audioLink = await (selectedContentType === "AUDIO" ||
        selectedContentType === "VIDEO"
          ? payload.file_path
          : payload.audio_path);
        payload["bg_path"] = bgLink;
        payload["interval"] = payload.interval ?? null;

        payload[
          selectedContentType === "AUDIO" || selectedContentType === "VIDEO"
            ? "file_path"
            : "audio_path"
        ] = audioLink ?? null;
        payload["instructor"] =
          payload?.instructor !== "" ? payload?.instructor : null;
        // console.log("FORM VALUES", payload);
        let response;
        if (selectedContentType === "JOURNAL") {
          response = updateModal
            ? await ContentApi.updateJournal(payload, editData.id)
            : await ContentApi.addJournal(payload);
        } else if (selectedContentType === "AUDIO") {
          const audioLink2 = await payload.file_two_path;
          const audioLink3 = await payload.file_three_path;
          fileDuration(audioLink, 1);
          fileDuration(audioLink2, 2);
          fileDuration(audioLink3, 3);
          // console.log("modalLoading", modalLoading);
          setTimeout(async () => {
            payload["file_time_duration"] =
              filePathOne ??
              (payload?.file_time_duration !== ""
                ? payload?.file_time_duration
                : "00:00:00");
            payload["file_two_path"] = audioLink2;
            payload["file_two_time_duration"] =
              filePathTwo ??
              (payload?.file_two_time_duration !== ""
                ? payload?.file_two_time_duration
                : "00:00:00");
            payload["file_three_path"] = audioLink3;
            payload["file_three_time_duration"] =
              filePathThree ??
              (payload?.file_three_time_duration !== ""
                ? payload?.file_three_time_duration
                : "00:00:00");
            // console.log("pay=====", filePathThree);
            // console.log("090909", payload?.file_three_time_duration);
            response = updateModal
              ? await ContentApi.updateAudio(payload, editData.id)
              : await ContentApi.addAudio(payload);
            if (response?.status === 200) {
              filterData["page"] = currentPageNumber ?? 1;
              filterData["limit"] = 20;
              dispatch(ActionCreators.getContentsAction(filterData));
              setvisibleAddContentModal(false);
              setUpdateModal(false);
            }
          }, 3000);
        } else if (selectedContentType === "VIDEO") {
          if (payload?.audio_path) {
            delete payload?.audio_path;
          }
          response = updateModal
            ? await ContentApi.updateVideo(payload, editData.id)
            : await ContentApi.addVideo(payload);
        } else if (selectedContentType === "ARTICLE") {
          response = updateModal
            ? await ContentApi.updateArticle(payload, editData.id)
            : await ContentApi.addArticle(payload);
        }
        // console.log("res========>", response);
        if (response?.status === 201 || response?.status === 200) {
          filterData["page"] = currentPageNumber ?? 1;
          filterData["limit"] = 20;
          dispatch(ActionCreators.getContentsAction(filterData));
          setvisibleAddContentModal(false);
          setUpdateModal(false);
        }
        setmodalLoading(false);
      } else {
        setmodalLoading(false);
      }
    },
    [
      currentPageNumber,
      editData,
      selectedContentType,
      dispatch,
      filePathOne,
      filePathTwo,
      filePathThree,
      filterData,
      updateModal,
      submitPress,
    ]
  );

  const onSubmit = useCallback(
    (params) => {
      setCurrentPageNumber(1);
      let action = ActionCreators.getContentsAction(params);
      dispatch(action);
      setFilterData(params);
    },
    [dispatch]
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      ellipsis: true,
      textWrap: "word-break",
      width: 130,
    },
    {
      title: "NAME",
      dataIndex: "title",
      width: 200,
    },
    {
      title: "TYPE",
      dataIndex: "type",
      width: 100,
      align: "center",
    },
    {
      title: "EXTENTED TYPE",
      dataIndex: "extended_type",
      width: 100,
      align: "center",
    },
    {
      title: "PREMIUM",
      dataIndex: "is_premium",
      width: 100,
      align: "center",
      render: (value) =>
        value ? (
          <CheckCircleFilled style={{ color: "#22c55e" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#ef4444" }} />
        ),
    },
    {
      title: "BACKGROUND IMAGE",
      dataIndex: "bg_path",
      width: 130,
      align: "center",
      render: (text) => (
        <Space size="middle">
          <img src={text} alt="N/A" style={{ height: 60, width: 100 }} />
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "role",
      key: "id",
      width: 180,
      render: (text, record) => (
        <Space size="small">
          {showCreateUpdate && (
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={() => editButtonClick(record)}
            >
              Edit
            </Button>
          )}
          {showDelete && (
            <Button
              type="danger"
              shape="round"
              size="large"
              onClick={() => deletePress(record.id, record.title)}
            >
              Delete
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      {data.errMsg ? <p className={styles.err}>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ marginBottom: 0, textAlign: "left" }}>
            CONTENT
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL CONTENT : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          {showCreateUpdate && (
            <Button
              icon={<PlusCircleOutlined />}
              size={"large"}
              type="primary"
              shape="round"
              onClick={() => addContentButtonClick()}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              ADD CONTENT
            </Button>
          )}
        </Col>
      </Row>
      <ContentFilter
        onSubmit={onSubmit}
        currentPageNumber={currentPageNumber}
      />
      <Table
        columns={columns}
        dataSource={data.contents}
        // scroll={{ x: 1 }}
        scroll={{ x: 1, y: window.innerHeight - 350 }}
        // size="middle"
        loading={data.loading}
        rowKey={(data) => data.id}
        pagination={{
          total: data.count,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />

      <Modal
        title={`Content Name: ${deleteName}`}
        open={showDeleteConfirmModal}
        // footer={null}
        closable={false}
        width={1000}
        okText="DELETE"
        onOk={deleteConfirm}
        okButtonProps={{
          type: "danger",
          shape: "round",
        }}
        cancelButtonProps={{
          shape: "round",
        }}
        onCancel={() => setshowDeleteConfirmModal(false)}
      >
        {modalLoading ? (
          <Spin
            style={{
              position: "absolute",
              top: "48%",
              left: "48%",
              zIndex: 1,
            }}
            size="large"
          />
        ) : (
          <>
            <Title level={5}>{deletePopupData?.message}</Title>
            <Row>
              <strong level={5}>
                {deletePopupData?.course_content?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.course_content?.message}</Text>
            </Row>
            <Row>
              <strong level={5}>
                {deletePopupData?.solo_dive_content?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.solo_dive_content?.message}</Text>
            </Row>
            <Row>
              <strong level={5}>
                {deletePopupData?.user_content?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.user_content?.message}</Text>
            </Row>
            <Row>
              <strong level={5}>
                {deletePopupData?.user_course_content?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.user_course_content?.message}</Text>
            </Row>

            <Row>
              <strong level={5}>
                {deletePopupData?.user_favourite?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.user_favourite?.message}</Text>
            </Row>
            <Row>
              <strong level={5}>
                {deletePopupData?.daily_dive?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.daily_dive?.message}</Text>
            </Row>
          </>
        )}
      </Modal>

      <Modal
        title={updateModal ? "UPDATE " + selectedContentType : "ADD CONTENT"}
        open={updateModal ? updateModal : visibleAddContentModal}
        footer={null}
        closable={false}
        width={1000}
      >
        <AddEditContentModal
          setvisibleAddContentModal={
            updateModal ? setUpdateModal : setvisibleAddContentModal
          }
          onSubmitAddContentModal={onSubmitAddContentModal}
          data={editData}
          loader={modalLoading}
          submitPress={submitPress}
          selectedContentType={selectedContentType}
          setselectedContentType={setselectedContentType}
        />
      </Modal>
    </div>
  );
};

export default Content;
