import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddEditCourseModal from "../../components/modals/AddEditCourseModal";
import DeleteItemConfirmationModal from "../../components/modals/DeleteItemConfirmationModal";
import { ActionCreators } from "../../store";
import { CourseApi } from "../../store/api";

const { Title } = Typography;
const { Search } = Input;

const Course = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.CourseReducer);
  const insrtuctorOptions = useSelector((state) => state.InstructorReducer);
  const categoryOptions = useSelector((state) => state.CategoryReducer);
  const benefitsOptions = useSelector((state) => state.BenefitReducer);

  const [loader, setLoader] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [visibleAddMoodModal, setvisibleAddMoodModal] = useState(false);
  const [modalLoading, setmodalLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [submitPress, setSubmitPress] = useState(false);

  const [deletePopupData, setdeletePopupData] = useState({});
  const [showDeleteConfirmModal, setshowDeleteConfirmModal] = useState(false);
  const [deleteId, setdeleteId] = useState("");
  const [deleteName, setdeleteName] = useState("");
  const me = useSelector((state) => state.MeReducer?.response);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex((item) => item?.type === "course");
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  useEffect(() => {
    if (data.loading) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [data]);

  useEffect(() => {
    dispatch(ActionCreators.getCourseAction(currentPageNumber, 20));
  }, [dispatch, currentPageNumber]);

  useEffect(() => {
    dispatch(ActionCreators.getInstructorsAction(1, 20));
    dispatch(ActionCreators.getCategoriesAction(1, 20));
    dispatch(ActionCreators.getBenefitsAction(1, 20));
  }, [dispatch]);

  const AddCourse = () => {
    setSubmitPress(false);
    setEditData({});
    setUpdateModal(false);
    setvisibleAddMoodModal(true);
  };

  const editModalOpenPress = async (params) => {
    setSubmitPress(false);
    setmodalLoading(true);

    let benefits = [];
    let categories = [];
    const res = await CourseApi.getCourseDetails(params.id);
    if (res.status === 200) {
      (await res.data.benefits.length) > 0 &&
        res.data.benefits.map((res) => benefits.push(res.id));
      (await res.data.categories.length) > 0 &&
        res.data.categories.map((res) => categories.push(res.id));
      res.data["benefitsFilter"] = benefits;
      res.data["categoriesFilter"] = categories;
      setEditData(res.data);
    } else {
      setEditData(params);
    }
    setUpdateModal(true);
    setmodalLoading(false);
    setvisibleAddMoodModal(false);
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    dispatch(ActionCreators.getCourseAction(page, 20));
  };

  const onSubmitAddContentModal = useCallback(
    async (values) => {
      setmodalLoading(true);
      if (!values.errorFields && !submitPress) {
        setSubmitPress(false);
        delete values.content_type;
        const payload = { ...values };
        payload.instructor = values.instructor?.label
          ? values.instructor?.value
          : values.instructor;
        payload.categories = values.categories?.[0]?.label
          ? values.categories?.map((item) => item.value)
          : values.categories;
        payload.benefits = values.benefits?.[0]?.label
          ? values.benefits?.map((item) => item.value)
          : values.benefits;

        payload.amount_money = {
          amount: values?.amount,
          currency: values?.currency ?? "CAD",
        };

        const bgLink = await values.cover_image;
        payload.cover_image = await bgLink;
        // console.log(payload);
        let response = updateModal
          ? await CourseApi.updateCourse(payload, editData.id)
          : await CourseApi.addCourse(payload);
        if (response?.status === 201 || response?.status === 200) {
          dispatch(ActionCreators.getCourseAction(currentPageNumber, 20));
        }
        setmodalLoading(false);
        setvisibleAddMoodModal(false);
        setUpdateModal(false);
      } else {
        setmodalLoading(false);
      }
    },
    [currentPageNumber, dispatch, editData, updateModal, submitPress]
  );

  const showDetails = useCallback(
    async (params) => {
      navigate(`/course_details`, {
        state: {
          courseId: params.id,
          currentPageNumber: currentPageNumber,
        },
      });
    },
    [navigate, currentPageNumber]
  );

  const deletePress = async (params) => {
    setdeleteName(params.title);
    setmodalLoading(true);
    const res = await CourseApi.deleteSingleCourse(params.id, false);
    if (res.status === 200) {
      setdeletePopupData(res.data);
      setdeleteId(params.id);
      setshowDeleteConfirmModal(true);
      setmodalLoading(false);
    } else {
      setmodalLoading(false);
    }
  };

  const deleteConfirm = async () => {
    setmodalLoading(true);
    // let type = params.type.toLowerCase();
    const res = await CourseApi.deleteSingleCourse(deleteId, true);
    if (res.status === 200) {
      console.log(res.data);
      setshowDeleteConfirmModal(false);
      message.success(res.data.message);
      dispatch(ActionCreators.getCourseAction(currentPageNumber, 20));
    } else {
      message.error(res.data.message);
    }
    setmodalLoading(false);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 150,
      textWrap: "word-break",
    },
    {
      title: "TITLE",
      dataIndex: "title",
      width: 200,
      textWrap: "word-break",
    },
    {
      title: "INSTRUCTOR NAME",
      dataIndex: "instructor",
      width: 200,
      textWrap: "word-break",
      render: (text, record) => <Space size="middle">{text.name}</Space>,
    },
    {
      title: "INSTRUCTOR EMAIL",
      dataIndex: "instructor",
      width: 200,
      textWrap: "word-break",
      render: (text, record) => <Space size="middle">{text.email}</Space>,
    },
    {
      title: "BACKGROUND IMAGE",
      dataIndex: "cover_image",
      align: "center",
      width: 120,
      render: (text, record) => (
        <Space size="middle">
          <img src={text} alt="N/A" style={{ height: 70, width: 90 }} />
        </Space>
      ),
    },
    {
      title: "IS PREMIUM",
      dataIndex: "is_premium",
      align: "center",
      width: 80,
      render: (text, record) =>
        text ? (
          <CheckCircleFilled style={{ color: "#22c55e" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#ef4444" }} />
        ),
    },
    {
      title: "IS PUBLISHED",
      dataIndex: "is_published",
      align: "center",
      width: 80,
      render: (text, record) =>
        text ? (
          <CheckCircleFilled style={{ color: "#22c55e" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#ef4444" }} />
        ),
    },
    {
      title: "IS FEATURE",
      dataIndex: "is_feature",
      align: "center",
      width: 80,
      render: (text, record) =>
        text ? (
          <CheckCircleFilled style={{ color: "#22c55e" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#ef4444" }} />
        ),
    },
    {
      title: "IN FEATURE",
      dataIndex: "in_feature",
      align: "center",
      width: 80,
      render: (text, record) =>
        text ? (
          <CheckCircleFilled style={{ color: "#22c55e" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#ef4444" }} />
        ),
    },
    {
      title: "ACTION",
      dataIndex: "role",
      key: "name",
      align: "center",
      width: 350,
      render: (text, record) => (
        <Space size="middle">
          <Button
            style={{ backgroundColor: "#F0F2F5", color: "#000" }}
            shape="round"
            onClick={() => showDetails(record)}
          >
            SHOW DETAILS
          </Button>
          {showCreateUpdate && (
            <Button
              type="primary"
              shape="round"
              onClick={() => editModalOpenPress(record)}
            >
              EDIT
            </Button>
          )}
          {showDelete && (
            <Button
              type="danger"
              shape="round"
              onClick={() => deletePress(record)}
            >
              Delete
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const onSearchCourse = useCallback(
    (q) => {
      // console.log(value)
      setCurrentPageNumber(1);
      let action = ActionCreators.getCourseAction(1, 20, q);
      dispatch(action);
      setCurrentPageNumber(1);
    },
    [dispatch]
  );

  return (
    <React.Fragment>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            COURSE
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL COURSE : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          {showCreateUpdate && (
            <Button
              icon={<PlusCircleOutlined />}
              size={"large"}
              type="primary"
              shape="round"
              onClick={() => AddCourse()}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              ADD COURSE
            </Button>
          )}
        </Col>
      </Row>

      <Row style={{ justifyContent: "space-between" }}>
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search instructor here..."
            enterButton
            size="large"
            onSearch={onSearchCourse}
          />
        </Col>
      </Row>

      <Table
        rowKey={(data) => data.id}
        columns={columns}
        dataSource={data.course}
        // scroll={{ x: 1 }}
        scroll={{ x: 1, y: window.innerHeight - 230 }}
        size="middle"
        loading={loader}
        style={{ height: window.innerHeight }}
        pagination={{
          total: data.count,
          pageSize: 20,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />

      <Modal
        title={`Course Name: ${deleteName}`}
        open={showDeleteConfirmModal}
        // footer={null}
        closable={false}
        width={1000}
        okText="DELETE"
        onOk={deleteConfirm}
        okButtonProps={{
          type: "danger",
          shape: "round",
        }}
        cancelButtonProps={{
          shape: "round",
        }}
        onCancel={() => setshowDeleteConfirmModal(false)}
      >
        <DeleteItemConfirmationModal
          deletePopupData={deletePopupData}
          modalLoading={modalLoading}
        />
      </Modal>

      <Modal
        title={updateModal ? "UPDATE COURSE" : "ADD COURSE"}
        open={updateModal ? updateModal : visibleAddMoodModal}
        footer={null}
        closable={false}
        width={1000}
      >
        <AddEditCourseModal
          setvisibleAddCourseModal={
            updateModal ? setUpdateModal : setvisibleAddMoodModal
          }
          onSubmitAddCourseModal={onSubmitAddContentModal}
          loader={modalLoading}
          insrtuctorOptions={insrtuctorOptions?.instructors}
          categoryOptions={categoryOptions?.categories}
          benefitsOptions={benefitsOptions?.benefits}
          data={editData}
        />
      </Modal>
    </React.Fragment>
  );
};

export default Course;
