import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import React, { memo, useEffect, useState } from "react";
import { AppointmentApi } from "../../store/api";

const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

const AddEditAppointmentTypeModal = memo(
  ({
    setvisibleAppointTypeFormModal,
    onSubmitAddAppointmentTypeModal,
    data,
    modalLoading,
    isUpdateModal,
  }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [appointmentTypes, setappointmentTypes] = useState([]);
    const [appointmentKeys, setappointmentKeys] = useState([]);

    useEffect(() => {
      // console.log(form.getFieldsValue());
      form.resetFields();
      getAppointmentTypes();
      getAppointmentKeys();
      // console.log("Usama", data);
    }, [form, data]);

    const getAppointmentTypes = async () => {
      const response = await AppointmentApi.getAppointmentTypesOptions();
      if (response?.status === 200) {
        // console.log(response?.data);
        setappointmentTypes(response?.data?.result);
      } else {
        message.error("Error");
      }
    };

    const getAppointmentKeys = async () => {
      const response = await AppointmentApi.getAppointmentTypeKeys();
      if (response?.status === 200) {
        // console.log(response?.data);
        setappointmentKeys(response?.data);
      } else {
        message.error("Error");
      }
    };

    const onSubmit = (value) => {
      if (value.errorFields) {
        message.error("Please fill up required fields");
      } else {
        form.resetFields();
        onSubmitAddAppointmentTypeModal(value);
      }
    };

    return (
      <>
        {modalLoading ? (
          <Spin
            size="large"
            style={{ display: "flex", justifyContent: "center" }}
          />
        ) : (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{
              appointment_types: data?.appointment_name ?? [],
              key_types: data?.key_types ?? [],
            }}
            onFinish={onSubmit}
            onFinishFailed={onSubmit}
            autoComplete="off"
          >
            <Form.Item label="Appointment Types" name="appointment_types">
              <Select>
                {appointmentTypes?.map((item, key) => (
                  <Option key={key} value={JSON.stringify(item)} label="USAMA">
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Appointment Keys" name="key_types">
              <Select mode={"multiple"}>
                {appointmentKeys?.map((item, key) => (
                  <Option key={key} value={item?.key}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Row gutter={5} style={{ justifyContent: "flex-end" }}>
                <Col>
                  <Button
                    type="danger"
                    onClick={() => {
                      form.resetFields();
                      setvisibleAppointTypeFormModal(false);
                    }}
                    size="large"
                    shape="round"
                  >
                    Close
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    shape="round"
                    loading={loading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}
      </>
    );
  }
);

export default AddEditAppointmentTypeModal;
