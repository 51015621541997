/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class SoloDiveOverviewApi {
  static async getAllSoloDiveOverview(payload) {
    const response = await http.get("/api/admin/over_view", payload);
    return response;
  }
  static async getSoloDiveOverviewDetails(payload) {
    const response = await http.get(`/api/admin/over_view/${payload.id}`);
    return response;
  }
  static async deleteSingleSoloDiveOverview(id) {
    const response = await http.del(`api/admin/over_view/${id}`);
    return response;
  }

  static async editSoloDiveOverview(payload, id) {
    if (!payload.priority) delete payload.priority;

    const response = await http.put(`api/admin/over_view/${id}`, payload);
    return response;
  }

  static async addSoloDiveOverview(payload) {
    if (!payload.priority) delete payload.priority;

    const response = await http.post("api/admin/over_view", payload);
    return response;
  }
}
