import { constant } from "../Constant";

// feeling
export const getFeelingsAction = (page, limit, q) => {
  return {
    type: constant.GET_FEELINGS,
    page: page,
    limit: limit,
    q: q,
  };
};
export const getFeelingDetailsAction = (id) => {
  return {
    type: constant.GET_FEELING_DETAILS,
    id: id,
  };
};

// tag
export const getTagsAction = (page, limit, q) => {
  return {
    type: constant.GET_TAGS,
    page: page,
    limit: limit,
    q: q,
  };
};
export const getTagDetailsAction = (id) => {
  return {
    type: constant.GET_TAG_DETAILS,
    id: id,
  };
};

// overview
export const getOverviewAction = (page, limit) => {
  return {
    type: constant.GET_OVERVIEW,
    page: page,
    limit: limit,
  };
};

export const getScalingsAction = (page, limit, q) => {
  return {
    type: constant.GET_SCALINGS,
    page: page,
    limit: limit,
    q: q,
  };
};
export const getScalingDetailsAction = (id) => {
  return {
    type: constant.GET_SCALING_DETAILS,
    id: id,
  };
};

export const getMoodsAction = (page, limit, q) => {
  return {
    type: constant.GET_MOODS,
    page: page,
    limit: limit,
    q: q,
  };
};
export const getMoodDetailsAction = (id) => {
  return {
    type: constant.GET_MOOD_DETAILS,
    id: id,
  };
};

export const getContentsAction = (params) => {
  // console.log(params);
  return {
    type: constant.GET_CONTENTS,
    params: {
      page: params.page,
      limit: params.limit,
      type: params.type ?? null,
      extended_type: params.extended_type ?? null,
      interval: params.interval ?? null,
      is_published: params.is_published ?? null,
      is_premium: params.is_premium ?? null,
      is_feature: params.is_feature ?? null,
      sort: params.sort ?? null,
      order: params.order ?? null,
      q: params.q ?? null,
    },
  };
};
export const getContentDetailsAction = (id, type) => {
  return {
    type: constant.GET_CONTENT_DETAILS,
    payload: {
      id,
      type,
    },
  };
};
