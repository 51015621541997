import { constant } from "../Constant";

const initialState = {
  loading: true,
  appointment: [],
  count:0,
  errMsg: "",
  details: {},
};

const AppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_APPOINTMENT:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointment: action.appointment,
        count: action.count,
        errMsg: "",
      };
    case constant.GET_APPOINTMENT_DETAILS:
      return {
        ...state,
        id: action.id,
        loading: true,
      };
    case constant.GET_APPOINTMENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: action.details,
        errMsg: "",
      };
    case constant.GET_APPOINTMENT_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    case constant.CLEAN_APPOINTMENT:
      return {
        ...state,
        loading: false,
        appointment: [],
        count: 0,
        errMsg: "",
      };
    default:
      return state;
  }
};

export default AppointmentReducer;
