import http from "./utils/NetworkClient";

export default class LogsApi {
  static async getAppointmentsLog(payload) {
    const response = await http.get(
      "/api/admin/appointment_event_logs",
      payload
    );
    return response;
  }
  static async getAppointmentsDetailsLog(id) {
    const response = await http.get(`/api/admin/appointment_event_log/${id}`);
    return response;
  }
}
