import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Col, Row, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "../../store";

const Reminder = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.ReminderReducer);

  //modal
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  useEffect(() => {
    dispatch(ActionCreators.getRemindersAction(1, 20));
  }, [dispatch]);

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    dispatch(ActionCreators.getRemindersAction(page, 20));
  };

  const columns = [
    // {
    //   title: "Serial No",
    //   key: "index",
    //   width: 100,
    //   render: (value, item, index) => <Space size="middle">{index}</Space>,
    // },
    // {
    //   title: "Id",
    //   dataIndex: "id",
    //   ellipsis: true,
    //   textWrap: "word-break",
    //   width: 130,
    // },
    {
      title: "Added By",
      dataIndex: "added_by",
      width: 130,
      render:(text)=><>{text.email}</>
    },
    {
      title: "STATUS",
      dataIndex: "status",
      width: 100,
      align: "center",
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },
    // {
    //   title: "Day Offset",
    //   dataIndex: "day_offset",
    //   width: 130,
    //   align:'center',
    // },
    {
      title: "Time Zone",
      dataIndex: "time_zone",
      width: 180,
      align:'center',
    },
    {
      title: "UTC Hour",
      dataIndex: "utc_hour",
      width: 130,
      align:'center',
    },
  ];

  return (
    <React.Fragment>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            REMINDER
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL REMINDERS : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>

      <Table
        rowKey={(data) => data.id}
        columns={columns}
        dataSource={data.reminders}
        scroll={{ x: 1,y:window.innerHeight - 230 }}
        size="middle"
        loading={data.loading}
        pagination={{
          total: data.count,
          pageSize: 20,
          current:currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
    </React.Fragment>
  );
};

export default Reminder;
