import { Button, Col, Input, Row, Space, Table, Typography } from "antd";
import Column from "antd/lib/table/Column";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogsApi } from "../../store/api";

const { Title } = Typography;
const { Search } = Input;

const AppointmentLogs = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const me = useSelector((state) => state.MeReducer?.response);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex(
        (item) => item?.type === "content"
      );
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (page, q) => {
    setLoading(true);
    const data = {
      page: page ?? currentPageNumber,
      limit: 20,
      q: q ?? searchText,
    };
    const res = await LogsApi.getAppointmentsLog(data);
    if (res.status === 200) {
      setData(res.data);
    }
    setLoading(false);
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    fetchData(page);
  };

  const searchTextHandle = (e) => {
    setSearchText(e);
    fetchData(null, e);
  };

  const setDetailsHandle = async (id) => {
    setLoading(true);
    const res = await LogsApi.getAppointmentsDetailsLog(id);
    if (res.status === 200) {
      navigate("/appointments-log-details", {
        state: { data: res.data },
      });
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "USER EMAIL",
      dataIndex: "appointment_details",
      render: (a) => a.user.email,
    },
    {
      title: "USER NAME",
      dataIndex: "appointment_details",
      render: (a) => a.user.name,
    },
    // {
    //   title: "AMOUNT",
    //   dataIndex: "appointment_details",
    //   render: (a) => a.amount_money.amount,
    // },
    // {
    //   title: "CURRENCY",
    //   dataIndex: "appointment_details",
    //   render: (a) => a.amount_money.currency,
    // },
    {
      title: "INSTRUCTOR NAME",
      dataIndex: "appointment_details",
      render: (a) => a.instructor.name,
    },
    {
      title: "INSTRUCTOR EMAIL",
      dataIndex: "appointment_details",
      render: (a) => a.instructor.email,
    },
    {
      title: "ERROR MESSAGE",
      dataIndex: "message",
    },
    {
      title: "ERROR STATUS",
      dataIndex: "event_type",
    },
    {
      title: "Action",
      dataIndex: "appointment_details",
      key: "name",
      // align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={() => setDetailsHandle(text.id)}
          >
            Details
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            APPOINTMENTS LOG
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL ERROR LOGS : <strong>{data.count}</strong>
          </p>
          <Search
            placeholder="Search by email or name"
            size="large"
            enterButton
            onSearch={(e) => searchTextHandle(e)}
            style={{ paddingTop: 10, paddingBottom: 10 }}
          />
        </Col>
      </Row>
      <Table
        dataSource={data.results}
        rowKey={(data) => data.id}
        loading={loading}
      >
        <ColumnGroup title="User">
          <Column
            title="Name"
            dataIndex="appointment_details"
            key="name"
            align="center"
            width={120}
            render={(a) => a?.user.name}
          />
          <Column
            title="Email"
            dataIndex="appointment_details"
            key="email"
            align="center"
            width={120}
            render={(a) => a?.user.email}
          />
        </ColumnGroup>

        <Column
          title="Instructor"
          dataIndex="appointment_details"
          key="name"
          align="center"
          width={150}
          render={(a) => a?.instructor.name}
        />

        <Column
          title="Location Name"
          dataIndex="appointment_details"
          key="email"
          align="center"
          width={180}
          render={(a) => a?.studio_location?.location_name}
        />

        <Column
          title="Amount"
          dataIndex="appointment_details"
          key="amount"
          align="center"
          width={120}
          render={(a) => (
            <>
              {a?.amount_money?.amount} {a?.amount_money?.currency}
            </>
          )}
        />
        <ColumnGroup title="Appointment Type">
          <Column
            title="Appointment Name"
            dataIndex="appointment_details"
            key="name"
            width={200}
            align="center"
            render={(a) => a?.appointment_type?.appointment_name}
          />
          <Column
            title="Created At"
            dataIndex="created_at"
            key="email"
            width={150}
            align="center"
            render={(a) => moment.utc(a).local().format("YYYY-MM-DD hh:mm A")}
          />
          <Column
            title="Start At"
            dataIndex="appointment_details"
            key="amount"
            width={150}
            align="center"
            render={(a) => (
              <>
                {moment.utc(a?.start_at).local().format("YYYY-MM-DD hh:mm A")}
              </>
            )}
          />
        </ColumnGroup>

        <Column
          title="Action"
          dataIndex="appointment_details"
          key="amount"
          align="center"
          width={120}
          render={(text) => (
            <Space size="middle">
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={() => setDetailsHandle(text.id)}
              >
                Details
              </Button>
            </Space>
          )}
        />
      </Table>
      {/* <Table
        rowKey={(data) => data.id}
        columns={columns}
        dataSource={data.results}
        scroll={{ x: 1, y: window.innerHeight - 280 }}
        size="middle"
        loading={loading}
        pagination={{
          total: data.count,
          pageSize: 20,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      /> */}
    </div>
  );
};

export default AppointmentLogs;
