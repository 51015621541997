export const constant = {
  USER_LOGIN: "USER_LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",

  // solo dive
  GET_SOLO_DIVES: "GET_SOLO_DIVES",
  GET_SOLO_DIVES_SUCCESS: "GET_SOLO_DIVES_SUCCESS",
  GET_SOLO_DIVES_FAILED: "GET_SOLO_DIVES_FAILED",
  CLEAN_SOLO_DIVES: "CLEAN_SOLO_DIVES",

  // solo dive overview
  GET_SOLO_DIVE_OVERVIEW: "GET_SOLO_DIVE_OVERVIEW",
  GET_SOLO_DIVE_OVERVIEW_SUCCESS: "GET_SOLO_DIVE_OVERVIEW_SUCCESS",
  GET_SOLO_DIVE_OVERVIEW_FAILED: "GET_SOLO_DIVE_OVERVIEW_FAILED",
  CLEAN_SOLO_DIVE_OVERVIEW: "CLEAN_SOLO_DIVE_OVERVIEW",

  // users detail
  GET_USER_DETAIL: "GET_USER_DETAIL",
  GET_USER_DETAIL_SUCCESS: "GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_FAILED: "GET_USER_DETAIL_FAILED",
  CLEAN_USER_DETAIL: "CLEAN_USER_DETAIL",

  // permission group
  GET_PERMISSION_GROUP: "GET_PERMISSION_GROUP",
  GET_PERMISSION_GROUP_SUCCESS: "GET_PERMISSION_GROUP_SUCCESS",
  GET_PERMISSION_GROUP_FAILED: "GET_PERMISSION_GROUP_FAILED",
  CLEAN_PERMISSION_GROUP: "CLEAN_PERMISSION_GROUP",

  // users
  GET_USERS: "GET_USERS",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILED: "GET_USERS_FAILED",
  CLEAN_USERS: "CLEAN_USERS",

  // user content
  GET_USER_CONTENT: "GET_USER_CONTENT",
  GET_USER_CONTENT_SUCCESS: "GET_USER_CONTENT_SUCCESS",
  GET_USER_CONTENT_FAILED: "GET_USER_CONTENT_FAILED",
  CLEAN_USER_CONTENT: "CLEAN_USER_CONTENT",

  // user course content
  GET_USER_COURSE_CONTENT: "GET_USER_COURSE_CONTENT",
  GET_USER_COURSE_CONTENT_SUCCESS: "GET_USER_COURSE_CONTENT_SUCCESS",
  GET_USER_COURSE_CONTENT_FAILED: "GET_USER_COURSE_CONTENT_FAILED",
  CLEAN_USER_COURSE_CONTENT: "CLEAN_USER_COURSE_CONTENT",

  // notification
  GET_NOTIFICATION: "GET_NOTIFICATION",
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAILED: "GET_NOTIFICATION_FAILED",
  CLEAN_NOTIFICATION: "CLEAN_NOTIFICATION",

  // USER_COURSE_REVIEW
  GET_USER_COURSE_REVIEW: "GET_USER_COURSE_REVIEW",
  GET_USER_COURSE_REVIEW_SUCCESS: "GET_USER_COURSE_REVIEW_SUCCESS",
  GET_USER_COURSE_REVIEW_FAILED: "GET_USER_COURSE_REVIEW_FAILED",
  CLEAN_USER_COURSE_REVIEW: "CLEAN_USER_COURSE_REVIEW",

  // favourite
  GET_FAVOURITE: "GET_FAVOURITE",
  GET_FAVOURITE_SUCCESS: "GET_FAVOURITE_SUCCESS",
  GET_FAVOURITE_FAILED: "GET_FAVOURITE_FAILED",
  CLEAN_FAVOURITE: "CLEAN_FAVOURITE",

  // overview
  GET_OVERVIEW: "GET_OVERVIEW",
  GET_OVERVIEW_SUCCESS: "GET_OVERVIEW_SUCCESS",
  GET_OVERVIEW_FAILED: "GET_OVERVIEW_FAILED",
  CLEAN_OVERVIEW: "CLEAN_OVERVIEW",

  // me
  GET_ME: "GET_ME",
  GET_ME_SUCCESS: "GET_ME_SUCCESS",
  GET_ME_FAILED: "GET_ME_FAILED",
  CLEAN_GET_ME: "CLEAN_GET_ME",

  // instructors
  GET_INSTRUCTORS: "GET_INSTRUCTORS",
  GET_INSTRUCTORS_SUCCESS: "GET_INSTRUCTORS_SUCCESS",
  GET_INSTRUCTOR_DETAILS: "GET_INSTRUCTOR_DETAILS",
  GET_INSTRUCTOR_DETAILS_SUCCESS: "GET_INSTRUCTOR_DETAILS_SUCCESS",
  GET_INSTRUCTORS_FAILED: "GET_INSTRUCTORS_FAILED",
  CLEAN_INSTRUCTOR: "CLEAN_INSTRUCTOR",

  // subscribers
  GET_SUBSCRIBERS: "GET_SUBSCRIBERS",
  GET_SUBSCRIBERS_SUCCESS: "GET_SUBSCRIBERS_SUCCESS",
  GET_SUBSCRIBERS_FAILED: "GET_SUBSCRIBERS_FAILED",
  CLEAN_SUBSCRIBER: "CLEAN_SUBSCRIBER",

  // categories
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_CATEGORY_DETAILS: "GET_CATEGORY_DETAILS",
  GET_CATEGORY_DETAILS_SUCCESS: "GET_CATEGORY_DETAILS_SUCCESS",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAILED: "GET_CATEGORIES_FAILED",
  CLEAN_CATEGORIES: "CLEAN_CATEGORIES",

  // intervals
  GET_INTERVALS: "GET_INTERVALS",
  GET_INTERVALS_SUCCESS: "GET_INTERVALS_SUCCESS",
  GET_INTERVAL_DETAILS: "GET_INTERVAL_DETAILS",
  GET_INTERVAL_DETAILS_SUCCESS: "GET_INTERVAL_DETAILS_SUCCESS",
  GET_INTERVALS_FAILED: "GET_INTERVALS_FAILED",
  CLEAN_INTERVAL: "CLEAN_INTERVAL",

  // benefit
  GET_BENEFITS: "GET_BENEFITS",
  GET_BENEFITS_SUCCESS: "GET_BENEFITS_SUCCESS",
  GET_BENEFIT_DETAILS: "GET_BENEFIT_DETAILS",
  GET_BENEFIT_DETAILS_SUCCESS: "GET_BENEFIT_DETAILS_SUCCESS",
  GET_BENEFITS_FAILED: "GET_BENEFITS_FAILED",
  CLEAN_BENEFIT: "CLEAN_BENEFIT",

  // feelings
  GET_FEELINGS: "GET_FEELINGS",
  GET_FEELINGS_SUCCESS: "GET_FEELINGS_SUCCESS",
  GET_FEELING_DETAILS: "GET_FEELING_DETAILS",
  GET_FEELING_DETAILS_SUCCESS: "GET_FEELING_DETAILS_SUCCESS",
  GET_FEELINGS_FAILED: "GET_FEELINGS_FAILED",
  CLEAN_FEELING: "CLEAN_FEELING",

  // tags
  GET_TAGS: "GET_TAGS",
  GET_TAGS_SUCCESS: "GET_TAGS_SUCCESS",
  GET_TAG_DETAILS: "GET_TAG_DETAILS",
  GET_TAG_DETAILS_SUCCESS: "GET_TAG_DETAILS_SUCCESS",
  GET_TAGS_FAILED: "GET_TAGS_FAILED",
  CLEAN_TAG: "CLEAN_TAG",

  // scalings
  GET_SCALINGS: "GET_SCALINGS",
  GET_SCALINGS_SUCCESS: "GET_SCALINGS_SUCCESS",
  GET_SCALING_DETAILS: "GET_SCALING_DETAILS",
  GET_SCALING_DETAILS_SUCCESS: "GET_SCALING_DETAILS_SUCCESS",
  GET_SCALINGS_FAILED: "GET_SCALINGS_FAILED",
  CLEAN_SCALING: "CLEAN_SCALING",

  // moods
  GET_MOODS: "GET_MOODS",
  GET_MOODS_SUCCESS: "GET_MOODS_SUCCESS",
  GET_MOOD_DETAILS: "GET_MOOD_DETAILS",
  GET_MOOD_DETAILS_SUCCESS: "GET_MOOD_DETAILS_SUCCESS",
  GET_MOODS_FAILED: "GET_MOODS_FAILED",
  CLEAN_MOOD: "CLEAN_MOOD",

  // contents
  GET_CONTENTS: "GET_CONTENTS",
  GET_CONTENTS_SUCCESS: "GET_CONTENTS_SUCCESS",
  GET_CONTENT_DETAILS: "GET_CONTENT_DETAILS",
  GET_CONTENT_DETAILS_SUCCESS: "GET_CONTENT_DETAILS_SUCCESS",
  GET_CONTENTS_FAILED: "GET_CONTENTS_FAILED",
  CLEAN_CONTENT: "CLEAN_CONTENT",

  // daily dive
  GET_DAILYDIVES: "GET_DAILYDIVES",
  GET_DAILYDIVES_SUCCESS: "GET_DAILYDIVES_SUCCESS",
  GET_DAILYDIVE_DETAILS: "GET_DAILYDIVE_DETAILS",
  GET_DAILYDIVE_DETAILS_SUCCESS: "GET_DAILYDIVE_DETAILS_SUCCESS",
  GET_DAILYDIVES_FAILED: "GET_DAILYDIVES_FAILED",
  CLEAN_DAILYDIVE: "CLEAN_DAILYDIVE",

  // reminders
  GET_REMINDERS: "GET_REMINDERS",
  GET_REMINDERS_SUCCESS: "GET_REMINDERS_SUCCESS",
  GET_REMINDER_DETAILS: "GET_REMINDER_DETAILS",
  GET_REMINDER_DETAILS_SUCCESS: "GET_REMINDER_DETAILS_SUCCESS",
  GET_REMINDERS_FAILED: "GET_REMINDERS_FAILED",
  CLEAN_REMINDER: "CLEAN_REMINDER",

  //course
  GET_COURSE: "GET_COURSE",
  GET_COURSE_SUCCESS: "GET_COURSE_SUCCESS",
  GET_COURSE_FAILED: "GET_COURSE_FAILED",

  //questionaries
  GET_IDENTIFIERS: "GET_IDENTIFIERS",
  GET_IDENTIFIERS_SUCCESS: "GET_IDENTIFIERS_SUCCESS",
  GET_IDENTIFIERS_FAILED: "GET_IDENTIFIERS_FAILED",

  //options
  GET_OPTIONS: "GET_OPTIONS",
  GET_OPTIONS_SUCCESS: "GET_OPTIONS_SUCCESS",
  GET_OPTIONS_FAILED: "GET_OPTIONS_FAILED",

  //location
  GET_LOCATION: "GET_LOCATION",
  GET_LOCATION_SUCCESS: "GET_LOCATION_SUCCESS",
  GET_LOCATION_FAILED: "GET_LOCATION_FAILED",

  //form group
  GET_FORM_GROUP: "GET_FORM_GROUP",
  GET_FORM_GROUP_SUCCESS: "GET_FORM_GROUP_SUCCESS",
  GET_FORM_GROUP_FAILED: "GET_FORM_GROUP_FAILED",

  //form group
  GET_FORM: "GET_FORM",
  GET_FORM_SUCCESS: "GET_FORM_SUCCESS",
  GET_FORM_FAILED: "GET_FORM_FAILED",

  FORM_DETAILS: "FORM_DETAILS",

  // appointment
  GET_APPOINTMENT: "GET_APPOINTMENT",
  GET_APPOINTMENT_SUCCESS: "GET_APPOINTMENT_SUCCESS",
  GET_APPOINTMENT_DETAILS: "GET_APPOINTMENT_DETAILS",
  GET_APPOINTMENT_DETAILS_SUCCESS: "GET_APPOINTMENT_DETAILS_SUCCESS",
  GET_APPOINTMENT_FAILED: "GET_APPOINTMENT_FAILED",
  CLEAN_APPOINTMENT: "CLEAN_APPOINTMENT",

  // appointment type
  GET_APPOINTMENT_TYPES: "GET_APPOINTMENT_TYPES",
  GET_APPOINTMENT_TYPES_SUCCESS: "GET_APPOINTMENT_TYPES_SUCCESS",
  GET_APPOINTMENT_TYPES_FAILED: "GET_APPOINTMENT_TYPES_FAILED",
  CLEAN_APPOINTMENT_TYPES: "CLEAN_APPOINTMENT_TYPES",

  //instructor info
  GET_INSTRUCTOR_INFO: "GET_INSTRUCTOR_INFO",
  GET_INSTRUCTOR_INFO_SUCCESS: "GET_INSTRUCTOR_INFO_SUCCESS",
  GET_INSTRUCTOR_INFO_FAILED: "GET_INSTRUCTOR_INFO_FAILED",
};
