import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  message,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import AddEditCategoryModal from "../../components/modals/AddEditCategoryModal";
import { ActionCreators } from "../../store";
import { CategoryApi } from "../../store/api";

const Category = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const { getCategoriesAction } = bindActionCreators(ActionCreators, dispatch); //call action
  const data = useSelector((state) => state.CategoryReducer);
  const me = useSelector((state) => state.MeReducer?.response);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  //modal
  const [visibleAddCategoryModal, setvisibleAddCategoryModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [modalLoading, setmodalLoading] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex(
        (item) => item?.type === "content_configuration"
      );
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  useEffect(() => {
    dispatch(ActionCreators.getCategoriesAction(1, 20));
  }, [dispatch]);

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    dispatch(ActionCreators.getCategoriesAction(page, 20));
  };

  const addCategoryButtonClick = useCallback(() => {
    setvisibleAddCategoryModal(true);
    setUpdateModal(false);
    setEditData({});
  }, []);

  //edit button click
  const editButtonClick = useCallback((param) => {
    setEditData(param);
    setUpdateModal(true);
  }, []);

  //Add Category
  const onSubmitAddCategoryModal = useCallback(
    async (res) => {
      if (!res.errorFields) {
        setmodalLoading(true);
        const values = {
          name: res.name,
          bg_path: res.bg_path ? res.bg_path : null,
          icon_path: res.icon_path,
          priority: res.priority ? res.priority : null,
        };
        const response = updateModal
          ? await CategoryApi.editCategory(values, editData.id)
          : await CategoryApi.addCategory(values);
        if (response.status === 201 || response.status === 200) {
          getCategoriesAction(currentPageNumber, 20);
          setvisibleAddCategoryModal(false);
          setUpdateModal(false);
          message.success(
            `Category successfully ${updateModal ? "Updated" : "Added"}`
          );
          setmodalLoading(false);
        } else {
          message.error(response?.data?.detail);
          setmodalLoading(false);
        }
      }
    },
    [getCategoriesAction, editData, updateModal, currentPageNumber]
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      ellipsis: true,
      textWrap: "word-break",
      // width: 150,
    },
    {
      title: "Name",
      dataIndex: "name",
      // width: 200,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      align: "center",
      // width: 150,
      render: (text) => (
        <>{text !== null && text !== "9999999999" ? <>{text}</> : "N/A"}</>
      ),
    },
    {
      title: "Action",
      dataIndex: "role",
      align: "center",
      // width: 200,
      key: "id",
      render: (text, record) => (
        <Space size="middle">
          {showCreateUpdate && (
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={() => editButtonClick(record)}
            >
              Edit
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            CATEGORY
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL CATEGORIES : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          {showCreateUpdate && (
            <Button
              icon={<PlusCircleOutlined />}
              size={"large"}
              shape="round"
              type="primary"
              onClick={() => addCategoryButtonClick()}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              ADD CATEGORY
            </Button>
          )}
        </Col>
      </Row>
      <Table
        rowKey={(data) => data.id}
        columns={columns}
        dataSource={data.categories}
        loading={data.loading}
        size="middle"
        scroll={{ x: 1, y: window.innerHeight - 280 }}
        pagination={{
          total: data.count,
          pageSize: 20,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
      <Modal
        title={updateModal ? "UPDATE Category" : "ADD CATEGORY"}
        open={updateModal ? updateModal : visibleAddCategoryModal}
        footer={null}
        closable={false}
      >
        <AddEditCategoryModal
          setvisibleAddCategoryModal={
            updateModal ? setUpdateModal : setvisibleAddCategoryModal
          }
          onSubmitAddCategoryModal={onSubmitAddCategoryModal}
          data={editData}
          modalLoading={modalLoading}
        />
      </Modal>
    </React.Fragment>
  );
};

export default Category;
