import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { FormHelperText } from "@material-ui/core";
import {
  Button,
  Calendar,
  Card,
  Col,
  Form,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Typography
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddEditUserModal from "../../components/modals/AddEditUserModal";
import AddOrEditAddPatientModal from "../../components/modals/AddOrEditAddPatientModal";
import { ActionCreators } from "../../store";
import { AppointmentApi, UserApi } from "../../store/api";
import { SQUARE_APP_ID, SQUARE_LOCATION_ID } from "../../store/api/Config";
import "./customStyle.css";
import styles from "./style.module.scss";

export default function CreateAppointment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const cardRef = useRef();

  const appointmentTypeList = useSelector(
    (state) => state.AppointmentTypesReducer?.response
  );

  console.log("appointmentTypeList", appointmentTypeList);

  const formLocation = Form.useWatch("studio_location", form);
  const formAppointmentType = Form.useWatch("appointment_type", form);
  const formInstructor = Form.useWatch("instructor", form);
  const formSelectedDate = Form.useWatch("selected_date", form);
  const formSelectedTime = Form.useWatch("start_at", form);
  const formSelectedUser = Form.useWatch("selected_user", form);

  console.log("=-=-=-=-=-=-=-=-", formAppointmentType);

  const [loading, setloading] = useState(false);
  const [formItemLoading, setformItemLoading] = useState(false);
  const [calendarLoading, setcalendarLoading] = useState(false);

  const [bookingData, setbookingData] = useState({
    instructor: null,
    appointment_type_id: null,
    studio_location: null,
    start_at: null,
    other_patients: [],
    payment_method: null,
    contact_details: null,
  });
  const [studioLocationList, setstudioLocationList] = useState([]);
  const [instructorList, setinstructorList] = useState([]);
  const [instructorAvailablibilityData, setinstructorAvailablibilityData] =
    useState(null);
  const [userList, setuserList] = useState([]);
  const [searchedLocation, setsearchedLocation] = useState("");
  const [searchedAppointmentType, setsearchedAppointmentType] = useState("");
  const [searchedInstructor, setsearchedInstructor] = useState("");
  const [searchedUser, setsearchedUser] = useState("");
  const [searchedOtherClient, setsearchedOtherClient] = useState("");

  const [selectedDate, setselectedDate] = useState(moment.utc().format());
  const [selectedMonth, setselectedMonth] = useState(moment.utc().format());
  const [availableTimes, setavailableTimes] = useState([]);
  const [selectedTime, setselectedTime] = useState();
  const [selectedUser, setselectedUser] = useState(null);

  const [visibleAddUserModal, setvisibleAddUserModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [modalLoading, setmodalLoading] = useState(false);
  const [paitientList, setpaitientList] = useState([]);

  const [visibleAddNewClientModal, setvisibleAddNewClientModal] =
    useState(false);

  const [userDetail, setuserDetail] = useState(null);

  const [card, setcard] = useState();
  const [cardToken, setcardToken] = useState();
  const [cardId, setcardId] = useState();
  // const [isNewCardAdded, setisNewCardAdded] = useState(false);
  const [isIndividual, setisIndividual] = useState(false);
  const [showCardEntryForm, setshowCardEntryForm] = useState(false);
  const [newlyAddedCardInfo, setnewlyAddedCardInfo] = useState();

  useEffect(() => {
    form.resetFields();
    window.document.getElementById("card").innerHTML = "<div></div>";
    setshowCardEntryForm(false);
    setnewlyAddedCardInfo(null);
    // getAppointmentType();
  }, []);
  useEffect(() => {
    if (showCardEntryForm) {
      initializeCard();
      form.setFieldValue("selected_payment_method", null);
      setnewlyAddedCardInfo(null);
    } else {
      window.document.getElementById("card").innerHTML = "<div></div>";
    }
  }, [showCardEntryForm]);

  useEffect(() => {
    if (!formInstructor) {
      setavailableTimes([]);
      setuserList([]);
      setuserDetail(null);
      setselectedTime(null);
      setselectedUser(null);
      setshowCardEntryForm(false);
      window.document.getElementById("card").innerHTML = "<div></div>";
    }
  }, [formInstructor]);

  useEffect(() => {
    let { studio_location } = form.getFieldValue();
    form.resetFields();
    form.setFieldValue("studio_location", studio_location);
    setselectedDate(moment.utc().format());
    setselectedMonth(moment.utc().format());
    setsearchedAppointmentType("");
    setsearchedInstructor("");
    setsearchedOtherClient("");
    setsearchedUser("");
    setshowCardEntryForm(false);
    setinstructorList([]);
    if (formLocation) {
      getAppointmentType();
    }
  }, [formLocation]);

  useEffect(() => {
    let { studio_location, appointment_type } = form.getFieldValue();
    form.resetFields();
    form.setFieldValue("studio_location", studio_location);
    form.setFieldValue("appointment_type", appointment_type);
    setselectedDate(moment.utc().format());
    setselectedMonth(moment.utc().format());

    let data = appointmentTypeList?.find(
      (item) => item?.id === appointment_type
    );
    // let isIndi = data?.key_types?.includes("INDIVIDUAL");
    // if (isIndi) {
    //   setisIndividual(isIndi);
    // }

    let index = data?.key_types?.findIndex((item) => item === "INDIVIDUAL");

    if (index > -1) {
      setisIndividual(true);
    } else {
      setisIndividual(false);
    }

    if (studio_location && appointment_type) {
      getInstructor();
    }
  }, [formAppointmentType]);

  useEffect(() => {
    if (formInstructor) {
      setselectedTime(null);
      form.setFieldValue("start_at", null);
      getInstructorAvailablity();
    }
  }, [formInstructor, selectedMonth]);

  useEffect(() => {
    if (formSelectedUser) {
      form.setFieldValue("selected_other_clients", []);
      form.setFieldValue("selected_payment_method", null);
      setnewlyAddedCardInfo(null);
      setpaitientList([]);
      getUserDetail();
      setshowCardEntryForm(false);
    }
  }, [formSelectedUser]);

  useEffect(() => {
    if (instructorAvailablibilityData?.available_times?.length > 0) {
      setselectedDate(
        moment.utc(instructorAvailablibilityData?.available_times?.[0]).format()
      );
    }
  }, [instructorAvailablibilityData]);

  useEffect(() => {
    setselectedTime(null);
    form.setFieldValue("start_at", null);
    if (formInstructor) {
      let array = instructorAvailablibilityData?.available_times?.filter(
        (item) => {
          if (
            moment.utc(selectedDate).local().format("YYYY-MM-DD") ===
            moment.utc(item).local().format("YYYY-MM-DD")
          ) {
            return item;
          }
        }
      );
      setavailableTimes(array);
    }
  }, [selectedDate]);

  //on search useeffect starts
  useEffect(() => {
    const getData = setTimeout(() => {
      getLocation();
    }, 2000);
    return () => {
      clearTimeout(getData);
      setstudioLocationList([]);
    };
  }, [searchedLocation]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (formLocation) {
        getAppointmentType();
      }
    }, 2000);
    return () => {
      clearTimeout(getData);
    };
  }, [searchedAppointmentType]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (formAppointmentType && formLocation) {
        getInstructor();
      }
    }, 2000);
    return () => {
      clearTimeout(getData);
    };
  }, [searchedInstructor]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (formSelectedTime) {
        getUsers();
      }
    }, 2000);
    return () => {
      clearTimeout(getData);
    };
  }, [searchedUser, formSelectedTime]);

  // useEffect(() => {
  //  if(editData?.id){
  //   setUpdateModal(true)
  //  }else if(!updateModal){
  //   setEditData({})

  //  }
  // }, [editData,updateModal])

  //on search useeffect ends

  const onPressSubmit = async () => {
    // console.log("Form2", form.getFieldsValue());
    let {
      instructor,
      appointment_type,
      selected_other_clients,
      selected_payment_method,
      selected_user,
      start_at,
      studio_location,
    } = form.getFieldsValue();

    console.log("usama", userDetail);

    if (!JSON.parse(selected_user)?.name) {
      setEditData(userDetail);
      setUpdateModal(true);
      message.error("This user doesn't have full name");

      return;
    }
    if (!userDetail?.user_profile?.phone_number) {
      setEditData(userDetail);
      setUpdateModal(true);

      message.error("This user doesn't have phone number");
      return;
    }
    if (!JSON.parse(selected_user)?.email) {
      setEditData(userDetail);
      setUpdateModal(true);
      message.error("This user doesn't have email address");
      return;
    }
    if (!userDetail?.user_profile?.gender) {
      setEditData(userDetail);
      setUpdateModal(true);
      message.error("This user doesn't select  gender");
      return;
    }

    if (!userDetail?.user_profile?.date_of_birth) {
      setEditData(userDetail);
      setUpdateModal(true);
      message.error("This user doesn't select  date of birth");
      return;
    }

    setloading(true);

    let payload = {
      instructor: instructor,
      appointment_type_id: appointment_type,
      studio_location: studio_location,
      start_at: moment.utc(start_at).format(),
      user_id: userDetail?.user_profile?.user,
      contact_details: {
        email: JSON.parse(selected_user)?.email,
        full_name: JSON.parse(selected_user)?.name,
        date_of_birth: userDetail?.user_profile?.date_of_birth ?? null,
        phone_number: userDetail?.user_profile?.phone_number,
        gender: userDetail?.user_profile?.gender,
        emergency_contact_name:
          userDetail?.user_profile?.emergency_contact_name,
        emergency_contact_number:
          userDetail?.user_profile?.emergency_contact_number,
        emergency_contact_relation:
          userDetail?.user_profile?.emergency_contact_relation,
      },
      // payment_method: {
      //   card_id: "id",
      // },
      other_patients: selected_other_clients,
    };

    if (selected_payment_method) {
      payload["payment_method"] = {
        card_id: selected_payment_method,
      };
    } else if (cardToken) {
      payload["payment_method"] = {
        card_token: cardToken,
      };
    } else {
      payload["payment_method"] = null;
    }

    const response = await AppointmentApi.createAppointment(payload);

    if (response?.status === 201 || response?.status === 200) {
      message.success("Successfully appointment created");
      navigate("/appointment-detail/" + response?.data?.id);
      setloading(false);
    } else {
      message.error(response?.data?.detail);
      setloading(false);
    }
  };

  // api call starts
  const getLocation = async () => {
    setformItemLoading(true);
    const response = await AppointmentApi.getStudioLocations(searchedLocation);
    if (response?.status === 200) {
      setstudioLocationList(response?.data?.results);
      setformItemLoading(false);
    } else {
      setformItemLoading(false);
    }
  };
  const getAppointmentType = () => {
    dispatch(
      ActionCreators.getAppointmentTypesAction(
        1,
        20,
        searchedAppointmentType,
        formLocation
      )
    );
  };
  const getInstructor = async () => {
    setformItemLoading(true);
    const response = await AppointmentApi.getInstructorList(
      formLocation,
      formAppointmentType,
      searchedInstructor
    );
    if (response?.status === 200) {
      setinstructorList(response?.data?.results);
      setformItemLoading(false);
    } else {
      setformItemLoading(false);
    }
  };
  const getInstructorAvailablity = async () => {
    setcalendarLoading(true);
    const response = await AppointmentApi.getInstructorAvailablity(
      formInstructor,
      formLocation,
      formAppointmentType,
      selectedMonth
    );
    if (response?.status === 200) {
      setinstructorAvailablibilityData(response?.data);
      setcalendarLoading(false);
    } else {
      setcalendarLoading(false);
    }
  };
  const getUsers = async () => {
    setformItemLoading(true);
    const response = await AppointmentApi.getUserList(searchedUser);
    if (response?.status === 200) {
      setuserList(response?.data?.results);
      setformItemLoading(false);
    } else {
      setformItemLoading(false);
    }
  };
  const getUserDetail = async () => {
    const response = await UserApi.getUserDetail(
      JSON.parse(formSelectedUser)?.id
    );
    if (response?.status === 200) {
      setuserDetail(response?.data);
      setpaitientList(response?.data?.relatives);
      if (response?.data?.cards?.length > 0) {
        // setisNewCardAdded(false);

        window.document.getElementById("card").innerHTML = "<div></div>";
        setformItemLoading(false);
      } else {
        // setisNewCardAdded(true);
        setformItemLoading(false);
      }
    }
  };

  const onSubmitAddUserModal = useCallback(
    async (res) => {
      setmodalLoading(true);
      console.log(res);
      try {
        if (!res.errorFields) {
          const values = {
            name: res.name,
            email: res.email,
            first_name: res.first_name,
            last_name: res.last_name,
            membership: res.membership,
            role: res.role,
            permissions: [],
            bio: res.bio,
            photo_link: res.photo_link,
            is_active: res.is_active,
            user_profile: {
              date_of_birth: res?.date_of_birth
                ? moment(res?.date_of_birth).format("YYYY-MM-DD")
                : null,
              phone_number: res?.phone_number,
              pronoun: res?.pronoun,
              gender: res?.gender,
              emergency_contact_name: res?.emergency_contact_name,
              emergency_contact_number: res?.emergency_contact_number,
              emergency_contact_relation: res?.emergency_contact_relation,
            },
          };

          if (!updateModal) {
            values.password = res.password;
            if (values.first_name === "") {
              delete values.first_name;
            }
            if (values.last_name === "") {
              delete values.last_name;
            }
            if (values.membership === "") {
              delete values.membership;
            }
            if (values.role === "") {
              delete values.role;
            }

            if (values.bio === "") {
              delete values.bio;
            }
          }

          const response = updateModal
            ? await UserApi.editUser(values, editData.id)
            : await UserApi.addUser(values);

          // console.log("USAMA", response);
          if (response.status === 201 || response.status === 200) {
            message.success(
              `User Successfully ${updateModal ? "Updated" : "Created"}`
            );
            // getUsersAction(currentPageNumber, 20);
            if (updateModal) {
              getUserDetail();
            }
            setvisibleAddUserModal(false);
            setUpdateModal(false);
          } else {
            setmodalLoading(false);
            message.error(response?.data?.detail);
          }
          setmodalLoading(false);
        } else {
          setmodalLoading(false);
        }
      } catch (error) {
        message.error("Bad Request");
        setmodalLoading(false);
      }
    },
    [editData, updateModal]
  );

  // api call ends

  const initializeCard = async () => {
    const payments = await window?.Square?.payments(
      SQUARE_APP_ID,
      SQUARE_LOCATION_ID
    );
    // if (card == undefined) {
    const card = await payments?.card();
    await card?.attach("#card");
    setcard(card);
    // }
  };

  const addUserButtonClick = useCallback(() => {
    setvisibleAddUserModal(true);
    setUpdateModal(false);
    setEditData({});
  }, []);

  return (
    <Row
      style={{
        justifyContent: "center",
        height: "100%",
        display: "flex",
        flex: 1,
      }}
    >
      <Col xxl={8} lg={12} sm={20} style={{ flexDirection: "column" }}>
        <Typography.Title>Create A New Appointment</Typography.Title>

        <Card style={{ borderRadius: 15 }}>
          <Form
            layout={"vertical"}
            form={form}
            initialValues={{}}
            onFinish={() => onPressSubmit()}
          >
            <Form.Item
              label="Select Location"
              name="studio_location"
              style={{ textAlign: "left" }}
            >
              <Select
                showSearch
                placeholder="Select Location"
                style={{ textAlign: "left" }}
                onSearch={setsearchedLocation}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                notFoundContent={
                  formItemLoading ? (
                    <Spin
                      size="small"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  ) : null
                }
              >
                {studioLocationList?.map((item) => {
                  if (item?.is_published) {
                    return (
                      <Select.Option key={item?.id} value={item?.id}>
                        {item?.location_name}
                      </Select.Option>
                    );
                  }
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Select Appointment Type" name="appointment_type">
              <Select
                showSearch
                style={{ textAlign: "left" }}
                placeholder="Select Appointment Type"
                onSearch={setsearchedAppointmentType}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                notFoundContent={
                  formItemLoading ? (
                    <Spin
                      size="small"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  ) : null
                }
              >
                {appointmentTypeList?.map((item, key) => (
                  <Select.Option key={key} value={item?.id}>
                    {item?.appointment_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Select Instructor" name="instructor">
              <Select
                showSearch
                placeholder="Select Instructor"
                style={{ textAlign: "left" }}
                onSearch={setsearchedInstructor}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                notFoundContent={
                  formItemLoading ? (
                    <Spin
                      size="small"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  ) : null
                }
              >
                {instructorList?.map((item, key) => (
                  <Select.Option key={key} value={item?.id}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {calendarLoading ? (
              <Spin
                size="small"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            ) : (
              <Calendar
                fullscreen={false}
                disabledDate={(date) => {
                  if (formAppointmentType && formInstructor && formLocation) {
                    if (
                      instructorAvailablibilityData?.available_times?.find(
                        (item) =>
                          moment(item).format("YYYY-MM-DD") ===
                          moment(date).format("YYYY-MM-DD")
                      )
                    ) {
                      return false;
                    }
                    return true;
                  } else {
                    return true;
                  }
                }}
                headerRender={({ value, type, onChange, onTypeChange }) => {
                  const current = value.clone();
                  const localeData = value.localeData();

                  const months = [];
                  for (let i = 0; i < 12; i++) {
                    current.month(i);
                    months.push(localeData.monthsShort(current));
                  }

                  return (
                    <div
                      style={{
                        padding: 3,
                      }}
                    >
                      <Typography.Title
                        level={4}
                        style={{ textAlign: "left", marginBottom: 10 }}
                      >
                        Select a Date
                      </Typography.Title>
                      <Card
                        bodyStyle={{
                          padding: 10,
                          backgroundColor: "#F5F5F5",
                        }}
                      >
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Col>
                            <LeftOutlined
                              className={styles.rightIcon}
                              onClick={() => {
                                onChange(
                                  moment(value?.clone()).add(-1, "month")
                                );
                              }}
                            />
                          </Col>
                          <Col>
                            <p className={styles.selectedMonthText}>
                              {moment(value?.clone()).format("MMMM")} -{" "}
                              {moment(value?.clone()).format("YYYY")}
                            </p>
                          </Col>
                          <Col>
                            <RightOutlined
                              className={styles.rightIcon}
                              onClick={() => {
                                onChange(
                                  moment(value?.clone()).add(1, "month")
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  );
                }}
                onPanelChange={(value, mode) => {
                  setselectedMonth(moment.utc(value).format());
                }}
                onSelect={(data) => {
                  setselectedDate(moment.utc(data).format());
                }}
                value={moment(selectedDate)}
              />
            )}

            <Form.Item label="Select Time" name="start_at">
              <Select
                style={{ textAlign: "left" }}
                placeholder="Select Time"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                notFoundContent={
                  formItemLoading ? (
                    <Spin
                      size="small"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  ) : null
                }
              >
                {availableTimes?.map((time) => (
                  <Select.Option key={time} value={time}>
                    {moment.utc(time).local().format("hh:mm A")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Select User"
              name="selected_user"
              style={{ marginBottom: 0 }}
            >
              <Select
                showSearch
                placeholder="Select User"
                style={{ width: "100%", marginBottom: 10, textAlign: "left" }}
                onSearch={setsearchedUser}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                notFoundContent={
                  formItemLoading ? (
                    <Spin
                      size="small"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  ) : null
                }
              >
                {userList?.map((item) => {
                  return (
                    <Select.Option key={item?.id} value={JSON.stringify(item)}>
                      {item?.email}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            {formSelectedTime && (
              <Button type="primary" block onClick={() => addUserButtonClick()}>
                Add New User
              </Button>
            )}
            {!isIndividual && (
              <>
                <Form.Item
                  label="Select Other Clients"
                  name="selected_other_clients"
                  style={{ marginBottom: 0 }}
                >
                  <Select
                    mode="multiple"
                    showSearch={false}
                    placeholder="Select Another Client"
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      textAlign: "left",
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent={
                      formItemLoading ? (
                        <Spin
                          size="small"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        />
                      ) : null
                    }
                  >
                    {paitientList?.map((item) => {
                      // if (item?.full_name) {
                        return (
                          <Select.Option key={item?.id} value={item?.id}>
                            {item?.first_name} {item?.last_name}
                          </Select.Option>
                        );
                      // }
                    })}
                  </Select>
                </Form.Item>
                {formSelectedTime && (
                  <Button
                    type="primary"
                    block
                    onClick={() => setvisibleAddNewClientModal(true)}
                  >
                    Create New Client
                  </Button>
                )}
              </>
            )}
            <Form.Item
              label="Select Payment"
              name="selected_payment_method"
              style={{}}
            >
              {!showCardEntryForm && (
                <Select
                  placeholder="Select Card"
                  showSearch={false}
                  style={{ width: "100%", marginBottom: 10, textAlign: "left" }}
                  // onSearch={setsearchedUser}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {userDetail?.cards?.map((item) => {
                    return (
                      <Select.Option key={item?.id} value={item?.id}>
                        ({item?.card_brand}) ****{item?.card_number} -{" "}
                        {item?.expire_month}/{item?.expire_year}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>

            <div id="card" ref={cardRef}></div>

            {showCardEntryForm && formSelectedUser && (
              <>
                <Button
                  type="primary"
                  style={{ marginBottom: 10 }}
                  onClick={async () => {
                    const result = await card?.tokenize();
                    if (result?.status == "Invalid") {
                      result?.errors?.map((er) => message.error(er?.message));
                    } else {
                      setcardToken(result?.token);
                      console.log(card);
                      setnewlyAddedCardInfo(result?.details?.card);
                      message.success("Successfully card added");
                      setshowCardEntryForm(false);
                    }
                  }}
                  block
                >
                  Add Card
                </Button>
                <FormHelperText
                  style={{ color: "Highlight", textAlign: "center" }}
                >
                  Press 'Add Card' button in order to add this card with this
                  patient before confirming appointment.
                </FormHelperText>

                <Button
                  type="primary"
                  style={{ marginBottom: 10 }}
                  onClick={() => {
                    setshowCardEntryForm(false);
                  }}
                  block
                >
                  Cancel New Card
                </Button>
              </>
            )}

            {!showCardEntryForm && formSelectedUser && (
              <>
                {newlyAddedCardInfo?.last4 && (
                  <Card
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography.Title level={5}>
                        Newly Added Card Info -{" "}
                      </Typography.Title>
                      <Typography.Text>
                        Last Four Digits: {newlyAddedCardInfo?.last4}
                      </Typography.Text>
                      <Typography.Text>
                        Expire Date: {newlyAddedCardInfo?.expMonth}/
                        {newlyAddedCardInfo?.expYear}{" "}
                      </Typography.Text>
                      <Typography.Text>
                        Card Brand: {newlyAddedCardInfo?.brand}
                      </Typography.Text>
                    </div>
                  </Card>
                )}
                <Button
                  type="primary"
                  style={{ marginBottom: 10 }}
                  onClick={() => {
                    setshowCardEntryForm(true);
                  }}
                  block
                >
                  Create A New Card
                </Button>
              </>
            )}

            {/* {isNewCardAdded && (
              <Button
                type="primary"
                block
                style={{ marginBottom: 10 }}
                onClick={async () => {
                  const result = await card?.tokenize();
                  if (result?.status == "Invalid") {
                    result?.errors?.map((er) => message.error(er?.message));
                  } else {
                    setcardToken(result?.token);
                    message.success("Successfully card added");
                    setisNewCardAdded(false);
                  }
                }}
              >
                Add Card
              </Button>
            )}*/}
            {/* 
            {!isNewCardAdded && formSelectedUser && (
              <Button
                type="primary"
                style={{ marginBottom: 10 }}
                onClick={() => {
                  // setisNewCardAdded(true);
                }}
                block
              >
                Create A New Card
              </Button>
            )} */}

            <Form.Item>
              <Button
                disabled={!formSelectedUser}
                type="primary"
                htmlType="submit"
                loading={loading}
                block
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>

        <Modal
          title={updateModal ? "UPDATE USER" : "ADD USER"}
          open={updateModal ? updateModal : visibleAddUserModal}
          footer={null}
          closable={false}
        >
          <AddEditUserModal
            setvisibleAddUserModal={
              updateModal ? setUpdateModal : setvisibleAddUserModal
            }
            onSubmitAddUserModal={onSubmitAddUserModal}
            data={editData}
            modalLoading={modalLoading}
            isUpdateModal={updateModal}
          />
        </Modal>

        <Modal
          open={visibleAddNewClientModal}
          // className={styles.modal}
          bodyStyle={{
            padding: 0,
            paddingLeft: 35,
            paddingTop: 25,
            paddingRight: 35,
          }}
          centered={true}
          closable={true}
          width={700}
          onCancel={() => {
            setvisibleAddNewClientModal(false);
          }}
          title={"ADD ANOTHER PATIENT"}
          footer={<></>}
        >
          <AddOrEditAddPatientModal
            owner={userDetail?.user_profile?.user}
            setvisibleModal={setvisibleAddNewClientModal}
            paitientList={paitientList}
            setpaitientList={setpaitientList}
            appointmentType={
              appointmentTypeList.length > 0 && formAppointmentType
                ? appointmentTypeList.filter(
                    (res) => res.id === formAppointmentType
                  )
                : null
            }
            getUserDetail={getUserDetail}
          />
        </Modal>
      </Col>
    </Row>
  );
}
