/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class CategoryApi {
  static async getAllCategory(payload) {
    const response = await http.get("/api/admin/category", payload);
    return response;
  }
  static async getCategoryDetails(payload) {
    const response = await http.get(`/api/admin/category/${payload.id}`);
    return response;
  }
  static async deleteSingleCategory(id) {
    const response = await http.del(`/api/admin/category/${id}`);
    return response;
  }

  static async editCategory(payload, id) {
    const response = await http.put(`/api/admin/category/${id}`, payload);
    return response;
  }

  static async addCategory(payload) {
    const response = await http.post("/api/admin/category", payload);
    return response;
  }
}
