import { Space, Table, Typography } from "antd";
import moment from "moment";
import React from "react";
const { Title } = Typography;

export default function DailyDiveDetailModal({ data }) {
  const columns = [
    {
      title: "SCALE",
      dataIndex: "user_scaling",
      render: (text, record) => (
        <>{record.user_scaling?.scaling?.name ?? "N/A"}</>
      ),
    },
    {
      title: "SOLO DIVE",
      dataIndex: "solo_dive",
      render: (text) => <>{text?.title ?? "N/A"}</>,
    },
    {
      title: "JOURNAL",
      dataIndex: "user_journal",
      render: (text) => <>{text?.content?.title ?? "N/A"}</>,
    },
    {
      title: "AUDIO",
      dataIndex: "user_audio",
      render: (text) => <>{text?.content?.title ?? "N/A"}</>,
    },
    {
      title: "MOOD",
      dataIndex: "user_mood",
      render: (text) => <>{text?.mood?.name ?? "N/A"}</>,
    },

    {
      title: "CREATED DATE & TIME",
      dataIndex: "added_by",
      render: (text, record) => (
        <Space size="middle">
          {moment.utc(record.created_at).local().format("MM/DD/YYYY hh:mm:A")}
        </Space>
      ),
    },
  ];

  return (
    <>
      {data[0]?.user_scaling == null ? (
        <>
          {data[0]?.user_feelings?.map((item, index) => (
            <>
              <Title level={4}>Feeling Id: {item?.feeling?.id}</Title>
              <Title level={4}>Feeling Name: {item?.feeling?.name}</Title>
              <Title level={4}>
                Created At:{" "}
                {moment(item?.feeling?.created_at)
                  .utc()
                  .format("MM/DD/YYYY hh:mm:A")}
              </Title>
            </>
          ))}
        </>
      ) : (
        <Table
          rowKey={(data) => data.added_by}
          dataSource={data}
          columns={columns}
          pagination={false}
          scroll={{ x: 1 }}
        />
      )}
    </>
  );
}
