/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class UserContentApi {
  static async getAllUserContent(payload) {
    const response = await http.get("/api/admin/user_content", payload);
    return response;
  }

  static async deleteSingleUserContent(payload) {
    const response = await http.del(`/api/admin/user_content/${payload}`);
    return response;
  }

  static async editUserContent(payload, id) {
    const response = await http.put(`/api/admin/user_content/${id}`, payload);
    return response;
  }

  static async addUserContent(payload) {
    const response = await http.post("/api/admin/user_content", payload);
    return response;
  }

  static async getAudioContentDetail(id) {
    const response = await http.get(`/api/admin/user_audio/${id}`);
    return response;
  }

  static async getJournalContentDetail(id) {
    const response = await http.get(`/api/admin/user_journal/${id}`);
    return response;
  }
  static async getVideoContentDetail(id) {
    const response = await http.get(`/api/admin/user_video/${id}`);
    return response;
  }
  static async getOtherContentDetail(id) {
    const response = await http.get(`/api/admin/user_content/${id}`);
    return response;
  }

  static async getAllUserCourseContent(id) {
    const response = await http.get(`/api/admin/user_course_content`);
    return response;
  }
}
