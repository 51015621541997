import http from "./utils/NetworkClient";

export default class CourseApi {
  static async getAllCourse(payload) {
    const response = await http.get("/api/admin/course", payload);
    return response;
  }
  static async addCourse(payload) {
    const response = await http.post("/api/admin/course", payload);
    return response;
  }
  static async deleteSingleCourse(id,isDelete) {
    const response = await http.del(`/api/admin/course/${id}?delete=${isDelete}`);
    return response;
  }
  static async updateCourse(payload, id) {
    const response = await http.put(`/api/admin/course/${id}`, payload);
    return response;
  }
  static async getCourseDetails(id) {
    const response = await http.get(`/api/admin/course/${id}`);
    return response;
  }
  static async addChapter(id, payload) {
    const response = await http.post(
      `/api/admin/course/${id}/chapter`,
      payload
    );
    return response;
  }
  static async deleteSingleChapter(courseId, chapterId,isDelete) {
    const response = await http.del(
      `/api/admin/course/${courseId}/chapter/${chapterId}?delete=${isDelete}`
    );
    return response;
  }
  static async updateChapter(courseId, chapterId, payload) {
    const response = await http.put(
      `/api/admin/course/${courseId}/chapter/${chapterId}`,
      payload
    );
    return response;
  }

  //content
  static async addContent(courseId, chapterId, payload) {
    const response = await http.post(
      `/api/admin/course/${courseId}/chapter/${chapterId}/course_content`,
      payload
    );
    return response;
  }
  static async editContent(courseId, chapterId, courseContentId, payload) {
    const response = await http.put(
      `/api/admin/course/${courseId}/chapter/${chapterId}/course_content/${courseContentId}`,
      payload
    );
    return response;
  }
  static async deleteSingleContent(courseId, chapterId, courseContentId,isDelete) {
    const response = await http.del(
      `/api/admin/course/${courseId}/chapter/${chapterId}/course_content/${courseContentId}?delete=${isDelete}`
    );
    return response;
  }
}
