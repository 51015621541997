/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class AppointmentApi {
  static async getAppointments(payload) {
    const response = await http.get("/api/admin/appointments", payload);
    return response;
  }
  static async getAppointmentDetails(payload) {
    const response = await http.get(`/api/admin/appointments/${payload.id}`);
    return response;
  }
  static async deleteSinglAappointment(id) {
    const response = await http.del(`/api/admin/appointments/${id}`);
    return response;
  }

  static async editAppointment(id, payload) {
    const response = await http.put(`/api/admin/appointment/${id}`, payload);
    return response;
  }

  static async addAppointment(payload) {
    const response = await http.post("/api/admin/appointments", payload);
    return response;
  }

  static async getAppointmentTypes(payload) {
    const response = await http.get("/api/admin/appointment_types", payload);
    return response;
  }

  static async getAppointmentTypesOptions() {
    const response = await http.get(
      "/api/admin/appointment-management/appointment_types"
    );
    return response;
  }

  static async getAppointmentTypeKeys() {
    const response = await http.get("/api/admin/appointment_type_keys");
    return response;
  }

  static async createAppointmentType(payload) {
    const response = await http.post("/api/admin/appointment_types", payload);
    return response;
  }

  static async updateAppointmentType(id, payload) {
    const response = await http.put(
      `/api/admin/appointment_types/${id}`,
      payload
    );
    return response;
  }

  static async deleteSinglAappointmentType(id) {
    const response = await http.del(`/api/admin/appointment_types/${id}`);
    return response;
  }

  static async cancelAppointment(id) {
    const response = await http.post(`/api/admin/cancel_appointment/${id}`);
    return response;
  }

  static async payAppointment(id, payload) {
    const response = await http.post(
      `/api/admin/pay_appointment/${id}`,
      payload
    );
    return response;
  }

  static async payAppointmentWithCard(id, cardID) {
    const response = await http.post(
      `/api/admin/pay_appointment/${id}?card_info_id=${cardID}`
    );
    return response;
  }

  static async getAppointmentDetail(id) {
    const response = await http.get(`/api/admin/appointment/${id}`);
    return response;
  }

  static async getStudioLocations(q) {
    const response = await http.get(`/api/admin/studio-locations?q=${q}`);
    return response;
  }

  static async getInstructorList(studio_location_id, appointment_type_id, q) {
    const response = await http.get(
      `/api/admin/instructor?studio_location_id=${studio_location_id}&appointment_type_id=${appointment_type_id}&q=${q}`
    );
    return response;
  }
  static async getInstructorAvailablity(
    instructor_id,
    studio_location_id,
    appointment_type_id,
    appointment_date
  ) {
    const response = await http.get(
      `/api/admin/instructor-availability/${instructor_id}/studio-location/${studio_location_id}/appointment-type/${appointment_type_id}?appointment_date=${appointment_date}
      `
    );
    return response;
  }

  static async getUserList(q) {
    const response = await http.get(`/api/admin/user?q=${q}`);
    return response;
  }

  static async addAnotherPatient(payload) {
    const response = await http.post("/api/admin/user-relative", payload);
    return response;
  }

  static async getUserRelatives(payload) {
    const response = await http.get("/api/admin/user-relatives", payload);
    return response;
  }

  static async addPatientToAnAppointment(appointment_id, payload) {
    const response = await http.put(
      `/api/admin/group-appointment/${appointment_id}/add-patient`,
      payload
    );
    return response;
  }
  static async removeClientFromAppoinment(appointment_id, payload) {
    const response = await http.put(
      `/api/admin/group-appointment/${appointment_id}/remove-patient`,
      payload
    );
    return response;
  }



  static async createAppointment(payload) {
    const response = await http.post(`/api/admin/appointments`, payload);
    return response;
  }

  static async preview(id) {
    const response = await http.get(
      `/api/admin/invoice/preview?appointment_id=${id}`
    );
    return response;
  }

  static async rescheduleAppointment(id, payload) {
    const response = await http.put(
      `/api/admin/appointment/${id}/reschedule`,
      payload
    );
    return response;
  }
}
