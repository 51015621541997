import {
  Button, Checkbox, Col,
  Form,
  Input,
  message,
  Row, Select, Spin
} from "antd";
import React, { memo, useEffect } from "react";
import { ActionCreators } from "../../store";
import { QuestionariesApi } from "../../store/api";

const { Option } = Select;

const AddEditInstructorInfoModal = memo(
  ({
    setVisibleModal,
    onSubmitModal,
    data,
    modalLoading,
    dispatch,
    allIdentifiers,
    allOptions,
    setAllOptions,
    mainData,
  }) => {
    // console.log(data?.form_identifier);
    const [form] = Form.useForm();

    useEffect(() => {
      form.resetFields();
    }, [form, data]);

    // useEffect(() => {
    //   onchangeIdentifier(data?.form_identifier);
    // }, [data]);

    const initialValues = {
      form_identifier: data?.form_identifier ?? null,
      form_options: data?.form_options ?? [],
      title: data?.title ?? null,
      order: data?.order ?? null,
      is_show: data?.is_show ?? false,
    };

    const onSubmit = (value) => {
      if (value.errorFields) {
        message.error("Please fill up required fields");
      } else {
        onSubmitModal(value);
        form.resetFields();
      }
    };

    const onSearchIdentifier = (value) => {
      if (value === "") {
        dispatch(ActionCreators.getIdentifier(1, 20));
      } else {
        dispatch(ActionCreators.getIdentifier(1, 20, value));
      }
    };

    const onSearchFormOptions = async (value) => {};

    const onchangeIdentifier = async (e) => {
      form.setFieldsValue({
        form_options: [],
      });
      const checker = await mainData?.filter((response) =>
        response.form_identifier === e ? true : false
      );
      if (checker?.length > 0) {
        message.error("This identifier is already selected");
        form.setFieldsValue({
          form_identifier: null,
        });
        return;
      } else {
        const values = {
          page: 1,
          limit: 100,
          form_identifier: e,
        };
        const response = await QuestionariesApi.getIAllOptions(values);
        if (response.status === 200) {
          setAllOptions(response.data.results);
        } else {
          setAllOptions([]);
        }
      }
    };

    return (
      <React.Fragment>
        {modalLoading ? (
          <Spin
            size="large"
            style={{ display: "flex", justifyContent: "center" }}
          />
        ) : (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={initialValues}
            onFinish={onSubmit}
            onFinishFailed={onSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="FORM IDENTIFIER"
              name="form_identifier"
              rules={[
                {
                  required: true,
                  message: "Please select identifier",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select/Search Identifier"
                optionFilterProp="children"
                onChange={(value) => onchangeIdentifier(value)}
                onSearch={(value) => onSearchIdentifier(value)}
                onClear={() => onSearchIdentifier("")}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {allIdentifiers?.length > 0 &&
                  allIdentifiers.map((e, key) => {
                    return (
                      <Option key={key} value={e.id}>
                        {e.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label="FORM OPTIONS"
              name="form_options"
              rules={[
                {
                  required: true,
                  message: "Please select form options",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select/Search form options"
                optionFilterProp="children"
                onChange={() => onSearchIdentifier("")}
                onSearch={(value) => onSearchFormOptions(value)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                mode={"multiple"}
              >
                {allOptions?.length > 0 &&
                  allOptions.map((e, key) => {
                    return (
                      <Option key={key} value={e.id}>
                        {e.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label="TITLE"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Title is required!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Row gutter={16}>
              <Col lg={12} sm={12} xs={24}>
                <Form.Item name="is_show" valuePropName="checked">
                  <Checkbox>IS SHOW</Checkbox>
                </Form.Item>
              </Col>
              <Col
                lg={12}
                sm={12}
                xs={24}
                style={{ display: "flex", alignContent: "center" }}
              >
                <span style={{ marginTop: 5, paddingRight: 5 }}>ORDER: </span>
                <Form.Item
                  label=""
                  name="order"
                  rules={[
                    {
                      required: true,
                      message: "Order is required!",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Row gutter={5} style={{ justifyContent: "flex-end" }}>
                <Col>
                  <Button
                    type="danger"
                    shape="round"
                    onClick={() => {
                      form.resetFields();
                      setVisibleModal(false);
                    }}
                    size="large"
                  >
                    Close
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    shape="round"
                    htmlType="submit"
                    size="large"
                    loading={modalLoading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}
      </React.Fragment>
    );
  }
);

export default AddEditInstructorInfoModal;
