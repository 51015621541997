import { combineReducers } from "redux";
import { constant } from "../Constant";
import AppointmentReducer from "./AppointmentReducer";
import AppointmentTypesReducer from "./AppointmentTypesReducer";
//Import all reducers in here
import AuthReducer from "./AuthReducer";
import BenefitReducer from "./BenefitReducer";
import CategoryReducer from "./CategoryReducer";
import ContentReducer from "./ContentReducer";
import CourseReducer from "./CourseReducer";
import DailyDiveReducer from "./DailyDiveReducer";
import FavouriteReducer from "./FavouriteReducer";
import FeelingReducer from "./FeelingReducer";
import InstructorReducer from "./InstructorReducer";
import IntervalReducer from "./IntervalReducer";
import MeReducer from "./MeReducer";
import MoodReducer from "./MoodReducer";
import NotificationReducer from "./NotificationReducer";
import OverviewReducer from "./OverviewReducer";
import PermissionReducer from "./PermissionReducer";
import QuestionariesReducer from "./QuestionariesReducer";
import ReminderReducer from "./ReminderReducer";
import ScalingReducer from "./ScalingReducer";
import SoloDiveOverviewReducer from "./SoloDiveOverviewReducer";
import SoloDivesReducer from "./SoloDivesReducer";
import SubscriberReducer from "./SubscriberReducer";
import TagReducer from "./TagReducer";
import UserContentReducer from "./UserContentReducer";
import UserCourseContentReducer from "./UserCourseContentReducer";
import UserCourseReviewReducer from "./UserCourseReviewReducer";
import UserReducer from "./UserReducer";

const appReducer = combineReducers({
  AuthReducer,
  UserReducer,
  InstructorReducer,
  SubscriberReducer,
  FeelingReducer,
  TagReducer,
  CategoryReducer,
  IntervalReducer,
  BenefitReducer,
  ScalingReducer,
  MoodReducer,
  ContentReducer,
  DailyDiveReducer,
  ReminderReducer,
  UserContentReducer,
  NotificationReducer,
  FavouriteReducer,
  CourseReducer,
  OverviewReducer,
  UserCourseContentReducer,
  UserCourseReviewReducer,
  SoloDiveOverviewReducer,
  SoloDivesReducer,
  PermissionReducer,
  MeReducer,
  QuestionariesReducer,
  AppointmentReducer,
  AppointmentTypesReducer
});

const reducers = (state, action) => {
  if (action.type === constant.LOGOUT_SUCCESS) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default reducers;
