import { Menu, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Sidebar({ onPressLogout, menu, loading = true }) {
  const navigate = useNavigate();
  const location = useLocation();
  // const me = useSelector((state) => state.MeReducer);

  const [defaultOpenKeys, setdefaultOpenKeys] = useState(["userManagement"]);
  const [defaultSelectedKeys, setdefaultSelectedKeys] = useState(["users"]);
  const [openKeys, setopenKeys] = useState();
  const [selectedKeys, setselectedKeys] = useState();
  // const [loading, setloading] = useState(false);

  useEffect(() => {
    if (
      location.pathname.substring(1) === "users" ||
      location.pathname.substring(1) === "instructors" ||
      location.pathname.substring(1) === "subscribers" ||
      location.pathname.substring(1) === "instructor-info"
    ) {
      setdefaultOpenKeys(["userManagement"]);
      setopenKeys(["userManagement"]);
    } else if (
      location.pathname.substring(1) === "user_course" ||
      location.pathname.substring(1) === "user_content" ||
      location.pathname.substring(1) === "notification" ||
      location.pathname.substring(1) === "reminder" ||
      location.pathname.substring(1) === "favourite" ||
      location.pathname.substring(1) === "daily_dive" ||
      location.pathname.substring(1) === "user_course_content" ||
      location.pathname.substring(1) === "user_chapter" ||
      location.pathname.substring(1) === "user_course_review" ||
      location.pathname.substring(1) === "requested-location"
    ) {
      setdefaultOpenKeys(["userActivity"]);
      setopenKeys(["userActivity"]);
    } else if (
      location.pathname.substring(1) === "content" ||
      location.pathname.substring(1) === "course" ||
      location.pathname.substring(1) === "course_details"
    ) {
      setdefaultOpenKeys(["menuContent"]);
      setopenKeys(["menuContent"]);
    } else if (
      location.pathname.substring(1) === "appointment" ||
      location.pathname.substring(1) === "location" ||
      location.pathname.substring(1) === "consent_form" ||
      location.pathname.substring(1) === "appointment_types" ||
      location.pathname.substring(1) === "user-submitted-form" ||
      location.pathname.substring(1) === "appointment_policies" ||
      location.pathname.substring(1) === "user-submitted-form-details"
    ) {
      setdefaultOpenKeys(["booking_management"]);
      setopenKeys(["booking_management"]);
    } else if (
      location.pathname.substring(1) === "category" ||
      location.pathname.substring(1) === "benefit" ||
      location.pathname.substring(1) === "interval" ||
      location.pathname.substring(1) === "mood" ||
      location.pathname.substring(1) === "feeling" ||
      location.pathname.substring(1) === "tag" ||
      location.pathname.substring(1) === "scaling" ||
      location.pathname.substring(1) === "overview"
    ) {
      setdefaultOpenKeys(["content_configuration"]);
      setopenKeys(["content_configuration"]);
    } else if (
      location.pathname.substring(1) === "settings" ||
      location.pathname.substring(1) === "permission_settings" ||
      location.pathname.substring(1) === "support_info" ||
      location.pathname.substring(1) === "secret_keys"
    ) {
      setdefaultOpenKeys(["site_configuration"]);
      setopenKeys(["site_configuration"]);
    } else if (
      location.pathname.substring(1) === "solo_dive_overview" ||
      location.pathname.substring(1) === "solo_dives" ||
      location.pathname.includes("solo_dives_details")
    ) {
      setdefaultOpenKeys(["dives"]);
      setopenKeys(["dives"]);
    } else if (
      location.pathname.substring(1) === "user_report" ||
      location.pathname.substring(1) === "premium_content_report"
    ) {
      setdefaultOpenKeys(["report"]);
      setopenKeys(["report"]);
    } else if (
      location.pathname.substring(1) === "appointments-log" ||
      location.pathname.substring(1) === "appointments-log-details"
    ) {
      setdefaultOpenKeys(["Logs"]);
      setopenKeys(["Logs"]);
    }
    if (
      location.pathname.substring(1) === "identifier" ||
      location.pathname.substring(1) === "identifier_details" ||
      location.pathname.substring(1) === "form-groups" ||
      location.pathname.substring(1) === "form-groups-details" ||
      location.pathname.substring(1) === "add-edit-form"
    ) {
      setdefaultOpenKeys(["Questionaries"]);
      setopenKeys(["Questionaries"]);
    }
    setdefaultSelectedKeys([location.pathname.substring(1)]);
    setselectedKeys([location.pathname.substring(1)]);
    // setloading(false);
  }, [location]);

  // useEffect(() => {
  //  console.log('Usama',me);
  // }, [me])

  const onPressSideMenu = (item) => {
    setselectedKeys(item.key);
    if (item.key === "logout") {
      onPressLogout();
    } else {
      navigate("/" + item.key);
    }
  };

  const onOpenChange = (item) => {
    setopenKeys(item);
  };

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <Menu
          theme="dark"
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={defaultOpenKeys}
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          mode="inline"
          items={menu}
          onOpenChange={(item) => onOpenChange(item)}
          onClick={(item) => onPressSideMenu(item)}
        />
      )}
    </>
  );
}
