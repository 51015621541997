import { Button, Card, Form, Input, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { SettingApi } from "../../store/api";

export default function AppointmentPolicies() {
  const [formCancel] = Form.useForm();

  const [cancelLoading, setcancelLoading] = useState(true);
  const [cancelData, setcancelData] = useState({});

  useEffect(() => {
    getCancelInfo();
  }, []);

  const getCancelInfo = async () => {
    setcancelLoading(true);

    const response = await SettingApi.getCancelInfo();
    if (response.status === 200) {
      setcancelData(response?.data);
      setcancelLoading(false);
    } else {
      setcancelLoading(false);
      message.error(response?.data?.detail);
    }
  };

  const onSubmitCancelConfig = async (value) => {
    try {
      setcancelLoading(true);

      const response = await SettingApi.updateCanceltInfo(value);
      if (response.status === 201 || response.status === 200) {
        message.success("Successfully updated.");
        getCancelInfo();
        setcancelLoading(false);
      } else {
        setcancelLoading(false);
        message.error("Update unsuccessful");
      }
    } catch (error) {
      setcancelLoading(false);
      message.error("Update unsuccessful");
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Card
        title="Appointment Policy Settings"
        style={{
          width: 600,
        }}
      >
        {cancelLoading ? (
          <Spin size="large" />
        ) : (
          <Form
            form={formCancel}
            layout="vertical"
            name="basic"
            initialValues={{
              //   wait_list_expiry_period: cancelData?.wait_list_expiry_period,
              //   time_slot_size: cancelData?.time_slot_size,
              minimum_cancellation_time: cancelData?.minimum_cancellation_time,
            }}
            onFinish={onSubmitCancelConfig}
            onFinishFailed={onSubmitCancelConfig}
            autoComplete="off"
          >
            {/* <Form.Item
              label="Wait List Expire Period(in hours)"
              name="wait_list_expiry_period"
            >
              <Input type={"number"} />
            </Form.Item>

            <Form.Item label="Time slot size(in minutes)" name="time_slot_size">
              <Input type={"number"} />
            </Form.Item> */}
            <Form.Item
              label="Minimum cancellation time(in hours)"
              name="minimum_cancellation_time"
            >
              <Input type={"number"} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                shape="round"
                size="large"
                htmlType="submit"
                block
              >
                UPDATE APPOINTMENT POLICIES
              </Button>
            </Form.Item>
          </Form>
        )}
      </Card>
    </div>
  );
}
