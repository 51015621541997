import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ActionCreators } from "../../store";
import { AppointmentApi } from "../../store/api";
import Payment from "./Payment";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const Appointment = () => {
  const { Title } = Typography;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.AppointmentReducer);
  const instructorData = useSelector(
    (state) => state.InstructorReducer?.instructors
  );
  const me = useSelector((state) => state.MeReducer?.response);

  const [showCollectPayment, setshowCollectPayment] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [currentPageNumber, setCurrentPageNumber] = useState(1); //modal

  const [qVal, setQVal] = useState();
  const [appoinment_type, setAppoinment_type] = useState();
  const [appoinment_category, setAppoinment_category] = useState();
  const [appointment_location, setAppointment_location] = useState();
  const [is_canceled, setIs_canceled] = useState();
  const [is_completed, setis_completed] = useState();
  const [is_payment, setis_payment] = useState();
  const [start_date, setstartDate] = useState();
  const [end_date, setendDate] = useState();
  const [cancel_start_date, setcancel_start_date] = useState();
  const [cancel_end_date, setcancel_end_date] = useState();
  const [instructor_id, setinstructor_id] = useState();
  const [sortVal, setSortVal] = useState();
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
      setshowCollectPayment(true);
    } else {
      let index = me?.permissions?.findIndex(
        (item) => item?.type === "appointment"
      );
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }

      index = me?.permissions?.findIndex(
        (item) => item?.type === "APPOINTMENT_PAYMENT"
      );
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCollectPayment(true);
        }
        // if (me?.permissions?.[index]?.value?.includes("D")) {
        //   // console.log('usama Can Delete');
        //   setshowDelete(true);
        // }
      }
    }
  }, [me]);

  // useEffect(() => {
  //   let index = me?.permissions?.findIndex(
  //     (item) => item?.type === "APPOINTMENT_PAYMENT"
  //   );
  //   if (index > -1) {
  //     setshowCollectPayment(true);
  //   }
  // }, []);

  // console.log(data.appointment);
  useEffect(() => {
    dispatch(ActionCreators.getAppointmentAction(1, 20));
    dispatch(ActionCreators.getInstructorsAction(1, 50));
  }, [dispatch]);

  const handlePageChange = (page) => {
    let data = {
      page: page,
      limit: 20,
      q: qVal,
      appointment_type: appoinment_type,
      appointment_age_category: appoinment_category,
      appointment_location: appointment_location,
      is_canceled: is_canceled,
      is_completed: is_completed,
      is_payment: is_payment,
      start_date: start_date,
      end_date: end_date,
      instructor_id: instructor_id,
      sort: sortVal,
    };
    setCurrentPageNumber(page);
    dispatch(ActionCreators.getAppointmentAction(data));
  };

  const cancelBtnClicked = async (id) => {
    let data = {
      page: currentPageNumber,
      limit: 20,
      q: qVal,
      appointment_type: appoinment_type,
      appointment_age_category: appoinment_category,
      appointment_location: appointment_location,
      is_canceled: is_canceled,
      is_completed: is_completed,
      is_payment: is_payment,
      start_date: start_date,
      end_date: end_date,
      instructor_id: instructor_id,
    };
    const res = await AppointmentApi.cancelAppointment(id);
    if (res.status === 200) {
      message.success("Successfully Cancelled.");
      dispatch(ActionCreators.getAppointmentAction(data));
    } else {
      message.error(res?.data?.detail);
    }
  };

  const collectPayment = async (id, payload, cardID = null) => {
    let data = {
      page: currentPageNumber,
      limit: 20,
      q: qVal,
      appointment_type: appoinment_type,
      appointment_age_category: appoinment_category,
      appointment_location: appointment_location,
      is_canceled: is_canceled,
      is_completed: is_completed,
      is_payment: is_payment,
      start_date: start_date,
      end_date: end_date,
      instructor_id: instructor_id,
    };
    let res;
    if (cardID) {
      res = await AppointmentApi.payAppointmentWithCard(id, cardID);
    } else {
      res = await AppointmentApi.payAppointment(id, payload);
    }
    if (res.status === 200) {
      dispatch(ActionCreators.getAppointmentAction(data));
      setPaymentModal(false);
      message.success("Successfully payment completed.");
    } else {
      message.error(res?.data?.detail);
    }
  };

  const onSearch = (type, param) => {
    let data = {
      page: 1,
      limit: 20,
      q: qVal,
      appointment_type: appoinment_type,
      appointment_age_category: appoinment_category,
      appointment_location: appointment_location,
      is_canceled: is_canceled,
      is_completed: is_completed,
      is_payment: is_payment,
      start_date: start_date,
      end_date: end_date,
      instructor_id: instructor_id,
      sort: sortVal,
    };
    if (type === "q") {
      setQVal(param);
      data.q = param;
    } else if (type === "appoinment_type") {
      setAppoinment_type(param);
      data.appointment_type = param;
    } else if (type === "appoinment_category") {
      setAppoinment_category(param);
      data.appointment_age_category = param;
    } else if (type === "appointment_location") {
      setAppointment_location(param);
      data.appointment_location = param;
    } else if (type === "is_canceled") {
      setIs_canceled(param);
      data.is_canceled = param;
    } else if (type === "is_completed") {
      setis_completed(param);
      data.is_completed = param;
    } else if (type === "is_payment") {
      setis_payment(param);
      data.is_payment = param;
    } else if (type === "instructor_id") {
      setinstructor_id(param);
      data.instructor_id = param;
    } else if (type === "sort") {
      setSortVal(param);
      data.sort = param;
    } else if (type === "dateRange") {
      setstartDate(param[0] !== "" ? param[0] : null);
      setendDate(param[1] !== "" ? param[1] : null);
      data.start_date = param[0] !== "" ? param[0] : null;
      data.end_date = param[1] !== "" ? param[1] : null;
    } else if (type === "dateRange_cancel") {
      setcancel_start_date(param[0] !== "" ? param[0] : null);
      setcancel_end_date(param[1] !== "" ? param[1] : null);
      data.cancel_start_date = param[0] !== "" ? param[0] : null;
      data.cancel_end_date = param[1] !== "" ? param[1] : null;
    }
    dispatch(ActionCreators.getAppointmentAction(data));
  };

  const paymentHandle = (id) => {
    setPaymentModal(true);
    setModalData(id);
  };

  const columns = [
    {
      title: "EMAIL",
      dataIndex: "user",
      align: "center",
      width: 140,
      render: (text) => <>{text.email}</>,
    },
    {
      title: "NAME",
      dataIndex: "user",
      align: "center",
      width: 110,
      render: (text) => <>{text.name}</>,
    },
    {
      title: "START AT",
      dataIndex: "start_at",
      align: "center",
      width: 120,
      render: (text) => (
        <>{moment.utc(text).local().format("YYYY-MM-DD hh:mm A")}</>
      ),
    },
    {
      title: "CREATED AT",
      dataIndex: "created_at",
      align: "center",
      width: 120,
      render: (text) => (
        <>{moment.utc(text).local().format("YYYY-MM-DD hh:mm A")}</>
      ),
    },
    {
      title: "CANCELLED AT",
      dataIndex: "cancel_at",
      align: "center",
      width: 120,
      render: (text) => (
        <>
          {text ? moment.utc(text).local().format("YYYY-MM-DD hh:mm A") : "N/A"}
        </>
      ),
    },
    {
      title: "AMOUNT",
      dataIndex: "amount_money",
      align: "center",
      width: 80,
      render: (text) => <>${text?.amount}</>,
    },

    {
      title: "APPOINTMENT TYPE",
      dataIndex: "appointment_type",
      align: "center",
      width: 160,
      render: (text) => <>{text?.appointment_name}</>,
    },

    {
      title: "CANCELLED",
      dataIndex: "cancel",
      align: "center",
      width: 100,
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },
    {
      title: "COMPLETED",
      dataIndex: "is_completed",
      align: "center",
      width: 100,
      render: (text) => (
        <>
          {text === null ? (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          ) : // <CheckCircleFilled style={{ color: "#22c55e" }} />
          text === true ? (
            "Arrived"
          ) : (
            "Did not Arrived"
          )}
        </>
      ),
    },

    {
      title: "RESCHEDULED",
      dataIndex: "reschedule",
      align: "center",
      width: 110,
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },

    {
      title: "PAYMENT",
      dataIndex: "payment",
      align: "center",
      width: 80,
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },

    {
      title: "INSTRUCTOR NAME",
      dataIndex: "instructor",
      align: "center",
      width: 140,
      render: (text) => <>{text.name}</>,
    },

    {
      title: "Action",
      dataIndex: "role",
      align: "center",

      key: "id",
      width: 370,
      render: (text, record) => (
        <Space
          size="middle"
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          {showCollectPayment && (
            <Button
              shape="round"
              size="large"
              onClick={() => paymentHandle(record)}
            >
              Collect Payment
            </Button>
          )}

          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={() => {
              navigate("/appointment-detail/" + record?.id);
            }}
          >
            Detail
          </Button>
          {!record?.cancel && showDelete && (
            <Popconfirm
              title="Are you sure to cancel this Appointment?"
              onConfirm={() => cancelBtnClicked(record?.id)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" shape="round" size="large">
                Cancel
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            APPOINTMENT
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL APPOINTMENTS : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          {showCreateUpdate && (
            <Button
              icon={<PlusCircleOutlined />}
              size={"large"}
              type="primary"
              onClick={() => {
                // onClickCreateApplicationType();
                navigate("/create-appointment");
              }}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              CREATE APPOINTMENT
            </Button>
          )}
        </Col>
      </Row>
      <Row
        gutter={[16, 8]}
        style={{ alignItems: "center", marginTop: 10, marginBottom: 10 }}
      >
        <Col lg={6} sm={8} xs={8}>
          <Search
            placeholder="Search by email or name"
            enterButton
            size="large"
            // onChange={(e) => onSearch(e.target.value)}
            onSearch={(e) => onSearch("q", e)}
            style={{ width: "100%" }}
          />
        </Col>
        <Col lg={6} sm={8} xs={8}>
          <Select
            placeholder="Appointment Type"
            size="large"
            onChange={(e) => onSearch("appoinment_type", e)}
            style={{ width: "100%" }}
          >
            <Option value={null}>Select all type</Option>
            <Option value="INDIVIDUAL">Individual</Option>
            <Option value="COUPLES">Couples</Option>
            <Option value="FAMILY">Family</Option>
          </Select>
        </Col>
        <Col lg={6} sm={8} xs={8}>
          <Select
            placeholder="Age Category"
            size="large"
            onChange={(e) => onSearch("appoinment_category", e)}
            style={{ width: "100%" }}
          >
            <Option value={null}>Select all category</Option>
            <Option value="CHILD">Child</Option>
            <Option value="YOUTH">Youth</Option>
            <Option value="ADULT">Adult</Option>
          </Select>
        </Col>
        <Col lg={6} sm={8} xs={8}>
          <Select
            placeholder="Appointment Location"
            size="large"
            onChange={(e) => onSearch("appointment_location", e)}
            style={{ width: "100%" }}
          >
            <Option value={null}>Select all location</Option>
            <Option value="IN_PERSON">In Person</Option>
            <Option value="VIRTUAL">Virtual</Option>
          </Select>
        </Col>
        <Col lg={6} sm={8} xs={8}>
          <Select
            placeholder="Is Canceled"
            size="large"
            onChange={(e) => onSearch("is_canceled", e)}
            style={{ width: "100%" }}
          >
            <Option value={null}>Select All Cancelled Status</Option>
            <Option value={true}>Cancelled</Option>
            <Option value={false}>Not Cancelled</Option>
          </Select>
        </Col>
        <Col lg={6} sm={8} xs={8}>
          <Select
            placeholder="Is Completed"
            size="large"
            onChange={(e) => onSearch("is_completed", e)}
            style={{ width: "100%" }}
          >
            <Option value={null}>Select All Completed Status</Option>
            <Option value={true}>Arrived</Option>
            <Option value={false}>Not Arrived</Option>
          </Select>
        </Col>
        <Col lg={6} sm={8} xs={8}>
          <Select
            placeholder="Is Payment"
            size="large"
            onChange={(e) => onSearch("is_payment", e)}
            style={{ width: "100%" }}
          >
            <Option value={null}>Select All Payment Status</Option>
            <Option value={true}>Payment Paid</Option>
            <Option value={false}>Payment Due</Option>
          </Select>
        </Col>

        <Col lg={6} sm={8} xs={8}>
          <Select
            placeholder="Search by Instructor"
            size="large"
            onChange={(e) => onSearch("instructor_id", e)}
            style={{ width: "100%" }}
          >
            <Option value={null}>Select All</Option>
            {instructorData &&
              instructorData.map((item, index) => (
                <Option value={item?.id} key={index}>
                  {item?.name}
                </Option>
              ))}
          </Select>
        </Col>
        <Col lg={6} sm={8} xs={8}>
          <Select
            placeholder="Sorting"
            size="large"
            onChange={(e) => onSearch("sort", e)}
            style={{ width: "100%" }}
          >
            <Option value={null}>--</Option>
            <Option value={"id:asc"}>Created at ASC</Option>
            <Option value={"id:desc"}>Created at DESC</Option>
            <Option value={"start_at:asc"}>Start at ASC</Option>
            <Option value={"start_at:desc"}>Start at DESC</Option>
            <Option value={"cancel_at:asc"}>Cancel at ASC</Option>
            <Option value={"cancel_at:desc"}>Cancel at DESC</Option>
          </Select>
        </Col>
        <Col lg={9} sm={8} xs={8}>
          <RangePicker
            style={{ display: "flex" }}
            placeholder={["Appointment Start Date", "Appointment End Date"]}
            onChange={(date, dateString) => onSearch("dateRange", dateString)}
          />
        </Col>
        <Col lg={9} sm={8} xs={8}>
          <RangePicker
            style={{ display: "flex" }}
            placeholder={["Cancel Start Date", "Cancel End Date"]}
            onChange={(date, dateString) =>
              onSearch("dateRange_cancel", dateString)
            }
          />
        </Col>
      </Row>

      <Table
        rowKey={(data) => data.id}
        columns={columns}
        dataSource={data.appointment}
        scroll={{ x: 1, y: window.innerHeight - 280 }}
        size="middle"
        loading={data.loading}
        pagination={{
          total: data.count,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
      <Modal
        open={paymentModal}
        footer={null}
        closable={true}
        onCancel={() => setPaymentModal(false)}
      >
        <Payment data={modalData} collectPayment={collectPayment} />
      </Modal>
    </React.Fragment>
  );
};

export default Appointment;
