import { put } from "redux-saga/effects";
import { constant } from "../Constant";
import axios from "axios";
import { API_BASE_URL } from "../api/Config";
import { setToken } from "../api/utils/AxiosConfig";
// workers
function* userLoginAction(param) {
  try {
    const formData = new FormData();
    formData.append("username", param.email);
    formData.append("password", param.password);
    const res = yield axios.post(`${API_BASE_URL}/token`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (res.status === 200) {
      const payload = {
        type: constant.LOGIN_SUCCESS,
        loginStatus: true,
        access_token: res.data.access_token,
        refresh_token: res.data.refresh_token,
      };
      yield setToken(res.data.access_token);
      yield localStorage.setItem("token", res.data.access_token);
      yield localStorage.setItem("refreshToken", res.data.refresh_token);
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.LOGIN_FAILED,
        loginStatus: false,
        err: error.response.data.detail,
      };
      yield put(payload);
    }
  }
}

function* userLogOutAction() {
  localStorage.removeItem("persist:root");
  localStorage.removeItem("root");
  localStorage.clear();
  const payload = {
    type: constant.LOGOUT_SUCCESS,
    loginStatus: false,
  };
  yield put(payload);
  window.location.reload();
}

export { userLoginAction, userLogOutAction };
