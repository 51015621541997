import { Button, Col, Form, Input, message, Row, Spin } from "antd";
import React, { memo, useEffect } from "react";
const { TextArea } = Input;

const AddEditOverviewModal = memo(
  ({
    setvisibleAddOverviewModal,
    onSubmitAddOverviewModal,
    data,
    modalLoading,
  }) => {
    const [form] = Form.useForm();

    useEffect(() => {
      form.resetFields();
    }, [form, data]);

    const onSubmit = (value) => {
      if (value.errorFields) {
        message.error("Please fill up required fields");
      } else {
        form.resetFields();
        onSubmitAddOverviewModal(value);
      }
    };

    return (
      <>
        {modalLoading ? (
          <Spin
            size="large"
            style={{ display: "flex", justifyContent: "center" }}
          />
        ) : (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{
              title_1: data?.title_1 ?? "",
              transition_1: data?.transition_1 ?? "",
              title_2: data?.title_2 ?? "",
              transition_2: data?.transition_2 ?? "",
              audio_overview: data?.audio_overview ?? "",
              journal_overview: data?.journal_overview ?? "",
            }}
            onFinish={onSubmit}
            onFinishFailed={onSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="TITLE 1"
              name="title_1"
              rules={[
                {
                  required: true,
                  message: "Please input Title 1!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="TRANSITION ONE" name="transition_1">
              <TextArea rows={4} placeholder="Write from here..." />
            </Form.Item>
            <Form.Item
              label="TITLE 2"
              name="title_2"
              rules={[
                {
                  required: true,
                  message: "Please input Title 2!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="TRANSITION TWO" name="transition_2">
              <TextArea rows={4} placeholder="Write from here..." />
            </Form.Item>
            <Form.Item label="AUDIO OVERVIEW" name="audio_overview">
              <TextArea rows={4} placeholder="Write from here..." />
            </Form.Item>
            <Form.Item label="JOURNAL OVERVIEW" name="journal_overview">
              <TextArea rows={4} placeholder="Write from here..." />
            </Form.Item>

            <Form.Item>
              <Row gutter={5} style={{ justifyContent: "flex-end" }}>
                <Col>
                  <Button
                    type="danger"
                    shape="round"
                    onClick={() => {
                      form.resetFields();
                      setvisibleAddOverviewModal(false);
                    }}
                    size="large"
                  >
                    Close
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" shape="round" htmlType="submit" size="large">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}
      </>
    );
  }
);

export default AddEditOverviewModal;
