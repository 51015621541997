import { Button, Col, Form, Input, message, Row, Select, Spin } from "antd";
import React, { memo, useEffect } from "react";

const { TextArea } = Input;
const { Option } = Select;

const AddEditIntervalModal = memo(
  ({
    setvisibleAddIntervalModal,
    onSubmitAddIntervalModal,
    data,
    modalLoading,
  }) => {
    const [form] = Form.useForm();

    useEffect(() => {
      form.resetFields();
    }, [form, data]);

    const onSubmit = (value) => {
      // console.log(value);

      if (value.errorFields) {
        message.error("Please fill up required fields");
      } else {
        form.resetFields();
        onSubmitAddIntervalModal(value);
      }
    };

    return (
      <>
        {modalLoading ? (
          <Spin
            size="large"
            style={{ display: "flex", justifyContent: "center" }}
          />
        ) : (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{
              interval: data?.interval ?? "",
              step_one: data?.step_one ?? "",
              step_two: data?.step_two ?? "",
              step_three: data?.step_three ?? "",
              step_four: data?.step_four ?? "",
            }}
            onFinish={onSubmit}
            onFinishFailed={onSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="TIME INTERVAL"
              name="interval"
              rules={[{ required: true, message: "Please select interval!" }]}
            >
              <Select placeholder="Select Interval" onChange={() => {}}>
                <Option value="MORNING">MORNING</Option>
                <Option value="AFTERNOON">AFTERNOON</Option>
                <Option value="EVENING">EVENING</Option>
                <Option value="NIGHT">NIGHT</Option>
              </Select>
            </Form.Item>
            <Row gutter={10}>
              <Col lg={12} sm={24} xs={24}>
                <Form.Item label="STEP ONE" name="step_one">
                  <TextArea rows={3} placeholder="Write from here..." />
                </Form.Item>
              </Col>
              <Col lg={12} sm={24} xs={24}>
                <Form.Item label="STEP TWO" name="step_two">
                  <TextArea rows={3} placeholder="Write from here..." />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col lg={12} sm={24} xs={24}>
                <Form.Item label="STEP THREE" name="step_three">
                  <TextArea rows={3} placeholder="Write from here..." />
                </Form.Item>
              </Col>
              <Col lg={12} sm={24} xs={24}>
                <Form.Item label="STEP FOUR" name="step_four">
                  <TextArea rows={3} placeholder="Write from here..." />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Row gutter={5} style={{ justifyContent: "flex-end" }}>
                <Col>
                  <Button
                    type="danger"
                    shape="round"
                    onClick={() => {
                      form.resetFields();
                      setvisibleAddIntervalModal(false);
                    }}
                    size="large"
                  >
                    Close
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" shape="round" htmlType="submit" size="large">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}
      </>
    );
  }
);

export default AddEditIntervalModal;
