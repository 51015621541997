import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DailyDiveDetailModal from "../../components/modals/DailyDiveDetailModal";
import { ActionCreators } from "../../store";
import { DailyDiveApi } from "../../store/api";
const { Search } = Input;
const { RangePicker } = DatePicker;

const DailyDive = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.DailyDiveReducer);
  const [modalDataLoading, setmodalDataLoading] = useState(false);
  const [detailData, setdetailData] = useState([]);
  const [visibleDailyDiveModal, setvisibleDailyDiveModal] = useState(false);
  const [searchedText, setsearchedText] = useState("");
  const [searchedMood, setsearchedMood] = useState("");
  const [searchedScaling, setsearchedScaling] = useState("");
  const [searchedStartDate, setsearchedStartDate] = useState("");
  const [searchedEndDate, setsearchedEndDate] = useState("");

  //modal
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // useEffect(() => {
  //   dispatch(ActionCreators.getDailyDivesAction(1, 20));
  // }, [dispatch]);

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    dispatch(
      ActionCreators.getDailyDivesAction(
        page,
        20,
        searchedText,
        searchedMood,
        searchedScaling,
        searchedStartDate,
        searchedEndDate
      )
    );
  };

  const viewDetailButtonClick = useCallback(async (id) => {
    try {
      setmodalDataLoading(true);
      // console.log(id);
      let response = await DailyDiveApi.getDailyDiveDetails(id);
      if (response.status === 201 || response.status === 200) {
        setdetailData([response.data]);
        setvisibleDailyDiveModal(true);
        setmodalDataLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  // const onSearchDailyDive = useCallback(
  //   (q,searchedStartDate="",searchedEndDate) => {
  //     console.log("USAMA START", searchedStartDate);
  //     console.log("USAMA END", searchedEndDate);
  //     setCurrentPageNumber(1);
  //     let action = ActionCreators.getDailyDivesAction(
  //       1,
  //       20,
  //       q,
  //       searchedMood,
  //       searchedMood,
  //       searchedStartDate,
  //       searchedEndDate
  //     );
  //     dispatch(action);
  //     setCurrentPageNumber(1);
  //     setsearchedText(q);
  //   },
  //   [dispatch,searchedEndDate,searchedStartDate]
  // );

  const onSearchDailyDive = () => {
    setCurrentPageNumber(1);
    let action = ActionCreators.getDailyDivesAction(
      1,
      20,
      searchedText,
      searchedMood,
      searchedScaling,
      searchedStartDate,
      searchedEndDate
    );
    dispatch(action);
  };

  const columns = [
    // {
    //   title: "Serial No",
    //   key: "index",
    //    100,
    //   render: (value, item, index) => <Space size="middle">{index}</Space>,
    // },
    {
      title: "Id",
      dataIndex: "id",
      ellipsis: true,
      width: 100,
      textWrap: "word-break",
    },
    {
      title: "EMAIL",
      dataIndex: "added_by",
      ellipsis: true,
      textWrap: "word-break",
      width: 150,
      render: (text) => <>{text?.email ?? "N/A"}</>,
    },
    {
      title: "SOLO DIVE",
      dataIndex: "solo_dive",
      align: "center",
      width: 120,
      render: (text) => <>{text?.title ?? "N/A"}</>,
    },
    {
      title: "SCALE NAME",
      dataIndex: "user_scaling",
      align: "center",
      width: 120,
      render: (text) => (
        <>
          {text?.scaling?.name} {text?.label ? `(${text?.label})` : ""}
        </>
      ),
    },
    {
      title: "JOURNAL NAME",
      dataIndex: "user_journal",
      align: "center",
      width: 170,

      render: (text) => <>{text?.journal?.title ?? "N/A"}</>,
    },

    {
      title: "AUDIO NAME",
      dataIndex: "user_audio",
      align: "center",
      width: 130,

      render: (text) => <>{text?.audio?.title ?? "N/A"}</>,
    },
    {
      title: "MOOD NAME",
      dataIndex: "user_mood",
      align: "center",
      width: 120,

      render: (text) => <>{text?.mood?.name ?? "N/A"}</>,
    },

    {
      title: "CREATED DATE & TIME",
      dataIndex: "created_at",
      width: 170,

      render: (text) => (
        <Space size="middle">
          {moment.utc(text).local().format("MM/DD/YYYY hh:mm:A")}
        </Space>
      ),
    },

    {
      title: "Action",
      dataIndex: "role",
      key: "id",
      align: "center",
      width: 130,

      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={() => viewDetailButtonClick(record.id)}
          >
            VIEW DETAIL
          </Button>
        </Space>
      ),
    },
  ];

  // useEffect(() => {
  //   if (searchedStartDate !== "" && searchedEndDate !== "") {
  //     onSearchDailyDive();
  //   }
  // }, [searchedStartDate, searchedEndDate]);

  useEffect(() => {
    onSearchDailyDive();
  }, [
    searchedText,
    searchedMood,
    searchedScaling,
    searchedStartDate,
    searchedEndDate,
  ]);

  const onChangeDateRange = (date, dateString) => {
    setsearchedStartDate(dateString[0]);
    setsearchedEndDate(dateString[1]);
  };

  return (
    <React.Fragment>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            DAILYDIVE
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL DAILYDIVES : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col
          lg={6}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search by email or solo dive name"
            enterButton
            size="large"
            onSearch={(text) => setsearchedText(text)}
          />
        </Col>
        <Col
          lg={6}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search by mood..."
            enterButton
            size="large"
            onSearch={(text) => setsearchedMood(text)}
          />
        </Col>
        <Col
          lg={6}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search by scaling..."
            enterButton
            size="large"
            onSearch={(text) => setsearchedScaling(text)}
          />
        </Col>
        <Col
          lg={6}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <RangePicker size="large" onChange={onChangeDateRange} />
        </Col>
      </Row>
      {modalDataLoading ? (
        <Spin size="large" />
      ) : (
        <Table
          rowKey={(data) => data.id}
          columns={columns}
          dataSource={data.dailydives}
          size="middle"
          scroll={{ x: 1, y: window.innerHeight - 250 }}
          loading={data.loading}
          pagination={{
            total: data.count,
            pageSize: 20,
            current: currentPageNumber,
            showSizeChanger: false,
            onChange: (page) => {
              handlePageChange(page);
            },
          }}
        />
      )}
      <Modal
        title={`DIVE DETAIL`}
        open={visibleDailyDiveModal}
        width={window.innerWidth}
        footer={[
          <Button
            key="back"
            type="danger"
            shape="round"
            size="large"
            onClick={() => setvisibleDailyDiveModal(false)}
          >
            CLOSE
          </Button>,
        ]}
        closable={false}
      >
        <DailyDiveDetailModal data={detailData} />
      </Modal>
    </React.Fragment>
  );
};

export default DailyDive;
