import { constant } from "../Constant";

const initialState = {
  loading: true,
  allIdentifiers: [],
  allOptions: [],
  allFromGroups: [],
  allForms: [],
  count: 0,
  identifierCount: 0,
  errMsg: "",
};

const QuestionariesReducer = (state = initialState, action) => {
  switch (action.type) {
    //identifiers
    case constant.GET_IDENTIFIERS:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_IDENTIFIERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allIdentifiers: action.allIdentifiers,
        identifierCount: action.count,
        errMsg: "",
      };
    case constant.GET_IDENTIFIERS_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    //options
    case constant.GET_OPTIONS:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        allOptions: action.allOptions,
        count: action.count,
        errMsg: "",
      };
    case constant.GET_OPTIONS_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    //locations
    case constant.GET_LOCATION:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        allLocations: action.allLocations,
        count: action.count,
        errMsg: "",
      };
    case constant.GET_LOCATION_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    //formgroups
    case constant.GET_FORM_GROUP:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_FORM_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        allFromGroups: action.allFromGroups,
        count: action.count,
        errMsg: "",
      };
    case constant.GET_FORM_GROUP_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    //forms
    case constant.GET_FORM:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        allForms: action.allForms,
        count: action.count,
        errMsg: "",
      };
    case constant.GET_FORM_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    case constant.FORM_DETAILS:
      return {
        ...state,
        formDetails: action.payload,
      };

    //instructor infos
    case constant.GET_INSTRUCTOR_INFO:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_INSTRUCTOR_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        allInstructorInfo: action.allInstructorInfo,
        count: action.count,
        errMsg: "",
      };
    case constant.GET_INSTRUCTOR_INFO_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    default:
      return state;
  }
};

export default QuestionariesReducer;
