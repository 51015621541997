import React, { memo, useEffect, useState } from "react";
import { Button, Col, message, Row, Spin, Select } from "antd";
import { QuestionariesApi } from "../../store/api";

const { Option } = Select;

const OptionsModal = memo(
  ({
    all_Options,
    submitData,
    defaultData,
    setModalVisible,
    editId,
    navigate,
    params,
    formNext,
    getFormNextData,
  }) => {
    const [values, setValues] = useState([]);
    const [submitClick, setSubmitClick] = useState(false);
    const [allOptions, setAllOptions] = useState(all_Options);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (defaultData?.length > 0) {
        console.log(defaultData);
        const arr = defaultData.map((res) => ({
          form_option: res.form_option?.id ?? "",
          next_form: res.next_form?.id ?? "",
        }));
        setValues(arr);
      } else {
        const initialValues = [{ form_option: "", next_form: "" }];
        setValues(initialValues);
      }
    }, [defaultData]);

    useEffect(() => {
      function getDifference(array1, array2) {
        return array1.filter((object1) => {
          return !array2.some((object2) => {
            return object1.id === object2.form_option;
          });
        });
      }
      const filterOptions = getDifference(all_Options, values);
      setAllOptions(filterOptions);
    }, [values, all_Options]);

    const AddButtonClick = () => {
      const addValues = [...values, { form_option: "", next_form: "" }];
      setValues(addValues);
      setSubmitClick(false);
    };

    const deleteButtonClick = (index) => {
      let arr = values.filter((res, key) => index !== key);
      setValues(arr);
      setSubmitClick(false);
    };

    const selectOptionControl = (value, key) => {
      const newState = values.map((item, index) => {
        if (index === key) {
          return { form_option: value, next_form: item.next_form };
        }
        return { ...item };
      });
      setValues(newState);
    };

    const nextFormControl = (value, key) => {
      // console.log(value, key);
      const newState = values.map((item, index) => {
        if (index === key) {
          return { form_option: item.form_option, next_form: value };
        }
        return { ...item };
      });
      setValues(newState);
    };

    const handleSubmit = async (e) => {
      setLoading(true);
      e.preventDefault();
      setSubmitClick(true);
      let check = false;
      values.filter((res) => {
        if (res.form_option === "") {
          message.error("Please fill up all required fields");
          check = false;
        } else {
          check = true;
        }
        return null;
      });
      if (check) {
        const newState = values.map((item) => {
          if (item.next_form === "") {
            return { form_option: item.form_option, next_form: null };
          }
          return { ...item };
        });
        submitData["form_options"] = newState;
        const response = editId
          ? await QuestionariesApi.updateForm(submitData, editId)
          : await QuestionariesApi.addForm(submitData);
        if (response.status === 200) {
          navigate(`/form-groups-details`, {
            state: { params: params },
          });
        } else {
          message.error("Invalid Data!");
        }
      }
      setLoading(false);
    };

    return (
      <React.Fragment>
        {loading ? (
          <Spin
            size="large"
            style={{ display: "flex", justifyContent: "center" }}
          />
        ) : (
          <form onSubmit={handleSubmit}>
            {values?.length > 0 &&
              values.map((res, index) => {
                return (
                  <div key={index} style={{ marginBottom: 10 }}>
                    <Row gutter={16}>
                      <Col lg={8} sm={8} xs={12}>
                        Select Options
                      </Col>
                      <Col lg={16} sm={16} xs={16}>
                        Select Next Form ID
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col lg={8} sm={8} xs={12}>
                        <Select
                          placeholder="Search to Select"
                          value={res.form_option}
                          onChange={(e) => selectOptionControl(e, index)}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showSearch
                          // allowClear
                          // onSearch={onSearchIdentifier}
                          style={{ width: "100%", backgroundColor: "white" }}
                        >
                          {allOptions &&
                            allOptions.map((data, index) => {
                              return (
                                <Option key={index} value={data?.id}>
                                  {data?.name}
                                </Option>
                              );
                            })}
                        </Select>
                        <span
                          style={{
                            color: "red",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {res.form_option === "" && submitClick
                            ? "required"
                            : ""}
                        </span>
                      </Col>
                      <Col lg={11} sm={11} xs={12}>
                        <Select
                          placeholder="Enter Next Form ID"
                          value={res.next_form}
                          onChange={(e) => nextFormControl(e, index)}
                          onSearch={(e) => getFormNextData(e)}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showSearch
                          style={{ width: "100%", backgroundColor: "white" }}
                        >
                          {formNext.map((data, index) => {
                            return (
                              <Option key={index} value={data?.id}>
                                {data?.name}
                              </Option>
                            );
                          })}
                        </Select>
                        <span
                          style={{
                            color: "red",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {res.form_option === "" && submitClick
                            ? "required"
                            : ""}
                        </span>
                      </Col>
                      <Col lg={5} sm={5} xs={12}>
                        <Row gutter={5} style={{ justifyContent: "flex-end" }}>
                          <Col>
                            {values?.length !== 1 && (
                              <Button onClick={() => deleteButtonClick(index)}>
                                X
                              </Button>
                            )}
                          </Col>
                          <Col>
                            <Button onClick={() => AddButtonClick()}>
                              Add More
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            <Row gutter={5} style={{ justifyContent: "center", marginTop: 20 }}>
              <Col>
                <Button
                  type="danger"
                  shape="round"
                  onClick={() => {
                    setModalVisible(false);
                  }}
                  size="large"
                >
                  Close
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  size="large"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </React.Fragment>
    );
  }
);

export default OptionsModal;
