import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Select,
  Checkbox,
} from "antd";
import React, { memo, useEffect } from "react";

const { Option } = Select;

const AddEditFormGroup = memo(
  ({ setVisibleModal, onSubmitModal, data, modalLoading }) => {
    const [form] = Form.useForm();

    useEffect(() => {
      form.resetFields();
    }, [form, data]);

    const onSubmit = (value) => {
      if (value.errorFields) {
        message.error("Please fill up required fields");
      } else {
        onSubmitModal(value);
        form.resetFields();
      }
    };

    return (
      <React.Fragment>
        {modalLoading ? (
          <Spin
            size="large"
            style={{ display: "flex", justifyContent: "center" }}
          />
        ) : (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{
              name: data?.name ?? "",
              platform_type: data?.platform_type ?? "",
              order: data?.order ?? null,
              is_published: data?.is_published ?? false,
            }}
            onFinish={onSubmit}
            onFinishFailed={onSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="NAME"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input title!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Platform Type"
              name="platform_type"
              rules={[
                {
                  required: true,
                  message: "Please select the Platform Type",
                },
              ]}
            >
              <Select placeholder="Select Platform Type" onChange={() => {}}>
                <Option value="WEB">WEB</Option>
                <Option value="MOBILE">MOBILE</Option>
              </Select>
            </Form.Item>

            <Row gutter={16}>
              <Col lg={12} sm={12} xs={12}>
                <Form.Item name="is_published" valuePropName="checked">
                  <Checkbox>IS PUBLISHED</Checkbox>
                </Form.Item>
              </Col>
              <Col lg={12} sm={12} xs={12} style={{ display: "flex" }}>
                <span style={{ paddingTop: 5, paddingRight: 5 }}>PRIORITY</span>
                <Form.Item name="order">
                  <Input type={"number"} placeholder="Priority" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Row gutter={5} style={{ justifyContent: "flex-end" }}>
                <Col>
                  <Button
                    type="danger"
                    shape="round"
                    onClick={() => {
                      form.resetFields();
                      setVisibleModal(false);
                    }}
                    size="large"
                  >
                    Close
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    shape="round"
                    htmlType="submit"
                    size="large"
                    loading={modalLoading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}
      </React.Fragment>
    );
  }
);

export default AddEditFormGroup;
