/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class TagApi {
  static async getTags(payload) {
    const response = await http.get("/api/admin/tag", payload);
    return response;
  }
  static async getTagDetails(payload) {
    const response = await http.get(`/api/admin/tag/${payload.id}`);
    return response;
  }
  static async deleteSingleTag(id) {
    const response = await http.del(`/api/admin/tag/${id}`);
    return response;
  }

  static async editTag(payload, id) {
    if (!payload.priority) delete payload.priority;
    const response = await http.put(`/api/admin/tag/${id}`, payload);
    return response;
  }

  static async addTag(payload) {
    if (!payload.priority) delete payload.priority;

    const response = await http.post("/api/admin/tag", payload);
    return response;
  }
}
