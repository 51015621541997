import { constant } from "../Constant";

const initialState = {
  loading: true,
  dailydives: [],
  count:0,
  errMsg: "",
  details: {},
};

const DailyDiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_DAILYDIVES:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_DAILYDIVES_SUCCESS:
      return {
        ...state,
        loading: false,
        dailydives: action.dailydives,
        count:action.count,
        errMsg: "",
      };
    case constant.GET_DAILYDIVE_DETAILS:
      return {
        ...state,
        id: action.id,
        loading: true,
      };
    case constant.GET_DAILYDIVE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: action.details,
        errMsg: "",
      };
    case constant.GET_DAILYDIVES_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    case constant.CLEAN_DAILYDIVE:
      return {
        ...state,
        loading: false,
        dailydives: [],
        count:0,
        errMsg: "",
      };
    default:
      return state;
  }
};

export default DailyDiveReducer;
