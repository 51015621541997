import { put } from "redux-saga/effects";
import { constant } from "../Constant";
import { NotificationApi } from "../api";
// workers
function* getAllNotificationAction(param) {
  try {
    const res = yield NotificationApi.getAllNotification({
      page: param.page,
      limit: param.limit,
      q: param.q
    });

    if (res.status === 200) {
      const payload = {
        type: constant.GET_NOTIFICATION_SUCCESS,
        allNotification: res.data.results,
        count:res.data.count
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_NOTIFICATION_FAILED,
        errMsg: "Invalid notification",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_NOTIFICATION_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}

export { getAllNotificationAction };
