import { constant } from "../Constant";

const initialState = {
  loading: true,
  soloDiveOverview: [],
  errMsg: "",
  details: {},
  count: 0,
};

const SoloDiveOverviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_SOLO_DIVE_OVERVIEW:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_SOLO_DIVE_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        soloDiveOverview: action.soloDiveOverview,
        errMsg: "",
        count: action.count,
      };
    case constant.GET_SoloDiveOverview_DETAILS:
      return {
        ...state,
        id: action.id,
        loading: true,
      };
    case constant.GET_SoloDiveOverview_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: action.details,
        errMsg: "",
      };
    case constant.GET_SOLO_DIVE_OVERVIEW_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    case constant.CLEAN_SUBSCRIBER:
      return {
        ...state,
        loading: false,
        soloDiveOverview: [],
        errMsg: "",
      };
    default:
      return state;
  }
};

export default SoloDiveOverviewReducer;
