/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class MoodApi {
  static async getAllMood(payload) {
    const response = await http.get("/api/admin/mood", payload);
    return response;
  }
  static async getMoodDetails(payload) {
    const response = await http.get(`/api/admin/mood/${payload.id}`);
    return response;
  }
  static async deleteSingleMood(id) {
    const response = await http.del(`/api/admin/mood/${id}`);
    return response;
  }

  static async editMood(payload, id) {
    if (!payload.priority) delete payload.priority;

    const response = await http.put(`/api/admin/mood/${id}`, payload);
    return response;
  }

  static async addMood(payload) {
    if (!payload.priority) delete payload.priority;

    const response = await http.post("/api/admin/mood", payload);
    return response;
  }
}
