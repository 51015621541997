import { constant } from "../Constant";

const initialState = {
  loading: true,
  soloDives: [],
  errMsg: "",
  details: {},
  count: 0,
};

const SoloDivesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_SOLO_DIVES:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_SOLO_DIVES_SUCCESS:
      return {
        ...state,
        loading: false,
        soloDives: action.soloDives,
        errMsg: "",
        count: action.count,
      };
    case constant.GET_SOLO_DIVES_DETAILS_SUCCESS:
      return {
        ...state,
        id: action.id,
        loading: true,
      };

    case constant.GET_SOLO_DIVES_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    case constant.CLEAN_SOLO_DIVES:
      return {
        ...state,
        loading: false,
        soloDives: [],
        errMsg: "",
      };
    default:
      return state;
  }
};

export default SoloDivesReducer;
