import { Button, Col, Form, Input, message, Row, Spin } from "antd";
import React, { memo, useEffect, useState } from "react";

const AddEditTagModal = memo(
  ({ setvisibleAddTagModal, onSubmitAddTagModal, data, modalLoading }) => {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);


    useEffect(() => {
      form.resetFields();
    }, [form, data]);

    const onSubmit = (value) => {
      if (value.errorFields) {
        message.error("Please fill up required fields");
      } else {
        form.resetFields();
        onSubmitAddTagModal(value);
      }
    };

    return (
      <>
        {modalLoading ? (
          <Spin
            size="large"
            style={{ display: "flex", justifyContent: "center" }}
          />
        ) : (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{
              name: data?.name ?? "",
              priority: data?.priority ?? "",
            }}
            onFinish={onSubmit}
            onFinishFailed={onSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input Tag name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Priority" name="priority">
              <Input type="number" />
            </Form.Item>

            <Form.Item>
              <Row gutter={5} style={{ justifyContent: "flex-end" }}>
                <Col>
                  <Button
                    type="danger"
                    shape="round"
                    onClick={() => {
                      form.resetFields();
                      setvisibleAddTagModal(false);
                    }}
                    size="large"
                  >
                    Close
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit" shape="round" size="large" loading={loading}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}
      </>
    );
  }
);

export default AddEditTagModal;
