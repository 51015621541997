import {
  CheckCircleFilled,
  CloseCircleFilled,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Image,
  message,
  Modal,
  Space,
  Table,
  Typography,
  Upload,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SelectInstructorModal from "../../components/modals/SelectInstructorModal";
import { ActionCreators } from "../../store";
import { UserApi } from "../../store/api";
import IntakeFormApi from "../../store/api/IntakeFormApi";

export default function UserDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const instructorDetail = useSelector((state) => state.InstructorReducer);
  const [patientId, setpatientId] = useState("");
  const [userId, setuserId] = useState("");
  const [visibleInstructorModal, setvisibleInstructorModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const [cardList, setcardList] = useState([]);
  const [familyMemberList, setfamilyMemberList] = useState([]);
  const [assignedInstructorList, setAssignedInstructorList] = useState();
  const [consentFormList, setconsentFormList] = useState([]);
  const [purchasedCourseList, setpurchasedCourseList] = useState([]);

  useEffect(() => {
    getDetail();
  }, [currentPageNumber]);

  const getDetail = async () => {
    setloading(true);
    let payload = {
      page: currentPageNumber,
      limit: 20,
      user_id: id,
    };
    const resCard = await UserApi.getUserCardInfo(payload);
    if (resCard.status === 200) {
      // console.log(resCard?.data?.results);
      if (resCard?.data?.results?.length > 0) {
        setcardList(resCard?.data?.results);
      }
    } else {
      console.log("Error while fetch solodives content list data!");
    }

    const resFam = await UserApi.getUserFamilyMembers(payload);
    if (resFam.status === 200) {
      // console.log(resFam?.data?.results);
      if (resFam?.data?.results?.length > 0) {
        setfamilyMemberList(resFam?.data?.results);
      }
    } else {
      console.log("Error while fetch solodives content list data!");
    }

    const resAssignedInstructors = await UserApi.getUserAssignedInstructors(
      payload
    );
    if (resAssignedInstructors.status === 200) {
      // console.log(resFam?.data?.results);
      if (resAssignedInstructors?.data?.results?.length > 0) {
        setAssignedInstructorList(resAssignedInstructors?.data?.results);
      }
    } else {
      console.log("Error while fetch solodives content list data!");
    }
    const resConsentForms = await UserApi.getUserConsentForms(payload);
    if (resConsentForms.status === 200) {
      // console.log(resFam?.data?.results);
      if (resConsentForms?.data?.results?.length > 0) {
        setconsentFormList(resConsentForms?.data?.results);
      }
    } else {
      console.log("Error while fetch consent forms list data!");
    }
    const resPurchasedCourses = await UserApi.getUserPurchasedCourses(payload);
    if (resPurchasedCourses.status === 200) {
      // console.log(resFam?.data?.results);
      if (resPurchasedCourses?.data?.results?.length > 0) {
        setpurchasedCourseList(resPurchasedCourses?.data?.results);
      }
    } else {
      console.log("Error while fetch consent forms list data!");
    }
    setloading(false);
  };

  const uploadHandler = async (e, id, user_id, type) => {
    if (e.file) {
      setloading(true);
      const formData = new FormData();
      formData.append("file", e.file);
      const res = await IntakeFormApi.uploadForm(formData, user_id, type);
      if (res.status === 200 || res.status === 201) {
        const payload = {
          is_save: false,
          is_submit: false,
          is_upload: true,
          attachment_id: res.data.id,
          attachment_url: res.data.url,
          consent_form_content: {},
        };
        const uploadRes = await IntakeFormApi.updateIntakeForm(
          payload,
          id,
          user_id
        );
        if (uploadRes.status === 200 || uploadRes.status === 201) {
          message.success("Form uploaded successfully!");
          getDetail();
        }
      }
      setloading(false);
    }
  };

  const donwloadHandler = async (item) => {
    setloading(true);
    const res = await IntakeFormApi.downloadForm(item.id);
    if (res.status === 200) {
      window.location.replace(res.data);
      message.success(
        `${item.consent_form.cliniko_patient_form_template_name} form downloaded successfully!`
      );
      getDetail();
    }
    setloading(false);
  };

  const onSelectInstructor = async (id) => {
    console.log(id);
    dispatch(ActionCreators.getInstructorDetailsAction(id));
  };

  const onSelectLocation = async (locId, insId) => {
    console.log(id);
    let payload = {
      user: userId,
      instructor: insId,
      studio_location: locId,
    };
    const response = await UserApi.updateUserAssignedInstructor(
      patientId,
      payload
    );
    if (response.status === 200) {
      console.log(response);
      setvisibleInstructorModal(false);
      getDetail();
    }
    // dispatch(ActionCreators.getInstructorDetailsAction(id));
  };

  const cardColumns = [
    {
      title: "Id",
      dataIndex: "id",
      ellipsis: true,
      textWrap: "word-break",
      width: 100,
    },
    {
      title: "Card Type",
      dataIndex: "card_type",
      width: 130,
    },
    {
      title: "Card Brand",
      dataIndex: "card_brand",
      width: 150,
    },

    {
      title: "Cardholder Name",
      dataIndex: "card_name",
      ellipsis: true,
      textWrap: "word-break",
      width: 130,
      align: "center",
    },
    {
      title: "Card number",
      dataIndex: "card_number",
      ellipsis: true,
      textWrap: "word-break",
      width: 130,
      align: "center",
    },
    {
      title: "Expire Date",
      dataIndex: "expire_date",
      ellipsis: true,
      textWrap: "word-break",
      width: 130,
      align: "center",
    },
    {
      title: "CVC",
      dataIndex: "cvc",
      ellipsis: true,
      textWrap: "word-break",
      width: 130,
      align: "center",
    },

    {
      title: "Created At",
      dataIndex: "created_at",
      width: 200,
      render: (text) => (
        <>{moment.utc(text).local().format("MM/DD/YYYY hh:mm:A")}</>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      width: 200,
      render: (text) => (
        <>{moment.utc(text).local().format("MM/DD/YYYY hh:mm:A")}</>
      ),
    },
  ];

  const familyColumns = [
    {
      title: "Id",
      dataIndex: "id",
      ellipsis: true,
      textWrap: "word-break",
      width: 100,
    },
    {
      title: "Relation Type",
      dataIndex: "relation_type",
      width: 130,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 150,
    },

    {
      title: "Full Name",
      dataIndex: "full_name",
      ellipsis: true,
      textWrap: "word-break",
      width: 130,
      align: "center",
    },
    {
      title: "Date of Birth",
      dataIndex: "date_of_birth",
      ellipsis: true,
      textWrap: "word-break",
      width: 130,
      align: "center",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      ellipsis: true,
      textWrap: "word-break",
      width: 130,
      align: "center",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      ellipsis: true,
      textWrap: "word-break",
      width: 130,
      align: "center",
    },

    {
      title: "Created At",
      dataIndex: "created_at",
      width: 200,
      render: (text) => (
        <>{moment.utc(text).local().format("MM/DD/YYYY hh:mm:A")}</>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      width: 200,
      render: (text) => (
        <>{moment.utc(text).local().format("MM/DD/YYYY hh:mm:A")}</>
      ),
    },
  ];

  const instructorColumns = [
    {
      title: "Id",
      dataIndex: "id",
      ellipsis: true,
      textWrap: "word-break",
      width: 100,
    },
    {
      title: "INSTRUCTOR ID",
      dataIndex: "instructor",
      align: "center",
      width: 160,
      render: (text) => <>{text.id}</>,
    },

    {
      title: "INSTRUCTOR NAME",
      dataIndex: "instructor",
      align: "center",
      width: 130,
      render: (text) => <>{text.name}</>,
    },
    {
      title: "INSTRUCTOR DESIGNATION",
      dataIndex: "instructor",
      align: "center",
      width: 150,
      render: (text) => <>{text?.instructor?.designation}</>,
    },
    {
      title: "INSTRUCTOR IMAGE",
      dataIndex: "instructor",
      align: "center",
      width: 120,
      render: (text) => (
        <>
          {text !== null ? (
            <Image
              preview={false}
              width={100}
              height={100}
              src={text?.instructor?.image_path}
            />
          ) : (
            "N/A"
          )}
        </>
      ),
    },

    {
      title: "Location Name",
      dataIndex: "studio_location",
      align: "center",
      width: 150,
      render: (text) => <>{text?.location_name}</>,
    },

    {
      title: "Action",
      dataIndex: "role",
      key: "name",
      align: "center",
      width: 100,
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={() => {
              setuserId(record?.user?.id);
              setpatientId(record?.id);
              setvisibleInstructorModal(true);
            }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  const consentFormColumns = [
    {
      title: "Name",
      dataIndex: "consent_form",
      width: 130,
      render: (text) => <>{text?.cliniko_patient_form_template_name}</>,
    },

    {
      title: "Saved",
      dataIndex: "is_saved",
      width: 130,
      align: "center",
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },

    {
      title: "Submitted",
      dataIndex: "is_submitted",
      width: 130,
      align: "center",
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },

    {
      title: "Downloaded",
      dataIndex: "is_downloaded",
      width: 130,
      align: "center",
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },

    {
      title: "Action",
      dataIndex: "role",
      key: "name",
      align: "center",
      width: 180,
      render: (text, record) => (
        <Space size="middle">
          <Upload
            beforeUpload={() => false}
            multiple={false}
            maxCount={1}
            //   disabled={loading}
            //   accept=".png, .jpg, .jpeg"
            showUploadList={false}
            onChange={(e) =>
              uploadHandler(
                e,
                record?.id,
                record?.user?.id,
                record?.consent_form?.form_type
              )
            }
            disabled={record?.is_submitted}
          >
            <Button
              type="primary"
              shape="round"
              size="large"
              disabled={record?.is_submitted}
            >
              <UploadOutlined />
            </Button>
          </Upload>

          {record?.consent_form?.file_path ? (
            <Button
              type="primary"
              shape="round"
              size="large"
              href={record?.consent_form?.file_path}
            >
              <DownloadOutlined />
            </Button>
          ) : (
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={() => donwloadHandler(record)}
            >
              <DownloadOutlined />
            </Button>
          )}

          {/* <Button
            type="primary"
            shape="round"
            size="large"
            onClick={()=>donwloadHandler(record)}
          >
            <DownloadOutlined />
          </Button> */}

          <Button
            type="primary"
            shape="round"
            disabled={record?.is_submitted}
            size="large"
            onClick={() => {
              navigate("/intake-form", {
                state: {
                  id: record?.id,
                  user_id: record?.user?.id,
                },
              });
            }}
          >
            {record?.is_submitted ? "Submitted" : "Edit"}
          </Button>
        </Space>
      ),
    },
  ];

  const purchasedCourseColumns = [
    {
      title: "Course id",
      dataIndex: "course",
      ellipsis: true,
      align: "center",
      textWrap: "word-break",
      width: 100,
      render: (text) => <>{text?.id}</>,
    },
    {
      title: "Course Title",
      dataIndex: "course",
      align: "center",
      ellipsis: true,
      textWrap: "word-break",
      width: 100,
      render: (text) => <>{text?.title}</>,
    },
    {
      title: "Amount",
      dataIndex: "amount_money",
      align: "center",
      ellipsis: true,
      textWrap: "word-break",
      width: 100,
      render: (text) => (
        <>
          {text?.amount} {text?.currency}
        </>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      align: "center",
      width: 200,
      render: (text) => (
        <>{moment.utc(text).local().format("MM/DD/YYYY hh:mm:A")}</>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      align: "center",
      width: 200,
      render: (text) => (
        <>{moment.utc(text).local().format("MM/DD/YYYY hh:mm:A")}</>
      ),
    },

    {
      title: "Payment",
      dataIndex: "payment_status",
      width: 130,
      align: "center",
      render: (text) => (
        <>
          {text === "COMPLETED" ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },
  ];

  return (
    <div>
      <Typography.Title level={2} style={{ textAlign: "left" }}>
        CARD INFO
      </Typography.Title>
      <Table
        style={{ marginTop: 10 }}
        columns={cardColumns}
        dataSource={cardList}
        scroll={{ x: 1, y: window.innerHeight - 280 }}
        size="middle"
        loading={loading}
        rowKey={(data) => data.id}
        pagination={{
          total: cardList?.length,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            setCurrentPageNumber(page);
          },
        }}
      />

      <Typography.Title level={2} style={{ textAlign: "left" }}>
        FAMILY MEMBERS INFO
      </Typography.Title>
      <Table
        style={{ marginTop: 10 }}
        columns={familyColumns}
        dataSource={familyMemberList}
        scroll={{ x: 1, y: window.innerHeight - 280 }}
        size="middle"
        loading={loading}
        rowKey={(data) => data.id}
        pagination={{
          total: familyMemberList?.length,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            setCurrentPageNumber(page);
          },
        }}
      />

      <Typography.Title level={2} style={{ textAlign: "left" }}>
        ASSIGNED INSTRUCTORS
      </Typography.Title>
      <Table
        style={{ marginTop: 10 }}
        columns={instructorColumns}
        dataSource={assignedInstructorList}
        scroll={{ x: 1, y: window.innerHeight - 280 }}
        size="middle"
        loading={loading}
        rowKey={(data) => data.id}
        pagination={{
          total: assignedInstructorList?.length,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            setCurrentPageNumber(page);
          },
        }}
      />

      <Typography.Title level={2} style={{ textAlign: "left" }}>
        Consent Forms
      </Typography.Title>
      <Table
        style={{ marginTop: 10 }}
        columns={consentFormColumns}
        dataSource={consentFormList}
        scroll={{ x: 1, y: window.innerHeight - 280 }}
        size="middle"
        loading={loading}
        rowKey={(data) => data.id}
        pagination={{
          total: consentFormList?.length,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            setCurrentPageNumber(page);
          },
        }}
      />

      <Typography.Title level={2} style={{ textAlign: "left" }}>
        Purchased Courses
      </Typography.Title>
      <Table
        style={{ marginTop: 10 }}
        columns={purchasedCourseColumns}
        dataSource={purchasedCourseList}
        scroll={{ x: 1, y: window.innerHeight - 280 }}
        size="middle"
        loading={loading}
        rowKey={(data) => data.id}
        pagination={{
          total: purchasedCourseList?.length,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            setCurrentPageNumber(page);
          },
        }}
      />

      <Modal
        title={"SELECT INSTRUCTOR"}
        open={visibleInstructorModal}
        footer={null}
        closable={true}
        onCancel={() => setvisibleInstructorModal(false)}
      >
        <SelectInstructorModal
          onSelectInstructor={onSelectInstructor}
          onSelectLocation={onSelectLocation}
          locationList={instructorDetail?.details?.instructor?.studio_locations}
        />
      </Modal>
    </div>
  );
}
