import { constant } from "../Constant";

// appointment
export const getAppointmentAction = ({
  page,
  limit,
  q,
  appointment_type,
  appointment_age_category,
  appointment_location,
  is_canceled,
  is_completed,
  is_payment,
  instructor_id,
  start_date,
  end_date,
  cancel_start_date,
  cancel_end_date,
  sort,
}) => {
  return {
    type: constant.GET_APPOINTMENT,
    page: page,
    limit: limit,
    q: q ?? null,
    appointment_type: appointment_type ?? null,
    appointment_age_category: appointment_age_category ?? null,
    appointment_location: appointment_location ?? null,
    is_canceled: is_canceled ?? null,
    is_completed: is_completed ?? null,
    is_payment: is_payment ?? null,
    start_date: start_date ?? null,
    end_date: end_date ?? null,
    cancel_start_date: cancel_start_date ?? null,
    cancel_end_date: cancel_end_date ?? null,
    instructor_id: instructor_id ?? null,
    sort: sort ?? null,
  };
};

// appointment
export const getAppointmentTypesAction = (
  page,
  limit,
  q = null,
  studio_location_id
) => {
  return {
    type: constant.GET_APPOINTMENT_TYPES,
    page: page,
    limit: limit,
    q: q,
    studio_location_id: studio_location_id,
  };
};
