// import UserCourseContentDetailModal from "../../components/modals/UserCourseContentDetailModal";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Col, Input, Row, Select, Space, Table, Typography } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "../../store";
const { Search } = Input;
const { Option } = Select;

const UserCourseContent = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.UserCourseContentReducer);
  const [searchedText, setsearchedText] = useState(null);
  const [orderValue, setorderValue] = useState(null);
  //modal
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  useEffect(() => {
    dispatch(ActionCreators.getUserCourseContentAction(1, 20));
  }, [dispatch]);

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    dispatch(
      ActionCreators.getUserCourseContentAction(
        page,
        20,
        searchedText,
        orderValue
      )
    );
  };

  const onSearchContent = useCallback(() => {
    setCurrentPageNumber(1);
    let action = ActionCreators.getUserCourseContentAction(
      1,
      20,
      searchedText ?? null,
      orderValue ?? null
    );
    dispatch(action);
    setCurrentPageNumber(1);
  }, [searchedText, orderValue, dispatch]);

  const onChangeOrderValue = useCallback(
    (value) => {
      setorderValue(value);
      setCurrentPageNumber(1);
      dispatch(
        ActionCreators.getUserCourseContentAction(
          1,
          20,
          searchedText ?? null,
          value ?? null
        )
      );
    },
    [searchedText, dispatch]
  );

  const columns = [
    {
      title: "User-Course",
      dataIndex: ["user", "course"],
      render: (text, record) => (
        <>
          {record?.user?.email} {record?.user?.email && '-'}  {record?.course?.title}
        </>
      ),
    },
    {
      title: "Course-Content",
      dataIndex: ["course", "course_content"],
      render: (text, record) => (
        <>
          {record?.course?.title} {record?.course?.title && '-'} {record?.course_content?.content?.title}{" "}
        </>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "is_finished",
      width: 130,
      align: "center",
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },

    {
      title: "Created at",
      dataIndex: "created_at",
      width: 130,
      align: "center",
      render: (text) => (
        <Space size="middle">
          {moment(text).utc().format("MM/DD/YYYY hh:mm:A")}
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            USER COURSE CONTENT
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL USER COURSE CONTENT : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: 5, marginBottom: 5 }}>
        <Col
          lg={12}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Select
            placeholder="Order by."
            size="large"
            value={orderValue}
            onChange={onChangeOrderValue}
          >
            <Option value="1">ASCE</Option>
            <Option value="-1">DESC</Option>
          </Select>
        </Col>
        <Col
          lg={12}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search by name or course name..."
            enterButton
            size="large"
            onChange={(e) => setsearchedText(e.target.value)}
            onSearch={onSearchContent}
          />
        </Col>
      </Row>

      <Table
        rowKey={(data) => data.id}
        columns={columns}
        dataSource={data.allUserCourseContent}
        size="middle"
        scroll={{ x: 1, y: window.innerHeight - 310 }}
        loading={data.loading}
        pagination={{
          total: data.count,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
    </React.Fragment>
  );
};

export default UserCourseContent;
