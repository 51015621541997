import { DownOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Layout,
  Menu,
  message,
  Modal,
  Row,
  Space
} from "antd";
import "antd/dist/antd.min.css";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import "./App.css";
import ResetPasswordModal from "./components/modals/ResetPasswordModal";
import Sidebar from "./components/sidebar";
import Navigations from "./routes";
import { ActionCreators } from "./store";
import { AuthApi } from "./store/api";
import { SQUARE_URL } from "./store/api/Config";
// import { refreshToken } from "./store/api/utils/AxiosConfig";
const { Header, Sider, Content } = Layout;

// const { Title, Text } = Typography;

function App() {
  const dispatch = useDispatch();
  const { logOutAction } = bindActionCreators(ActionCreators, dispatch); //call action
  const isAuthenticated = useSelector((state) => state.AuthReducer.loginStatus);
  const me = useSelector((state) => state.MeReducer?.response);
  let navigate = useNavigate();

  const [isLogoutModalVisible, setisLogoutModalVisible] = useState(false);
  // const [me, setme] = useState({});
  const [visibleResetPassModal, setvisibleResetPassModal] = useState(false);
  const [modalLoading, setmodalLoading] = useState(false);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = SQUARE_URL;
    script.async = true;

    document.body.appendChild(script);
  }, []);

  function getItem(label, key, children) {
    return {
      key,
      children,
      label,
    };
  }

  const RenderButton = ({ text, item }) => {
    return (
      <Link to={`/${item}`}>
        <Button
          type="link"
          style={{ color: "white", padding: 0, fontSize: 12.5 }}
        >
          {text}
        </Button>
      </Link>
    );
  };

  // const [isNewAndConfirmPassMatched, setisNewAndConfirmPassMatched] =
  //   useState(false);

  const [navItem, setNavItem] = useState([
    getItem("User Management", "userManagement", [
      getItem(<RenderButton text="Users" item="users" />, "users"),
      getItem(
        <RenderButton text="Instructors" item="instructors" />,
        "instructors"
      ),
      getItem(
        <RenderButton text="Subscribers" item="subscribers" />,
        "subscribers"
      ),
    ]),
    getItem("User Activity", "userActivity", [
      getItem(
        <RenderButton text="Daily Dive" item="daily_dive" />,
        "daily_dive"
      ),
      getItem(<RenderButton text="Reminder" item="reminder" />, "reminder"),
      getItem(
        <RenderButton text="Notification" item="notification" />,
        "notification"
      ),
      getItem(<RenderButton text="Favourite" item="favourite" />, "favourite"),
      getItem(
        <RenderButton text="User Chapter" item="user_chapter" />,
        "user_chapter"
      ),
      getItem(
        <RenderButton text="User Course" item="user_course" />,
        "user_course"
      ),
      getItem(
        <RenderButton text="User Content" item="user_content" />,
        "user_content"
      ),
      getItem(
        <RenderButton text="User Course Content" item="user_course_content" />,
        "user_course_content"
      ),
      getItem(
        <RenderButton text="User Course Review" item="user_course_review" />,
        "user_course_review"
      ),
      getItem(
        <RenderButton text="Requested Location" item="requested-location" />,
        "requested-location"
      ),
    ]),
    getItem("Content", "menuContent", [
      getItem(<RenderButton text="Content" item="content" />, "content"),
      getItem(<RenderButton text="Course" item="course" />, "course"),
    ]),
    getItem("Booking Management", "booking_management", [
      getItem(
        <RenderButton text="Appointment" item="appointment" />,
        "appointment"
      ),

      getItem(
        <RenderButton text="Appointment Types" item="appointment_types" />,
        "appointment_types"
      ),
      getItem(
        <RenderButton text="Studio Location" item="location" />,
        "location"
      ),
      getItem(
        <RenderButton text="Consent Form" item="consent_form" />,
        "consent_form"
      ),
      getItem(
        <RenderButton
          text="Appointment Policies"
          item="appointment_policies"
        />,
        "appointment_policies"
      ),
      getItem(
        <RenderButton
          text="Matching Questionnaires"
          item="user-submitted-form"
        />,
        "user-submitted-form"
      ),
    ]),
    getItem("Content Configuration", "content_configuration", [
      getItem(<RenderButton text="Feeling" item="feeling" />, "feeling"),
      getItem(<RenderButton text="Scaling" item="scaling" />, "scaling"),
      getItem(<RenderButton text="Mood" item="mood" />, "mood"),
      getItem(<RenderButton text="Tag" item="tag" />, "tag"),
      getItem(
        <RenderButton text="Transition Screen" item="overview" />,
        "overview"
      ),
      getItem(<RenderButton text="Category" item="category" />, "category"),
      getItem(<RenderButton text="Interval" item="interval" />, "interval"),
      getItem(<RenderButton text="Benefit" item="benefit" />, "benefit"),
    ]),
    getItem("Site Configuration", "site_configuration", [
      getItem(<RenderButton text="Settings" item="settings" />, "settings"),
      getItem(
        <RenderButton text="Support Info" item="support_info" />,
        "support_info"
      ),
      // getItem(
      //   <RenderButton text="Permission" item="permission_settings" />,
      //   "permission_settings"
      // ),
      // getItem(
      //   <RenderButton text="Secret Keys" item="secret_keys" />,
      //   "secret_keys"
      // ),
    ]),
    getItem("Dives", "dives", [
      getItem(
        <RenderButton text="Solo Dive Overview" item="solo_dive_overview" />,
        "solo_dive_overview"
      ),
      getItem(
        <RenderButton text="Solo Dives" item="solo_dives" />,
        "solo_dives"
      ),
    ]),
    getItem("Report", "report", [
      getItem(
        <RenderButton text="User Report" item="user_report" />,
        "user_report"
      ),
      getItem(
        <RenderButton
          text="Premium Content Report"
          item="premium_content_report"
        />,
        "premium_content_report"
      ),
    ]),
    getItem("Questionaries", "Questionaries", [
      getItem(
        <RenderButton text="Identifier" item="identifier" />,
        "identifier"
      ),

      getItem(
        <RenderButton text="Form Groups" item="form-groups" />,
        "form-groups"
      ),
    ]),
    getItem("Logs", "Logs", [
      getItem(
        <RenderButton text="Appointments Log" item="appointments-log" />,
        "appointments-log"
      ),
      // getItem(
      //   <RenderButton
      //     text="Appointments Log Details"
      //     item="appointments-log-details"
      //   />,
      //   "appointments-log-details"
      // ),
    ]),
  ]);

  useEffect(() => {
    setloading(true);
    if (me?.permissions?.length > 0) {
      for (var m of me?.permissions) {
        if (m.type === "USER") {
          m.type = "users";
        }
        if (m.type === "INSTRUCTOR") {
          m.type = "instructors";
        }
        if (m.type === "USER_DIVE") {
          m.type = "daily_dive";
        }
        if (m.type === "REMINDER") {
          m.type = "reminder";
        }
        if (m.type === "NOTIFICATION") {
          m.type = "notification";
        }
        if (m.type === "COURSE") {
          m.type = "course";
        }
        if (m.type === "CONTENT") {
          m.type = "content";
        }
        if (m.type === "FAVOURITE") {
          m.type = "favourite";
        }
        if (m.type === "SOLO_DIVE") {
          m.type = "dives";
        }
        if (m.type === "CONTENT_CONFIGURATION") {
          m.type = "content_configuration";
        }
        if (m.type === "SITE_CONFIGURATION") {
          m.type = "site_configuration";
        }
        if (m.type === "SUBSCRIBER") {
          m.type = "subscribers";
        }
        if (m.type === "USER_CONTENT") {
          m.type = "user_content";
        }
        if (m.type === "QUESTIONARIES") {
          m.type = "Questionaries";
        }
        if (m.type === "FORM_IDENTIFIER") {
          m.type = "identifier";
        }
        if (m.type === "FORM_GROUP") {
          m.type = "form-groups";
        }
        if (m.type === "APPOINTMENT_EVENT") {
          m.type = "appointments-log";
        }

        if (m.type === "APPOINTMENT") {
          m.type = "appointment";
        }
        if (m.type === "APPOINTMENT_TYPE") {
          m.type = "appointment_types";
        }
        if (m.type === "STUDIO_LOCATION") {
          m.type = "location";
        }
        if (m.type === "REQUESTED_STUDIO_LOCATION") {
          m.type = "requested-location";
        }
      }
      let temp = navItem;
      let filteredArray = [];
      let filteredChildArray = [];
      temp.map((t, index) => {
        // console.log('usama parent',t);
        t?.children.map((c, index) => {
          // console.log('usama child',c);
          me?.permissions.map((m, index) => {
            // console.log('usama permission',m);
            if (m?.type === c?.key) {
              filteredChildArray = [...filteredChildArray, c];
            } else if (
              m?.type === "content_configuration" &&
              t?.key === "content_configuration"
            ) {
              filteredChildArray = [...filteredChildArray, c];
            } else if (
              m?.type === "site_configuration" &&
              t?.key === "site_configuration"
            ) {
              filteredChildArray = [...filteredChildArray, c];
            } else if (m?.type === "dives" && t?.key === "dives") {
              filteredChildArray = [...filteredChildArray, c];
            } else if (m?.type === "USER_COURSE") {
              if (
                c?.key === "user_course" ||
                c?.key === "user_course_content" ||
                c?.key === "user_course_review"
              ) {
                filteredChildArray = [...filteredChildArray, c];
              }
            } else if (m?.type === "REPORT" && t?.key === "report") {
              filteredChildArray = [...filteredChildArray, c];
            }
          });
        });
        let obj = {
          key: t.key,
          label: t.label,
          children: filteredChildArray,
        };
        filteredArray = [...filteredArray, obj];
        filteredChildArray = [];
      });

      filteredArray = filteredArray.filter((f) => f.children.length > 0);
      setNavItem(filteredArray);
      navigate("/" + filteredArray?.[0]?.children?.[0]?.key);
      setloading(false);
    } else {
      if (me?.permissions) {
        setloading(false);
      }
    }
  }, [me]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(ActionCreators.getMeAction(1, 20));
    }
  }, [isAuthenticated, dispatch]);

  const onPressLogout = () => {
    setisLogoutModalVisible(true);
  };

  const onSubmitResetPassword = async (value) => {
    setmodalLoading(true);

    let payload = {
      current_password: value.current_password,
      new_password: value.new_password,
    };

    const response = await AuthApi.resetPassword(payload);
    if (response.status === 200) {
      message.success(response?.data?.message);
      setvisibleResetPassModal(false);
      setmodalLoading(false);
    } else {
      message.error(response?.data?.detail);
      setmodalLoading(false);
    }
  };

  const handleMenuClick = (e) => {
    if (e.key === "1") {
      setvisibleResetPassModal(true);
    } else {
      onPressLogout();
    }
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: "RESET PASSWORD",
          key: "1",
        },
        {
          label: "LOGOUT",
          key: "2",
        },
      ]}
    />
  );

  return (
    <div className="App">
      <Layout
        style={{ minHeight: window.innerHeight, minWidth: window.innerWidth }}
      >
        {isAuthenticated && (
          <Header
            style={{
              color: "#fff",
            }}
          >
            <Row>
              <Col span={14}>
                <Space style={{ float: "right" }}>
                  <img
                    src={require("./static/icon.png")}
                    style={{ height: 30, width: 30, marginRight: 5 }}
                    alt=""
                  />
                  WELCOME TO DIVETHRU ADMIN
                </Space>
              </Col>
              <Col span={10}>
                <Space style={{ float: "right" }}>
                  <Dropdown overlay={menu}>
                    <Button type="text" style={{ color: "white" }}>
                      {me?.email} <DownOutlined />
                    </Button>
                  </Dropdown>
                </Space>
              </Col>
            </Row>
          </Header>
        )}
        <Layout>
          {isAuthenticated && (
            <Sider breakpoint="lg" collapsedWidth="0" width={210}>
              <Sidebar
                me={me}
                menu={navItem}
                loading={loading}
                onPressLogout={onPressLogout}
              />
            </Sider>
          )}

          <Content className="content">
            <>
              <Modal
                title="CONFIRMATION!"
                open={isLogoutModalVisible}
                onOk={() => {
                  setisLogoutModalVisible(false);
                  setTimeout(() => {
                    logOutAction();
                  }, 500);
                }}
                onCancel={() => {
                  setisLogoutModalVisible(false);
                }}
                okText={"YES"}
              >
                <p>ARE YOU SURE YOU WANT TO LOGOUT?</p>
              </Modal>

              <Modal
                title={"RESET PASSWORD"}
                open={visibleResetPassModal}
                footer={null}
                closable={false}
              >
                <ResetPasswordModal
                  setvisibleResetPassModal={setvisibleResetPassModal}
                  onSubmitResetPassword={onSubmitResetPassword}
                  // isNewAndConfirmPassMatched={isNewAndConfirmPassMatched}
                  // setisNewAndConfirmPassMatched={setisNewAndConfirmPassMatched}
                  modalLoading={modalLoading}
                />
              </Modal>
              <Navigations />
            </>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default App;
