/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class AuthApi {
  static async refreshToken(payload) {
    const response = await http.post("/update-access-token", payload);
    return response;
  }

  static async getMe() {
    const response = await http.get("api/v3/me");
    return response;
  }

  static async resetPassword(payload) {
    const response = await http.post("api/admin/reset-password",payload);
    return response;
  }
}
