import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Col, Input, Row, Space, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { UserActivityApi } from "../../store/api";

const { Search } = Input;

const UserChapter = () => {
  const { Title } = Typography;
  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchApiData = async (params) => {
    setLoading(true);
    const res = await UserActivityApi.getAllUserChapter(params);
    console.log(res);
    if (res.status === 200) {
      setCount(res.data.count);
      setData(res.data.results);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApiData({ page: 1, limit: 20 });
  }, []);

  const columns = [
    {
      title: "CHAPTER",
      dataIndex: "chapter",
      width: 180,
      textWrap: "word-break",
      render: (value) => <Space>{value?.title}</Space>,
    },
    {
      title: "COURSE TITLE",
      dataIndex: "course",
      width: 200,
      textWrap: "word-break",
      render: (value) => <Space>{value?.title}</Space>,
    },
    {
      title: "USER",
      dataIndex: "user",
      ellipsis: true,
      width: 180,
      render: (value) => <Space>{value?.email}</Space>,
    },
    {
      title: "IS FINISHED",
      dataIndex: "is_finished",
      align: "center",
      width: 100,
      render: (value) =>
        value ? (
          <CheckCircleFilled style={{ color: "#22c55e" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#ef4444" }} />
        ),
    },
    {
      title: "IMAGE",
      dataIndex: "course",
      align: "center",
      width: 100,
      render: (text) => (
        <Space size="middle">
          <img
            src={text.cover_image}
            alt="N/A"
            style={{ height: 70, width: 90 }}
          />
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            USER CHAPTER
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL CHAPTER : <strong>{count}</strong>
          </p>
          <Search
            placeholder="Search here..."
            allowClear
            size="medium"
            onSearch={(value) => fetchApiData({ page: 1, limit: 20, q: value })}
            style={{ width: 300, marginBottom: 10 }}
          />
        </Col>
      </Row>
      <Table
        rowKey={(data) => data.id}
        columns={columns}
        dataSource={data}
        scroll={{ x: 1, y: window.innerHeight - 275 }}
        size="middle"
        loading={loading}
        pagination={{
          showSizeChanger: false,
          total: count,
          pageSize: 20,
          onChange: (page) => {
            fetchApiData({ page: page, limit: 20 });
          },
        }}
      />
    </React.Fragment>
  );
};

export default UserChapter;
