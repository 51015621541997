import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import thunk from "redux-thunk";
import reducers from "./reducer";
import SagaActions from "./saga-actions";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const sagaMiddleWare = createSagaMiddleware();

const middleware = [sagaMiddleWare, thunk];

const persistConfig = {
  key: "root",
  storage,
};

if (process.env.NODE_ENV === "development") {
  middleware.push(logger);
}

const persistedReducer = persistReducer(persistConfig, reducers);

let store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

const persistor = persistStore(store);

sagaMiddleWare.run(SagaActions);

export { store, persistor };
