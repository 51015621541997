/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class ReminderApi {
  static async getReminders(payload) {
    const response = await http.get("/api/admin/reminder", payload);
    return response;
  }
  static async getReminderDetails(payload) {
    const response = await http.get(`/api/admin/reminder/${payload.id}`);
    return response;
  }
  static async deleteSingleReminder(id) {
    const response = await http.del(`/api/admin/reminder/${id}`);
    return response;
  }

  static async editReminder(payload, id) {
    const response = await http.put(`/api/admin/reminder/${id}`, payload);
    return response;
  }

  static async addReminder(payload) {
    const response = await http.post("/api/admin/reminder", payload);
    return response;
  }
}
