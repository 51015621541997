import React, { useCallback } from "react";
// import styles from "./header.module.css";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { ActionCreators, persistor } from "../../store";

const Header = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { logOutAction } = bindActionCreators(ActionCreators, dispatch); //call action

  const logOutPress = useCallback(() => {
    logOutAction();
    persistor.purge();
  }, [logOutAction]);

  const subscribePress = useCallback(() => {
    navigate("/subscriber");
  }, [navigate]);

  const usersPress = useCallback(() => {
    navigate("/user");
  }, [navigate]);

  const instructorsPress = useCallback(() => {
    navigate("/instructor");
  }, [navigate]);

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand onClick={() => navigate("/dashboard")}>
          DiveThru App Management
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="Users Management" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={usersPress}>Users</NavDropdown.Item>
              <NavDropdown.Item onClick={instructorsPress}>
                Instructors
              </NavDropdown.Item>
              <NavDropdown.Item onClick={subscribePress}>
                Subscribers
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Content" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => navigate("/content")}>
                Content
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/feeling")}>
                Feeling
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/tag")}>
                Tag
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/scaling")}>
                Scaling
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/mood")}>
                Mood
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Configuration" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => navigate("/category")}>
                Category
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/interval")}>
                Time Interval
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/benefit")}>
                Benefit
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/reminder")}>
                Reminder
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Daily Dive" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => navigate("/daily_dive")}>
                Daily Dive
              </NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link onClick={usersPress}>Users</Nav.Link> */}
            <Nav.Link onClick={logOutPress}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
