import { constant } from "../Constant";

// category
export const getCategoriesAction = (page, limit, q) => {
  return {
    type: constant.GET_CATEGORIES,
    page: page,
    limit: limit,
    q: q,
  };
};
export const getCategoryDetailsAction = (id) => {
  return {
    type: constant.GET_CATEGORY_DETAILS,
    id: id,
  };
};

// interval
export const getIntervalsAction = (page, limit) => {
  return {
    type: constant.GET_INTERVALS,
    page: page,
    limit: limit,
  };
};
export const getIntervalDetailsAction = (id) => {
  return {
    type: constant.GET_INTERVAL_DETAILS,
    id: id,
  };
};

// benefit
export const getBenefitsAction = (page, limit, q) => {
  return {
    type: constant.GET_BENEFITS,
    page: page,
    limit: limit,
    q: q,
  };
};
export const getBenefitDetailsAction = (id) => {
  return {
    type: constant.GET_BENEFIT_DETAILS,
    id: id,
  };
};

// reminder
export const getRemindersAction = (page, limit) => {
  return {
    type: constant.GET_REMINDERS,
    page: page,
    limit: limit,
  };
};
export const getReminderDetailsAction = (id) => {
  return {
    type: constant.GET_REMINDER_DETAILS,
    id: id,
  };
};
