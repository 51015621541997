import { put } from "redux-saga/effects";
import { constant } from "../Constant";
import { ContentApi } from "../api";
// workers
function* getContentsAction(payload) {
  try {
    const res = yield ContentApi.getAllContent({
      params: payload.params,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_CONTENTS_SUCCESS,
        contents: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_CONTENTS_FAILED,
        errMsg: "Invalid content",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_CONTENTS_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
function* getContentDetailsAction(param) {
  try {
    const res = yield ContentApi.getContentDetails(param);
    if (res.status === 200) {
      const payload = {
        type: constant.GET_CONTENT_DETAILS_SUCCESS,
        details: res.data,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_CONTENTS_FAILED,
        errMsg: "Invalid content",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_CONTENTS_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
export { getContentsAction, getContentDetailsAction };
