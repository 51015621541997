import { constant } from "../Constant";

const initialState = {
  loading: true,
  categories: [],
  count:0,
  errMsg: "",
  categoriesDetails: [],
};

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_CATEGORIES:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_CATEGORY_DETAILS:
      return {
        ...state,
        id: action.id,
        loading: true,
      };
    case constant.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.categories,
        count:action.count,
        errMsg: "",
      };
    case constant.GET_CATEGORY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryDetails: action.categoryDetails,
        errMsg: "",
      };
    case constant.GET_CATEGORIES_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    case constant.CLEAN_CATEGORIES:
      return {
        ...state,
        loading: false,
        categories: [],
        count:0,
        errMsg: "",
      };
    default:
      return state;
  }
};

export default CategoryReducer;
