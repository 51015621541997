import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Image,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AddEditChapterModal from "../../components/modals/AddChapterModal";
import AddEditCourseContentModal from "../../components/modals/AddEditCourseContentModal";
import DeleteItemConfirmationModal from "../../components/modals/DeleteItemConfirmationModal";
import { CourseApi } from "../../store/api";
import { CustomTable, TitleView } from "./TableColumn";

const { Title } = Typography;

const CourseDetails = () => {
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  //modal add course
  const [visibleAddChapterModal, setvisibleAddChapterModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [modalLoading, setmodalLoading] = useState(false);

  //content modal control
  const [contentAddModal, setContentAddModal] = useState(false);
  const [contentEditModal, setContentEditModal] = useState(false);
  const [contentRecord, setContentRecord] = useState({});

  const [deletePopupData, setdeletePopupData] = useState({});
  const [showDeleteConfirmModal, setshowDeleteConfirmModal] = useState(false);
  const [deleteId, setdeleteId] = useState("");
  const [deleteName, setdeleteName] = useState("");
  const [deleteChapterId, setdeleteChapterId] = useState("");
  const [isChapterDelete, setisChapterDelete] = useState(false);

  const me = useSelector((state) => state.MeReducer?.response);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex((item) => item?.type === "course");
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  useEffect(() => {
    async function fetchData() {
      setLoader(true);
      const res = await CourseApi.getCourseDetails(state.courseId);
      if (res.status === 200) {
        setData(res.data);
        setLoader(false);
      } else {
        console.log("Error while fetch course details data!");
        setLoader(false);
      }
    }
    fetchData();
  }, [state]);

  //chapter edit button click
  const editButtonClick = useCallback(async (param) => {
    setUpdateModal(true);
    setEditData(param);
  }, []);

  //onsubmit add chapter
  const onSubmitAddChapterModal = async (callBackValue) => {
    setmodalLoading(true);
    const res = updateModal
      ? await CourseApi.updateChapter(data.id, editData.id, callBackValue)
      : await CourseApi.addChapter(data.id, callBackValue);
    if (res.status === 200) {
      fetchMyAPI();
      setmodalLoading(false);
      setvisibleAddChapterModal(false);
      setUpdateModal(false);
    } else {
      console.log("Error in chapter submit api", res);
      setmodalLoading(false);
      setvisibleAddChapterModal(false);
      setUpdateModal(false);
    }
  };

  const deleteChapterPress = async (params) => {
    setdeleteName(params.title);
    setisChapterDelete(true);
    setmodalLoading(true);
    // let type = params.type.toLowerCase();
    const res = await CourseApi.deleteSingleChapter(data.id, params.id, false);
    if (res.status === 200) {
      setdeletePopupData(res.data);
      setdeleteId(params.id);
      setshowDeleteConfirmModal(true);
    }
    setmodalLoading(false);
  };

  const deleteChapterConfirm = async () => {
    setmodalLoading(true);
    // let type = params.type.toLowerCase();
    const res = await CourseApi.deleteSingleChapter(data.id, deleteId, "true");
    if (res.status === 200) {
      setshowDeleteConfirmModal(false);
      message.success(res.data.message);
      fetchMyAPI();
    } else {
      message.error(res.data.message);
    }
    setmodalLoading(false);
  };

  const contentDeletePress = async (param) => {
    setdeleteName(param.title);
    setLoader(true);
    setisChapterDelete(false);
    const res = await CourseApi.deleteSingleContent(
      data.id,
      param.chapter,
      param.id,
      false
    );
    if (res.status === 200) {
      setdeletePopupData(res.data);
      setdeleteId(param.id);
      setdeleteChapterId(param.chapter);
      setshowDeleteConfirmModal(true);
    } else {
      console.log("Error in content delete api", res);
      setLoader(false);
    }
  };

  const deleteContentConfirm = async () => {
    setmodalLoading(true);
    // let type = params.type.toLowerCase();
    const res = await CourseApi.deleteSingleContent(
      data.id,
      deleteChapterId,
      deleteId,
      true
    );
    if (res.status === 200) {
      setshowDeleteConfirmModal(false);
      message.success(res.data.message);
      fetchMyAPI();
    } else {
      message.error(res.data.message);
    }
    setmodalLoading(false);
  };

  //comtent add click
  const contentAddClick = async (param) => {
    let chapterId = {
      chapter: param.id,
    };
    setContentRecord(chapterId);
    setContentAddModal(true);
  };

  //content button delete

  //content edit press
  const contentEditPress = (param) => {
    setContentRecord(param);
    setContentEditModal(true);
  };

  //submit content form
  const contentFormSubmit = async (values) => {
    setmodalLoading(true);

    if (values.order) values["order"] = parseInt(values?.order);
    else delete values.order;

    if (contentRecord?.content?.id) {
      values["content"] = contentRecord?.content?.id ?? null;
    }
    const res = contentEditModal
      ? await CourseApi.editContent(
          data.id,
          contentRecord.chapter,
          contentRecord.id,
          values
        )
      : await CourseApi.addContent(data.id, contentRecord.chapter, values);

    if (res.status === 200) {
      fetchMyAPI();
      setmodalLoading(false);
      setContentEditModal(false);
      setContentAddModal(false);
      setContentRecord({});
    } else {
      console.log("Error in chapter submit api", res);
      setmodalLoading(false);
      setContentEditModal(false);
      setContentAddModal(false);
      setContentRecord({});
    }
  };

  //load total page data
  async function fetchMyAPI() {
    setLoader(true);
    const res = await CourseApi.getCourseDetails(state.courseId);
    if (res.status === 200) {
      setData(res.data);
      setLoader(false);
    } else {
      console.log("Error while fetch course details data!");
      setLoader(false);
    }
  }

  //chapter => content table (nested view/table)
  const expandedRowRender = (record) => {
    // console.log("ecord.course_contents", record.course_contents);
    return (
      <Table
        className="nestedTable"
        columns={contentColumns}
        dataSource={record.course_contents}
        pagination={false}
        rowKey={(data) => data.id}
      />
    );
  };

  //Chapter column
  const chapterColumn = [
    // {
    //   title: "id",
    //   dataIndex: "id",
    // },
    {
      title: "TITLE",
      dataIndex: "title",
      render: (text, record) => (
        <Space size="middle">{text ? text : "Not Available"}</Space>
      ),
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      render: (text, record) => (
        <Space size="middle">{text ? text : "Not Available"}</Space>
      ),
    },
    {
      title: "ADDED BY",
      dataIndex: "added_by",
    },
    {
      title: "ORDER",
      dataIndex: "order",
      render: (text) => (
        <>
          {text !== null && text.toString() !== "9999999999" ? (
            <>{text}</>
          ) : (
            "N/A"
          )}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "course_contents",
      key: "name",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          {showCreateUpdate && (
            <>
              <Button
                style={{ backgroundColor: "#53ABA0", color: "#fff" }}
                shape="round"
                onClick={() => contentAddClick(record)}
              >
                Add Content
              </Button>

              <Button
                type="primary"
                shape="round"
                onClick={() => editButtonClick(record)}
              >
                Edit
              </Button>
            </>
          )}
          {showDelete && (
            <Button
              type="danger"
              shape="round"
              onClick={() => deleteChapterPress(record)}
            >
              Delete
            </Button>
          )}
        </Space>
      ),
    },
  ];

  //content column
  const contentColumns = [
    // {
    //   title: "CONTENT ID",
    //   dataIndex: "id",
    // },
    {
      title: "TITLE",
      dataIndex: "content",
      render: (text, record) => <Space>{text?.title ?? "Not Available"}</Space>,
    },
    {
      title: "CONTENT DESCRIPTION",
      dataIndex: "description",
      render: (text, record) => (
        <Space size="middle">
          {record.description === undefined || record.description === ""
            ? "Not Available"
            : record.description}
        </Space>
      ),
    },
    {
      title: "TYPE",
      dataIndex: "content",
      align: "left",
      render: (text, record) => <Space>{text?.type}</Space>,
    },

    {
      title: "FILE PATH",
      dataIndex: "content",
      width: 100,
      align: "center",
      render: (text, record) => {
        return text?.file_path && text?.file_path !== null ? (
          <Space>
            <a
              href={text?.file_path}
              target={"_blank"}
              style={{ textDecoration: "none" }}
              rel="noreferrer"
            >
              SHOW_VIDEO
            </a>
          </Space>
        ) : text?.audio_path && text?.audio_path !== null ? (
          <Space>
            <a
              href={text?.audio_path}
              target={"_blank"}
              style={{ textDecoration: "none" }}
              rel="noreferrer"
            >
              LISTEN_AUDIO
            </a>
          </Space>
        ) : (
          <Image width={130} height={80} src={text?.bg_path} />
        );
      },
    },
    {
      title: "IS PREMIUM",
      dataIndex: "content",
      align: "center",
      render: (text) => (
        <>
          {text?.is_premium ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },
    {
      title: "IS FEATURE",
      dataIndex: "content",
      align: "center",
      render: (text) => (
        <>
          {text?.is_feature ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },
    {
      title: "IS PUBLISHED",
      dataIndex: "content",
      align: "center",
      render: (text) => (
        <>
          {text?.is_published ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "left",
      // width: "20%",
      render: (text, record) => (
        <Space size="middle">
          {showCreateUpdate && (
            <Button
              type="primary"
              shape="round"
              onClick={() => contentEditPress(record)}
            >
              Edit
            </Button>
          )}

          {showDelete && (
            <Button
              type="danger"
              shape="round"
              onClick={() => contentDeletePress(record)}
            >
              Delete
            </Button>
          )}
          {/* <Popconfirm
            title="Are you sure to delete this Content?"
            onConfirm={() => contentDeletePress(record)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      {loader && (
        <Spin
          style={{
            position: "absolute",
            top: "50%",
            left: "60%",
            zIndex: 1,
          }}
          size="large"
        />
      )}

      <TitleView data={data} />
      <CustomTable title="Benefits: " data={data?.benefits} />
      <CustomTable title="Categories: " data={data?.categories} />

      <Row style={{ marginTop: 10 }}>
        <Title level={5}>Chapter:</Title>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          {showCreateUpdate && (
            <Button
              icon={<PlusCircleOutlined />}
              size={"large"}
              type="primary"
              shape="round"
              onClick={() => setvisibleAddChapterModal(true)}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              ADD CHAPTER
            </Button>
          )}
        </Col>
      </Row>
      <Table
        rowKey={(data) => data.id}
        columns={chapterColumn}
        dataSource={data?.chapters ?? []}
        scroll={{ x: 1 }}
        expandedRowRender={expandedRowRender}
        className="components-table-demo-nested"
        expandIconColumnIndex={4}
        loading={loader}
        expandIcon={({ expanded, onExpand, record }) =>
          !expanded ? (
            <Button
              style={{ float: "right" }}
              shape="round"
              onClick={(e) => onExpand(record, e)}
            >
              Show Content
            </Button>
          ) : (
            <Button shape="round" onClick={(e) => onExpand(record, e)}>
              Hide Content
            </Button>
          )
        }
        pagination={{
          total: data?.chapters?.length,
          pageSize: 10,
          showSizeChanger: false,
          onChange: (page) => {},
        }}
      />
      {/* // add chapter modal */}
      <Modal
        title={updateModal ? "UPDATE CHAPTER" : "ADD CHAPTER"}
        open={updateModal ? updateModal : visibleAddChapterModal}
        footer={null}
        closable={false}
      >
        <AddEditChapterModal
          visibleAddChapterModal={
            updateModal ? setUpdateModal : setvisibleAddChapterModal
          }
          onSubmitAddChapterModal={onSubmitAddChapterModal}
          modalLoading={modalLoading}
          data={editData}
        />
      </Modal>

      {/* //add content modal */}
      <Modal
        title={contentEditModal ? "UPDATE CONTENT" : "ADD CONTENT"}
        open={contentEditModal ? contentEditModal : contentAddModal}
        footer={null}
        closable={false}
      >
        <AddEditCourseContentModal
          visibleAddContentModal={
            contentEditModal ? setContentEditModal : setContentAddModal
          }
          onSubmitAddContentModal={contentFormSubmit}
          modalLoading={modalLoading}
          data={contentRecord}
        />
      </Modal>

      <Modal
        title={`${deleteName}`}
        open={showDeleteConfirmModal}
        // footer={null}
        closable={false}
        width={1000}
        okText="DELETE"
        onOk={isChapterDelete ? deleteChapterConfirm : deleteContentConfirm}
        okButtonProps={{
          type: "danger",
          shape: "round",
        }}
        cancelButtonProps={{
          shape: "round",
        }}
        onCancel={() => setshowDeleteConfirmModal(false)}
      >
        <DeleteItemConfirmationModal
          deletePopupData={deletePopupData}
          modalLoading={modalLoading}
        />
      </Modal>
    </React.Fragment>
  );
};

export default CourseDetails;
