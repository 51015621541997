import { put } from "redux-saga/effects";
import { UserApi } from "../api";
import { constant } from "../Constant";
// workers
function* getAllUserAction(param) {
  try {
    const res = yield UserApi.getAllUser({
      page: param.page,
      limit: param.limit,
      membership: param.membership,
      q: param.q,
      is_active: param.is_active,
      role: param.role,
      sort: param.sort,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_USERS_SUCCESS,
        allUsers: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_USERS_FAILED,
        errMsg: "Invalid number",
      };
      yield put(payload);
    }
  } catch (error) {
    // if (error.response) {
    const payload = {
      type: constant.GET_USERS_FAILED,
      errMsg: error.data.detail,
    };
    yield put(payload);
    // }
  }
}

export { getAllUserAction };
