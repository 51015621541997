import { constant } from "../Constant";

const initialState = {
  loading: true,
  course: [],
  errMsg: "",
  count: 0,
};

const CourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_COURSE:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        course: action.course,
        errMsg: "",
        count: action.count,
      };
    case constant.GET_COURSE_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg ?? "Failed to load course data",
        count: 0,
      };
    default:
      return state;
  }
};

export default CourseReducer;
