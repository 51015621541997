import {
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    message,
    Row,
    Select,
    Space,
    Table
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { AppointmentApi } from "../../store/api";

const AddOrEditGroupMemberModal = ({
  detailData,
  key,
  getAppointmentDetail,
}) => {
  const [form] = Form.useForm();

  const [userRelativeList, setuserRelativeList] = useState([]);
  const [selectedClient, setselectedClient] = useState();
  const [showAddNewClientForm, setshowAddNewClientForm] = useState(false);
  const [is_old, setis_old] = useState(true);

  useEffect(() => {
    getUserRelatives();

    return () => {};
  }, []);

  const getUserRelatives = async () => {
    const res = await AppointmentApi.getUserRelatives({
      page: 1,
      limit: 100,
      user_id: detailData?.user?.id,
    });

    if (res?.status === 200) {
      console.log(res?.data);
      setuserRelativeList(res?.data?.results);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "user",
      render: (text) => (
        <>
          {text?.first_name ? `${text?.first_name} ${text?.last_name}` : "N/A"}
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "user",
      render: (text) => <>{text?.email ?? "N/A"}</>,
    },
    {
      title: "Cliniko Patient Id",
      dataIndex: "cliniko_patient_id",
      render: (text) => <>{text ?? "N/A"}</>,
    },
    {
      title: "Action",
      dataIndex: "",
      align: "center",
      render: (record) => (
        <Button
          shape="round"
          type="danger"
          disabled={record?.user?.id === detailData?.user?.id}
          onClick={() => {
            onClickRemoveClient(record?.user?.id);
          }}
        >
          Remove
        </Button>
      ),
    },
  ];

  const onSubmitAddNewClient = async (values) => {
    if (
      form.getFieldsError().filter((item) => item?.errors?.length > 0).length >
      0
    ) {
      // alert('cannot submit')
      message.error("Please fill up the form");
    } else {
      let payload = {
        ...values,
        owner: detailData?.user?.id,
        date_of_birth: moment(values?.date_of_birth).format("YYYY-MM-DD"),
      };
      const response = await AppointmentApi.addAnotherPatient(payload);
      if (response?.status === 201) {
        message.success("Client successfully created.");
        getUserRelatives();
        setshowAddNewClientForm(false);
      } else {
        message.error(response?.data?.detail);
      }
    }
  };

  const onPressConfirm = async () => {
    const index = detailData?.group_patients?.findIndex(
      (item) => item?.user?.id === selectedClient?.user
    );
    if (index > -1) {
      message.info("Client already added.");
      return;
    }

    const res = await AppointmentApi.addPatientToAnAppointment(detailData?.id, {
      user_relation_id: selectedClient?.id,
    });

    if (res?.status === 201 || res?.status === 200) {
      message.success("Client successfully added.");
      getAppointmentDetail();
      setselectedClient();
    } else {
      message.error(res?.data?.detail);
    }
  };

  const onClickRemoveClient = async (id) => {
    const res = await AppointmentApi.removeClientFromAppoinment(
      detailData?.id,
      { user_id: id }
    );

    if (res?.status === 201 || res?.status === 200) {
      message.success("Client successfully removed.");
      getAppointmentDetail();
    } else {
      message.error(res?.data?.detail);
    }
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card title={"Selected Client(s)"} bodyStyle={{ padding: 2 }}>
          <Table
            rowKey={(data) => data.cliniko_patient_id}
            columns={columns}
            dataSource={detailData?.group_patients}
            scroll={{ x: 1, y: window.innerHeight - 280 }}
            size="small"
            pagination={false}
          />
        </Card>
      </Col>
      <Divider />
      {!showAddNewClientForm && (
        <Col span={24}>
          <Card title={"Select Client(s)"}>
            <Select
              showSearch={false}
              placeholder="Select Another Client"
              style={{
                width: "100%",
                marginBottom: 10,
                textAlign: "left",
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSelect={(e) => {
                setselectedClient(JSON.parse(e));
              }}
            >
              {userRelativeList?.map((item) => {
                if (item?.user) {
                  return (
                    <Select.Option
                      key={item?.id}
                      value={JSON.stringify({ id: item?.id, user: item?.user })}
                    >
                      {item?.first_name} {item?.last_name}
                    </Select.Option>
                  );
                }
              })}
            </Select>
            <Space style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                onClick={() => {
                  setshowAddNewClientForm(true);
                }}
              >
                Create New Client
              </Button>
              <Button
                type="primary"
                disabled={!selectedClient?.id}
                onClick={onPressConfirm}
              >
                Add Client
              </Button>
            </Space>
          </Card>
        </Col>
      )}
      {showAddNewClientForm && (
        <Col span={24}>
          <Card title={"Create New Client"}>
            <Form
              name="basic"
              layout="vertical"
              form={form}
              initialValues={{}}
              onFinish={onSubmitAddNewClient}
              onFinishFailed={onSubmitAddNewClient}
              autoComplete="off"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label={"First Name"}
                    name={"first_name"}
                    rules={[
                      {
                        required: true,
                        message: "Please input patient's first name!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={"Last Name"}
                    name={"last_name"}
                    rules={[
                      {
                        required: true,
                        message: "Please input patient's last name!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={"Email"}
                    name={"email"}
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please input client's email!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={"Date of Birth"}
                    name={"date_of_birth"}
                    rules={[
                      {
                        required: true,
                        message: "Please input patient's date of birth!",
                      },
                    ]}
                  >
                    <DatePicker
                      format={["DD/MM/YYYY", "DD/MM/YY"]}
                      // value={date_of_birth}
                      // onChange={onChange}
                      //   className={styles.DatePicker}
                      style={{ width: "100%", marginTop: 15 }}
                      disabledDate={(date) => {
                        if (moment(date) < moment().add(-1, "day")) {
                          return false;
                        }
                        return true;
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={"Phone Number"}
                    name={"phone_number"}
                    rules={[
                      {
                        required: true,
                        message: "Please input phone number!",
                      },
                    ]}
                  >
                    <PhoneInput
                      country={"ca"}
                      placeholder="Enter phone number"
                      // value={phone_number}
                      // onChange={setphone_number}
                      containerStyle={{ marginTop: 10, marginBottom: 20 }}
                      inputStyle={{
                        width: "100%",
                        height: 40,
                        borderRadius: 10,
                        backgroundColor: "#f5f5f5",
                      }}
                      buttonStyle={{
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                      }}
                      dropdownStyle={{ textAlign: "left" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="" name={"is_child"}>
                    <Checkbox
                      onChange={() => {
                        setis_old(!is_old);
                        form.setFieldValue("is_child", !is_old);
                      }}
                      checked={is_old}
                    >
                      Is this person 13 or older? If so, we’ll create them their
                      own DiveThru account.
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Space>
                  <Button
                    htmlType="submit"
                    //   shape="round"
                    size="large"
                    type="primary"
                  >
                    Save
                  </Button>
                  <Button
                    // htmlType="submit"
                    size="large"
                    type="primary"
                    onClick={() => {
                      setshowAddNewClientForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Space>
              </Row>
            </Form>
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default AddOrEditGroupMemberModal;
