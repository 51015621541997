import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { memo } from "react";
import { useNavigate } from "react-router";
// import BackArrowGray from "../assets/BackArrowGray.png";
// import DownloadBtnActive from "../assets/DownloadBtnActive.png";

const IntakeFormHeader = memo(({ downloadHandle, saveHandle }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: 40,
        marginBottom: 40,
      }}
    >
      <div style={{ display: "flex",}}>
        <Button type="text" size="large"  onClick={() => navigate(-1)}>
          <LeftOutlined />
        </Button>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <img
          src={BackArrowGray}
          alt="back-arrow"
          style={{ height: 32, width: 32, marginLeft: -10, cursor: "pointer" }}
          onClick={() => navigate(-1)}
        /> */}

        <p
          style={{
            color: "#909090",
            fontSize: 22,
            letterSpacing: "0.03em",
          }}
        >
          Forms
        </p>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <img
          src={DownloadBtnActive}
          alt="download-logo"
          style={{
            height: 32,
            width: 32,
            marginTop: 5,
            marginRight: 20,
            cursor: "pointer",
          }}
          onClick={downloadHandle}
        /> */}
        <p
          style={{
            cursor: "pointer",
            backgroundColor: "#1BCDB7",
            color: "#fff",
            fontSize: 18,
            fontWeight: "700",
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 25,
            paddingRight: 25,
            borderRadius: 8,
          }}
          onClick={saveHandle}
        >
          Save
        </p>
      </div>
    </div>
  );
});

export default IntakeFormHeader;
