import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
  Spin,
  Switch,
  Upload,
} from "antd";
import moment from "moment";
import React, { memo, useEffect, useRef, useState } from "react";
import NetworkClient from "../../store/api/utils/NetworkClient";
const { Option } = Select;
const { TextArea } = Input;

const AddEditUserModal = memo(
  ({
    setvisibleAddUserModal,
    onSubmitAddUserModal,
    data,
    modalLoading,
    isUpdateModal,
  }) => {
    const [form] = Form.useForm();
    const formRef = useRef();
    const formRole = Form.useWatch("role", form);

    const [loading, setLoading] = useState(false);
    const [imagePath, setimagePath] = useState(undefined);
    const [disableSubmit, setdisableSubmit] = useState(false);
    const [showPassword, setshowPassword] = useState(false);
    useEffect(() => {
      form.resetFields();
      setimagePath(data?.photo_link);
      if (isUpdateModal) {
        if (data?.role !== "BASIC") {
          setshowPassword(true);
        } else {
          setshowPassword(false);
        }
      } else {
        setshowPassword(false);
      }
    }, [form, data]);

    useEffect(() => {
      let { role } = form.getFieldValue();
      if (role) {
        if (role !== "BASIC") {
          setshowPassword(true);
        } else {
          setshowPassword(false);
        }
      }
    }, [formRole]);

    const imageUpload = async (e) => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("image", e.file);
        const res = await NetworkClient.filePost(
          "/api/admin/upload-image",
          formData
        );
        const { data } = res;
        console.log("uploaded image link", data);
        setimagePath(data);
        form.setFieldsValue({ photo_link: data });
        setLoading(false);
        return data;
      } catch (error) {
        setdisableSubmit(true);
      }
    };

    const onSubmit = (value) => {
      // console.log(value);
      if (value.errorFields) {
        message.error("Please fill up the required fields");
      } else {
        form.resetFields();
        onSubmitAddUserModal(value);
      }
    };

    return (
      <>
        {modalLoading ? (
          <Spin
            size="large"
            style={{ display: "flex", justifyContent: "center" }}
          />
        ) : (
          <Form
            form={form}
            ref={formRef}
            name="basic"
            layout="vertical"
            initialValues={{
              name: data?.name ?? "",
              email: data?.email ?? "",
              first_name: data?.first_name ?? "",
              last_name: data?.last_name ?? "",
              membership: data?.membership ?? "",
              phone_number: data?.user_profile?.phone_number ?? "",
              role: data?.role ?? "",
              // bio: data?.bio ?? "",
              photo_link: data?.photo_link ?? "",
              is_active: data?.is_active,
              date_of_birth: data?.user_profile?.date_of_birth ?? null,
              gender: data?.user_profile?.gender,
              pronoun: data?.user_profile?.pronoun,
              phone_number: data?.user_profile?.phone_number,
              emergency_contact_name:
                data?.user_profile?.emergency_contact_name,
              emergency_contact_relation:
                data?.user_profile?.emergency_contact_relation,
            }}
            onFinish={onSubmit}
            onFinishFailed={onSubmit}
          >
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="NAME"
                  name="name"
                  rules={[
                    { required: true, message: "Please input the User name!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Pronoun" name="pronoun">
                  <Select placeholder="Select Pronoun">
                    <Option value="she/her">she/her</Option>
                    <Option value="he/him">he/him</Option>
                    <Option value="they/them">they/them</Option>
                    <Option value="other">other</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col lg={12} sm={24} xs={24}>
                <Form.Item label="FIRST NAME" name="first_name">
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12} sm={24} xs={24}>
                <Form.Item label="LAST NAME" name="last_name">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col lg={12} sm={24} xs={24}>
                <Form.Item
                  label="EMAIL"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12} sm={24} xs={24}>
                <Form.Item
                  label="PHONE NUMBER"
                  name="phone_number"
                  rules={[
                    {
                      required: isUpdateModal ? false : true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col lg={12} sm={24} xs={24}>
                <Form.Item label="MEMBERSHIP" name="membership">
                  <Select placeholder="Select membership" onChange={() => {}}>
                    <Option value="FREE">FREE</Option>
                    <Option value="TRIAL">TRIAL</Option>
                    <Option value="UNLOCKED">UNLOCKED</Option>
                    <Option value="MONTHLY">MONTHLY</Option>
                    <Option value="YEARLY">YEARLY</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} sm={24} xs={24}>
                <Form.Item label="ROLE" name="role">
                  <Select placeholder="Select role" onChange={() => {}}>
                    <Option value="ADMIN">ADMIN</Option>
                    <Option value="STAFF">STAFF</Option>
                    <Option value="INSTRUCTOR">INSTRUCTOR</Option>
                    <Option value="BASIC">BASIC</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {!isUpdateModal && showPassword && (
              <Form.Item
                label="Password"
                name="password"
                // rules={[
                //   { required: true, message: "Please input your password!" },
                // ]}
              >
                <Input.Password />
              </Form.Item>
            )}

            <Row gutter={12}>
              <Col lg={12} sm={24} xs={24}>
                <Form.Item
                  label="DOB"
                  name="date_of_birth"
                  rules={[
                    {
                      required: isUpdateModal ? false : true,
                      message: "Please input date of birth!",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    defaultValue={
                      data?.user_profile?.date_of_birth &&
                      moment(data?.user_profile?.date_of_birth)
                      // if( data?.user_profile?.date_of_birth){
                      //   moment(data?.user_profile?.date_of_birth)
                      // }
                      // data?.user_profile?.date_of_birth
                      //   ? moment(data?.user_profile?.date_of_birth)
                      //   : null
                    }
                    onChange={(date, dateString) => {
                      form.setFieldValue("date_of_birth", date);
                    }}
                    disabledDate={(date) => {
                      if (moment(new Date()) < moment(date).add(1, "day")) {
                        return true;
                      }
                      return false;
                    }}
                  />
                  <Input style={{ display: "none" }} />
                </Form.Item>
              </Col>
              <Col lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Gender"
                  name="gender"
                  rules={[
                    {
                      required: isUpdateModal ? false : true,
                      message: "Please select gender!",
                    },
                  ]}
                >
                  <Select>
                    <Select.Option value="MAN">MAN</Select.Option>
                    <Select.Option value="WOMAN">WOMAN</Select.Option>
                    <Select.Option value="NON_BINARY">NON BINARY</Select.Option>
                    <Select.Option value="OTHER">OTHER</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col lg={8} sm={24}>
                <Form.Item
                  label="Emergency Contact name"
                  name="emergency_contact_name"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24}>
                <Form.Item
                  label="Emergency Contact number"
                  name="emergency_contact_number"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24}>
                <Form.Item
                  label="Emergency Contact relation"
                  name="emergency_contact_relation"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            {/* <Form.Item label="BIO" name="bio">
              <TextArea rows={4} placeholder="Write from here..." />
            </Form.Item> */}

            <Row>
              <Col lg={8} sm={24} xs={24}>
                <Form.Item
                  label="PHOTO LINK"
                  name="photo_link"
                  valuePropName="string"
                  getValueFromEvent={(e) => imageUpload(e)}
                >
                  <Upload
                    beforeUpload={() => false}
                    multiple={false}
                    maxCount={1}
                    disabled={loading}
                    accept=".png, .jpg, .jpeg"
                  >
                    <Button icon={<UploadOutlined />} shape="round">
                      UPLOAD
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col lg={8} sm={24} xs={24}>
                {imagePath && (
                  <Image width={100} height={100} src={imagePath} />
                )}
              </Col>
              <Col lg={8} sm={24} xs={24}>
                <Form.Item label="IS USER ACTIVE" name="is_active">
                  <Switch defaultChecked={data?.is_active} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Row gutter={5} style={{ justifyContent: "flex-end" }}>
                <Col>
                  <Button
                    type="danger"
                    shape="round"
                    onClick={() => {
                      form.resetFields();
                      setimagePath(undefined);
                      setvisibleAddUserModal(false);
                    }}
                    size="large"
                  >
                    Close
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    shape="round"
                    disabled={disableSubmit}
                    loading={loading}
                    htmlType="submit"
                    size="large"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}
      </>
    );
  }
);

export default AddEditUserModal;
