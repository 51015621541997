import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "../../store";
import { Table, Col, Row, Typography,Input} from "antd";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

const { Title } = Typography;
const { Search } = Input;


const Notification = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.NotificationReducer);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchedText, setsearchedText] = useState(null);


  useEffect(() => {
    dispatch(ActionCreators.getNotificationAction(1, 20));
  }, [dispatch]);

  const handlePageChange = (page) => {
    let action = ActionCreators.getNotificationAction(page, 20,searchedText);
    dispatch(action);
    setCurrentPageNumber(page);
    setCurrentPageNumber(page);
  };

  const onSearchNotification = useCallback(
    (q) => {
      // console.log(value)
      setCurrentPageNumber(1);
      let action = ActionCreators.getNotificationAction(1, 20, q);
      dispatch(action);
      setCurrentPageNumber(1);
      setsearchedText(q);
    },
    [dispatch]
  );


  const columns = [
    {
      title: "ADDED BY",
      dataIndex: "added_by",
      width: 200,
      ellipsis: true,
      textWrap: "word-break",
      render:(text)=><>{text.email}</>
    },
    {
      title: "STATUS",
      dataIndex: "status",
      width: 100,
      align: "center",
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },
    {
      title: "TIME ZONE",
      dataIndex: "time_zone",
      width: 180,
    },
    {
      title: "UTC TIME",
      dataIndex: "utc_time",
      width: 130,
    },
    {
      title: "DIVE TIME",
      dataIndex: "dive_time",
      width: 130,
    },

    {
      title: "MERCH DAYS",
      dataIndex: "merch_days",
      width: 130,
      align: "center",
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },

    {
      title: "PICK ME UP",
      dataIndex: "pick_me_up",
      width: 130,
      align: "center",
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },

    {
      title: "STEAK REMINDERS",
      dataIndex: "steak_reminders",
      width: 130,
      align: "center",
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },
    {
      title: "ANNOUNCEMENTS",
      dataIndex: "announcements",
      width: 130,
      align: "center",
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },
  ];

  return (
    <div>
      {/* {data.errMsg ? <p>{data.errMsg}</p> : null} */}
      <Row>
        <Col>
          <Title level={2} style={{ marginBottom: 0, textAlign: "left" }}>
            NOTIFICATION
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL NOTIFICATIONS : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-between" }}>
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search here..."
            enterButton
            size="large"
            onSearch={onSearchNotification}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={data.allNotification}
        scroll={{ x: 1,y:window.innerHeight - 250 }}
        size="middle"
        loading={data.loading}
        rowKey={(data) => data.id}
        pagination={{
          total: data.count,
          pageSize: 20,
          current:currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
    </div>
  );
};

export default Notification;
