import React, { memo, useEffect, useState } from "react";
import { Form, Input, Row, Col, Button, Spin, message, Select } from "antd";
import { ContentApi } from "../../store/api";

const { TextArea } = Input;
const { Option } = Select;

const AddEditCourseContentModal = memo(
  ({ visibleAddContentModal, onSubmitAddContentModal, data, modalLoading }) => {
    const [form] = Form.useForm();
    const [contentList, setContentList] = useState([]);
    const [loader, setLoader] = useState(false);

    //loading from mother component
    useEffect(() => {
      if (modalLoading) {
        setLoader(true);
      } else {
        setLoader(false);
      }
    }, [modalLoading]);

    //reset form
    useEffect(() => {
      form.resetFields();
      form.setFieldsValue({
        content: data?.content?.title ?? "",
        description:
          data?.description === undefined || data?.description === ""
            ? ""
            : data?.description,
        order: data?.order ?? null,
        time_duration: data?.time_duration ?? "00:00:00",
      });
    }, [form, data]);

    //content list
    useEffect(() => {
      const fetchData = async () => {
        if (!data?.id) {
          setLoader(true);
          const res = await ContentApi.getAllContentList({
            page: 1,
            limit: 100,
            extended_type: "COURSE",
            unused_course_content: true,
          });
          if (res.status === 200) {
            if (res.data.results.length === 0) {
              message.error(
                "All contents are already used! Please add new content."
              );
              visibleAddContentModal(false);
            } else {
              setContentList(res.data.results);
              setLoader(false);
            }
          } else {
            setLoader(false);
          }
        } else {
          // console.log("------");
        }
      };
      fetchData();
    }, [data, visibleAddContentModal]);

    //search
    const onSearch = async (param) => {
      const values = {
        page: 1,
        limit: 100,
        extended_type: "COURSE",
        unused_course_content: true,
      };
      param !== "" && (values["q"] = param);
      const res = await ContentApi.getAllContentList(values);
      if (res.status === 200) {
        setContentList(res.data.results);
      }
    };

    //submit button click
    const onSubmit = (value) => {
      if (value.errorFields) {
        message.error("Please fill up required fields");
      } else {
        form.resetFields();

        if (value.order) value["order"] = parseInt(value.order);
        else delete value.order;

        onSubmitAddContentModal(value);
      }
    };

    return (
      <>
        {loader ? (
          <Spin
            size="large"
            style={{ display: "flex", justifyContent: "center" }}
          />
        ) : (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{
              content: data?.content?.title ?? "",
              description:
                data?.description === undefined || data?.description === ""
                  ? ""
                  : data?.description,
              order: data?.order ?? null,
              time_duration: data?.time_duration ?? "00:00:00",
            }}
            onFinish={onSubmit}
            onFinishFailed={onSubmit}
            autoComplete="off"
          >
            {!(data && data?.id) && (
              <Form.Item label="CONTENT" name="content">
                <Select
                  showSearch
                  placeholder="Select/Search Content"
                  optionFilterProp="children"
                  onChange={() => {}}
                  onSearch={(value) => onSearch(value)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {contentList.length > 0 &&
                    contentList.map((e, key) => {
                      return (
                        <Option key={key} value={e.id}>
                          {e.title}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            )}
            <Form.Item label="DESCRIPTION" name="description">
              <TextArea rows={4} placeholder="Write Content Description..." />
            </Form.Item>
            <Form.Item label="ORDER" name="order">
              <Input type="number" />
            </Form.Item>
            <Form.Item label="TIME DURATION" name="time_duration">
              <Input />
            </Form.Item>

            <Form.Item>
              <Row gutter={10} style={{ justifyContent: "flex-end" }}>
                <Col>
                  <Button
                    type="danger"
                    onClick={() => {
                      form.resetFields();
                      visibleAddContentModal(false);
                    }}
                    size="large"
                  >
                    Close
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit" size="large">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}
      </>
    );
  }
);

export default AddEditCourseContentModal;
