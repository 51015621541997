import { Button, Col, Form, Input, message, Row, Spin } from "antd";
import React from "react";

export default function ResetPasswordModal({
  setvisibleResetPassModal,
  onSubmitResetPassword,

  modalLoading,
}) {
  const [form] = Form.useForm();

  const onSubmit = (value) => {
    if (value.errorFields) {
      message.error("Please fill up required fields");
    } else if (
      form.getFieldValue("new_password") !==
      form.getFieldValue("confirm_password")
    ) {
      message.error("New password and confirm password doesn't match");
    } else {
      form.resetFields();
      onSubmitResetPassword(value);
    }
  };

  return (
    <div>
      {modalLoading ? (
        <Spin
          size="large"
          style={{ display: "flex", justifyContent: "center" }}
        />
      ) : (
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onSubmit}
          onFinishFailed={onSubmit}
          autoComplete="off"
        >
          <Form.Item
            label="Current Password"
            name="current_password"
            
            rules={[
              {
                required: true,
                message: "Please enter your current password",
              },
            ]}
          >
            <Input.Password  />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="new_password"
            rules={[
              {
                required: true,
                message: "Please enter your new password",
              },
            ]}
          >
            <Input.Password  />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            rules={[
              {
                required: true,
                message: "Please enter your new password",
              },
            ]}
          >
            <Input.Password  />
          </Form.Item>
          <Row gutter={10} style={{ justifyContent: "flex-end" }}>
            <Col>
              <Button
                type="danger"
                onClick={() => {
                  form.resetFields();
                  setvisibleResetPassModal(false);
                }}
                size="large"
              >
                Close
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={modalLoading}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
}
