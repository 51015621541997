import http from "./utils/NetworkClient";

export default class IntakeFormApi {
  static async getIntakeFormStatus() {
    const response = await http.get("/api/admin/user_consent_form_status");
    return response;
  }

  static async getFormList(page = 1, limit = 5) {
    const response = await http.get(
      `/api/admin/user_consent_forms?page=${page}&limit=${limit}`
    );
    return response;
  }

  static async getFormDetails(id) {
    const response = await http.get(`/api/admin/user_consent_form/${id}`);
    return response;
  }

  static async updateIntakeForm(payload, id, user_id) {
    const response = await http.put(
      `/api/admin/user_consent_form/${id}?user_id=${user_id}`,
      payload
    );
    return response;
  }

  static async getSignatureImg(url) {
    const response = await http.get(
      `/api/admin/cliniko_attachment_response?url=${url}`
    );
    return response;
  }

  static async uploadSignature(payload, user_id) {
    const response = await http.filePost(
      `/api/admin/cliniko_file_attachment?user_id=${user_id}&file_type=SIGNATURE`,
      payload
    );
    return response;
  }

  static async downloadForm(id) {
    const response = await http.get(
      `/api/admin/download_consent_form/pdf?user_consent_form_id=${id}`
    );
    return response;
  }

  static async uploadForm(payload, user_id, type) {
    const response = await http.filePost(
      `/api/admin/cliniko_file_attachment?user_id=${user_id}&file_type=${type}`,
      payload
    );
    return response;
  }

  static async deleteImg(consentId, userId) {
    const response = await http.put(
      `/api/admin/cliniko_file_archive?user_consent_form_id=${consentId}&user_id=${userId}`
    );
    return response;
  }
}
