import { constant } from "../Constant";

export const getPermissionGroupAction = (page, limit, q) => {
  return {
    type: constant.GET_PERMISSION_GROUP,
    page: page,
    limit: limit,
    q: q,
  };
};

export const getMeAction = () => {
  return {
    type: constant.GET_ME,
  };
};
