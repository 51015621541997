import { constant } from "../Constant";

const initialState = {
  loading: true,
  response: [],
  count:0,
  errMsg: "",
};

const AppointmentTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_APPOINTMENT_TYPES:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_APPOINTMENT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.response,
        count: action.count,
        errMsg: "",
      };
    case constant.GET_APPOINTMENT_TYPES_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    case constant.CLEAN_APPOINTMENT_TYPES:
      return {
        ...state,
        loading: false,
        response: [],
        count:0,
        errMsg: "",
      };
    default:
      return state;
  }
};

export default AppointmentTypesReducer;
