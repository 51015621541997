import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserContentDetailModal from "../../components/modals/UserContentDetailModal";
import { ActionCreators } from "../../store";
import { UserContentApi } from "../../store/api";
const { Search } = Input;
const { Option } = Select;

const UserContent = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.UserContentReducer);
  const [modalDataLoading, setmodalDataLoading] = useState(false);
  const [detailData, setdetailData] = useState({});
  const [
    visibleUserContentModalDetailModal,
    setvisibleUserContentModalDetailModal,
  ] = useState(false);
  const [selectedContentType, setselectedContentType] = useState(null);
  const [searchedText, setsearchedText] = useState(null);
  const [sortValue] = useState(null);
  const [orderValue, setorderValue] = useState(null);

  //modal
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  useEffect(() => {
    dispatch(
      ActionCreators.getUserContentAction(
        currentPageNumber,
        20,
        selectedContentType
      )
    );
  }, [dispatch]);

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    dispatch(
      ActionCreators.getUserContentAction(
        page,
        20,
        selectedContentType,
        searchedText,
        null,
        orderValue
      )
    );
  };

  const viewDetailButtonClick = useCallback(async (id, type) => {
    try {
      setmodalDataLoading(true);
      let response = null;
      if (type === "AUDIO") {
        response = await UserContentApi.getAudioContentDetail(id);
        if (response.status === 201 || response.status === 200) {
          // console.log(response);
          setdetailData(response.data);
          setvisibleUserContentModalDetailModal(true);
          setmodalDataLoading(false);
        }
      } else if (type === "JOURNAL") {
        response = await UserContentApi.getJournalContentDetail(id);
        if (response.status === 201 || response.status === 200) {
          // console.log(response);
          setdetailData(response.data);
          setvisibleUserContentModalDetailModal(true);
          setmodalDataLoading(false);
        }
      } else if (type === "VIDEO") {
        response = await UserContentApi.getVideoContentDetail(id);
        if (response.status === 201 || response.status === 200) {
          // console.log(response);
          setdetailData(response.data);
          setvisibleUserContentModalDetailModal(true);
          setmodalDataLoading(false);
        }
      } else {
        response = await UserContentApi.getOtherContentDetail(id);
        if (response.status === 201 || response.status === 200) {
          // console.log(response);
          setdetailData(response.data);
          setvisibleUserContentModalDetailModal(true);
          setmodalDataLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onChangeContentTyoe = useCallback(
    (value) => {
      setselectedContentType(value);
      setCurrentPageNumber(1);
      dispatch(
        ActionCreators.getUserContentAction(
          1,
          20,
          value ?? null,
          searchedText ?? null,
          sortValue ?? null,
          orderValue ?? null
        )
      );
    },
    [selectedContentType, searchedText, sortValue, orderValue]
  );

  const onSearchContent = useCallback(() => {
    setCurrentPageNumber(1);
    let action = ActionCreators.getUserContentAction(
      1,
      20,
      selectedContentType ?? null,
      searchedText ?? null,
      sortValue ?? null,
      orderValue ?? null
    );
    dispatch(action);
    setCurrentPageNumber(1);
  }, [selectedContentType, searchedText, sortValue, orderValue]);

  const onChangeOrderValue = useCallback(
    (value) => {
      setorderValue(value);
      setCurrentPageNumber(1);
      dispatch(
        ActionCreators.getUserContentAction(
          1,
          20,
          selectedContentType ?? null,
          searchedText ?? null,
          sortValue ?? null,
          value ?? null
        )
      );
    },
    [selectedContentType, searchedText, sortValue, orderValue]
  );

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      ellipsis: true,
      textWrap: "word-break",
      width: 100,
    },
    {
      title: "EMAIL",
      dataIndex: "user",
      width: 130,
      render: (text) => <>{text?.email}</>,
    },
    {
      title: "CONTENT",
      dataIndex: "content",
      width: 150,
      render: (text) => <>{text?.title}</>,
    },
    {
      title: "STATUS",
      dataIndex: "is_finished",
      width: 130,
      align: "center",
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },
    {
      title: "TYPE",
      dataIndex: "type",
      ellipsis: true,
      textWrap: "word-break",
      width: 130,
      align: "center",
    },

    {
      title: "Created at",
      dataIndex: "created_at",
      width: 130,
      align: "center",
      render: (text) => (
        <Space size="middle">
          {moment.utc(text).local().format("MM/DD/YYYY hh:mm:A")}
        </Space>
      ),
    },

    {
      title: "Action",
      dataIndex: "role",
      key: "id",
      width: 130,
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={() => viewDetailButtonClick(record.id, record.type)}
          >
            VIEW DETAIL
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            USER CONTENT
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL USER CONTENT : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: 5, marginBottom: 5 }}>
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Select
            placeholder="Select Content Type..."
            size="large"
            value={selectedContentType}
            onChange={onChangeContentTyoe}
            style={{ minWidth: 150 }}
          >
            <Option value="JOURNAL">JOURNAL</Option>
            <Option value="AUDIO">AUDIO</Option>
            <Option value="ARTICLE">ARTICLE</Option>
            <Option value="VIDEO">VIDEO</Option>
            <Option value="OTHERS">OTHERS</Option>
            <Option value={null}>ALL</Option>
          </Select>
        </Col>
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Select
            placeholder="Order by."
            size="large"
            value={orderValue}
            onChange={onChangeOrderValue}
          >
            <Option value="1">ASCE</Option>
            <Option value="-1">DESC</Option>
          </Select>
        </Col>
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search by user name or content name..."
            enterButton
            size="large"
            onChange={(e) => setsearchedText(e.target.value)}
            onSearch={onSearchContent}
          />
        </Col>
      </Row>

      {modalDataLoading ? (
        <Spin size="large" />
      ) : (
        <Table
          rowKey={(data) => data.id}
          columns={columns}
          dataSource={data.allUserContent}
          size="middle"
          scroll={{ x: 1, y: window.innerHeight - 310 }}
          loading={data.loading}
          pagination={{
            total: data.count,
            pageSize: 20,
            current: currentPageNumber,
            showSizeChanger: false,
            onChange: (page) => {
              handlePageChange(page);
            },
          }}
        />
      )}

      <Modal
        title={`${detailData.type} DETAIL`}
        open={visibleUserContentModalDetailModal}
        footer={[
          <Button
            key="back"
            type="danger"
            shape="round"
            size="large"
            onClick={() => setvisibleUserContentModalDetailModal(false)}
          >
            CLOSE
          </Button>,
        ]}
        closable={false}
      >
        <UserContentDetailModal data={detailData} />
      </Modal>
    </React.Fragment>
  );
};

export default UserContent;
