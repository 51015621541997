import { constant } from "../Constant";

const initialState = {
  loading: true,
  benefits: [],
  errMsg: "",
  count:0,
  details: {},
};

const BenefitReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_BENEFITS:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_BENEFITS_SUCCESS:
      return {
        ...state,
        loading: false,
        benefits: action.benefits,
        count:action.count,
        errMsg: "",
      };
    case constant.GET_BENEFIT_DETAILS:
      return {
        ...state,
        id: action.id,
        loading: true,
      };
    case constant.GET_BENEFIT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: action.details,
        errMsg: "",
      };
    case constant.GET_BENEFITS_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    case constant.CLEAN_BENEFIT:
      return {
        ...state,
        loading: false,
        benefits: [],
        count:0,
        errMsg: "",
      };
    default:
      return state;
  }
};

export default BenefitReducer;
