import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import {
  Col,
  Input,
  Row,
  Space,
  Table,
  Typography,
  Select,
  DatePicker,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { CourseApi, InstructorApi, UserActivityApi } from "../../store/api";
import moment from "moment";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const UserCourse = () => {
  const { Title } = Typography;
  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setcurrentPageNumber] = useState(1);
  const [searchedText, setsearchedText] = useState(undefined);
  const [courseId, setCourseId] = useState(null);
  const [instructorId, setInstructorId] = useState(null);
  const [isPaid, setIsPaid] = useState(null);
  const [sort, setSort] = useState(null);
  const [allCourseData, setAllCourseData] = useState([]);
  const [allInstructorData, setAllInstructorData] = useState([]);
  const [createdForm, setCreatedForm] = useState(null);
  const [createdTo, setCreatedTo] = useState(null);
  const [paidForm, setPaidForm] = useState(null);
  const [paidTo, setPaidTo] = useState(null);

  console.log("allInstructorData", data);

  // const fetchApiData = async () => {
  //   setLoading(true);
  //   const res = await UserActivityApi.getAllUserCourse({
  //     page: currentPageNumber,
  //     limit: 10,
  //     query: searchedText ?? "",
  //   });
  //   if (res.status === 200) {
  //     setCount(res.data.count);
  //     setData(res.data.results);
  //     setLoading(false);
  //   } else {
  //     setLoading(false);
  //   }
  // };

  const fetchApiData = useCallback(async () => {
    setLoading(true);
    const res = await UserActivityApi.getAllUserCourse({
      page: currentPageNumber,
      limit: 10,
      q: searchedText ?? "",
      course_id: courseId,
      instructor_id: instructorId,
      is_paid: isPaid,
      sort: sort,
      created_from: createdForm ?? null,
      created_to: createdTo ?? null,
      paid_from: paidForm ?? null,
      paid_to: paidTo ?? null,
    });
    if (res.status === 200) {
      setCount(res.data.count);
      setData(res.data.results);
    }
    setLoading(false);
  }, [
    currentPageNumber,
    searchedText,
    courseId,
    instructorId,
    isPaid,
    sort,
    createdForm,
    createdTo,
    paidForm,
    paidTo,
  ]);

  useEffect(() => {
    if (currentPageNumber > 0) {
      fetchApiData();
    }
  }, [currentPageNumber, fetchApiData]);

  useEffect(() => {
    fetchInstructorData();
    fetchCourseData();
  }, []);

  const fetchInstructorData = async (q = null) => {
    const allInstructors = await InstructorApi.getInstructors({
      page: 1,
      limit: 20,
      q: q,
    });
    if (allInstructors.status === 200) {
      setAllInstructorData(allInstructors.data.results);
    }
  };

  const fetchCourseData = async (q = null) => {
    const allCourse = await CourseApi.getAllCourse({
      page: 1,
      limit: 20,
      q: q,
    });
    if (allCourse.status === 200) {
      setAllCourseData(allCourse.data.results);
    }
  };

  const onChangeDateRange = (date, dateString) => {
    setCreatedForm(dateString[0]);
    setCreatedTo(dateString[1]);
  };

  const onChangePaidRange = (date, dateString) => {
    setPaidForm(dateString[0]);
    setPaidTo(dateString[1]);
  };

  const columns = [
    {
      title: "COURSE",
      dataIndex: "course",
      textWrap: "word-break",
      width: 250,
      render: (value) => <Space>{value?.title ?? "N/A"}</Space>,
    },
    {
      title: "IS PREMIUM",
      dataIndex: "course",
      align: "center",
      width: 100,
      render: (value) =>
        value?.is_premium ? (
          <CheckCircleFilled style={{ color: "#22c55e" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#ef4444" }} />
        ),
    },
    {
      title: "USER",
      dataIndex: "user",
      textWrap: "word-break",
      width: 200,
      render: (value) => (
        <Space style={{ display: "inline-block", wordBreak: "break-word" }}>
          {value?.email ?? "N/A"}
        </Space>
      ),
    },
    {
      title: "INSTRUCTOR",
      dataIndex: "instructor",
      textWrap: "word-break",
      width: 200,
      render: (value) => <Space>{value?.name ?? "N/A"}</Space>,
    },
    {
      title: "IS FINISHED",
      dataIndex: "is_finished",
      align: "center",
      width: 100,
      render: (value) =>
        value ? (
          <CheckCircleFilled style={{ color: "#22c55e" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#ef4444" }} />
        ),
    },
    {
      title: "IS PAID",
      dataIndex: "is_paid",
      align: "center",
      width: 100,
      render: (value) =>
        value ? (
          <CheckCircleFilled style={{ color: "#22c55e" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#ef4444" }} />
        ),
    },
    {
      title: "PAID AT",
      dataIndex: "paid_at",
      align: "center",
      textWrap: "word-break",
      width: 100,
      render: (value) => (
        <Space>{moment(value).format("DD/MM/YYYY hh:mm A") ?? "N/A"}</Space>
      ),
    },
    {
      title: "CREATED AT",
      dataIndex: "created_at",
      textWrap: "word-break",
      width: 100,
      render: (value) => (
        <Space>{moment(value).format("DD/MM/YYYY hh:mm A") ?? "N/A"}</Space>
      ),
    },

    // {
    //   title: "IMAGE",
    //   dataIndex: "course",
    //   align: "center",
    //   // width: 100,
    //   render: (text) => (
    //     <Space size="middle">
    //       <img
    //         src={text?.cover_image ?? "N/A"}
    //         alt="N/A"
    //         style={{ height: 70, width: 90 }}
    //       />
    //     </Space>
    //   ),
    // },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            USER COURSE
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL COURSE : <strong>{count}</strong>
          </p>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginBottom: 20, marginTop: 10 }}>
        <Col lg={6} sm={12} xs={24}>
          <Search
            size="large"
            placeholder="Search by user name or email"
            allowClear
            enterButton
            // size="medium"
            onSearch={(value) => {
              setsearchedText(value);
              setcurrentPageNumber(0);
              setcurrentPageNumber(1);
            }}
            style={{ width: "100%", height: "100%" }}
          />
        </Col>
        <Col lg={6} sm={12} xs={24}>
          <Select
            placeholder="Is Paid"
            size="large"
            onChange={(e) => setIsPaid(e)}
            style={{ width: "100%" }}
          >
            <Option value={null}>Select all Paid/Unpaid courses</Option>
            <Option value={true}>Paid Courses</Option>
            <Option value={false}>Unpaid Courses</Option>
          </Select>
        </Col>
        <Col lg={6} sm={12} xs={24}>
          <Select
            placeholder="Sorting"
            size="large"
            onChange={(e) => setSort(e)}
            style={{ width: "100%" }}
          >
            <Option value={null}>Default Sorting</Option>
            <Option value={"id:asc"}>Created at ASC</Option>
            <Option value={"id:desc"}>Created at DESC</Option>
            <Option value={"paid_at:asc"}>Payment at ASC</Option>
            <Option value={"paid_at:desc"}>Payment at DESC</Option>
          </Select>
        </Col>
        <Col lg={6} sm={12} xs={24}>
          <Select
            size="large"
            showSearch
            allowClear
            placeholder="Search by Instructor"
            optionFilterProp="children"
            onChange={(value) => setInstructorId(value)}
            onSearch={(value) => fetchInstructorData(value)}
            onClear={() => fetchInstructorData(null)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }}
          >
            <Option value={null}>Select All Instructors</Option>
            {allInstructorData &&
              allInstructorData.length > 0 &&
              allInstructorData.map((item, index) => (
                <Option value={item?.id} key={index}>
                  {item?.name}
                </Option>
              ))}
          </Select>
        </Col>
        <Col lg={6} sm={12} xs={24}>
          <Select
            size="large"
            showSearch
            allowClear
            placeholder="Search by Course"
            optionFilterProp="children"
            onChange={(value) => setCourseId(value)}
            onSearch={(value) => fetchCourseData(value)}
            onClear={() => fetchCourseData(null)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }}
          >
            <Option value={null}>Select All Course</Option>
            {allCourseData &&
              allCourseData.length > 0 &&
              allCourseData.map((item, index) => (
                <Option value={item?.id} key={index}>
                  {item?.title}
                </Option>
              ))}
          </Select>
        </Col>
        <Col lg={8} sm={12} xs={24}>
          <RangePicker
            style={{ width: "100%" }}
            size="large"
            placeholder={["Created Start Date", "Created End Date"]}
            onChange={onChangeDateRange}
          />
        </Col>
        <Col lg={8} sm={12} xs={24}>
          <RangePicker
            style={{ width: "100%" }}
            size="large"
            placeholder={["Payment Start Date", "Payment End Date"]}
            onChange={onChangePaidRange}
          />
        </Col>
      </Row>
      <Table
        rowKey={(data) => data.id}
        columns={columns}
        dataSource={data}
        scroll={{ x: 1, y: window.innerHeight - 275 }}
        size="middle"
        loading={loading}
        pagination={{
          showSizeChanger: false,
          total: count,
          pageSize: 20,
          current: currentPageNumber,
          onChange: async (page) => {
            setcurrentPageNumber(page);
            // fetchApiData({ page: page, limit: 20 });
          },
        }}
      />
    </React.Fragment>
  );
};

export default UserCourse;
