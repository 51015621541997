import { constant } from "../Constant";

export const getIdentifier = (page, limit, q) => {
  return {
    type: constant.GET_IDENTIFIERS,
    page: page,
    limit: limit,
    q: q ?? null,
  };
};

export const getOptions = (page, limit, q, form_identifier) => {
  return {
    type: constant.GET_OPTIONS,
    page: page,
    limit: limit,
    q: q ?? null,
    form_identifier: form_identifier,
  };
};

export const getLocations = (page, limit, q) => {
  return {
    type: constant.GET_LOCATION,
    page: page,
    limit: limit,
    q: q ?? null,
  };
};

export const getFromGroup = (page, limit, q) => {
  return {
    type: constant.GET_FORM_GROUP,
    page: page,
    limit: limit,
    q: q ?? null,
  };
};

export const getFroms = (page, limit, form_group, q) => {
  return {
    type: constant.GET_FORM,
    page: page,
    limit: limit,
    form_group: form_group,
    q: q ?? null,
  };
};

export const formDetails = (params) => {
  return {
    type: constant.FORM_DETAILS,
    payload: params,
  };
};

export const getInstructorInfo = (
  page,
  limit,
  q,
  instructor,
  form_identifier
) => {
  return {
    type: constant.GET_INSTRUCTOR_INFO,
    page: page,
    limit: limit,
    q: q ?? null,
    instructor: instructor ?? null,
    form_identifier: form_identifier ?? null,
  };
};
