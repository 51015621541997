/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class UserCourseReviewApi {
  static async getAllUserCourseReview(payload) {
    const response = await http.get("/api/admin/user_course_review", payload);
    return response;
  }

  static async deleteSingleUserCourseReview(payload) {
    const response = await http.del(`/api/admin/user_course_review/${payload}`);
    return response;
  }

  static async editUserCourseReview(payload, id) {
    const response = await http.put(`/api/admin/user_course_review/${id}`, payload);
    return response;
  }

  static async addUserCourseReview(payload) {
    const response = await http.post("/api/admin/user_course_review", payload);
    return response;
  }
}
