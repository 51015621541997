import http from "./utils/NetworkClient";

export default class QuestionariesApi {
  //identifier
  static async getIAlldentifier(payload) {
    const response = await http.get("/api/admin/form-identifiers", payload);
    return response;
  }
  static async deleteSingleIdentifier(id) {
    const response = await http.del(`api/admin/form-identifier/${id}`);
    return response;
  }
  static async addIdentifier(payload) {
    const response = await http.post(`/api/admin/form-identifier`, payload);
    return response;
  }
  static async updateIdentifier(payload, id) {
    const response = await http.put(
      `/api/admin/form-identifier/${id}`,
      payload
    );
    return response;
  }

  //options
  static async getIAllOptions(payload) {
    const response = await http.get("/api/admin/form-options", payload);
    return response;
  }
  static async deleteSingleOption(id) {
    const response = await http.del(`/api/admin/form-option/${id}`);
    return response;
  }
  static async addOption(payload) {
    const response = await http.post(`/api/admin/form-option`, payload);
    return response;
  }
  static async updateOption(payload, id) {
    const response = await http.put(`/api/admin/form-option/${id}`, payload);
    return response;
  }

  //location
  static async getIAllLocations(payload) {
    const response = await http.get("/api/admin/studio-locations", payload);
    return response;
  }
  static async deleteSingleLocation(id) {
    const response = await http.del(`/api/admin/studio-location/${id}`);
    return response;
  }
  static async addLocation(payload) {
    const response = await http.post(`/api/admin/studio-location`, payload);
    return response;
  }
  static async updateLocation(payload, id) {
    const response = await http.put(
      `/api/admin/studio-location/${id}`,
      payload
    );
    return response;
  }

  //form group
  static async getIAllFormGroups(payload) {
    const response = await http.get("/api/admin/form-groups", payload);
    return response;
  }
  static async deleteFormGroup(id) {
    const response = await http.del(`/api/admin/form-group/${id}`);
    return response;
  }
  static async addFormGroup(payload) {
    const response = await http.post(`/api/admin/form-group`, payload);
    return response;
  }
  static async updateFormGroup(payload, id) {
    const response = await http.put(`/api/admin/form-group/${id}`, payload);
    return response;
  }

  //form
  static async getIAllForms(payload) {
    const response = await http.get("/api/admin/forms", payload);
    return response;
  }
  static async getFormDetails(id) {
    const response = await http.get(`/api/admin/form/${id}`);
    return response;
  }
  static async deleteForm(id) {
    const response = await http.del(`/api/admin/form/${id}`);
    return response;
  }
  static async addForm(payload) {
    const response = await http.post(`/api/admin/form`, payload);
    return response;
  }
  static async updateForm(payload, id) {
    const response = await http.put(`/api/admin/form/${id}`, payload);
    return response;
  }

  //instructor info
  static async getIAllInstructorInfo(payload) {
    const response = await http.get("/api/admin/instructor-infos", payload);
    return response;
  }
  static async getInstructorInfoDetails(id) {
    const response = await http.get(`/api/admin/instructor-info/${id}`);
    return response;
  }
  static async deleteInstructorInfo(id) {
    const response = await http.del(`/api/admin/instructor-info/${id}`);
    return response;
  }
  static async addInstructorInfo(payload) {
    const response = await http.post(`/api/admin/instructor-info`, payload);
    return response;
  }
  static async updateInstructorInfo(payload, id) {
    const response = await http.put(
      `/api/admin/instructor-info/${id}`,
      payload
    );
    return response;
  }
  // static async syncInstructor(id) {
  //   const response = await http.post(
  //     `/api/admin/instructor/${id}/sync-cliniko-instructor-availabilities`
  //   );
  //   return response;
  // }
  static async getInstructorDailyAvail(page = 1, limit = 20, id) {
    const response = await http.get(
      `/api/admin/instructor_daily_availabilities?page=${page}&limit=${limit}&instructor_id=${id}`
    );
    return response;
  }
}
