import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AddEditInstructorInfoModal from "../../components/modals/AddEditInstructorInfoModal";
import { ActionCreators } from "../../store";
import { InstructorApi, QuestionariesApi } from "../../store/api";

const { Title } = Typography;
const { Search } = Input;

const InstructorInfo = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { instructorInfo } = state; // Read values passed on state
  const data = useSelector((state) => state.QuestionariesReducer);
  const me = useSelector((state) => state.MeReducer?.response);

  const instructorDetails = useSelector((state) => state.InstructorReducer);
  const [loader, setLoader] = useState(false);
  const [modalLoading, setmodalLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [availablityPageNumber, setAvailablityPageNumber] = useState(1);
  const [editData, setEditData] = useState({});
  const [updateInstructorInfoVisible, setUpdateInstructorInfoModal] =
    useState(false);
  const [addInstructorInfoVisible, setAddInstructorInfoModal] = useState(false);
  const [allOptions, setAllOptions] = useState([]);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const [dailyAvailibility, setDailyAvailability] = useState([]);

  console.log("dailyAvailibility>>>>>>>", dailyAvailibility);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex(
        (item) => item?.type === "instructors"
      );
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  useEffect(() => {
    dispatch(ActionCreators.getInstructorInfo(1, 20, null, instructorInfo?.id));
    dispatch(ActionCreators.getIdentifier(1, 20));
    dailyAvailability(1);
  }, [dispatch, instructorInfo?.id]);

  const dailyAvailability = async (page) => {
    setAvailablityPageNumber(page);
    const res = await QuestionariesApi.getInstructorDailyAvail(
      page,
      20,
      instructorInfo?.id
    );
    if (res.status === 200) {
      setDailyAvailability(res?.data);
    }
  };

  const addButtonClick = useCallback(() => {
    setUpdateInstructorInfoModal(false);
    setAddInstructorInfoModal(true);
    setEditData({});
  }, []);

  const editButtonClick = useCallback(async (param) => {
    setLoader(true);
    const values = {
      page: 1,
      limit: 100,
      form_identifier: param?.form_identifier,
    };
    const response = await QuestionariesApi.getIAllOptions(values);
    if (response.status === 200) {
      setAllOptions(response.data.results);
    } else {
      setAllOptions([]);
    }
    setEditData(param);
    setAddInstructorInfoModal(false);
    setUpdateInstructorInfoModal(true);
    setLoader(false);
  }, []);

  const deletePress = async (id) => {
    setLoader(true);
    try {
      const res = await QuestionariesApi.deleteInstructorInfo(id);
      if (res.status === 200) {
        dispatch(
          ActionCreators.getInstructorInfo(
            currentPageNumber,
            20,
            null,
            instructorInfo?.id
          )
        );
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const setsearchedText = (text) => {
    setCurrentPageNumber(1);
    dispatch(ActionCreators.getInstructorInfo(1, 20, text, instructorInfo?.id));
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    dispatch(
      ActionCreators.getInstructorInfo(page, 20, null, instructorInfo?.id)
    );
  };

  // const syncPress = async () => {
  //   setLoader(true);
  //   const res = await QuestionariesApi.syncInstructor(instructorInfo?.id);
  //   if (res.status === 200 || res.status === 201) {
  //     message.success(res?.data?.message ?? "Instructor sync successfully!");
  //   } else {
  //     message.error("Failed while sync Instructor data");
  //   }
  //   setLoader(false);
  // };

  const onSubmitModal = async (res) => {
    setmodalLoading(true);
    if (!res.errorFields) {
      res["instructor"] = instructorInfo?.id;
      const response = updateInstructorInfoVisible
        ? await QuestionariesApi.updateInstructorInfo(res, editData?.id)
        : await QuestionariesApi.addInstructorInfo(res);
      if (response.status === 200) {
        dispatch(
          ActionCreators.getInstructorInfo(
            currentPageNumber,
            20,
            null,
            instructorInfo.id
          )
        );
      } else {
        message.error("Failed to add/update form groups data!");
      }
      setmodalLoading(false);
      setAddInstructorInfoModal(false);
      setUpdateInstructorInfoModal(false);
    } else {
      setmodalLoading(false);
      setAddInstructorInfoModal(false);
      setUpdateInstructorInfoModal(false);
    }
  };

  const syncCliniko = async () => {
    setLoader(true);
    const res = await InstructorApi.syncInstructor(instructorInfo?.id);
    if (res.status === 200) {
      dispatch(ActionCreators.getInstructorDetailsAction(instructorInfo?.id));
      dispatch(
        ActionCreators.getInstructorInfo(1, 20, null, instructorInfo?.id)
      );
      message.success("Appointment inforamation in cliniko has been updated.");
    } else {
      message.error("Error! No data to sync!");
    }
    setLoader(false);
  };

  const appointmentColumns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "APPOINTMENT NAME",
      dataIndex: "appointment_name",
      render: (text) => <Space>{text}</Space>,
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: `id`,
      ellipsis: true,
      textWrap: "word-break",
      //   width: 100,
    },
    {
      title: "TITLE",
      dataIndex: "title",
      align: "center",
      //   width: 140,
      render: (text, record) => <Space size="middle">{text}</Space>,
    },
    {
      title: "IS SHOW",
      dataIndex: "is_show",
      align: "center",
      render: (text, record) =>
        text ? (
          <CheckCircleFilled style={{ color: "#22c55e" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#ef4444" }} />
        ),
    },
    {
      title: "ORDER",
      dataIndex: "order",
      align: "center",
      render: (text, record) => <Space size="middle">{text}</Space>,
    },

    {
      title: "ACTION",
      dataIndex: "role",
      key: "name",
      width: 200,
      align: "center",
      render: (text, record) => (
        <Space size="small">
          {showCreateUpdate && (
            <Button
              type="primary"
              shape="round"
              onClick={() => editButtonClick(record)}
            >
              EDIT
            </Button>
          )}

          {showDelete && (
            <Popconfirm
              title="Are you sure to delete this instructor info?"
              onConfirm={() => deletePress(record.id)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button shape="round" size="large" type="danger">
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const renderDay = (val) => {
    let day;
    switch (val) {
      case 1:
        day = "Monday";
        break;
      case 2:
        day = "Tuesday";
        break;
      case 3:
        day = "Wednesday";
        break;
      case 4:
        day = "Thursday";
        break;
      case 5:
        day = "Friday";
        break;
      case 6:
        day = "Saturday";
        break;
      case 7:
        day = "Sunday";
    }
    return day;
  };

  const availabilityColumns = [
    {
      title: "Day Of Week",
      dataIndex: "day_of_week",
      // align: "center",
      //   width: 140,
      render: (text, record) => <Space size="middle">{renderDay(text)}</Space>,
    },
    {
      title: "Studio Location",
      dataIndex: "studio_location",
      // align: "center",
      //   width: 140,
      render: (text, record) => (
        <Space size="middle">{text?.location_name}</Space>
      ),
    },
    {
      title: "Starts At",
      dataIndex: "availabilities",
      // align: "center",
      width: 150,
      render: (text, record) =>
        text?.length > 0
          ? text.map((res, key) => (
              <p size="middle" key={key}>
                {res?.starts_at}
              </p>
            ))
          : "N/A",
    },
    {
      title: "Ends At",
      dataIndex: "availabilities",
      width: 150,
      render: (text, record) =>
        text?.length > 0
          ? text.map((res, key) => (
              <p size="middle" key={key}>
                {res?.ends_at}
              </p>
            ))
          : "N/A",
    },
  ];

  return (
    <React.Fragment>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            Instructor Info
          </Title>
          <p style={{ textAlign: "left", marginBottom: 5 }}>
            <strong>Instructor Name</strong> :{" "}
            {instructorInfo?.name ?? "Not available"}
          </p>
          <p style={{ textAlign: "left", marginBottom: 5 }}>
            <strong>Instructor Email</strong> :{" "}
            {instructorInfo?.email ?? "Not available"}
          </p>
          <p style={{ textAlign: "left", marginBottom: 5 }}>
            <strong>Instructor Bio</strong> :{" "}
            {instructorInfo?.bio ?? "Not available"}
          </p>
          {/* <p style={{ textAlign: "left", marginBottom: 5 }}>
            <strong>Long Description </strong>:{" "}
            {instructorInfo?.instructor?.long_description ?? "Not available"}
          </p> */}
          <p style={{ textAlign: "left", marginBottom: 5 }}>
            <strong>TOTAL INSTRUCTOR INFOS </strong> :{" "}
            <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      {showCreateUpdate && (
        <Row style={{ marginBottom: 10 }}>
          <Col>
            <Button
              icon={<PlusCircleOutlined />}
              size={"large"}
              shape="round"
              type="primary"
              onClick={() => addButtonClick()}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              ADD INSTRUCTOR INFO
            </Button>
          </Col>
          {/* <Col>
            <Button
              size={"large"}
              shape="round"
              type="primary"
              onClick={() => syncPress()}
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 10,
              }}
            >
              SYNC
            </Button>
          </Col> */}
        </Row>
      )}
      <Row style={{ justifyContent: "space-between" }}>
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search here..."
            enterButton
            size="large"
            onSearch={(text) => setsearchedText(text)}
          />
        </Col>
      </Row>

      <Table
        style={{ marginTop: 10 }}
        columns={columns}
        dataSource={data.allInstructorInfo}
        scroll={{ x: 1, y: window.innerHeight - 350 }}
        size="middle"
        loading={loader}
        rowKey={(data) => data.id}
        pagination={{
          total: data.count,
          pageSize: 20,
          current: availablityPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            dailyAvailability(page);
          },
        }}
      />
      {/* <Button
        type="primary"
        style={{
          borderRadius: 40,
          height: 40,
          fontSize: 16,
          marginTop: 20,
          display: "block",
        }}
        onClick={() => syncCliniko()}
      >
        SYNC WITH CLINIKO
      </Button> */}
      <p style={{ fontSize: 20, fontWeight: "400" }}>Appointment Types</p>
      <Table
        style={{ marginTop: 10 }}
        columns={appointmentColumns}
        dataSource={instructorDetails?.details?.instructor?.appointment_types}
        scroll={{ x: 1, y: window.innerHeight - 350 }}
        size="middle"
        loading={loader}
        rowKey={(data) => data.id}
        pagination={null}
      />
      <p style={{ fontSize: 20, fontWeight: "400" }}>In-Person Appointments</p>
      <Table
        style={{ marginTop: 10 }}
        columns={availabilityColumns}
        dataSource={
          dailyAvailibility?.results?.length > 0
            ? dailyAvailibility?.results?.filter(
                (res) => res?.studio_location?.location_name !== "Alberta"
              )
            : []
        }
        scroll={{ x: 1, y: window.innerHeight - 350 }}
        size="middle"
        loading={loader}
        rowKey={(data) => data.id}
        pagination={false}
      />

      <p style={{ fontSize: 20, fontWeight: "400", marginTop: 20 }}>
        Virtual Appointments
      </p>
      <Table
        style={{ marginTop: 10 }}
        columns={availabilityColumns}
        dataSource={
          dailyAvailibility?.results?.length > 0
            ? dailyAvailibility?.results?.filter(
                (res) =>
                  res?.studio_location?.location_name !==
                  "DiveThru Edmonton Studio"
              )
            : []
        }
        scroll={{ x: 1, y: window.innerHeight - 350 }}
        size="middle"
        loading={loader}
        rowKey={(data) => data.id}
        pagination={false}
      />

      <Modal
        title={
          updateInstructorInfoVisible
            ? "UPDATE INSTRUCTOR INFOS"
            : "ADD INSTRUCTOR INFOS"
        }
        open={
          updateInstructorInfoVisible
            ? updateInstructorInfoVisible
            : addInstructorInfoVisible
        }
        footer={null}
        closable={false}
      >
        <AddEditInstructorInfoModal
          setVisibleModal={
            updateInstructorInfoVisible
              ? setUpdateInstructorInfoModal
              : setAddInstructorInfoModal
          }
          onSubmitModal={onSubmitModal}
          data={editData}
          modalLoading={modalLoading}
          setmodalLoading={setmodalLoading}
          dispatch={dispatch}
          allIdentifiers={data?.allIdentifiers}
          allOptions={allOptions}
          setAllOptions={setAllOptions}
          mainData={data.allInstructorInfo}
        />
      </Modal>
    </React.Fragment>
  );
};

export default InstructorInfo;
