/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class ScalingApi {
  static async getAllScaling(payload) {
    const response = await http.get("/api/admin/scaling", payload);
    return response;
  }
  static async getScalingDetails(payload) {
    const response = await http.get(`/api/admin/scaling/${payload.id}`);
    return response;
  }
  static async deleteSingleScaling(id) {
    const response = await http.del(`api/admin/scaling/${id}`);
    return response;
  }

  static async editScaling(payload, id) {
    if (!payload.priority) delete payload.priority;

    const response = await http.put(`api/admin/scaling/${id}`, payload);
    return response;
  }

  static async addScaling(payload) {
    if (!payload.priority) delete payload.priority;

    const response = await http.post("api/admin/scaling", payload);
    return response;
  }
}
