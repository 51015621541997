import { constant } from "../Constant";

// feeling
export const getCourseAction = (page, limit, q) => {
  return {
    type: constant.GET_COURSE,
    page: page,
    limit: limit,
    q: q,
  };
};
