import { put } from "redux-saga/effects";
import { BenefitApi } from "../api";
import { constant } from "../Constant";
// workers
function* getBenefitsAction(param) {
  try {
    const res = yield BenefitApi.getBenefits({
      page: param.page,
      limit: param.limit,
      q: param.q,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_BENEFITS_SUCCESS,
        benefits: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_BENEFITS_FAILED,
        errMsg: "Invalid benefit",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_BENEFITS_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
function* getBenefitDetailsAction(param) {
  try {
    const res = yield BenefitApi.getBenefitDetails(param);
    if (res.status === 200) {
      const payload = {
        type: constant.GET_BENEFIT_DETAILS_SUCCESS,
        details: res.data,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_BENEFITS_FAILED,
        errMsg: "Invalid benefit",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_BENEFITS_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
export { getBenefitsAction, getBenefitDetailsAction };
