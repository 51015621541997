/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class InstructorApi {
  static async getInstructors(payload) {
    const response = await http.get("/api/admin/instructor", payload);
    return response;
  }
  static async getInstructorDetails(payload) {
    const response = await http.get(`/api/admin/instructor/${payload.id}`);
    return response;
  }
  static async deleteSingleInstructor(id) {
    const response = await http.del(`/api/admin/instructor/${id}`);
    return response;
  }

  static async editInstructor(payload, id) {
    const response = await http.put(`/api/admin/instructor/${id}`, payload);
    return response;
  }

  static async addInstructor(payload) {
    const response = await http.post("/api/admin/instructor", payload);
    return response;
  }

  static async syncInstructor(id) {
    const response = await http.post(
      `/api/admin/instructor/${id}/sync-cliniko-appointment-types/`
    );
    return response;
  }
  static async syncInstructors() {
    const response = await http.post(
      `/api/admin/instructors/sync-cliniko-data`
    );
    return response;
  }
}
