import { constant } from "../Constant";

const initialState = {
  loading: true,
  loginStatus: false,
  access_token: "",
  refresh_token: "",
  errMsg: "",
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.USER_LOGIN:
      return {
        ...state,
        loading: true,
      };
    case constant.LOGIN_SUCCESS: {
      return {
        ...state,
        loginStatus: action.loginStatus,
        access_token: action.access_token,
        refresh_token: action.refresh_token,
        loading: false,
      };
    }
    case constant.LOGIN_FAILED: {
      return {
        ...state,
        loginStatus: action.loginStatus,
        errMsg: action.err,
        loading: false,
      };
    }
    case constant.LOGOUT: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.LOGOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loginStatus: false,
        access_token: "",
        refresh_token: "",
        errMsg: "",
      };
    }
    default:
      return state;
  }
};

export default AuthReducer;
