/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class SoloDivesApi {
  static async getAllSoloDives(payload) {
    const response = await http.get("/api/admin/solo_dive", payload);
    return response;
  }
  static async getSoloDivesDetails(payload) {
    const response = await http.get(`/api/admin/solo_dive/${payload}`);
    return response;
  }
  static async deleteSingleSoloDives(id,isDelete) {
    const response = await http.del(`api/admin/solo_dive/${id}?delete=${isDelete}`);
    return response;
  }

  static async editSoloDives(payload, id) {
    if (!payload.priority) delete payload.priority;

    const response = await http.put(`api/admin/solo_dive/${id}`, payload);
    return response;
  }

  static async addSoloDives(payload) {
    if (!payload.priority) delete payload.priority;

    const response = await http.post("api/admin/solo_dive", payload);
    return response;
  }

  static async addSoloDiveContent(id, payload) {
    const response = await http.post(
      `api/admin/solo_dive/${id}/solo_dive_content`,
      payload
    );
    return response;
  }
  static async deleteSoloDiveContent(payload) {
    const response = await http.del(
      `api/admin/solo_dive/${payload.solo_dive_id}/solo_dive_content/${payload.solo_dive_content_id}`    );
    return response;
  }
}
