import { Alert, Button, Card, Form, Input, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../store";
import { AuthApi } from "../../store/api";
import "./style.css";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showErrorMEssage, setshowErrorMEssage] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginAction } = bindActionCreators(ActionCreators, dispatch);
  const me = useSelector((state) => state.MeReducer?.response);
  const isAuthenticated = useSelector((state) => state.AuthReducer.loginStatus);
  const authReducer = useSelector((state) => state.AuthReducer);
  const [errorMessage, seterrorMessage] = useState("");
  const [loading, setloading] = useState(false);

  // useEffect(() => {
  //   console.log(me);
  // }, [me]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/users");
    } else {
      seterrorMessage(authReducer.errMsg);
    }

    if (loading) {
      if (!authReducer.loading) {
        setloading(false);
      }
    }
    return () => {};
  }, [isAuthenticated, authReducer, navigate, loading]);

  //validation
  const handleValidation = (event) => {
    let formIsValid = true;
    if (!email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
      formIsValid = false;
      message.error("Enter Valid Email");

      return false;
    } else {
      formIsValid = true;
    }
    if (password === "") {
      formIsValid = false;
      message.error("Password cannot be empty");

      return false;
    } else {
      formIsValid = true;
    }
    return formIsValid;
  };

  const onPressLogin = (e) => {
    if (handleValidation() === true) {
      setloading(true);
      setshowErrorMEssage(true);
      loginAction(email, password); //call redux action
    }
  };

  const onSubmitResetPassword = async (value) => {
    let payload = {
      current_password: value.current_password,
      new_password: value.new_password,
    };

    const response = await AuthApi.resetPassword(payload);

    console.log(response);
  };

  return (
    <div className="loginFormContainer">
      <div className="row d-flex justify-content-center">
        <Card
          title="Admin Login"
          bordered={true}
          style={{ width: 400, alignContent: "center" }}
        >
          {loading ? (
            <Spin size="large" />
          ) : (
            <Form
              name="basic"
              layout="vertical"
              autoComplete="off"
              onFinish={onPressLogin}
            >
              <Form.Item
                label="Email"
                name="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                rules={[
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              >
                <Input.Password />
              </Form.Item>
              {errorMessage !== "" && showErrorMEssage && (
                <Form.Item>
                  <Alert message={errorMessage} type="error" />
                </Form.Item>
              )}
              <Form.Item>
                <Button type="primary" block htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>
          )}
        </Card>
      </div>
    </div>
  );
}
