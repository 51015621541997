import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Calendar,
  Card,
  Col,
  Divider,
  Image,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddOrEditGroupMemberModal from "../../components/modals/AddOrEditGroupMemberModal";
import { AppointmentApi, LogsApi } from "../../store/api";
import ModalScreen from "./ModalScreen";
import styles from "./style.module.scss";

export default function AppointmentDetail() {
  const { id } = useParams();
  const [detailData, setdetailData] = useState();
  const [isCompleted, setisCompleted] = useState(false);
  const [loading, setloading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [previewModal, setPreviewModal] = useState(false);
  const [paymentLog, setpaymentLog] = useState();
  const me = useSelector((state) => state.MeReducer?.response);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  const [instructorAvailablibilityData, setinstructorAvailablibilityData] =
    useState(null);
  const [calendarLoading, setcalendarLoading] = useState(false);
  const [selectedDate, setselectedDate] = useState(moment.utc().format());
  const [selectedMonth, setselectedMonth] = useState(moment.utc().format());
  const [availableTimes, setavailableTimes] = useState([]);
  const [formItemLoading, setformItemLoading] = useState(false);
  const [selectedTime, setselectedTime] = useState();
  const [disablePreviousMonth, setdisablePreviousMonth] = useState(false);
  const [showAddOrEditGroupMember, setshowAddOrEditGroupMember] =
    useState(false);
  const [editGroupMemberData, seteditGroupMemberData] = useState();
  const [reloadKey, setReloadKey] = useState(0);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex(
        (item) => item?.type === "appointment"
      );
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  useEffect(() => {
    setselectedTime(null);
    // form.setFieldValue("start_at", null);
    // if (formInstructor) {
    let array = instructorAvailablibilityData?.available_times?.filter(
      (item) => {
        if (
          moment.utc(selectedDate).local().format("YYYY-MM-DD") ===
          moment.utc(item).local().format("YYYY-MM-DD")
        ) {
          return item;
        }
      }
    );
    setavailableTimes(array);
    // }
  }, [selectedDate]);

  useEffect(() => {
    let a = moment().format("M");
    let b = moment(selectedMonth).format("M");

    if (parseInt(b) <= parseInt(a)) {
      setdisablePreviousMonth(true);
    } else {
      setdisablePreviousMonth(false);
    }
    if (detailData?.instructor) {
      getInstructorAvailablity();
    }
  }, [selectedMonth]);

  const getAppointmentDetail = async () => {
    setloading(true);
    const response = await AppointmentApi.getAppointmentDetail(id);
    if (response?.status === 200) {
      setdetailData(response?.data);
      setisCompleted(
        response?.data?.is_completed === true
          ? 2
          : response?.data?.is_completed === false
          ? 3
          : 1
      );
      setReloadKey((prevKey) => prevKey + 1);
      setloading(false);
    } else {
      setloading(false);
    }
  };

  const getAppointmentsDetailsLog = async () => {
    const res = await LogsApi.getAppointmentsDetailsLog(id);
    if (res.status === 200) {
      setpaymentLog(res.data?.results);
      // navigate("/appointments-log-details", {
      //   state: { data: res.data },
      // });
    }
  };

  const getInstructorAvailablity = async () => {
    setcalendarLoading(true);
    const response = await AppointmentApi.getInstructorAvailablity(
      detailData?.instructor?.id,
      detailData?.studio_location?.id,
      detailData?.appointment_type?.id,
      selectedMonth
    );
    if (response?.status === 200) {
      setinstructorAvailablibilityData(response?.data);
      setcalendarLoading(false);
    } else {
      setcalendarLoading(false);
    }
  };

  useEffect(() => {
    getAppointmentDetail();
    getAppointmentsDetailsLog();
    return () => {
      setdetailData(null);
    };
  }, []);

  useEffect(() => {
    if (detailData?.instructor) {
      getInstructorAvailablity();
    }
  }, [detailData]);

  const onSubmitConfirmReschedule = async () => {
    if (!moment(selectedTime).isValid()) {
      message.error("Please select valid date and time.");
      return;
    }

    const response = await AppointmentApi.rescheduleAppointment(
      detailData?.id,
      {
        start_at: moment.utc(selectedTime).format(),
      }
    );

    if (response.status === 201 || response.status === 200) {
      message.success(response?.data?.message);
      getAppointmentDetail();
      getAppointmentsDetailsLog();
    } else {
      message.error(response?.data?.detail);
    }

    console.log(response);
  };

  const onChangeCompletedStatus = async (e) => {
    let param =
      e.target.value === 2 ? true : e.target.value === 3 ? false : null;
    setloading(true);
    const response = await AppointmentApi.editAppointment(id, {
      is_completed: param,
    });
    if (response?.status === 200) {
      getAppointmentDetail();
      setisCompleted(e.target.value);
      setloading(false);
      message.success("Information has been updated.");
    } else {
      message.error(response?.data?.detail);
      setloading(false);
    }
  };

  const createMarkup = async () => {
    const res = await AppointmentApi.preview(detailData.id);
    await setModalData(res?.data);
    setPreviewModal(true);
  };

  const columns = [
    {
      title: "EVENT TYPE",
      dataIndex: "event_type",
    },
    {
      title: "MESSAGE",
      dataIndex: "message",
      render: (a) => a ?? "Not Available",
    },
    {
      title: "CREATED AT",
      dataIndex: "created_at",
      render: (a) =>
        moment.utc(a).local().format("YYYY-MM-DD hh:mm A") ?? "Not Available",
    },
  ];

  return (
    <Row>
      {loading ? (
        <div style={{ position: "fixed", top: "50%", left: "50%" }}>
          <Spin size="large" />
        </div>
      ) : (
        <Col span={24}>
          <Divider orientation="left">Therpist Detail</Divider>
          <Row gutter={20}>
            <Col>
              <Image
                width={200}
                src={detailData?.instructor?.instructor?.image_path}
              />
            </Col>
            <Col style={{ textAlign: "left" }}>
              <h2>
                {" "}
                {detailData?.instructor?.name} (
                {detailData?.instructor?.instructor?.pronoun})
              </h2>
              <p>{detailData?.instructor?.email}</p>
              <p> {detailData?.instructor?.instructor?.designation}</p>
              <p> {detailData?.instructor?.instructor?.title}</p>
              <p> ${detailData?.instructor?.instructor?.rate}</p>
              {detailData?.payment && (
                <Row gutter={8}>
                  <Col>
                    {showCreateUpdate && (
                      <Button
                        type="primary"
                        shape="round"
                        size="small"
                        onClick={() => createMarkup()}
                      >
                        PREVIEW
                      </Button>
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Divider orientation="left">Appointment Detail</Divider>
          <Row gutter={20}>
            <Col
              lg={12}
              md={12}
              sm={24}
              xs={24}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p>
                <strong>Appointment Name: </strong>
                {detailData?.appointment_type?.appointment_name}
              </p>
              <p>
                <strong>Appointment Key Types: </strong>
                {detailData?.appointment_type?.key_types?.map(
                  (item) => item + ", "
                )}
              </p>
              <p>
                <strong>Created At: </strong>
                {detailData?.created_at &&
                  moment
                    .utc(detailData?.created_at)
                    .local()
                    .format("MM/DD/YYYY hh:mm:A")}
              </p>
              <p>
                <strong>Started At: </strong>
                {detailData?.start_at &&
                  moment
                    .utc(detailData?.start_at)
                    .local()
                    .format("MM/DD/YYYY hh:mm:A")}
              </p>
              <p>
                <strong>Updated At: </strong>
                {detailData?.updated_at &&
                  moment
                    .utc(detailData?.updated_at)
                    .local()
                    .format("MM/DD/YYYY hh:mm:A")}
              </p>
              {detailData?.cancel && (
                <p>
                  <strong>Cancelled At: </strong>
                  {detailData?.cancel_at &&
                    moment
                      .utc(detailData?.cancel_at)
                      .local()
                      .format("MM/DD/YYYY hh:mm:A")}
                </p>
              )}
              <div>
                <strong> Is Completed: </strong>

                <Radio.Group
                  onChange={onChangeCompletedStatus}
                  value={isCompleted}
                >
                  <Radio value={1}>Not Selected</Radio>
                  <Radio value={2}>Arrived</Radio>
                  <Radio value={3}>Did not Arrived</Radio>
                </Radio.Group>
              </div>
              <p style={{ marginTop: 15 }}>
                <strong>Payment Status: </strong>
                {detailData?.payment
                  ? "Payment Completed"
                  : "Payment Not Completed"}
              </p>
              <p>
                <strong>Reschedule Status: </strong>
                {detailData?.reschedule ? "Rescheduled" : " Not Rescheduled"}
              </p>
              {detailData?.reschedule_at && (
                <p>
                  <strong>Rescheduled At: </strong>
                  {moment
                    .utc(detailData?.reschedule_at)
                    .local()
                    .format("MM/DD/YYYY hh:mm:A")}
                </p>
              )}
              <p>
                <strong>Studio location: </strong>
                {detailData?.studio_location?.location_name}
              </p>
              <p>
                <strong>Amount: </strong>
                {detailData?.amount_money?.amount}{" "}
                {detailData?.amount_money?.currency}
              </p>
            </Col>

            {!detailData?.cancel && isCompleted !== 2 && (
              <Col lg={12} md={12} sm={24} xs={24}>
                <Card>
                  <Calendar
                    fullscreen={false}
                    disabledDate={(date) => {
                      // if (formAppointmentType && formInstructor && formLocation) {
                      if (
                        instructorAvailablibilityData?.available_times?.find(
                          (item) =>
                            moment(item).format("YYYY-MM-DD") ===
                            moment(date).format("YYYY-MM-DD")
                        )
                      ) {
                        return false;
                      }
                      return true;
                      // }

                      // else {
                      //   return true;
                      // }
                    }}
                    headerRender={({ value, type, onChange, onTypeChange }) => {
                      const current = value.clone();
                      const localeData = value.localeData();

                      const months = [];
                      for (let i = 0; i < 12; i++) {
                        current.month(i);
                        months.push(localeData.monthsShort(current));
                      }

                      return (
                        <div
                          style={{
                            padding: 3,
                          }}
                        >
                          <Typography.Title
                            level={4}
                            style={{ textAlign: "center", marginBottom: 10 }}
                          >
                            Reschedule Appointment
                          </Typography.Title>
                          <Card
                            bodyStyle={{
                              padding: 10,
                              backgroundColor: "#F5F5F5",
                            }}
                          >
                            <Row
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Col>
                                {!disablePreviousMonth && (
                                  <LeftOutlined
                                    className={styles.rightIcon}
                                    onClick={() => {
                                      onChange(
                                        moment(value?.clone()).add(-1, "month")
                                      );
                                    }}
                                  />
                                )}
                              </Col>
                              <Col>
                                <p className={styles.selectedMonthText}>
                                  {moment(value?.clone()).format("MMMM")} -{" "}
                                  {moment(value?.clone()).format("YYYY")}
                                </p>
                              </Col>
                              <Col>
                                <RightOutlined
                                  className={styles.rightIcon}
                                  onClick={() => {
                                    onChange(
                                      moment(value?.clone()).add(1, "month")
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </Card>
                        </div>
                      );
                    }}
                    onPanelChange={(value, mode) => {
                      setselectedMonth(moment.utc(value).format());
                    }}
                    onSelect={(data) => {
                      setselectedDate(moment.utc(data).format());
                    }}
                    value={moment(selectedDate)}
                  />

                  <Select
                    style={{
                      textAlign: "left",
                      width: "100%",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                    placeholder="Select Time"
                    onChange={(data) => {
                      console.log(moment.utc(data).format());
                      setselectedTime(data);
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {availableTimes?.map((time) => (
                      <Select.Option key={time} value={time}>
                        {moment.utc(time).local().format("hh:mm A")}
                      </Select.Option>
                    ))}
                  </Select>
                  <Button
                    type="primary"
                    block
                    onClick={onSubmitConfirmReschedule}
                  >
                    Confirm Reschedule
                  </Button>
                </Card>
              </Col>
            )}

            {detailData?.group_patients?.length > 0 && (
              <Col span={24} style={{ textAlign: "left" }}>
                <Card
                  title={"Group Patient(s)"}
                  extra={
                    <Button
                      type="primary"
                      onClick={() => {
                        // seteditGroupMemberData(detailData);
                        // setTimeout(() => {
                        //   setshowAddOrEditGroupMember(true);
                        // }, 500);
                        setshowAddOrEditGroupMember(true);
                      }}
                    >
                      Edit Group Member
                    </Button>
                  }
                >
                  <Row>
                    {detailData?.group_patients?.map((patient) => (
                      <Col
                        lg={6}
                        md={8}
                        sm={24}
                        xs={24}
                        key={patient?.cliniko_patient_id}
                      >
                        <p>
                          <strong> Name: </strong>
                          {patient?.user?.first_name} {patient?.user?.last_name}
                        </p>
                        <p style={{ wordBreak: "normal" }}>
                          <strong> Email: </strong>
                          {patient?.user?.email}
                        </p>

                        <p style={{ wordBreak: "normal" }}>
                          <strong> Cliniko Patient Id: </strong>
                          {patient?.cliniko_patient_id}
                        </p>
                      </Col>
                    ))}
                  </Row>
                </Card>
              </Col>
            )}
          </Row>
          <Divider orientation="left">User Detail</Divider>
          <Row gutter={20}>
            <Col style={{ textAlign: "left" }} span={24}>
              <Row>
                <Col span={24}>
                  <p>
                    <strong>User name: </strong>
                    {detailData?.user?.name}
                  </p>
                  <p>
                    <strong>User email: </strong>
                    {detailData?.user?.email}
                  </p>
                  <p>
                    <strong>User appointment location: </strong>
                    {detailData?.user_appointment_location}
                  </p>
                  <p>
                    <strong>User appointment type: </strong>
                    {detailData?.user_appointment_type}
                  </p>
                  <p>
                    <strong>User time duration: </strong>
                    {detailData?.user_time_duration}
                  </p>
                  <p>
                    <strong>User Appointment Category: </strong>
                    {detailData?.user_appointment_category}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider orientation="left">Payment Detail</Divider>
          <Row gutter={16} style={{ alignItems: "center" }}>
            <Col span={12} style={{ textAlign: "left" }}>
              {detailData?.payment ? (
                <>
                  {detailData?.payment_details?.response_dict?.source_type ===
                  "CARD" ? (
                    <>
                      <Card
                        title={`${detailData?.payment_details?.response_dict?.source_type} PAYMENT`}
                      >
                        <p>
                          <strong>Card Brand: </strong>
                          {
                            detailData?.payment_details?.response_dict
                              ?.card_details?.card?.card_brand
                          }
                          {"      "}
                          <strong>Card Type: </strong>
                          {
                            detailData?.payment_details?.response_dict
                              ?.card_details?.card?.card_type
                          }
                        </p>
                        <p>
                          <strong>Card Number: </strong>

                          {detailData?.payment_details?.response_dict
                            ?.card_details?.card?.last_4
                            ? `****${detailData?.payment_details?.response_dict?.card_details?.card?.last_4}`
                            : "N/A"}
                        </p>
                        <p>
                          <strong>Pre Paid Type: </strong>
                          {
                            detailData?.payment_details?.response_dict
                              ?.card_details?.card?.prepaid_type
                          }
                        </p>
                        <p>
                          <strong>Expire Month: </strong>
                          {
                            detailData?.payment_details?.response_dict
                              ?.card_details?.card?.exp_month
                          }
                          {"   "}
                          <strong>Expire Year: </strong>
                          {
                            detailData?.payment_details?.response_dict
                              ?.card_details?.card?.exp_year
                          }
                        </p>

                        <p>
                          <strong>Enabled: </strong>
                          {detailData?.payment_details?.square_card_id?.enabled
                            ? "Enabled"
                            : " Not Enabled"}
                        </p>
                      </Card>
                    </>
                  ) : (
                    <>
                      <Card
                        title={`${detailData?.payment_details?.response_dict?.source_type} PAYMENT`}
                      >
                        <p>
                          <strong>Customer Supplied Amount: </strong>
                          {
                            detailData?.payment_details?.response_dict
                              ?.cash_details?.buyer_supplied_money?.amount
                          }{" "}
                          {
                            detailData?.payment_details?.response_dict
                              ?.cash_details?.buyer_supplied_money?.currency
                          }
                        </p>

                        <p>
                          <strong>Change Amount: </strong>
                          {
                            detailData?.payment_details?.response_dict
                              ?.cash_details?.change_back_money?.amount
                          }{" "}
                          {
                            detailData?.payment_details?.response_dict
                              ?.cash_details?.change_back_money?.currency
                          }
                        </p>
                      </Card>
                    </>
                  )}
                </>
              ) : (
                <Alert
                  type="info"
                  showIcon
                  message=" Payment is not completed."
                />

                // <Card size="small">
                //   <p style={{ fontSize: 20, fontWeight: "bold" }}>
                //     Payment is not completed.
                //   </p>
                // </Card>
              )}
            </Col>
            <Col span={12} style={{ textAlign: "left" }}>
              {detailData?.user_card_info && (
                <Card title={"USER ATTACHED CARD INFO"}>
                  {/* <p>
                      <strong>Id: </strong>
                      {detailData?.user_card_info?.id}
                    </p> */}
                  <p>
                    <strong>Card Brand: </strong>
                    {detailData?.user_card_info?.card_brand}
                    {"      "}
                    <strong>Card Type: </strong>
                    {detailData?.user_card_info?.card_type}
                  </p>
                  <p>
                    <strong>Card Number: </strong>
                    ****{detailData?.user_card_info?.card_number}
                  </p>
                  <p>
                    <strong>Square Card Id: </strong>
                    {detailData?.user_card_info?.square_card_id}
                  </p>
                  <p>
                    <strong>Expire Month: </strong>
                    {detailData?.user_card_info?.expire_month}
                    {"   "}
                    <strong>Expire Year: </strong>
                    {detailData?.user_card_info?.expire_year}
                  </p>

                  <p>
                    <strong>Enabled: </strong>
                    {detailData?.user_card_info?.enabled
                      ? "Enabled"
                      : " Not Enabled"}
                  </p>
                </Card>
              )}
            </Col>
          </Row>
          <Divider orientation="left">Cliniko Detail</Divider>
          <Row gutter={20}>
            <Col style={{ textAlign: "left" }}>
              <p>
                <strong>Cliniko Appointment Type Id: </strong>
                {detailData?.appointment_type?.cliniko_appointment_type_id}
              </p>
              <p>
                <strong>Cliniko Appointment Status: </strong>
                {detailData?.cliniko_action_status}
              </p>
              <p>
                <strong>Cliniko Practioner Id: </strong>
                {detailData?.instructor?.instructor?.cliniko_practitioner_id}
              </p>

              <p>
                <strong>Cliniko Location Id: </strong>
                {detailData?.studio_location?.cliniko_location_id}
              </p>
            </Col>
          </Row>
          {paymentLog?.length > 0 && (
            <>
              <Divider orientation="left">Appointment Log</Divider>
              <Row>
                <Col span={24}>
                  <Table
                    rowKey={(data) => data.id}
                    columns={columns}
                    dataSource={paymentLog}
                    scroll={{ x: 1, y: window.innerHeight - 280 }}
                    size="middle"
                    pagination={false}
                  />
                </Col>
              </Row>
            </>
          )}
          <Divider />
        </Col>
      )}
      <Modal
        open={previewModal && modalData}
        footer={null}
        closable={true}
        onCancel={() => setPreviewModal(false)}
        width={800}
        // width={595}
      >
        <ModalScreen modalData={modalData} />
      </Modal>

      <Modal
        open={showAddOrEditGroupMember}
        footer={null}
        destroyOnClose
        closable={true}
        title={"Edit Group Member"}
        onCancel={() => setshowAddOrEditGroupMember(false)}
        width={800}
        // width={595}
      >
        <AddOrEditGroupMemberModal
          detailData={detailData}
          getAppointmentDetail={getAppointmentDetail}
          key={reloadKey}
        />
      </Modal>
    </Row>
  );
}
