import { put } from "redux-saga/effects";
import { SoloDiveOverviewApi } from "../api";
import { constant } from "../Constant";
// workers
function* getSoloDiveOverviewAction(param) {
  try {
    const res = yield SoloDiveOverviewApi.getAllSoloDiveOverview({
      page: param.page,
      limit: param.limit,
      q: param.q
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_SOLO_DIVE_OVERVIEW_SUCCESS,
        soloDiveOverview: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_SOLO_DIVE_OVERVIEW_FAILED,
        errMsg: "Invalid scaling",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_SOLO_DIVE_OVERVIEW_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
function* getScalingDetailsAction(param) {
  try {
    const res = yield SoloDiveOverviewApi.getScalingDetails(param);
    if (res.status === 200) {
      const payload = {
        type: constant.GET_SCALING_DETAILS_SUCCESS,
        details: res.data,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_SOLO_DIVE_OVERVIEW_FAILED,
        errMsg: "Invalid scaling",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_SOLO_DIVE_OVERVIEW_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
export { getSoloDiveOverviewAction, getScalingDetailsAction };
