import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { bindActionCreators } from "redux";
import Appointment from "../pages/appointment";
import AppointmentDetail from "../pages/appointment/appointmentDetail";
import CreateAppointment from "../pages/appointment/createAppointment";
import AppointmentPolicies from "../pages/appointmentPolicies";
import AppointmentType from "../pages/appointmentType";
// benefit
import Benefit from "../pages/benefit/Benefit";
// category
import Category from "../pages/category/Category";
import ConsentForm from "../pages/consentForm";
import AddOrEditConsent from "../pages/consentForm/AddOrEditConsent";
// content
import Content from "../pages/content/Content";
//course
import Course from "../pages/course";
import CourseDetails from "../pages/course/CourseDetails";
// dailydive
import DailyDive from "../pages/dailydive/DailyDive";
import Dashboard from "../pages/dashboard/Dashboard";
import SoloDiveOverview from "../pages/dives/SoloDiveOverview";
import SoloDives from "../pages/dives/SoloDives";
import SoloDivesDetails from "../pages/dives/SoloDivesDetails";
import Favourite from "../pages/favourite/Favourite";
// feeling
import Feeling from "../pages/feeling/Feeling";
import FormGroups from "../pages/formGroups";
import AddEditForm from "../pages/formGroups/AddEditForm";
import Form from "../pages/formGroups/Form";
import Identifier from "../pages/identifier/Identifier";
import Options from "../pages/identifier/Options";
// instructor
import Instructor from "../pages/instructor/Instructor";
import InstructorInfo from "../pages/instructorInfo/InstructorInfo";
// interval
import Interval from "../pages/interval/Interval";
import Locations from "../pages/location";
import Login from "../pages/login/Login";
import AppointmentLogs from "../pages/logs/AppointmentLogs";
import AppointmentLogsDetails from "../pages/logs/AppointmentLogsDetails";
// mood
import Mood from "../pages/mood/Mood";
import Notification from "../pages/notification/Notification";
import Overview from "../pages/overview/Overview";
// reminder
import Reminder from "../pages/reminder/Reminder";
import PremiumContentReport from "../pages/report/PremiumContentReport";
import UserReport from "../pages/report/UserReport";
import RequestedLocation from "../pages/requestedLocation/RequestedLocation";
// scaling
import Scaling from "../pages/scaling/Scaling";
import Settings from "../pages/settings/Settings";
import Subscriber from "../pages/subscriber/Subscriber";
import SupportInfo from "../pages/supportInfo";
// tag
import Tag from "../pages/tag/Tag";
import User from "../pages/user/User";
import UserChapter from "../pages/userChapter";
import UserContent from "../pages/userContent/UserContent";
import UserCourse from "../pages/userCourse";
import UserCourseContent from "../pages/userCourseContent/UserCourseContent";
import UserCourseReview from "../pages/userCourseReview/UserCourseReview";
import UserDetail from "../pages/userDetail";
import IntakeForm from "../pages/userDetail/IntakeForm";
import UserProfile from "../pages/userProfile";
import UserSubmittedForms from "../pages/userSubmittedForms/UserSubmittedForms";
import UserSubmittedFormsDetails from "../pages/userSubmittedForms/UserSubmittedFormsDetails";
import { ActionCreators } from "../store";

function RequireAuth({ children, redirectTo }) {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.AuthReducer.loginStatus);
  const { logOutAction } = bindActionCreators(ActionCreators, dispatch); //call action
  const meReducer = useSelector((state) => state.MeReducer?.response);
  if (meReducer?.role === "BASIC") {
    logOutAction();
    return <Navigate to={redirectTo} />;
  } else {
    return isAuthenticated ? children : <Navigate to={redirectTo} />;
  }
}

const Navigations = () => {
  const meReducer = useSelector((state) => state.MeReducer?.response);

  const getInitialRouteAfterLoging = () => {
    if (meReducer?.role === "BASIC") {
      return <Navigate to="/login" />;
    } else {
      return <Navigate to="/users" />;
    }
  };

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route
        path="*"
        element={
          <RequireAuth redirectTo="/login">
            <Routes>
              <Route path="*" element={getInitialRouteAfterLoging()} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/users" element={<User />} />
              <Route path="/user-detail/:id" element={<UserDetail />} />
              <Route path="/user/:id" element={<UserProfile />} />
              <Route path="/subscribers" element={<Subscriber />} />
              {/* user content */}
              <Route path="/user_content" element={<UserContent />} />
              <Route path="/notification" element={<Notification />} />
              <Route path="/favourite" element={<Favourite />} />
              <Route path="/user_course" element={<UserCourse />} />
              <Route path="/user_chapter" element={<UserChapter />} />

              {/* user course content */}
              <Route
                path="/user_course_content"
                element={<UserCourseContent />}
              />

              {/* user course content */}
              <Route
                path="/user_course_review"
                element={<UserCourseReview />}
              />

              {/* instructor */}
              <Route path="/instructors" element={<Instructor />} />
              {/* content */}
              <Route path="/content" element={<Content />} />
              {/* feeling */}
              <Route path="/feeling" element={<Feeling />} />
              {/* category */}
              <Route path="/category" element={<Category />} />
              {/* settings */}
              <Route path="/settings" element={<Settings />} />
              {/* tag */}
              <Route path="/tag" element={<Tag />} />
              {/* overview */}
              <Route path="/overview" element={<Overview />} />
              {/* interval */}
              <Route path="/interval" element={<Interval />} />
              {/* benefit */}
              <Route path="/benefit" element={<Benefit />} />
              {/* scaling */}
              <Route path="/scaling" element={<Scaling />} />
              {/* mood */}
              <Route path="/mood" element={<Mood />} />
              {/* daily dive */}
              <Route path="/daily_dive" element={<DailyDive />} />
              {/* reminder */}
              <Route path="/reminder" element={<Reminder />} />
              {/* Course */}
              <Route path="/course" element={<Course />} />
              <Route path="/course_details" element={<CourseDetails />} />
              <Route path="/support_info" element={<SupportInfo />} />
              <Route
                path="/appointment_policies"
                element={<AppointmentPolicies />}
              />
              {/* <Route
                path="/permission_settings"
                element={<PermissionSettings />}
              />
              <Route
                path="/permission_group_details/:id"
                element={<AddOrEditPermissionGroup />}
              /> */}
              {/* <Route path="/secret_keys" element={<SecretKeys />} /> */}

              {/* dives */}
              <Route
                path="/solo_dive_overview"
                element={<SoloDiveOverview />}
              />
              <Route path="/solo_dives" element={<SoloDives />} />
              <Route
                path="/solo_dives_details/:id"
                element={<SoloDivesDetails />}
              />
              {/* report */}
              <Route path="/user_report" element={<UserReport />} />
              <Route
                path="/premium_content_report"
                element={<PremiumContentReport />}
              />
              <Route path="/identifier" element={<Identifier />} />
              <Route path="/identifier_details" element={<Options />} />
              <Route path="/location" element={<Locations />} />
              <Route path="/form-groups" element={<FormGroups />} />
              <Route path="/form-groups-details" element={<Form />} />
              <Route path="/add-edit-form" element={<AddEditForm />} />
              <Route path="/instructor-info" element={<InstructorInfo />} />
              <Route path="/appointment" element={<Appointment />} />
              <Route
                path="/create-appointment"
                element={<CreateAppointment />}
              />
              <Route
                path="/appointment-detail/:id"
                element={<AppointmentDetail />}
              />
              <Route path="/appointment_types" element={<AppointmentType />} />

              <Route
                path="/requested-location"
                element={<RequestedLocation />}
              />
              <Route
                path="/user-submitted-form"
                element={<UserSubmittedForms />}
              />
              <Route
                path="/user-submitted-form-details"
                element={<UserSubmittedFormsDetails />}
              />

              <Route path="/intake-form" element={<IntakeForm />} />

              <Route path="/appointments-log" element={<AppointmentLogs />} />
              <Route path="/consent_form" element={<ConsentForm />} />
              <Route path="/consent_form/:id" element={<AddOrEditConsent />} />
              <Route
                path="/appointments-log-details"
                element={<AppointmentLogsDetails />}
              />
            </Routes>
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default Navigations;
