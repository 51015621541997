import { Col, Divider, Row, Typography } from "antd";
import moment from "moment";
import React from "react";

const { Text } = Typography;

export default function UserContentDetailModal({ data }) {
  return (
    <>
      <Row>
        <Col>
          <Text>ID:</Text>
        </Col>
        <Col offset={1}>
          <Text level={5}>{data?.id}</Text>
        </Col>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Col>
          <Text>EMAIL:</Text>
        </Col>
        <Col offset={1}>
          <Text>{data?.user?.email}</Text>
        </Col>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Col>
          <Text>CONTENT:</Text>
        </Col>
        <Col offset={1}>
          <Text>{data?.content?.title}</Text>
        </Col>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        {data?.solo_dive ? (
          <>
            <Col>
              <Text>DIVE:</Text>
            </Col>
            <Col offset={1}>
              <Text>{data?.solo_dive?.title ?? "N/A"}</Text>
            </Col>
            <Divider style={{ marginTop: 5, marginBottom: 5 }} />
          </>
        ) : (
          <>
            {data?.daily_dive && (
              <>
                <Col>
                  <Text>DAILY DIVE:</Text>
                </Col>
                <Col offset={1}>
                  <Text>{data?.daily_dive ?? "N/A"}</Text>
                </Col>
                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
              </>
            )}
          </>
        )}
        <Col>
          <Text>LAST PLAYTIME:</Text>
        </Col>
        <Col offset={1}>
          <Text>
            {data?.last_playtime == null
              ? "N/A"
              : moment(data?.last_playtime).format("MM-DD-YYYY hh:mm A")}
          </Text>
        </Col>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Col>
          <Text>WATCHED AT:</Text>
        </Col>
        <Col offset={1}>
          <Text>{moment(data?.watched_at).format("MM-DD-YYYY hh:mm A")}</Text>
        </Col>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Col>
          <Text>CREATED AT:</Text>
        </Col>
        <Col offset={1}>
          <Text>{moment(data?.created_at).format("MM-DD-YYYY hh:mm A")}</Text>
        </Col>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Col>
          <Text>UPDATED AT:</Text>
        </Col>
        <Col offset={1}>
          <Text>{moment(data?.updated_at).format("MM-DD-YYYY hh:mm A")}</Text>
        </Col>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Col>
          <Text>PROGRESS:</Text>
        </Col>
        <Col>
          <Text>
            [
            {data?.progress?.map((item, index) => (
              <Text>
                {item}% {index + 1 !== data?.progress.length ? "," : ""}{" "}
              </Text>
            ))}
            ]
          </Text>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col>
          {data?.prompts?.map((item, index) => (
            <>
              <Text>
                Prompts {index}: {item.prompt}
              </Text>
              <Text>Answer: {item.answer}</Text>
              <Divider style={{ marginTop: 5, marginBottom: 5 }} />
            </>
          ))}
        </Col>
      </Row>
    </>
  );
}
