import React from "react";
import { useLocation } from "react-router-dom";
import { Col, Row, Input, Table, Typography, Space, Button } from "antd";
import moment from "moment";

const { Title } = Typography;

const AppointmentLogsDetails = () => {
  const { state } = useLocation();
  const { data } = state; // Read values passed on state
  console.log(data);
  const columns = [

    {
      title: "EVENT TYPE",
      dataIndex: "event_type",
    },
    {
      title: "MESSAGE",
      dataIndex: "message",
      render: (a) => a ?? "Not Available",
    },
    {
      title: "CREATED AT",
      dataIndex: "created_at",
      render: (a) => moment.utc(a).local().format('YYYY-MM-DD hh:mm A') ?? "Not Available",
    },
  ];
  return (
    <div>
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            APPOINTMENTS LOG DETAILS
          </Title>
          <p style={{ textAlign: "left", marginBottom: 10 }}>
            TOTAL LOGS : <strong>{data?.count}</strong>
          </p>
        </Col>
      </Row>
      <Table
        rowKey={(data) => data.id}
        columns={columns}
        dataSource={data?.results}
        scroll={{ x: 1, y: window.innerHeight - 280 }}
        size="middle"
        pagination={false}
      />
    </div>
  );
};

export default AppointmentLogsDetails;