import {
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Row,
  Spin,
  Typography,
} from "antd";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ActionCreators } from "../../store";
import NetworkClient from "../../store/api/utils/NetworkClient";
import CustomSelect from "../utils/CustomSelect";
import FileUpload from "../utils/FileUpload";

const { TextArea } = Input;
const { Title } = Typography;

const AddEditSoloDivesModal = memo(
  ({
    setvisibleAddOrEditSoloDiveModal,
    onSubmitAddOrEditModal,
    loader,
    insrtuctorOptions,
    overviewOptions,
    categoryOptions,
    scallingsOptions,
    benefitsOptions,
    data,
    customizeScaleLabel,
    onChangeCustomizeScaleLabel,
  }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [modalLoading, setLoading] = useState(false);
    const [loadImage, setloadImage] = useState(false);

    // const [audioOverviewOptions, setaudioOverviewOptions] = useState(overviewOptions)
    // const [journalOverviewOptions, setjournalOverviewOptions] = useState(overviewOptions)
    // const [transitionOverviewOptions, settransitionOverviewOptions] = useState(overviewOptions)

    //image preview
    const [bgPathImagePreview, setbgPathImagePreview] = useState("");
    const [bgPathImgLoading, setbgPathImgLoading] = useState(false);
    const [scaleArray, setscaleArray] = useState([]);
    const [scalingObj, setscalingObj] = useState({});
    const [hasScalingObj, sethasScalingObj] = useState(false);
    const [disableSubmit, setdisableSubmit] = useState(false);

    const [searchActionName, setsearchActionName] = useState("");
    const [searchedValue, setsearchedValue] = useState("");

    useEffect(() => {
      if (data && data?.cover_image) {
        setbgPathImagePreview(data?.cover_image);
      } else {
        setbgPathImagePreview("");
      }
    }, [data]);

    useEffect(() => {
      if (scallingsOptions && scallingsOptions.length > 0) {
        let array = [];
        let obj = {};
        let scaleObj = {};
        for (let scale of scallingsOptions) {
          let custom_name = "";
          let list = [];
          list = data?.scalings
            ?.filter((data) => data?.scaling?.id === scale?.id)
            .map((data) => data.label);
          if (list) {
            custom_name = list[0];
          }

          obj = {
            id: scale.id,
            current_name: scale.name,
            customize_name: custom_name,
          };
          array = [...array, obj];
          scaleObj[scale.id] = custom_name;
          sethasScalingObj(true);
        }
        setscalingObj(scaleObj);
        setscaleArray(array);
      }
    }, [data]);

    useEffect(() => {
      // console.log();
      if (hasScalingObj) {
        form.setFieldsValue({ scaling: scalingObj });
      }
    }, [scalingObj]);

    //loader
    useEffect(() => {
      loader ? setLoading(true) : setLoading(false);
    }, [loader]);

    //reset fields
    useEffect(() => {
      form.resetFields();
    }, [form, data]);

    //background image upload
    const imageUpload = async (e) => {
      try {
        setLoading(true);
        setloadImage(true);
        setbgPathImgLoading(true);
        const formData = new FormData();
        formData.append("image", e.file);
        const res = await NetworkClient.filePost(
          "/api/admin/upload-image",
          formData
        );
        // console.log("res", res);
        const { data } = res;
        console.log("uploaded image link", data);
        data && setbgPathImagePreview(data);
        setLoading(false);
        setloadImage(false);

        setbgPathImgLoading(false);
        return data;
      } catch (error) {
        setdisableSubmit(true);
      }
    };

    const onSearchInstructor = async (value) => {
      setsearchedValue(value);
      setsearchActionName("Instructor");
    };

    const onSearchCategory = async (value) => {
      setsearchedValue(value);
      setsearchActionName("Category");
    };

    const onSearchOverview = (value) => {
      setsearchedValue(value);
      setsearchActionName("SoloDiveOverview");
    };

    const onSearchBenefit = async (value) => {
      setsearchedValue(value);
      setsearchActionName("Benefit");
    };

    useEffect(() => {
      const searchData = async () => {
        if (searchActionName === "SoloDiveOverview") {
          await dispatch(
            ActionCreators.getSoloDiveOverviewAction(1, 20, searchedValue)
          );
        } else if (searchActionName === "Benefit") {
          await dispatch(
            ActionCreators.getBenefitsAction(1, 20, searchedValue)
          );
        } else if (searchActionName === "Category") {
          await dispatch(
            ActionCreators.getCategoriesAction(1, 20, searchedValue)
          );
        } else if (searchActionName === "Instructor") {
          await dispatch(
            ActionCreators.getInstructorsAction(1, 20, searchedValue)
          );
        }
      };
      let timer = setTimeout(() => {
        searchData();
      }, 300);

      return () => {
        clearTimeout(timer);
      };
    }, [searchActionName, searchedValue, dispatch]);

    return (
      <>
        {modalLoading ? (
          <Spin
            style={{
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
            }}
            size="large"
          />
        ) : (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            onFinish={onSubmitAddOrEditModal}
            onFinishFailed={onSubmitAddOrEditModal}
            autoComplete="off"
            initialValues={{
              title: data?.title ?? null,
              instructor: data?.instructor?.id ?? null,
              categories: data?.categoriesFilter ?? [],
              journal_overviews: data?.journalOverviewsFilter ?? [],
              audio_overviews: data?.audioOverviewsFilter ?? [],
              transition_screens: data?.transitionScreensFilter ?? [],
              benefits: data?.benefitsFilter ?? [],
              is_premium: data?.is_premium ?? false,
              is_dailydive: data?.is_dailydive ?? false,
              display_feeling: data?.display_feeling ?? false,
              display_tag: data?.display_tag ?? false,
              // in_feature: data?.in_feature ?? false,
              is_published: data?.is_published ?? false,
              cover_image: data?.cover_image ?? null,
              description: data?.description ?? null,
              scaling_text: data?.scaling_text ?? null,
              mood_text: data?.mood_text ?? null,
              // priority: data?.priority ?? null,
            }}
          >
            <Form.Item
              label="TITLE"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input title!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Row gutter={16}>
              <CustomSelect
                item={insrtuctorOptions}
                label="INSTRUCTOR"
                name="instructor"
                onSearch={onSearchInstructor}
                // required={true}
              />

              <CustomSelect
                item={categoryOptions}
                label="CATEGORIES"
                name="categories"
                onSearch={onSearchCategory}
                defaultValue={data?.categories}
              />
            </Row>
            <Row gutter={16}>
              <CustomSelect
                item={overviewOptions}
                label="AUDIO OVERVIEW"
                onFocus={() => {
                  dispatch(ActionCreators.getSoloDiveOverviewAction(1, 20, ""));
                }}
                name="audio_overviews"
                onSearch={onSearchOverview}
                required={true}
                defaultValue={data?.journalOverviewsFilter}
              />
              <CustomSelect
                item={overviewOptions}
                label="JOURNAL OVERVIEW"
                onFocus={() => {
                  dispatch(ActionCreators.getSoloDiveOverviewAction(1, 20, ""));
                }}
                name="journal_overviews"
                onSearch={onSearchOverview}
                required={true}
                defaultValue={data?.journalOverviewsFilter}
              />
            </Row>
            <Row gutter={16}>
              <CustomSelect
                item={overviewOptions}
                onFocus={() => {
                  dispatch(ActionCreators.getSoloDiveOverviewAction(1, 20, ""));
                }}
                label="TRANSITION SCREENS"
                name="transition_screens"
                onSearch={onSearchOverview}
                required={true}
                defaultValue={data?.transitionScreensFilter}
              />
              <CustomSelect
                item={benefitsOptions}
                label="BENEFITS"
                name="benefits"
                onSearch={onSearchBenefit}
                defaultValue={data?.benefitsFilter}
              />
            </Row>

            {customizeScaleLabel && (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <Title level={5}>ORIGINAL SCALE</Title>
                  </Col>
                  <Col span={12}>
                    <Title level={5}>CUSTOMIZE SCALE</Title>
                  </Col>
                </Row>
                {scaleArray.map((item, index) => {
                  return (
                    <Row gutter={16} key={item?.id}>
                      <Col span={12}>
                        <Input
                          prefix={item?.current_name}
                          style={{ marginBottom: 5 }}
                          disabled
                        />
                      </Col>
                      <Col span={12}>
                        {/* <p>{JSON.stringify(item.customize_name)}</p> */}
                        <Form.Item
                          name={["scaling", item.id]}
                          defaultValue={item?.customize_name}
                          rules={[
                            {
                              required: true,
                              message: "Label is Required!",
                            },
                          ]}
                        >
                          <Input
                            name={item.current_name}
                            defaultValue={item?.customize_name}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
            <Form.Item>
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  onChangeCustomizeScaleLabel(!customizeScaleLabel);
                }}
              >
                {customizeScaleLabel
                  ? "HIDE CUSTOMIZE SCALE LABEL"
                  : "CUSTOMIZE SCALE LABEL"}
              </Button>
            </Form.Item>

            <Row gutter={16}>
              <Col lg={8} sm={24} xs={24}>
                <Form.Item label="DESCRIPTION" name="description">
                  <TextArea
                    rows={3}
                    placeholder="Write the course for whom..."
                  />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24} xs={24}>
                <Form.Item
                  label="SCALING TEXT"
                  name="scaling_text"
                  rules={[
                    {
                      required: true,
                      message: "Please input scaling text!",
                    },
                  ]}
                >
                  <TextArea rows={3} placeholder="Write your scaling text..." />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24} xs={24}>
                <Form.Item
                  label="MOOD TEXT"
                  name="mood_text"
                  rules={[
                    {
                      required: true,
                      message: "Please input mood text!",
                    },
                  ]}
                >
                  <TextArea rows={3} placeholder="Write your mood text..." />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col lg={8} sm={8} xs={12}>
                <Form.Item name="is_premium" valuePropName="checked">
                  <Checkbox>IS PREMIUM</Checkbox>
                </Form.Item>
              </Col>
              <Col lg={8} sm={8} xs={12}>
                <Form.Item name="is_dailydive" valuePropName="checked">
                  <Checkbox>IS DAILY DIVE</Checkbox>
                </Form.Item>
              </Col>
              {/* <Col lg={6} sm={6} xs={12}>
              <Form.Item name="in_feature" valuePropName="checked">
                <Checkbox>IN FEATURE</Checkbox>
              </Form.Item>
            </Col> */}
              <Col lg={8} sm={8} xs={12}>
                <Form.Item name="is_published" valuePropName="checked">
                  <Checkbox>IS PUBLISHED</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col lg={8} sm={8} xs={12}>
                <Form.Item name="display_feeling" valuePropName="checked">
                  <Checkbox>Display Feeling</Checkbox>
                </Form.Item>
              </Col>
              <Col lg={8} sm={8} xs={12}>
                <Form.Item name="display_tag" valuePropName="checked">
                  <Checkbox>Display Tag</Checkbox>
                </Form.Item>
              </Col>
              {/* <Col lg={6} sm={6} xs={12} style={{ display: "flex" }}>
              <span style={{ paddingTop: 5, paddingRight: 5 }}>PRIORITY</span>
              <Form.Item name="priority">
                <Input type={"number"} placeholder="Priority" />
              </Form.Item>
            </Col> */}
            </Row>
            <Row gutter={16}>
              <FileUpload
                label="BACKGROUND IMAGE"
                name="cover_image"
                upload={imageUpload}
                accept=".png, .jpg, .jpeg"
                modalLoading={modalLoading}
                // required={true}
              />
              <Col lg={8} sm={8} xs={12}>
                {bgPathImgLoading ? (
                  <Spin size="small" />
                ) : (
                  <Image width={150} src={bgPathImagePreview} />
                )}
              </Col>
            </Row>
            <Form.Item>
              <Row gutter={5} style={{ justifyContent: "flex-end" }}>
                <Col>
                  <Button
                    type="danger"
                    shape="round"
                    onClick={() => {
                      form.resetFields();
                      setvisibleAddOrEditSoloDiveModal(false);
                      // console.log(scaleArray);
                    }}
                    size="large"
                  >
                    Close
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    shape="round"
                    htmlType="submit"
                    size="large"
                    disabled={disableSubmit}
                    loading={loadImage}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}
      </>
    );
  }
);

export default AddEditSoloDivesModal;
