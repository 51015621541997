export const CONTENT_CHOICES = {
  JOURNAL: "JOURNAL",
  ARTICLE: "ARTICLE",
  AUDIO: "AUDIO",
  VIDEO: "VIDEO",
  OTHERS: "OTHERS",
};

export const ContentChoices = {
  journal: "journal",
  article: "article",
  audio: "audio",
  video: "video",
  others: "others",
};

export const TIME_CHOICES = {
  GENERAL: "GENERAL",
  MORNING: "MORNING",
  AFTERNOON: "AFTERNOON",
  EVENING: "EVENING",
  NIGHT: "NIGHT",
};
