/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class DailyDiveApi {
  static async getDailyDives(payload) {
    const response = await http.get("/api/admin/daily_dive", payload);
    return response;
  }
  static async getDailyDiveDetails(id) {
    const response = await http.get(`/api/admin/daily_dive/${id}`);
    return response;
  }
  static async deleteSingleDailyDive(id) {
    const response = await http.del(`/api/admin/daily_dive/${id}`);
    return response;
  }

  static async editDailyDive(payload, id) {
    const response = await http.put(`/api/admin/daily_dive/${id}`, payload);
    return response;
  }

  static async addDailyDive(payload) {
    const response = await http.post("/api/admin/daily_dive", payload);
    return response;
  }
}
