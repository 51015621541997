import { constant } from "../Constant";

// user content
export const getUserContentAction = (
  page,
  limit,
  content_type,
  q,
  sort,
  order
) => {
  return {
    type: constant.GET_USER_CONTENT,
    page: page,
    limit: limit,
    content_type: content_type,
    q: q,
    sort: sort,
    order: order,
  };
};

// Notification
export const getNotificationAction = (page, limit, q) => {
  return {
    type: constant.GET_NOTIFICATION,
    page: page,
    limit: limit,
    q: q,
  };
};

// user course review
export const getUserCourseReviewAction = (page, limit, q) => {
  return {
    type: constant.GET_USER_COURSE_REVIEW,
    page: page,
    limit: limit,
    q: q,
  };
};

// Favourite
export const getFavouriteAction = (
  page,
  limit,
  entity_type,
  q,
  sort,
  order
) => {
  return {
    type: constant.GET_FAVOURITE,
    page: page,
    limit: limit,
    entity_type: entity_type,
    q: q,
    sort: sort,
    order: order,
  };
};

// Favourite
export const getUserCourseContentAction = (page, limit, q, order) => {
  return {
    type: constant.GET_USER_COURSE_CONTENT,
    page: page,
    limit: limit,
    q: q,
    order: order,
  };
};
