import { put } from "redux-saga/effects";
import { DailyDiveApi } from "../api";
import { constant } from "../Constant";
// workers
function* getDailyDivesAction(param) {
  try {
    let payload = {};
    // console.log("USAMA",param);
    if (param.start_date === "" && param.end_date === "") {
      payload = {
        page: param.page,
        limit: param.limit,
        q: param.q,
        mood: param.mood,
        scaling: param.scaling,
      };
    } else {
      payload = {
        page: param.page,
        limit: param.limit,
        q: param.q,
        mood: param.mood,
        scaling: param.scaling,
        start_date: param.start_date,
        end_date: param.end_date,
      };
    }
    const res = yield DailyDiveApi.getDailyDives(payload);
    if (res.status === 200) {
      const payload = {
        type: constant.GET_DAILYDIVES_SUCCESS,
        dailydives: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_DAILYDIVES_FAILED,
        errMsg: "Invalid daily dive",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_DAILYDIVES_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
function* getDailyDiveDetailsAction(param) {
  try {
    const res = yield DailyDiveApi.getDailyDiveDetails(param);
    if (res.status === 200) {
      const payload = {
        type: constant.GET_DAILYDIVE_DETAILS_SUCCESS,
        details: res.data,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_DAILYDIVES_FAILED,
        errMsg: "Invalid daily dive",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_DAILYDIVES_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
export { getDailyDivesAction, getDailyDiveDetailsAction };
