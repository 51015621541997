import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import AddEditUserModal from "../../components/modals/AddEditUserModal";
import DeleteItemConfirmationModal from "../../components/modals/DeleteItemConfirmationModal";
import { ActionCreators } from "../../store";
import { UserApi } from "../../store/api";

const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const User = () => {
  const dispatch = useDispatch();
  const searchBar = useRef(null);
  const navigate = useNavigate();
  const { getUsersAction } = bindActionCreators(ActionCreators, dispatch); //call action
  const data = useSelector((state) => state.UserReducer);
  const me = useSelector((state) => state.MeReducer?.response);
  const [visibleAddUserModal, setvisibleAddUserModal] = useState(false);
  const [isActive, setisActive] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [updateModal, setUpdateModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [modalLoading, setmodalLoading] = useState(false);
  const [searchedText, setsearchedText] = useState(null);
  const [selectedMembership, setselectedMembership] = useState(null);
  const [deletePopupData, setdeletePopupData] = useState({});
  const [showDeleteConfirmModal, setshowDeleteConfirmModal] = useState(false);
  const [deleteId, setdeleteId] = useState("");
  const [deleteName, setdeleteName] = useState("");
  const [Role, setRole] = useState(null);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const [sortVal, setSortVal] = useState();

  useEffect(() => {
    if (me?.role) {
      if (me?.role === "OWNER" || me?.role === "ADMIN") {
        setshowCreateUpdate(true);
        setshowDelete(true);
      } else {
        let index = me?.permissions?.findIndex(
          (item) => item?.type === "users"
        );
        // console.log('usama',me?.permissions);
        if (index > -1) {
          if (me?.permissions?.[index]?.value?.includes("C")) {
            // console.log('usama Can Create or edit');
            setshowCreateUpdate(true);
          }
          if (me?.permissions?.[index]?.value?.includes("D")) {
            // console.log('usama Can Delete');
            setshowDelete(true);
          }
        }
      }
    }
  }, [me]);

  useEffect(() => {
    dispatch(ActionCreators.getUsersAction(1, 20));
  }, [dispatch]);

  const handlePageChange = (page) => {
    let action = ActionCreators.getUsersAction(
      page,
      20,
      selectedMembership,
      searchedText,
      isActive,
      Role
    );
    dispatch(action);
    setCurrentPageNumber(page);
  };

  const addUserButtonClick = useCallback(() => {
    setvisibleAddUserModal(true);
    setUpdateModal(false);
    setEditData({});
  }, []);

  //edit button click
  const editButtonClick = useCallback((param) => {
    // console.log(param);
    getUserDetail(param?.id);
    // setEditData(param);
    // setUpdateModal(true);
  }, []);

  const getUserDetail = async (id) => {
    const response = await UserApi.getUserDetail(id);
    if (response?.status === 200) {
      console.log(response?.data);
      setEditData(response?.data);
      setUpdateModal(true);
    }
  };

  //Add User
  const onSubmitAddUserModal = useCallback(
    async (res) => {
      setmodalLoading(true);

      try {
        if (!res.errorFields) {
          const values = {
            name: res.name,
            email: res.email,
            first_name: res.first_name,
            last_name: res.last_name,
            membership: res.membership,
            role: res.role,
            permissions: [],
            bio: res.bio,
            photo_link: res.photo_link,
            is_active: res.is_active,
            user_profile: {
              date_of_birth: res?.date_of_birth
                ? moment(res?.date_of_birth).format("YYYY-MM-DD")
                : null,
              phone_number: res?.phone_number,
              pronoun: res?.pronoun,
              gender: res?.gender,
              emergency_contact_name: res?.emergency_contact_name,
              emergency_contact_number: res?.emergency_contact_number,
              emergency_contact_relation: res?.emergency_contact_relation,
            },
          };

          if (!updateModal) {
            values.password = res.password;
            if (values.first_name === "") {
              delete values.first_name;
            }
            if (values.last_name === "") {
              delete values.last_name;
            }
            if (values.membership === "") {
              delete values.membership;
            }
            if (values.role === "") {
              delete values.role;
            }

            if (values.bio === "") {
              delete values.bio;
            }
          }

          // console.log(res);
          // console.log(values);

          const response = updateModal
            ? await UserApi.editUser(values, editData.id)
            : await UserApi.addUser(values);

          // console.log("USAMA", response);
          if (response.status === 201 || response.status === 200) {
            message.success(
              `User Successfully ${updateModal ? "Updated" : "Created"}`
            );
            getUsersAction(currentPageNumber, 20);
            setvisibleAddUserModal(false);
            setUpdateModal(false);
          } else {
            setmodalLoading(false);
            console.log(response);
            message.error(response?.data?.detail);
          }
          setmodalLoading(false);
        } else {
          setmodalLoading(false);
        }
      } catch (error) {
        message.error("Bad Request");
        setmodalLoading(false);
      }
    },
    [getUsersAction, currentPageNumber, editData, updateModal]
  );

  const deletePress = async (params) => {
    setdeleteName(params.email);
    setmodalLoading(true);
    const res = await UserApi.deleteSingleUser(params.id, false);
    if (res.status === 200) {
      setdeletePopupData(res.data);
      setdeleteId(params.id);
      setshowDeleteConfirmModal(true);
      setmodalLoading(false);
    } else {
      message.error(res.data.message);
      setmodalLoading(false);
    }
  };

  const deleteConfirm = async () => {
    setmodalLoading(true);
    // let type = params.type.toLowerCase();
    const res = await UserApi.deleteSingleUser(deleteId, true);
    if (res.status === 200) {
      console.log(res.data);
      setshowDeleteConfirmModal(false);
      message.success(res.data.message);
      let action = ActionCreators.getUsersAction(
        currentPageNumber,
        20,
        selectedMembership,
        searchedText,
        isActive,
        Role,
        sortVal ?? null
      );
      dispatch(action);
    } else {
      message.error(res.data.message);
    }
    setmodalLoading(false);
  };

  const onSearchUser = useCallback(() => {
    let action = ActionCreators.getUsersAction(
      1,
      20,
      selectedMembership ?? null,
      searchedText ?? null,
      isActive,
      Role,
      sortVal ?? null
    );
    dispatch(action);
    setCurrentPageNumber(1);
  }, [selectedMembership, sortVal, isActive, searchedText, Role, dispatch]);

  const onChangeMembership = useCallback(
    (membership) => {
      membership = membership === "ALL" ? null : membership;
      let action = ActionCreators.getUsersAction(
        1,
        20,
        membership ?? null,
        searchedText ?? null,
        isActive,
        Role,
        sortVal ?? null
      );
      dispatch(action);
      setCurrentPageNumber(1);
      setselectedMembership(membership);
    },
    [searchedText, sortVal, Role, isActive, dispatch]
  );

  const onChangeSort = useCallback(
    (sortVal) => {
      let action = ActionCreators.getUsersAction(
        1,
        20,
        selectedMembership ?? null,
        searchedText ?? null,
        isActive,
        Role,
        sortVal ?? null
      );
      dispatch(action);
      setCurrentPageNumber(1);
      setSortVal(sortVal);
    },
    [searchedText, Role, selectedMembership, isActive, dispatch]
  );

  const onFilterActiveStatus = useCallback(
    (status) => {
      status = status === "ALL" ? null : status;
      let action = ActionCreators.getUsersAction(
        1,
        20,
        selectedMembership ?? null,
        searchedText ?? null,
        status,
        Role,
        sortVal ?? null
      );
      dispatch(action);
      setCurrentPageNumber(1);
      setisActive(status);
    },
    [searchedText, sortVal, selectedMembership, Role, dispatch]
  );

  const onFilterRoleStatus = useCallback(
    (role) => {
      role = role === "ALL" ? null : role;
      let action = ActionCreators.getUsersAction(
        1,
        20,
        selectedMembership ?? null,
        searchedText ?? null,
        isActive ?? null,
        role ?? null,
        sortVal ?? null
      );
      dispatch(action);
      setCurrentPageNumber(1);
      setRole(role);
    },
    [searchedText, sortVal, selectedMembership, isActive, dispatch]
  );

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: `id`,
      ellipsis: true,
      // textWrap: "word-break",
      width: 160,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      textWrap: "word-break",
      width: 150,
    },
    {
      title: "Name",
      dataIndex: "name",
      dataInkeydex: "name",
      width: 130,
    },

    {
      title: "Membership",
      dataIndex: "membership",
      key: "membership",
      width: 100,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: `${"role"}id`,
      width: 130,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: `${"role"}id`,
      width: 130,
      render: (text) => <>{text ? "ACTIVE" : "INACTIVE"}</>,
    },
    {
      title: "LAST LOGIN",
      dataIndex: "last_login",
      key: `${"last_login"}id`,
      width: 150,
      render: (text) => (
        <>{moment.utc(text).local().format("MM/DD/YYYY hh:mm:A")}</>
      ),
    },

    {
      title: "Action",
      dataIndex: "created_at",
      key: "created_at",
      width: 430,
      // align: "center",
      render: (text, record) => (
        <Space>
          {showCreateUpdate && (
            <Button
              block
              type="primary"
              size="large"
              shape="round"
              onClick={() => editButtonClick(record)}
            >
              Edit
            </Button>
          )}

          <Button
            type="primary"
            size="large"
            shape="round"
            block
            onClick={() => {
              navigate("/user-detail/" + record?.id);
            }}
          >
            Detail
          </Button>
          {/* <Button
            type="primary"
            size="large"
            shape="round"
            block
            onClick={() => {
              navigate("/user/" + record?.id);
            }}
          >
            View Profile
          </Button> */}
          {/* {record?.role !== "ADMIN" && record?.role !== "INSTRUCTOR" && (
            <Button
              type="danger"
              size="large"
              shape="round"
              block
              onClick={() => deletePress(record)}
            >
              Delete
            </Button>
          )} */}
        </Space>
      ),
    },
  ];

  return (
    <div>
      {/* {data.errMsg ? <p className={styles.err}>{data.errMsg}</p> : null} */}
      <Row>
        <Col>
          <Title level={2} style={{ marginBottom: 0, textAlign: "left" }}>
            USER
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL USERS : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row>
        <Col
          lg={12}
          sm={12}
          xs={12}
          style={{ display: "flex", marginBottom: 5 }}
        >
          {showCreateUpdate && (
            <Button
              icon={<PlusCircleOutlined />}
              size={"large"}
              shape="round"
              type="primary"
              onClick={() => addUserButtonClick()}
            >
              ADD USER
            </Button>
          )}
        </Col>
      </Row>
      <Row gutter={24}>
        <Col lg={6} sm={12} xs={12} style={{ marginBottom: 5 }}>
          <Select
            placeholder="Select Membership..."
            size="large"
            onChange={onChangeMembership}
            style={{ width: "100%" }}
          >
            <Option value="FREE">FREE</Option>
            <Option value="TRIAL">TRIAL</Option>
            <Option value="UNLOCKED">UNLOCKED</Option>
            <Option value="MONTHLY">MONTHLY</Option>
            <Option value="YEARLY">YEARLY</Option>
            <Option value="ALL">ALL</Option>
          </Select>
        </Col>
        <Col lg={6} sm={12} xs={12} style={{ marginBottom: 5 }}>
          <Select
            placeholder="Select User Status"
            size="large"
            onChange={onFilterActiveStatus}
            style={{ width: "100%" }}
          >
            <Option value="true">ACTIVE</Option>
            <Option value="false">INACTIVE</Option>
            <Option value="ALL">ALL</Option>
          </Select>
        </Col>
        <Col lg={6} sm={12} xs={12} style={{ marginBottom: 5 }}>
          <Select
            placeholder="Select User Role"
            size="large"
            onChange={onFilterRoleStatus}
            style={{ width: "100%" }}
          >
            <Option value="ADMIN">ADMIN</Option>
            <Option value="STAFF">STAFF</Option>
            <Option value="INSTRUCTOR">INSTRUCTOR</Option>
            <Option value="BASIC">BASIC</Option>
            <Option value="ALL">ALL</Option>
          </Select>
        </Col>
        <Col lg={6} sm={12} xs={12} style={{ marginBottom: 5 }}>
          <Select
            placeholder="Sorting"
            size="large"
            // onChange={(e) => onSearch("sort", e)}
            onChange={onChangeSort}
            style={{ width: "100%" }}
          >
            <Option value={null}>--</Option>
            {/* <Option value={"id:asc"}>Created at ASC</Option>
            <Option value={"id:desc"}>Created at DESC</Option> */}
            {/* <Option value={"start_at:asc"}>Start at ASC</Option>
            <Option value={"start_at:desc"}>Start at DESC</Option> */}
            <Option value={"last_login:asc"}>Last Login at ASC</Option>
            <Option value={"last_login:desc"}>Last Login at DESC</Option>
          </Select>
        </Col>
        <Col lg={6} sm={24} xs={24} style={{ marginBottom: 5 }}>
          <Search
            ref={searchBar}
            placeholder="Search by name or email..."
            enterButton
            size="large"
            onChange={(e) => setsearchedText(e.target.value)}
            onSearch={onSearchUser}
          />
        </Col>
      </Row>

      <Table
        style={{ marginTop: 10 }}
        columns={columns}
        dataSource={data.allUsers}
        scroll={{ x: 1, y: window.innerHeight - 280 }}
        size="middle"
        loading={data.loading}
        rowKey={(data) => data.id}
        pagination={{
          total: data.count,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />

      <Modal
        title={updateModal ? "UPDATE USER" : "ADD USER"}
        open={updateModal ? updateModal : visibleAddUserModal}
        footer={null}
        closable={false}
        width={800}
      >
        <AddEditUserModal
          setvisibleAddUserModal={
            updateModal ? setUpdateModal : setvisibleAddUserModal
          }
          onSubmitAddUserModal={onSubmitAddUserModal}
          data={editData}
          modalLoading={modalLoading}
          isUpdateModal={updateModal}
        />
      </Modal>

      <Modal
        title={`EMAIL: ${deleteName}`}
        open={showDeleteConfirmModal}
        // footer={null}
        closable={false}
        width={1000}
        okText="DELETE"
        onOk={deleteConfirm}
        okButtonProps={{
          type: "danger",
          shape: "round",
        }}
        cancelButtonProps={{
          shape: "round",
        }}
        onCancel={() => setshowDeleteConfirmModal(false)}
      >
        <DeleteItemConfirmationModal
          deletePopupData={deletePopupData}
          modalLoading={modalLoading}
        />
      </Modal>
    </div>
  );
};

export default User;
