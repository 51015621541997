import { put } from "redux-saga/effects";
import { AuthApi } from "../api";
import { constant } from "../Constant";
// workers
function* getMeSagaAction(param) {
  try {
    const res = yield AuthApi.getMe();
    if (res.status === 200) {
      const payload = {
        type: constant.GET_ME_SUCCESS,
        response: res.data,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_ME_FAILED,
        errMsg: "Invalid ME",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_ME_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}

export { getMeSagaAction };
