import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Upload,
} from "antd";
import React, { memo, useEffect, useState } from "react";
import NetworkClient from "../../store/api/utils/NetworkClient";

const { TextArea } = Input;
const { Option } = Select;

const AddEditLocation = memo(
  ({ setVisibleModal, onSubmitModal, data, modalLoading, setmodalLoading }) => {
    const [form] = Form.useForm();
    const [locations, setLocations] = useState([]);

    const initialValues = {
      cliniko_location_id: data?.cliniko_location_id ?? null,
      location_name: data?.location_name ?? null,
      company_name: data?.company_name ?? "",
      street_address_1: data?.street_address_1 ?? null,
      street_address_2: data?.street_address_2 ?? null,
      city: data?.city ?? "",
      // city_area: data?.country_area ?? null,
      state: data?.state ?? "",
      postal_code: data?.postal_code ?? null,
      country: data?.country ?? "",
      // country_area: data?.country_area ?? null,
      phone: data?.phone ?? null,
      order: data?.order ?? null,
      image_path: data?.image_path ?? null,
      is_published: data?.is_published ?? false,
      sub_text: data?.sub_text ?? null,
      studio_location_types: data?.studio_location_types ?? [],
      about: data?.about ?? null,
      availability: data?.availability ?? null,
      email: data?.email ?? null,
    };

    const locationInput = async (res) => {
      const arr = locations.filter((response) => res === response.id);
      form.setFieldsValue({
        street_address_1: arr[0].address_1,
        street_address_2: arr[0].address_2,
        company_name: arr[0].business_name,
        city: arr[0].city,
        country: arr[0].country,
        location_name: arr[0].display_name,
      });
    };

    const getLocationId = async () => {
      const res = await NetworkClient.get(
        "api/admin/appointment-management/locations"
      );
      setLocations(res?.data?.result ?? []);
    };

    useEffect(() => {
      form.resetFields();
      getLocationId();
    }, [form, data]);

    const onSubmit = (value) => {
      if (value.errorFields) {
        message.error("Please fill up required fields");
      } else {
        onSubmitModal(value);
        form.resetFields();
      }
    };

    const imageUpload = async (e, image_name) => {
      setmodalLoading(true);
      const formData = new FormData();
      formData.append("image", e.file);
      const res = await NetworkClient.filePost(
        "/api/admin/upload-image",
        formData
      );
      const { data } = res;
      form.setFieldsValue({ image_path: data });
      console.log("uploaded image link", data);
      setmodalLoading(false);
      return data;
    };

    return (
      <React.Fragment>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={initialValues}
          onFinish={onSubmit}
          onFinishFailed={onSubmit}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col lg={12} sm={12} xs={12}>
              <Form.Item
                label="COMPANY NAME"
                name="company_name"
                rules={[
                  {
                    required: true,
                    message: "Please input the company name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} sm={12} xs={12}>
              <Form.Item
                label="STUDIO LOCATION TYPES"
                name="studio_location_types"
              >
                <Select
                  placeholder="Select location types"
                  mode="multiple"
                  allowClear
                >
                  <Option value="IN_PERSON">IN PERSON</Option>
                  <Option value="VIRTUAL">VIRTUAL</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col lg={12} sm={12} xs={12}>
              <Form.Item label="LOCATION NAME" name="location_name">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} sm={12} xs={12}>
              <Form.Item label="BUSINESS LOCATION" name="cliniko_location_id">
                <Select
                  placeholder="Select business location"
                  onChange={(res) => locationInput(res)}
                >
                  {locations.map((res, key) => {
                    return (
                      <Option value={res.id} key={key}>
                        {res.business_name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col lg={12} sm={12} xs={12}>
              <Form.Item label="ABOUT" name="about">
                <TextArea />
              </Form.Item>
            </Col>
            <Col lg={12} sm={12} xs={12}>
              <Form.Item label="AVAILABILITY" name="availability">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col lg={12} sm={12} xs={12}>
              <Form.Item label="STREET ADDRESS 1" name="street_address_1">
                <TextArea />
              </Form.Item>
            </Col>
            <Col lg={12} sm={12} xs={12}>
              <Form.Item label="STREET ADDRESS 2" name="street_address_2">
                <TextArea />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col lg={12} sm={12} xs={12}>
              <Form.Item label="CITY" name="city">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} sm={12} xs={12}>
              <Form.Item label="STATE" name="state">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col lg={12} sm={12} xs={12}>
              <Form.Item label="COUNTRY" name="country">
                <Input />
              </Form.Item>
            </Col>
            {/* <Col lg={12} sm={12} xs={12}>
              <Form.Item label="COUNTRY AREA" name="country_area">
                <Input />
              </Form.Item>
            </Col> */}
            {/* <Col lg={12} sm={12} xs={12}>
              <Form.Item name="sub_text" label="SUB TEXT">
                <Input />
              </Form.Item>
            </Col> */}
            <Col lg={12} sm={12} xs={12}>
              <Form.Item
                name="email"
                label="EMAIL"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input the email!",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col lg={8} sm={8} xs={12}>
              <Form.Item
                label="CONTACT NO"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your contact no!",
                  },
                ]}
              >
                <Input placeholder="Enter your contact no" />
              </Form.Item>
            </Col>
            <Col lg={8} sm={8} xs={12}>
              <Form.Item label="POSTAL CODE" name="postal_code">
                <Input placeholder="Enter your postal code" />
              </Form.Item>
            </Col>

            <Col lg={8} sm={8} xs={12}>
              <Form.Item label="ORDER" name="order">
                <Input type={"number"} placeholder="Order" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col lg={8} sm={8} xs={12}>
              <Form.Item name="is_published" valuePropName="checked">
                <Checkbox>IS PUBLISHED</Checkbox>
              </Form.Item>
            </Col>
            <Col lg={8} sm={8} xs={12}>
              <Form.Item
                label="Image"
                name="image_path"
                valuePropName="string"
                getValueFromEvent={(e) => imageUpload(e, "image_path")}
              >
                <Upload
                  beforeUpload={() => false}
                  multiple={false}
                  maxCount={1}
                  disabled={modalLoading}
                  accept=".png, .jpg, .jpeg"
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
              {data?.image_path && (
                <p style={{ fontSize: 12 }}>
                  {data?.image_path.split("/").pop()}
                </p>
              )}
            </Col>
          </Row>

          <Form.Item>
            <Row gutter={5} style={{ justifyContent: "flex-end" }}>
              <Col>
                <Button
                  type="danger"
                  shape="round"
                  onClick={() => {
                    form.resetFields();
                    setVisibleModal(false);
                  }}
                  size="large"
                >
                  Close
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  size="large"
                  loading={modalLoading}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }
);

export default AddEditLocation;
