import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Select,
} from "antd";
import React, { memo, useEffect } from "react";

const AddEditIdentifier = memo(
  ({ setVisibleModal, onSubmitModal, data, modalLoading }) => {
    const [form] = Form.useForm();

    console.log("=-=-=-=-=-", data);

    useEffect(() => {
      form.resetFields();
    }, [form, data]);

    const onSubmit = (value) => {
      if (value.errorFields) {
        message.error("Please fill up required fields");
      } else {
        onSubmitModal(value);
        form.resetFields();
      }
    };

    return (
      <React.Fragment>
        {modalLoading ? (
          <Spin
            size="large"
            style={{ display: "flex", justifyContent: "center" }}
          />
        ) : (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{
              name: data?.name ?? "",
              weight: data?.weight ?? "",
              order: data?.order ?? null,
              is_preference: data?.is_preference ?? false,
              strict_match: data?.strict_match ?? false,
              filter_type: data?.filter_type ?? null,
            }}
            onFinish={onSubmit}
            onFinishFailed={onSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="NAME"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input title!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="WEIGHT"
              name="weight"
              rules={[
                {
                  required: true,
                  message: "Please select the Matching Mood",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>

            <Row gutter={16}>
              <Col lg={12} sm={12} xs={12}>
                <Form.Item name="is_preference" valuePropName="checked">
                  <Checkbox>IS PREFERENCE</Checkbox>
                </Form.Item>
              </Col>
              <Col lg={12} sm={12} xs={12} style={{ display: "flex" }}>
                <span style={{ paddingTop: 5, paddingRight: 5 }}>PRIORITY</span>
                <Form.Item name="order">
                  <Input type={"number"} placeholder="Priority" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col lg={12} sm={12} xs={12}>
                <Form.Item name="strict_match" valuePropName="checked">
                  <Checkbox>STRICT MATCH</Checkbox>
                </Form.Item>
              </Col>
              <Col lg={12} sm={12} xs={12}>
                <Form.Item name="filter_type">
                  <Select placeholder="Filter Type">
                    <Select.Option value={null}>All</Select.Option>
                    <Select.Option value="AVAILABILITY">
                      AVAILABILITY
                    </Select.Option>
                    <Select.Option value="THERAPY_APPROACH">
                      THERAPY_APPROACH
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Row gutter={5} style={{ justifyContent: "flex-end" }}>
                <Col>
                  <Button
                    type="danger"
                    shape="round"
                    onClick={() => {
                      form.resetFields();
                      setVisibleModal(false);
                    }}
                    size="large"
                  >
                    Close
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    shape="round"
                    htmlType="submit"
                    size="large"
                    loading={modalLoading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}
      </React.Fragment>
    );
  }
);

export default AddEditIdentifier;
