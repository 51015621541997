import React, { memo } from "react";
import { Form, Button, Col, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const FileUpload = memo(
  ({ label, name, upload, accept, modalLoading, required = false }) => {
    return (
      <Col lg={6} sm={6} xs={12}>
        <Form.Item
          label={label}
          name={name}
          valuePropName="string"
          getValueFromEvent={upload}
          rules={[
            {
              required: required,
              message: label + " is Required!",
            },
          ]}
        >
          <Upload
            beforeUpload={() => false}
            multiple={false}
            maxCount={1}
            disabled={modalLoading}
            accept={accept}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
      </Col>
    );
  }
);

export default FileUpload;
