import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AppointmentApi } from "../../store/api";
import styles from "./addPatient.module.scss";
import PhoneInput from "react-phone-input-2";

export default function AddOrEditAddPatientModal({
  // id,
  owner,
  setvisibleModal,
  paitientList,
  setpaitientList,
  // count,
  appointmentType,
  getUserDetail,
}) {
  const [form] = Form.useForm();

  // const [date_of_birth, setdate_of_birth] = useState("");

  const [is_old, setis_old] = useState(true);

  useEffect(() => {
    form.resetFields();
  }, [paitientList]);

  const onChange = (date, dateString) => {
    // setdate_of_birth(dateString);
  };

  const onClickSave = async (values) => {
    if (
      form.getFieldsError().filter((item) => item?.errors?.length > 0).length >
      0
    ) {
      // alert('cannot submit')
      message.error("Please fill up the form");
    } else {
      let payload = {
        owner: owner,
        first_name: values?.first_name,
        last_name: values?.last_name,
        full_name: values?.first_name + " " + values?.last_name,
        email:
          values?.email === "" || values?.email === undefined
            ? null
            : values?.email,
        is_child: !is_old,
        date_of_birth: values?.date_of_birth
          ? moment(values?.date_of_birth).format("YYYY-MM-DD")
          : null,
      };

      let response;

      console.log("Usama called");

      response = await AppointmentApi.addAnotherPatient(payload);
      if (response?.status === 201) {
        message.success("Patient successfully added.");
        let data = values;
        data["id"] = response?.data?.id;
        let list = [...paitientList, data];

        setpaitientList(list);

        form.resetFields();
        setvisibleModal(false);
        getUserDetail();
      } else {
        message.error(response?.data?.detail);
      }
      // }
    }
  };

  return (
    <Form
      name="basic"
      layout="vertical"
      form={form}
      initialValues={{}}
      onFinish={onClickSave}
      onFinishFailed={onClickSave}
      autoComplete="off"
    >
      <Row gutter={30}>
        {/* <Col xs={24} sm={24} md={12} lg={12}>
          <Form.Item
            label={"Full Name"}
            name={"full_name"}
            rules={[
              {
                required: true,
                message: "Please input patient's full name!",
              },
            ]}
          >
            <Input className={styles.input} />
          </Form.Item>
        </Col> */}
        <Col span={12}>
          <Form.Item
            label={"First Name"}
            name={"first_name"}
            rules={[
              {
                required: true,
                message: "Please input patient's first name!",
              },
            ]}
          >
            <Input className={styles.input} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={"Last Name"}
            name={"last_name"}
            rules={[
              {
                required: true,
                message: "Please input patient's last name!",
              },
            ]}
          >
            <Input className={styles.input} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            label={"Date of Birth"}
            name={"date_of_birth"}
            rules={[
              {
                required: true,
                message: "Please input patient's date of birth!",
              },
            ]}
          >
            <DatePicker
              format={["DD/MM/YYYY", "DD/MM/YY"]}
              // value={date_of_birth}
              // onChange={onChange}
              className={styles.DatePicker}
              disabledDate={(date) => {
                if (moment(date) < moment().add(-1, "day")) {
                  return false;
                }
                return true;
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            label={"Email"}
            name={"email"}
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input client's email!",
              },
            ]}
          >
            <Input className={styles.input} />
          </Form.Item>
        </Col>
        {/* <Col xs={24} sm={24} md={12} lg={12}>
          <Form.Item
            label={"Gender"}
            name={"gender"}
            rules={[
              {
                required: true,
                message: "Please select client's gender!",
              },
            ]}
          >
            <Select
              placeholder="Select gender"
              size="large"
              bordered={true}
              className={styles.dropDown}
            >
              <Select.Option value="MAN">Man</Select.Option>
              <Select.Option value="WOMAN">Woman</Select.Option>
              <Select.Option value="NON_BINARY">Non Binary</Select.Option>
              <Select.Option value="OTHER">Other</Select.Option>
            </Select>
          </Form.Item>
        </Col> */}

        <Col span={24}>
          <Form.Item
            label={"Phone Number"}
            name={"phone_number"}
            rules={[
              {
                required: true,
                message: "Please input phone number!",
              },
            ]}
          >
            <PhoneInput
              country={"ca"}
              placeholder="Enter phone number"
              // value={phone_number}
              // onChange={setphone_number}
              containerStyle={{ marginTop: 10, marginBottom: 20 }}
              inputStyle={{
                width: "100%",
                height: 40,
                borderRadius: 10,
                backgroundColor: "#f5f5f5",
              }}
              buttonStyle={{
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              }}
              dropdownStyle={{ textAlign: "left" }}
            />
          </Form.Item>
        </Col>
        {appointmentType?.[0]?.key_types?.[0] === "FAMILY" && (
          <Col span={24}>
            <Form.Item label="" name={"is_child"}>
              <Checkbox
                onChange={() => {
                  setis_old(!is_old);
                }}
                checked={is_old}
              >
                Is this person 13 or older? If so, we’ll create them their own
                DiveThru account.
              </Checkbox>
            </Form.Item>
          </Col>
        )}

        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <Button
            htmlType="submit"
            shape="round"
            size="large"
            style={{ backgroundColor: "#1bcdb7", color: "#fff" }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
