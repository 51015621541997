import {
  Form,
  Select,
  // Row, Col, Tag
} from "antd";
import React, { memo } from "react";

const { Option } = Select;

// const tagRender = (props) => {
//   const { label, value, closable, onClose } = props;

//   console.log(window.innerWidth);

//   return (
//     <Row
//       style={{
//         display: "flex",
//         flex: 1,
//         justifyContent: "center",
//         flexDirection: "row",
//       }}
//     >
//       <Col>
//         <Tag
//           // color={value}
//           // onMouseDown={onPreventMouseDown}
//           closable={closable}
//           onClose={onClose}
//           style={{
//             // marginRight: 3,
//             // display: "flex",
//             // justifyContent: "center",
//             // alignContent: "center",
//             // alignSelf: "center",
//             // alignItems: "center",
//             // flex: 1,
//             // // width: 330,
//             // marginBottom: 5,
//             // padding: 5,
//           }}
//         >
//           {label}
//         </Tag>
//       </Col>
//     </Row>
//   );
// };

const CustomDropdownSelect = memo(
  ({ items, label, name, value, onNameChange, addItem, defaultValue }) => {
    return (
      <Form.Item label={label} name={name}>
        <Select
          mode="tags"
          style={{
            width: "100%",
          }}
          placeholder="Select/Search Intros"
          optionFilterProp="children"
          onChange={(value) => {
            if (value.length > 10) {
              let tem = value.slice(-1)[0];
              value.splice(9, value.length - 9, tem);
            }
          }}
          onSearch={() => {}}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          defaultOpen={false}
          defaultValue={defaultValue}
          allowClear
          // tagRender={tagRender}
          dropdownStyle={{ display: "none" }}
        >
          {items.map((e, key) => {
            return (
              <Option key={key} value={e}>
                {e}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
);

export default CustomDropdownSelect;
