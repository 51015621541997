/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class ConsentFormApi {
  static async getPatientFormTemplates(page, limit) {
    const response = await http.get(
      `/api/admin/patient_form_templates?page=${page}&limit=${limit}`
    );
    return response;
  }
  static async getPatientFormManagementTemplates() {
    const response = await http.get(
      `/api/admin/form-management/patient_form_templates`
    );
    return response;
  }
  static async createPatientFormTemplate(payload) {
    const response = await http.post(
      `/api/admin/patient_form_template`,
      payload
    );
    return response;
  }
  static async updatePatientFormTemplate(id, payload) {
    const response = await http.put(
      `/api/admin/patient_form_template/${id}`,
      payload
    );
    return response;
  }
  static async deletePatientFormTemplate(id) {
    const response = await http.del(`/api/admin/patient_form_template/${id}`);
    return response;
  }
}
