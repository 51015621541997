import { Button, Col, Input, Row, Space, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReportApi, UserActivityApi } from "../../store/api";

const { Title } = Typography;
const { Search } = Input;

const UserSubmittedForms = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [locationSearch, setLocationSearch] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (page = 1, q = null, location = null) => {
    setLoader(true);
    const values = {
      page: page,
      limit: 20,
      q: q ?? searchText,
      location: location ?? locationSearch,
    };
    console.log(values);
    const response = await UserActivityApi.getAllSubmittedForms(values);
    if (response.status === 200) {
      setData(response.data);
    } else {
      setData(null);
    }
    setLoader(false);
  };

  const setsearchedText = (type, text) => {
    setCurrentPageNumber(1);
    if (type === "searchText") {
      setSearchText(text);
      getData(1, text, locationSearch);
    } else {
      setLocationSearch(text);
      getData(1, searchText, text);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    getData(page);
  };

  const showDetails = (e) => {
    navigate("/user-submitted-form-details", { state: { formId: e.id } });
  };

  const downloadDataPdf = async (e) => {
    const res = await ReportApi.downloadUserSubmissionDataPdf({
      // user_id: e.user.id,
      user_form_submission_id: e.id,
    });
    if (res.status === 200) {
      window.location.replace(res?.data?.path);
    } else {
      console.warn("Something is wrong. Please try again and later!");
    }
  };

  const downloadDataExcel = async (e) => {
    const res = await ReportApi.downloadUserSubmissionDataExcel({
      // user_id: e.user.id,
      user_form_submission_id: e.id,
    });
    if (res.status === 200) {
      window.location.replace(res?.data?.path);
    } else {
      console.warn("Something is wrong. Please try again and later!");
    }
  };

  const columns = [
    {
      title: "EMAIL",
      dataIndex: "user",
      width: 130,
      render: (text, record) => (
        <Space size="middle">{text?.email ?? "Not Available"}</Space>
      ),
    },
    {
      title: "USER NAME",
      dataIndex: "user",
      width: 130,
      align: "center",
      render: (text, record) => (
        <Space size="middle">{text?.name ?? "Not Available"}</Space>
      ),
    },
    {
      title: "PLATFORM TYPE",
      dataIndex: "form_group",
      width: 130,
      align: "center",
      render: (text, record) => (
        <Space size="middle">{text?.platform_type ?? "Not Available"}</Space>
      ),
    },
    {
      title: "STUDIO LOCATION",
      dataIndex: "studio_location",
      width: 180,
      align: "center",
      render: (text, record) => (
        <Space size="middle">{text?.location_name ?? "Not Available"}</Space>
      ),
    },

    {
      title: "ACTION",
      dataIndex: "role",
      width: 250,
      key: "name",
      // width: 200,
      align: "center",
      render: (text, record) => (
        <Space size="small">
          <Button
            shape="round"
            style={{ backgroundColor: "#F0F2F5", color: "#000" }}
            onClick={() => showDetails(record)}
          >
            SHOW DETAILS
          </Button>
          <Button
            shape="round"
            type="primary"
            onClick={() => downloadDataPdf(record)}
          >
            DOWNLOAD PDF
          </Button>
          <Button
            shape="round"
            type="primary"
            onClick={() => downloadDataExcel(record)}
          >
            DOWNLOAD EXCEL
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            Matching Questionnaires
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            Total Questionnaires : <strong>{data?.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-between" }} gutter={[16, 8]}>
        <Col lg={8} sm={8} xs={8} style={{ marginBottom: 5, display: "flex" }}>
          <Search
            placeholder="Search by name or email"
            enterButton
            size="large"
            onSearch={(text) => setsearchedText("searchText", text)}
          />
        </Col>
        <Col lg={8} sm={8} xs={8} style={{ marginBottom: 5, display: "flex" }}>
          <Search
            placeholder="Search by location"
            enterButton
            size="large"
            onSearch={(text) => setsearchedText("location", text)}
          />
        </Col>
      </Row>
      <Table
        style={{ marginTop: 10 }}
        columns={columns}
        dataSource={data?.results}
        // scroll={{ x: 1, y: window.innerHeight - 280 }}
        size="middle"
        loading={loader}
        rowKey={(data) => data?.id}
        pagination={{
          total: data?.count,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
    </div>
  );
};

export default UserSubmittedForms;
