import { Button, Card, DatePicker, Form, Select, Spin } from "antd";
import React, { useRef, useState } from "react";
import { ReportApi } from "../../store/api";
const { RangePicker } = DatePicker;
const { Option } = Select;

export default function UserReport() {
  const form = useRef(null);

  const [start_date, setstartDate] = useState("");
  const [end_date, setendDate] = useState("");
  const [loading, setloading] = useState(false);

  const onPressDownload = async (param) => {
    setloading(true);
    let payload = { ...param };
    payload["start_date"] = start_date;
    payload["end_date"] = end_date;
    const res = await ReportApi.downloadUserReport(payload);
    // console.log(res);
    if (res.status === 200) {
      setloading(false);
      window.location.replace(res?.data?.path);
    } else {
      setloading(false);
    }
  };
  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : (
        <Card title="DOWNLOAD USER REPORT">
          <Form ref={form} onFinish={onPressDownload}>
            <Form.Item>
              <RangePicker
                style={{ display: "flex" }}
                onChange={(date, dateString) => {
                  setstartDate(dateString[0]);
                  setendDate(dateString[1]);
                }}
              />
            </Form.Item>
            <Form.Item name="role">
              <Select placeholder="Select role" optionFilterProp="children">
                <Option value="ADMIN">ADMIN</Option>
                <Option value="STAFF">STAFF</Option>
                <Option value="INSTRUCTOR">INSTRUCTOR</Option>
                <Option value="BASIC">BASIC</Option>
              </Select>
            </Form.Item>
            <Form.Item name="membership">
              <Select
                placeholder="Select membership"
                optionFilterProp="children"
                style={{ minWidth: 130 }}
              >
                <Option value="FREE">FREE</Option>
                <Option value="TIRAL">TIRAL</Option>
                <Option value="UNLOCKED">UNLOCKED</Option>
                <Option value="MONTHLY">MONTHLY</Option>
                <Option value="YEARLY">YEARLY</Option>
              </Select>
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              size="large"
              block
              disabled={start_date === "" || end_date === ""}
            >
              Download
            </Button>
          </Form>
        </Card>
      )}
    </div>
  );
}
