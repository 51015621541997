import { put } from "redux-saga/effects";
import { PermissionApi } from "../api";
import { constant } from "../Constant";

function* getPermissionGroupAction(param) {
  try {
    const res = yield PermissionApi.getPermissionGroup({
      page: param.page,
      limit: param.limit,
      q: param.q,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_PERMISSION_GROUP_SUCCESS,
        permission: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_PERMISSION_GROUP_FAILED,
        errMsg: "Invalid permission",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_PERMISSION_GROUP_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}

export { getPermissionGroupAction };
