import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Image,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import AddEditInstructorModal from "../../components/modals/AddorEditInstructorModal";
import { ActionCreators } from "../../store";
import { InstructorApi } from "../../store/api";
import styles from "./styles.module.css";
const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const Instructor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getInstructorsAction } = bindActionCreators(ActionCreators, dispatch); //call action
  const data = useSelector((state) => state.InstructorReducer);
  const me = useSelector((state) => state.MeReducer?.response);

  const allLocations = useSelector(
    (state) => state.QuestionariesReducer.allLocations
  );
  const [syncing, setsyncing] = useState(false);
  const [visibleAddInstructorModal, setvisibleAddInstructorModal] =
    useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [updateModal, setUpdateModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [modalLoading, setmodalLoading] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex(
        (item) => item?.type === "instructors"
      );
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  useEffect(() => {
    dispatch(ActionCreators.getInstructorsAction(1, 20));
    dispatch(ActionCreators.getLocations(1, 20));
  }, [dispatch]);

  const handlePageChange = (page) => {
    let action = ActionCreators.getInstructorsAction(page, 20);
    dispatch(action);
    setCurrentPageNumber(page);
  };

  const addInstructorButtonClick = useCallback(() => {
    setvisibleAddInstructorModal(true);
    setUpdateModal(false);
    setEditData({});
  }, []);

  //edit button click
  const editButtonClick = useCallback((param) => {
    setEditData(param);
    setUpdateModal(true);
  }, []);

  const onFilter = useCallback(
    (filter) => {
      filter = filter === "ALL" ? null : filter;
      let action = ActionCreators.getInstructorsAction(
        1,
        20,
        searchText,
        filter
      );
      dispatch(action);
      setCurrentPageNumber(1);
      setIsActive(filter);
    },
    [dispatch, searchText]
  );

  const onSearch = useCallback(
    (search) => {
      let action = ActionCreators.getInstructorsAction(1, 20, search, isActive);
      dispatch(action);
      setCurrentPageNumber(1);
    },
    [dispatch, isActive]
  );

  const viewProfile = (record) => {
    dispatch(ActionCreators.getInstructorDetailsAction(record.id));
    navigate("/instructor-info", {
      state: { instructorInfo: record },
    });
  };

  //Add Instructor
  const onSubmitAddInstructorModal = useCallback(
    async (res) => {
      setmodalLoading(true);
      // console.log("==========", res);
      if (!res.errorFields) {
        let values = {};
        values.email = res.email;
        values.is_active = res.is_active;
        // values.instructor_profile.long_description = res?.long_description

        if (!updateModal) {
          values.name = res.name;
          values.permissions = [];
          if (res.first_name !== "") {
            values.first_name = res.first_name;
          }
          if (res.last_name !== "") {
            values.last_name = res.last_name;
          }
          if (res.photo_link !== "") {
            values.photo_link = res.photo_link;
          }
          if (res.bio !== "") {
            values.bio = res.bio;
          }
          if (res.membership !== "") {
            values.membership = res.membership;
          }
          if (res.role !== "") {
            values.role = res.role;
          }
        }

        let instructor = {};
        instructor.is_available = res.is_available;
        instructor.studio_locations = res.studio_locations;
        instructor.rate = res.rate;
        instructor.long_description = res?.long_description;
        if (res.title !== "") {
          instructor.title = res.title;
        }
        if (res.description !== "") {
          instructor.description = res.description;
        }
        if (res.image_path !== "") {
          instructor.image_path = res.image_path;
        }
        if (res.cover_path !== "") {
          instructor.cover_path = res.cover_path;
        }
        if (res.file_path !== "") {
          instructor.file_path = res.file_path;
        }
        if (res.priority !== null) {
          instructor.priority = res.priority;
        }
        if (res.designation !== null) {
          instructor.designation = res.designation;
        }
        if (res.cliniko_practitioner_id !== null) {
          instructor.cliniko_practitioner_id = res.cliniko_practitioner_id;
        }
        if (res.pronoun !== null) {
          instructor.pronoun = res.pronoun;
        }

        if (instructor) {
          values.instructor = instructor;
        }
        values.instructor_profile = {
          long_description: res.long_description,
          // reference_numbers: res.reference_numbers,
          invoice_designation: res.invoice_designation,
        };
        // console.log("===============", values);
        const response = updateModal
          ? await InstructorApi.editInstructor(values, editData.id)
          : await InstructorApi.addInstructor(values);
        // console.log("USAMA", response);
        if (response.status === 201 || response.status === 200) {
          getInstructorsAction(currentPageNumber, 20);
          setvisibleAddInstructorModal(false);
          setUpdateModal(false);
          message.success(
            `Instructor successfully ${updateModal ? "Updated" : "Added"}`
          );
        } else {
          message.error(response.data.detail);
        }
        setmodalLoading(false);
      } else {
        setmodalLoading(false);
      }
    },
    [getInstructorsAction, currentPageNumber, editData, updateModal]
  );

  const syncCliniko = async () => {
    setsyncing(true);
    const res = await InstructorApi.syncInstructors();
    if (res.status === 200) {
      console.log(res);
      message.success(res?.data?.message);
      setsyncing(false);
    } else {
      message.error("Error! No data to sync!");
      setsyncing(false);
    }
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      width: 100,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      width: 170,
    },
    {
      title: "TITLE",
      dataIndex: "instructor",
      width: 100,
      align: "center",
      render: (text) => <>{text ? text.title : "N/A"}</>,
    },
    {
      title: "priority",
      dataIndex: "instructor",
      width: 100,
      align: "center",
      render: (text) => (
        <>
          {text !== null && text.priority.toString() !== "9999999999" ? (
            <>{text.priority}</>
          ) : (
            "N/A"
          )}
        </>
      ),
    },

    {
      title: "IMAGE",
      dataIndex: "photo_link",
      width: 150,
      align: "center",
      render: (text) => (
        <>
          {text !== null ? (
            <Image width={100} height={100} src={text} />
          ) : (
            "N/A"
          )}
        </>
      ),
    },

    {
      title: "INSTRUCTOR IMAGE",
      dataIndex: "instructor",
      width: 150,
      align: "center",
      render: (text) => (
        <>
          {text !== null ? (
            <Image width={100} height={100} src={text.image_path} />
          ) : (
            "N/A"
          )}
        </>
      ),
    },
    {
      title: "COVER IMAGE",
      dataIndex: "instructor",
      width: 150,
      align: "center",
      render: (text) => (
        <>
          {text !== null ? (
            <Image width={100} height={100} src={text.cover_path} />
          ) : (
            "N/A"
          )}
        </>
      ),
    },
    // {
    //   title: "FILE",
    //   dataIndex: "instructor",
    //   ellipsis: true,
    //   textWrap: "word-break",
    //   width: 150,
    //   align: "center",
    //   render: (text) => (
    //     <>
    //       {text !== null ? (
    //         <a href={text.file_path}>{text.file_path}</a>
    //       ) : (
    //         "N/A"
    //       )}
    //     </>
    //   ),
    // },
    {
      title: "Action",
      dataIndex: "role",
      key: "id",
      align: "center",
      width: 200,
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            style={{ backgroundColor: "#F0F2F5", color: "#000" }}
            onClick={() => viewProfile(record)}
          >
            VIEW PROFILE
          </Button>
          {showCreateUpdate && (
            <Button
              type="primary"
              size="large"
              shape="round"
              onClick={() => editButtonClick(record)}
            >
              Edit
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      {data.errMsg ? <p className={styles.err}>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ marginBottom: 0, textAlign: "left" }}>
            INSTRUCTOR
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL INSTRUCTORS : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      {showCreateUpdate && (
        <Row style={{ marginBottom: 10 }} gutter={16}>
          <Col>
            <Button
              icon={<PlusCircleOutlined />}
              size={"large"}
              shape="round"
              type="primary"
              onClick={() => addInstructorButtonClick()}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              ADD INSTRUCTOR
            </Button>
          </Col>
          <Col>
            <Button
              loading={syncing}
              size={"large"}
              shape="round"
              type="primary"
              onClick={() => syncCliniko()}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Sync Instructor Profiles with Cliniko
            </Button>
          </Col>
        </Row>
      )}

      <Row
        gutter={24}
        style={{ justifyContent: "space-between", marginBottom: 10 }}
      >
        <Col lg={6} sm={12} xs={12}>
          <Select
            placeholder="Select User Status"
            size="large"
            onChange={onFilter}
            style={{ width: "100%" }}
          >
            <Option value="TRUE">ACTIVE</Option>
            <Option value="FALSE">INACTIVE</Option>
            <Option value="ALL">ALL</Option>
          </Select>
        </Col>
        <Col lg={8} sm={24} xs={24}>
          <Search
            placeholder="Search by Name or Email"
            enterButton
            size="large"
            onChange={(e) => setSearchText(e.target.value)}
            onSearch={onSearch}
          />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={data.instructors}
        scroll={{ x: 1, y: window.innerHeight - 300 }}
        size="middle"
        loading={data.loading}
        rowKey={(data) => data.id}
        pagination={{
          total: data.count,
          pageSize: 20,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />

      <Modal
        title={updateModal ? "UPDATE INSTRUCTOR" : "ADD INSTRUCTOR"}
        open={updateModal ? updateModal : visibleAddInstructorModal}
        footer={null}
        closable={false}
        width={800}
      >
        <AddEditInstructorModal
          setvisibleAddInstructorModal={
            updateModal ? setUpdateModal : setvisibleAddInstructorModal
          }
          onSubmitAddInstructorModal={onSubmitAddInstructorModal}
          data={editData}
          modalLoading={modalLoading}
          isUpdateModal={updateModal}
          locationData={allLocations}
        />
      </Modal>
    </div>
  );
};

export default Instructor;
