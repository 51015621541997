import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddEditSoloDivesModal from "../../components/modals/AddEditSoloDivesModal";
import DeleteItemConfirmationModal from "../../components/modals/DeleteItemConfirmationModal";
import { ActionCreators } from "../../store";
import { SoloDivesApi } from "../../store/api";

const { Title } = Typography;
const { Search } = Input;

const SoloDives = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.SoloDivesReducer);
  const insrtuctorOptions = useSelector((state) => state.InstructorReducer);
  const overviewOptions = useSelector((state) => state.SoloDiveOverviewReducer);
  const benefitsOptions = useSelector((state) => state.BenefitReducer);
  const categoryOptions = useSelector((state) => state.CategoryReducer);
  const scallingsOptions = useSelector((state) => state.ScalingReducer);

  const [loader, setLoader] = useState(false);
  const [customizeScaleLabel, setcustomizeScaleLabel] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [visibleAddOrEditSoloDiveModal, setvisibleAddOrEditSoloDiveModal] =
    useState(false);
  const [modalLoading, setmodalLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deletePopupData, setdeletePopupData] = useState({});
  const [showDeleteConfirmModal, setshowDeleteConfirmModal] = useState(false);
  const [deleteId, setdeleteId] = useState("");
  const [deleteName, setdeleteName] = useState("");
  const [submitPress, setSubmitPress] = useState(false);
  const me = useSelector((state) => state.MeReducer?.response);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex((item) => item?.type === "dives");
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  useEffect(() => {
    dispatch(ActionCreators.getInstructorsAction(1, 20));
    dispatch(ActionCreators.getSoloDiveOverviewAction(1, 20));
    dispatch(ActionCreators.getBenefitsAction(1, 20));
    dispatch(ActionCreators.getBenefitsAction(1, 20));
    dispatch(ActionCreators.getCategoriesAction(1, 20));
    dispatch(ActionCreators.getScalingsAction(1, 20));
  }, [dispatch]);

  useEffect(() => {
    if (data.loading) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [data]);

  useEffect(() => {
    dispatch(ActionCreators.getSoloDivesAction(currentPageNumber, 20));
  }, [dispatch, currentPageNumber]);

  const AddSoloDives = () => {
    setSubmitPress(false);
    setEditData({});
    setUpdateModal(false);
    setcustomizeScaleLabel(false);
    setvisibleAddOrEditSoloDiveModal(true);
  };

  const editModalOpenPress = async (params) => {
    setSubmitPress(false);
    setmodalLoading(true);

    let benefits = [];
    let categories = [];
    let journalOverviews = [];
    let audioOverviews = [];
    let transitionScreens = [];
    const res = await SoloDivesApi.getSoloDivesDetails(params.id);
    if (res.status === 200) {
      // (await res.data.benefits.length) > 0 &&
      //   res.data.benefits.map((res) => benefits.push(res.id));

      (await res.data.benefits.length) > 0 &&
        res.data.benefits.map((data) =>
          benefits.push({ label: data.name, value: data.id })
        );

      (await res.data.categories.length) > 0 &&
        res.data.categories.map((data) =>
          categories.push({ label: data.name, value: data.id })
        );

      (await res.data.journal_overviews.length) > 0 &&
        res.data.journal_overviews.map((data) =>
          journalOverviews.push({ label: data.title, value: data.id })
        );
      (await res.data.audio_overviews.length) > 0 &&
        res.data.audio_overviews.map((data) =>
          audioOverviews.push({ label: data.title, value: data.id })
        );
      (await res.data.transition_screens.length) > 0 &&
        res.data.transition_screens.map((data) =>
          transitionScreens.push({ label: data.title, value: data.id })
        );
      res.data["benefitsFilter"] = benefits;
      res.data["categoriesFilter"] = categories;
      res.data["journalOverviewsFilter"] = journalOverviews;
      res.data["audioOverviewsFilter"] = audioOverviews;
      res.data["transitionScreensFilter"] = transitionScreens;
      setEditData(res.data);
      if (res.data.scalings?.[0]?.label != null) {
        setcustomizeScaleLabel(true);
      }
    } else {
      setEditData(params);
    }
    setUpdateModal(true);
    setmodalLoading(false);
    setvisibleAddOrEditSoloDiveModal(false);
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    dispatch(ActionCreators.getSoloDivesAction(page, 20));
  };

  const onSubmitAddOrEditModal = useCallback(
    async (values) => {
      if (!values.errorFields && !submitPress) {
        setSubmitPress(true);
        let scaling = [];
        for (var key in values?.scaling) {
          scaling = [
            ...scaling,
            {
              scaling: key,
              label: values?.scaling[key],
            },
          ];
        }
        values["scalings"] = scaling;
        delete values.content_type;
        const bgLink = await values.cover_image;
        values["cover_image"] = bgLink;
        values["journal_overviews"] = values?.journal_overviews?.map(
          (data) => data.value
        );
        values["audio_overviews"] = values?.audio_overviews?.map(
          (data) => data.value
        );
        values["transition_screens"] = values?.transition_screens?.map(
          (data) => data.value
        );
        values["instructor"] = values?.instructor?.value;
        values["categories"] = values?.categories?.map((data) => data.value);
        values["benefits"] = values?.benefits?.map((data) => data.value);

        // values["scalings"] = values?.scalings
        //   ?.filter((data) => data.customize_name !== "")
        //   .map((data) => {
        //     return { scaling: data.id, label: data.customize_name };
        //   });
        let response = updateModal
          ? await SoloDivesApi.editSoloDives(values, editData.id)
          : await SoloDivesApi.addSoloDives(values);
        if (response?.status === 201 || response?.status === 200) {
          dispatch(ActionCreators.getSoloDivesAction(currentPageNumber, 20));
        }
        setmodalLoading(false);
        setvisibleAddOrEditSoloDiveModal(false);
        setUpdateModal(false);
      } else {
        setmodalLoading(false);
      }
    },
    [currentPageNumber, dispatch, editData, updateModal, submitPress]
  );

  const showDetails = useCallback(
    async (params) => {
      navigate(`/solo_dives_details/` + params.id);
    },
    [navigate]
  );

  const deletePress = async (id, name) => {
    setdeleteName(name);
    setmodalLoading(true);
    // let type = params.type.toLowerCase();
    const res = await SoloDivesApi.deleteSingleSoloDives(id, false);
    if (res.status === 200) {
      setdeletePopupData(res.data);
      setdeleteId(id);
      setshowDeleteConfirmModal(true);
    }
    setmodalLoading(false);
  };

  const deleteConfirm = async () => {
    setmodalLoading(true);
    // let type = params.type.toLowerCase();
    const res = await SoloDivesApi.deleteSingleSoloDives(deleteId, "true");
    if (res.status === 200) {
      setshowDeleteConfirmModal(false);
      message.success(res.data.message);
      dispatch(ActionCreators.getSoloDivesAction(currentPageNumber, 20));
    } else {
      message.error(res.data.message);
    }
    setmodalLoading(false);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 130,
      textWrap: "word-break",
    },
    {
      title: "TITLE",
      dataIndex: "title",
      width: 150,
      textWrap: "word-break",
    },

    {
      title: "IS PREMIUM",
      dataIndex: "is_premium",
      align: "center",
      width: 100,
      render: (text, record) =>
        text ? (
          <CheckCircleFilled style={{ color: "#22c55e" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#ef4444" }} />
        ),
    },
    {
      title: "IS PUBLISHED",
      dataIndex: "is_published",
      align: "center",
      width: 100,
      render: (text, record) =>
        text ? (
          <CheckCircleFilled style={{ color: "#22c55e" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#ef4444" }} />
        ),
    },
    {
      title: "ACTION",
      dataIndex: "role",
      key: "name",
      width: 200,
      align: "center",
      render: (text, record) => (
        <Space size="small">
          <Button
            shape="round"
            style={{ backgroundColor: "#F0F2F5", color: "#000" }}
            onClick={() => showDetails(record)}
          >
            SHOW DETAILS
          </Button>
          {showCreateUpdate && (
            <Button
              type="primary"
              shape="round"
              onClick={() => editModalOpenPress(record)}
            >
              EDIT
            </Button>
          )}
          {showDelete && (
            <Button
              type="danger"
              shape="round"
              onClick={() => deletePress(record.id, record.title)}
            >
              Delete
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const onSearchSoloDives = useCallback(
    (q) => {
      // console.log(value)
      setCurrentPageNumber(1);
      let action = ActionCreators.getSoloDivesAction(1, 20, q);
      dispatch(action);
      setCurrentPageNumber(1);
    },
    [dispatch]
  );

  const onChangeCustomizeScaleLabel = (value) => {
    setcustomizeScaleLabel(value);
  };

  return (
    <React.Fragment>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            SOLO DIVES
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL SOLO DIVES : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          {showCreateUpdate && (
            <Button
              icon={<PlusCircleOutlined />}
              size={"large"}
              shape="round"
              type="primary"
              onClick={() => AddSoloDives()}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              ADD SOLO DIVES
            </Button>
          )}
        </Col>
      </Row>

      <Row style={{ justifyContent: "space-between" }}>
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search here..."
            enterButton
            size="large"
            onSearch={onSearchSoloDives}
          />
        </Col>
      </Row>
      <Table
        rowKey={(data) => data.id}
        columns={columns}
        dataSource={data.soloDives}
        scroll={{ x: 1, y: window.innerHeight - 230 }}
        size="middle"
        loading={loader}
        style={{ height: window.innerHeight }}
        pagination={{
          total: data.count,
          pageSize: 20,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
      <Modal
        title={`SoloDive Name: ${deleteName}`}
        open={showDeleteConfirmModal}
        // footer={null}
        closable={false}
        width={1000}
        okText="DELETE"
        onOk={deleteConfirm}
        okButtonProps={{
          type: "danger",
          shape: "round",
        }}
        cancelButtonProps={{
          shape: "round",
        }}
        onCancel={() => setshowDeleteConfirmModal(false)}
      >
        <DeleteItemConfirmationModal
          deletePopupData={deletePopupData}
          modalLoading={modalLoading}
        />
      </Modal>
      <Modal
        title={updateModal ? "UPDATE SOLODIVES" : "ADD SOLODIVES"}
        open={updateModal ? updateModal : visibleAddOrEditSoloDiveModal}
        footer={null}
        closable={false}
        width={1000}
      >
        <AddEditSoloDivesModal
          setvisibleAddOrEditSoloDiveModal={
            updateModal ? setUpdateModal : setvisibleAddOrEditSoloDiveModal
          }
          onSubmitAddOrEditModal={onSubmitAddOrEditModal}
          loader={modalLoading}
          insrtuctorOptions={insrtuctorOptions?.instructors}
          overviewOptions={overviewOptions?.soloDiveOverview}
          benefitsOptions={benefitsOptions?.benefits}
          categoryOptions={categoryOptions?.categories}
          scallingsOptions={scallingsOptions?.scalings}
          data={editData}
          customizeScaleLabel={customizeScaleLabel}
          onChangeCustomizeScaleLabel={onChangeCustomizeScaleLabel}
        />
      </Modal>
    </React.Fragment>
  );
};

export default SoloDives;
