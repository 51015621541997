/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class IntervalApi {
  static async getIntervals(payload) {
    const response = await http.get("/api/admin/interval", payload);
    return response;
  }
  static async getIntervalDetails(payload) {
    const response = await http.get(`/api/admin/interval/${payload.id}`);
    return response;
  }
  static async deleteSingleInterval(id) {
    const response = await http.del(`/api/admin/interval/${id}`);
    return response;
  }

  static async editInterval(payload, id) {
    const response = await http.put(`/api/admin/interval/${id}`, payload);
    return response;
  }

  static async addInterval(payload) {
    const response = await http.post("/api/admin/interval", payload);
    return response;
  }
}
