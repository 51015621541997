import { constant } from "../Constant";

// daily dive
export const getDailyDivesAction = (
  page,
  limit,
  q,
  mood,
  scaling,
  start_date,
  end_date
) => {
  return {
    type: constant.GET_DAILYDIVES,
    page: page,
    limit: limit,
    q: q,
    mood: mood,
    scaling: scaling,
    start_date: start_date,
    end_date: end_date,
  };
};
export const getDailyDiveDetailsAction = (id) => {
  return {
    type: constant.GET_DAILYDIVE_DETAILS,
    id: id,
  };
};
