import { Col, Input, Row, Table, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "../../store";

const { Title } = Typography;
const { Search } = Input;

const UserCourseReview = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.UserCourseReviewReducer);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchedText, setsearchedText] = useState(null);

  useEffect(() => {
    dispatch(ActionCreators.getUserCourseReviewAction(1, 20));
  }, [dispatch]);

  const handlePageChange = (page) => {
    let action = ActionCreators.getUserCourseReviewAction(
      page,
      20,
      searchedText
    );
    dispatch(action);
    setCurrentPageNumber(page);
    setCurrentPageNumber(page);
  };

  const onSearchUserCourseReview = useCallback(
    (q) => {
      // console.log(value)
      setCurrentPageNumber(1);
      let action = ActionCreators.getUserCourseReviewAction(1, 20, q);
      dispatch(action);
      setCurrentPageNumber(1);
      setsearchedText(q);
    },
    [dispatch]
  );

  const columns = [
    {
      title: "EMAIL",
      dataIndex: "user",
      width: 200,
      render: (text) => <>{text?.email}</>,
    },
    {
      title: "COURSE",
      dataIndex: "course",
      width: 200,
      render: (text) => <>{text?.title}</>,
    },
    {
      title: "RATING",
      dataIndex: "rating",
      align: "center",
      width: 130,
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      width: 300,
    },
  ];

  return (
    <div>
      {/* {data.errMsg ? <p>{data.errMsg}</p> : null} */}
      <Row>
        <Col>
          <Title level={2} style={{ marginBottom: 0, textAlign: "left" }}>
            USER COURSE REVIEW
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL USER COURSE REVIEWS : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-between" }}>
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search here..."
            enterButton
            size="large"
            onSearch={onSearchUserCourseReview}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={data.allUserCourseReview}
        scroll={{ x: 1, y: window.innerHeight - 300 }}
        size="middle"
        loading={data.loading}
        rowKey={(data) => data.id}
        pagination={{
          total: data.count,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
    </div>
  );
};

export default UserCourseReview;
