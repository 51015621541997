import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Col, Row, Space, Table, Typography } from "antd";
import React, { memo } from "react";

const { Title } = Typography;

const benefitsColumn = [
  // {
  //   title: "ID",
  //   dataIndex: "id",
  // },
  {
    title: "NAME",
    dataIndex: "name",
  },
  {
    title: "Priority",
    dataIndex: "priority",
    render: (text) => <>{(text !== null && text.toString() !==  "9999999999") ? <>{text}</> : "N/A"}</>,

  },
  {
    title: "IMAGE",
    dataIndex: "icon_path",
    render: (text) => (
      <Space size="middle">
        <img src={text} alt="N/A" style={{ height: 50, width: 50 }} />
      </Space>
    ),
  },
  {
    title: "BACKGROUND IMAGE",
    dataIndex: "bg_path",
    render: (text) => (
      <Space size="middle">
        <img src={text} alt="N/A" style={{ height: 50, width: 50 }} />
      </Space>
    ),
  },
];

const categoriesColumn = [
  // {
  //   title: "ID",
  //   dataIndex: "id",
  // },
  {
    title: "NAME",
    dataIndex: "name",
  },
  {
    title: "PRIORITY",
    dataIndex: "priority",
    render: (text) => <>{(text !== null && text.toString() !==  "9999999999") ? <>{text}</> : "N/A"}</>,

  },
  {
    title: "IS PUBLISHED",
    dataIndex: "is_published",
    render: (text) => <Space size="middle">{text ? "TRUE" : "FALSE"}</Space>,
  },
];

const TitleView = memo(({ data }) => {
  return (
    <React.Fragment>
      <Title
        level={2}
        style={{ justifyContent: "center", paddingTop: 10, paddingBottom: 25 }}
      >
        Title: {data?.title}
      </Title>

      <Row gutter={16}>
        <Col lg={14} md={14} sm={24} xs={24} style={{ textAlign: "justify" }}>
          <Title level={5}>INSTRUCTOR: {data?.instructor?.name}</Title>
          <Title level={5}>
            IS PREMIUM:{" "}
            {data?.is_premium ? (
              <CheckCircleFilled style={{ color: "#22c55e", fontSize: 20 }} />
            ) : (
              <CloseCircleFilled style={{ color: "#ef4444", fontSize: 20 }} />
            )}
          </Title>
          <Title level={5}>
            IS FEATURE:{" "}
            {data?.is_feature ? (
              <CheckCircleFilled style={{ color: "#22c55e", fontSize: 20 }} />
            ) : (
              <CloseCircleFilled style={{ color: "#ef4444", fontSize: 20 }} />
            )}
          </Title>
          <Title level={5}>
            IN FEATURE:{" "}
            {data?.in_feature ? (
              <CheckCircleFilled style={{ color: "#22c55e", fontSize: 20 }} />
            ) : (
              <CloseCircleFilled style={{ color: "#ef4444", fontSize: 20 }} />
            )}
          </Title>
          <Title level={5}>
            IS PUBLISHED:{" "}
            {data?.is_published ? (
              <CheckCircleFilled style={{ color: "#22c55e", fontSize: 20 }} />
            ) : (
              <CloseCircleFilled style={{ color: "#ef4444", fontSize: 20 }} />
            )}
          </Title>
          <Title level={5}>
            ABOUT THIS COURSE:{" "}
            <span style={{ fontSize: 15, fontWeight: "normal" }}>
              {data.description}
            </span>
          </Title>
          <Title level={5}>
            WHO THIS IS FOR:{" "}
            <span style={{ fontSize: 15, fontWeight: "normal" }}>
              {data.description1}
            </span>
          </Title>
        </Col>
        <Col lg={10} md={10} sm={24} xs={24}>
          <Space>
            <img
              src={data?.cover_image}
              alt="N/A"
              style={{ height: 259, width: 375 }}
            />
          </Space>
        </Col>
      </Row>
    </React.Fragment>
  );
});

const CustomTable = memo(({ title, data }) => {
  return (
    <>
      <Row style={{ marginTop: 10 }}>
        <Title level={5}>{title}</Title>
      </Row>
      <Table
        rowKey={(data) => data.id}
        columns={benefitsColumn}
        dataSource={data ?? []}
        scroll={{ x: 1 }}
        pagination={false}
      />
    </>
  );
});

export { categoriesColumn, benefitsColumn, TitleView, CustomTable };
