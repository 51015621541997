import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddEditAppointmentTypeModal from "../../components/modals/AddEditAppointmentTypeModal";
import { ActionCreators } from "../../store";
import { AppointmentApi } from "../../store/api";

const AppointmentTypes = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.AppointmentTypesReducer);

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [updateModal, setUpdateModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [modalLoading, setmodalLoading] = useState(false);

  const [visibleAppointTypeFormModal, setvisibleAppointTypeFormModal] =
    useState(false);

  const me = useSelector((state) => state.MeReducer?.response);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex(
        (item) => item?.type === "appointment_types"
      );
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  useEffect(() => {
    dispatch(ActionCreators.getAppointmentTypesAction(1, 20));
  }, [dispatch]);

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    dispatch(ActionCreators.getAppointmentTypesAction(page, 20));
  };

  const editButtonClick = useCallback((param) => {
    // console.log("nmnmnmnmnmn", param);
    setEditData(param);
    setUpdateModal(true);
    // setvisibleAppointTypeFormModal(true);
  }, []);

  const deletePress = async (id) => {
    // console.log(id);
    const res = await AppointmentApi.deleteSinglAappointmentType(id);
    if (res.status === 200) {
      dispatch(ActionCreators.getAppointmentTypesAction(currentPageNumber, 20));
      message.success(res?.data?.message);
    }
  };

  const onSubmitAddAppointmentTypeModal = async (value) => {
    setmodalLoading(true);
    let payload = {};
    if (isJsonString(value?.appointment_types)) {
      let application = JSON.parse(value?.appointment_types);
      payload = {
        cliniko_appointment_type_id:
          application?.cliniko_appointment_type_id ?? application?.id,
        cliniko_max_attendees:
          application?.cliniko_max_attendees ?? application?.max_attendees,
        appointment_name: application?.appointment_name ?? application?.name,
        key_types: value?.key_types,
      };
    } else {
      payload = {
        cliniko_appointment_type_id: editData?.cliniko_appointment_type_id,
        cliniko_max_attendees: editData?.cliniko_max_attendees,
        appointment_name: editData?.appointment_name,
        key_types: value?.key_types,
      };
    }

    let response = {};
    if (updateModal) {
      response = await AppointmentApi?.updateAppointmentType(
        editData?.id,
        payload
      );
    } else {
      response = await AppointmentApi?.createAppointmentType(payload);
    }
    if (response?.status === 201 || response?.status === 200) {
      dispatch(ActionCreators.getAppointmentTypesAction(currentPageNumber, 20));
      setvisibleAppointTypeFormModal(false);
      setUpdateModal(false);
      setmodalLoading(false);
    } else {
      message.error("Error");
      setmodalLoading(false);
    }
  };

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const columns = [
    {
      title: "APPOINTMENT NAME",
      dataIndex: "appointment_name",
      align: "center",
      width: 300,
    },

    {
      title: "KEY TYPES",
      dataIndex: "key_types",
      align: "center",
      textWrap: "word-break",
      width: 200,
      render: (record) => (
        <>
          {record?.map((text, index) => (
            <Space
              key={index}
              style={{
                display: "flex",
                marginBottom: 5,
                justifyContent: "center",
              }}
            >
              <Tag>{text}</Tag>
            </Space>
          ))}
        </>
      ),
    },

    {
      title: "CREATED AT",
      dataIndex: "created_at",
      align: "center",
      width: 180,
      render: (text) => (
        <Space size="middle">
          {moment.utc(text).local().format("MM/DD/YYYY hh:mm:A")}
        </Space>
      ),
    },

    {
      title: "UPDATED AT",
      dataIndex: "updated_at",
      align: "center",
      width: 180,
      render: (text) => (
        <Space size="middle">
          {moment.utc(text).local().format("MM/DD/YYYY hh:mm:A")}
        </Space>
      ),
    },

    {
      title: "Action",
      dataIndex: "role",
      key: "id",
      align: "center",
      width: 200,
      render: (text, record) => (
        <Space size="small">
          {showCreateUpdate && (
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={() => editButtonClick(record)}
            >
              Edit
            </Button>
          )}

          {showDelete && (
            <Popconfirm
              title="Are you sure to delete this Benifit?"
              onConfirm={() => deletePress(record?.id)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button shape="round" size="large" type="danger">
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            APPOINTMENT TYPES
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL APPOINTMENTS TYPES: <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>

      <Row style={{ marginBottom: 10 }}>
        <Col>
          {showCreateUpdate && (
            <Button
              icon={<PlusCircleOutlined />}
              size={"large"}
              type="primary"
              shape="round"
              onClick={() => {
                // onClickCreateApplicationType();
                setvisibleAppointTypeFormModal(true);
                setUpdateModal(false);
                setEditData({});
              }}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              CREATE APPOINTMENT TYPE
            </Button>
          )}
        </Col>
      </Row>

      <Table
        rowKey={(data) => data.id}
        columns={columns}
        dataSource={data.response}
        scroll={{ x: 1, y: window.innerHeight - 230 }}
        size="middle"
        loading={data.loading}
        pagination={{
          total: data.count,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />

      <Modal
        title={updateModal ? "UPDATE APPLICATION TYPE" : "ADD APPLICATION TYPE"}
        open={updateModal ? updateModal : visibleAppointTypeFormModal}
        footer={null}
        closable={false}
      >
        <AddEditAppointmentTypeModal
          onSubmitAddAppointmentTypeModal={onSubmitAddAppointmentTypeModal}
          setvisibleAppointTypeFormModal={
            updateModal ? setUpdateModal : setvisibleAppointTypeFormModal
          }
          data={editData}
          modalLoading={modalLoading}
        />
      </Modal>
    </React.Fragment>
  );
};

export default AppointmentTypes;
