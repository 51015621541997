import Button from "@material-ui/core/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import { Col, message, Modal, Row, Select, Spin } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import OptionsModal from "../../components/modals/OptionsModal";
import { ActionCreators } from "../../store";
import { QuestionariesApi } from "../../store/api";
import styles from "./styles.modules.css";

const { Option } = Select;

const AddEditForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.QuestionariesReducer);
  const [allOptions, setAllOptions] = useState(state?.allOptionsData ?? []);
  const [modalVisible, setModalVisible] = useState(false);
  const [submitData, setSubmitData] = useState(false);
  const [formNext, setFormNext] = useState(null);
  const [loading, setLoading] = useState(false);


  const getFormNextData = async (q = null) => {
    console.log(q);
    !q && setLoading(true);
    const values = {
      page: 1,
      limit: 50,
      q: q,
      form_group: state?.fromGroupId,
    };
    const response = await QuestionariesApi.getIAllForms(values);
    if (response.status === 200) {
      setFormNext(response.data.results);
    } else {
      setFormNext(null);
    }
    !q && setLoading(false);
  };

  useEffect(() => {
    getFormNextData();
  }, [state?.fromGroupId]);

  const onSearchIdentifier = (value) => {
    dispatch(ActionCreators.getIdentifier(1, 20, value));
  };

  const onchangeIdentifier = async (e) => {
    formik.setFieldValue("form_identifier", e);
    formik.setFieldValue("form_options", []);
    if (e === "") {
      setAllOptions(null);
      return;
    }
    const values = {
      page: 1,
      limit: 100,
      form_identifier: e,
    };
    const response = await QuestionariesApi.getIAllOptions(values);
    if (response.status === 200) {
      setAllOptions(response.data.results);
    } else {
      setAllOptions(null);
    }
  };

  const initialValues = useMemo(
    () => ({
      form_group: state?.fromGroupId ?? "",
      name: state?.formDetails?.name ?? "",
      form_identifier: state?.formDetails?.form_identifier ?? "",
      description: state?.formDetails?.description ?? "",
      description1: state?.formDetails?.description1 ?? "",
      percentage: state?.formDetails?.percentage ?? "",
      order: state?.formDetails?.order ?? "",
      design_type: state?.formDetails?.design_type ?? "",
      question_type: state?.formDetails?.question_type ?? "",
      form_options: state?.multiSelectDeafultOptions ?? [],
      form_next: state?.formDetails?.form_next?.id ?? "",
    }),
    [state]
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      name: yup
        .string("Enter your name")
        .min(3, "Password should be of minimum 3 characters length")
        .required("Name is required"),
      form_identifier: yup
        .string("kkk")
        .required("please select from identifier"),
      design_type: yup.string("").required("please select design type"),
      question_type: yup.string("").required("please select question type"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const multiSelect = [];
      values["description"] =
        values.description === "" ? null : values.description;
      values["description1"] =
        values.description1 === "" ? null : values.description1;
      values["form_next"] = values.form_next === "" ? null : values.form_next;
      values["percentage"] =
        values.percentage === "" ? null : values.percentage;
      if (
        values?.question_type === "MULTI_SELECT" &&
        values?.form_options?.length > 0
      ) {
        for (let i = 0; i < values.form_options.length; i++) {
          if (values.form_options[i] !== undefined) {
            multiSelect.push({
              form_option: values.form_options[i],
              next_form: null,
            });
          }
        }
      } else {
        delete multiSelect[{}];
      }
      values["form_options"] = multiSelect?.length > 0 ? multiSelect : [];
      // console.log(values);
      if (values.question_type !== "SINGLE_SELECT") {
        const response = state?.formDetails
          ? await QuestionariesApi.updateForm(values, state?.formDetails?.id)
          : await QuestionariesApi.addForm(values);
        if (response.status === 200) {
          navigate(`/form-groups-details`, {
            state: { params: data?.formDetails },
          });
        } else {
          message.error("Please fill up required fields");
        }
        setLoading(false);
      } else {
        setSubmitData(values);
        setModalVisible(true);
        setLoading(false);
      }
    },
  });

  const selectStyle = {
    display: "flex",
    alignItems: "flex-start",
    color: "gray",
    marginTop: 20,
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 20,
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            width: "70%",
            display: "flex",
            flexDirection: "column",
            paddingBottom: 50,
            position: "relative",
          }}
        >
          {loading && (
            <Spin
              size="large"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                zIndex: 1,
              }}
            />
          )}
          <div style={{ marginTop: 20, fontSize: 20 }}>
            {state?.formDetails ? "EDIT FORM" : "ADD FROM"}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginRight: "10vw",
              marginLeft: "10vw",
              marginTop: 20,
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <TextField
                required
                fullWidth
                id="name"
                name="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <div style={selectStyle}>Select Identifier *</div>
              <Select
                placeholder="Search to Select"
                defaultValue={formik.values.form_identifier}
                value={formik.values.form_identifier}
                onChange={(value) => onchangeIdentifier(value)}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
                className={styles}
                onSearch={onSearchIdentifier}
                style={{
                  width: "100%",
                  marginTop: 2,
                  marginBottom: 10,
                  backgroundColor: "#e9ecef",
                }}
              >
                {data?.allIdentifiers &&
                  data?.allIdentifiers.map((data, index) => {
                    return (
                      <Option key={index} value={data?.id}>
                        {data?.name}
                      </Option>
                    );
                  })}
              </Select>
              <span style={{ color: "red", fontSize: 12 }}>
                {formik.touched.form_identifier &&
                  formik.errors.form_identifier}
              </span>

              <TextareaAutosize
                placeholder="Description"
                id="description"
                name="description"
                label="Description"
                value={
                  formik.values.description ? formik.values.description : ""
                }
                onChange={formik.handleChange}
                style={{
                  marginBottom: 10,
                  width: "49%",
                  marginRight: "2%",
                  // backgroundColor: "#e9ecef",
                  padding: 5,
                  borderColor: "#d9d9d9",
                }}
                minRows={2}
              />
              <TextareaAutosize
                placeholder="Short Description"
                id="description1"
                name="description1"
                label="Short Description"
                value={
                  formik.values.description1 ? formik.values.description1 : ""
                }
                onChange={formik.handleChange}
                style={{
                  marginBottom: 10,
                  width: "49%",
                  // backgroundColor: "#e9ecef",
                  padding: 5,
                  borderColor: "#d9d9d9",
                }}
                minRows={2}
              />
              <Row gutter={16}>
                <Col lg={12} sm={12} xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="order"
                    name="order"
                    label="Order"
                    type="number"
                    value={formik.values.order}
                    onChange={formik.handleChange}
                    error={formik.touched.order && Boolean(formik.errors.order)}
                    helperText={formik.touched.order && formik.errors.order}
                    style={{ marginBottom: 10 }}
                  />
                </Col>
                <Col lg={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="percentage"
                    name="percentage"
                    label="Percentage"
                    value={
                      formik.values.percentage ? formik.values.percentage : ""
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.percentage &&
                      Boolean(formik.errors.percentage)
                    }
                    helperText={
                      formik.touched.percentage && formik.errors.percentage
                    }
                    style={{ marginBottom: 10 }}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col lg={12} sm={12} xs={12}>
                  <div style={selectStyle}>Select design type *</div>
                </Col>
                <Col lg={12} sm={12} xs={12}>
                  <div style={selectStyle}>Select question type *</div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col lg={12} sm={12} xs={12}>
                  <Select
                    placeholder="Select design type"
                    value={formik.values.design_type}
                    onChange={(e) => formik.setFieldValue("design_type", e)}
                    style={{ width: "100%" }}
                  >
                    <Option value="BOX">BOX</Option>
                    <Option value="RECTANGULAR">RECTANGULAR</Option>
                    <Option value="SELECT">SELECT</Option>
                    <Option value="PAYMENT">PAYMENT</Option>
                    <Option value="OVERVIEW">OVERVIEW</Option>
                  </Select>
                  <span style={{ color: "red", fontSize: 12 }}>
                    {formik.touched.design_type && formik.errors.design_type}
                  </span>
                </Col>
                <Col lg={12} sm={12} xs={12}>
                  <Select
                    placeholder="Select question type"
                    value={formik.values.question_type}
                    onChange={(e) => formik.setFieldValue("question_type", e)}
                    style={{ width: "100%" }}
                  >
                    <Option value="TEXT">TEXT</Option>
                    <Option value="SINGLE_SELECT">SINGLE_SELECT</Option>
                    <Option value="MULTI_SELECT">MULTI_SELECT</Option>
                    <Option value="EMPTY">EMPTY</Option>
                  </Select>
                  <span style={{ color: "red", fontSize: 12 }}>
                    {formik.touched.question_type &&
                      formik.errors.question_type}
                  </span>
                </Col>
              </Row>
              {formik.values.question_type === "MULTI_SELECT" && (
                <>
                  <div style={selectStyle}>Select Options</div>
                  <Select
                    placeholder="Search to Select"
                    value={formik.values.form_options}
                    onChange={(e) => formik.setFieldValue("form_options", e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    allowClear
                    className={styles}
                    onSearch={() => {}}
                    style={{
                      width: "100%",
                      marginTop: 2,
                      marginBottom: 10,
                      backgroundColor: "#e9ecef",
                    }}
                    mode={"multiple"}
                  >
                    {allOptions &&
                      allOptions.map((data, index) => {
                        return (
                          <Option key={index} value={data?.id}>
                            {data?.name}
                          </Option>
                        );
                      })}
                  </Select>
                  <span style={{ color: "red", fontSize: 12 }}>
                    {formik.touched.form_identifier &&
                      formik.errors.form_identifier}
                  </span>
                </>
              )}
              <div style={selectStyle}>Next Form ID</div>
              <Select
                placeholder="Next form Id"
                defaultValue={formik.values.form_next}
                value={formik.values.form_next}
                onChange={(value) => formik.setFieldValue("form_next", value)}
                onClear={() => formik.setFieldValue("form_next", "")}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear={formik.values.form_next !== "" ? true : false}
                showSearch
                className={styles}
                onSearch={(e) => getFormNextData(e)}
                style={{
                  width: "100%",
                  marginTop: 2,
                  marginBottom: 10,
                  backgroundColor: "#e9ecef",
                }}
              >
                {formNext &&
                  formNext.map((data, index) => {
                    return (
                      <Option key={index} value={data?.id}>
                        {data?.name}
                      </Option>
                    );
                  })}
              </Select>

              <Button
                variant="contained"
                fullWidth
                type="submit"
                style={{
                  marginTop: 40,
                  width: "20%",
                  backgroundColor: "#1890ff",
                  color: "white",
                  borderRadius: 22,
                  paddingTop: 8,
                  paddingBottom: 8,
                }}
                disabled={loading}
              >
                {formik.values.question_type === "SINGLE_SELECT"
                  ? "Next"
                  : "Submit"}
              </Button>
            </form>
          </div>
          {modalVisible && (
            <Modal
              title={"OPTIONS"}
              open={modalVisible}
              footer={null}
              closable={false}
              width={1000}
            >
              <OptionsModal
                all_Options={allOptions}
                submitData={submitData}
                defaultData={state?.formDetails?.form_options ?? []}
                setModalVisible={setModalVisible}
                editId={state?.formDetails?.id ?? null}
                navigate={navigate}
                params={data?.formDetails}
                formNext={formNext}
                getFormNextData={getFormNextData}
              />
            </Modal>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEditForm;
