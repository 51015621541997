/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class SettingApi {
  static async getAllSetting(payload) {
    const response = await http.get("/api/admin/setting", payload);
    return response;
  }

  static async deleteSingleSetting(payload) {
    const response = await http.del(`/api/admin/setting/${payload}`);
    return response;
  }

  static async editSetting(payload) {
    // console.log("USAMA");
    const response = await http.put("/api/admin/setting", payload);
    return response;
  }

  static async addSetting(payload) {
    const response = await http.post("/api/admin/setting", payload);
    return response;
  }

  static async getSupportInfo() {
    const response = await http.get("/api/admin/support-info");
    return response;
  }

  static async getCancelInfo() {
    const response = await http.get("/api/admin/appointment-config");
    return response;
  }

  static async updateCanceltInfo(payload) {
    const response = await http.put("/api/admin/appointment-config", payload);
    return response;
  }

  static async updateSupportInfo(payload) {
    const response = await http.put("/api/admin/support-info", payload);
    return response;
  }
}
