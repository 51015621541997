import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row, Spin, Upload } from "antd";
import React, { memo, useEffect, useState } from "react";
import NetworkClient from "../../store/api/utils/NetworkClient";

const AddEditBenefitModal = memo(
  ({
    setvisibleAddBenefitModal,
    onSubmitAddBenefitModal,
    data,
    modalLoading,
  }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      form.resetFields();
    }, [form, data]);

    const imageUpload = async (e, image_name) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("image", e.file);
      const res = await NetworkClient.filePost(
        "/api/admin/upload-image",
        formData
      );
      const { data } = res;
      console.log("uploaded image link", data);
      if (image_name === "icon_path") {
        form.setFieldsValue({ icon_path: data });
      } else {
        form.setFieldsValue({ bg_path: data });
      }
      setLoading(false);
      return data;
    };

    const onSubmit = (value) => {
      // console.log(value);

      if (value.errorFields) {
        message.error("Please fill up required fields");
      } else {
        form.resetFields();
        onSubmitAddBenefitModal(value);
      }
    };

    return (
      <>
        {modalLoading ? (
          <Spin
            size="large"
            style={{ display: "flex", justifyContent: "center" }}
          />
        ) : (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{
              name: data?.name ?? "",
              priority: data?.priority ?? "",
              bg_path: data?.bg_path ?? "",
              icon_path: data?.icon_path ?? "",
            }}
            onFinish={onSubmit}
            onFinishFailed={onSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input Benefit name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Priority" name="priority">
              <Input type="number" />
            </Form.Item>

            <Row>
              <Col lg={12} sm={12} xs={24}>
                <Form.Item
                  label="Icon Image"
                  name="icon_path"
                  valuePropName="string"
                  getValueFromEvent={(e) => imageUpload(e, "icon_path")}
                >
                  <Upload
                    beforeUpload={() => false}
                    multiple={false}
                    maxCount={1}
                    disabled={loading}
                    accept=".png, .jpg, .jpeg"
                  >
                    <Button icon={<UploadOutlined />}>UPLOAD</Button>
                  </Upload>
                </Form.Item>
                {data?.icon_path && (
                  <p style={{ fontSize: 12 }}>
                    {data?.icon_path.split("/").pop()}
                  </p>
                )}
              </Col>
              <Col lg={12} sm={12} xs={24}>
                <Form.Item
                  label="Background Image"
                  name="bg_path"
                  valuePropName="string"
                  getValueFromEvent={(e) => imageUpload(e, "bg_path")}
                >
                  <Upload
                    beforeUpload={() => false}
                    multiple={false}
                    maxCount={1}
                    disabled={loading}
                    accept=".png, .jpg, .jpeg"
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
                {data?.bg_path && (
                  <p style={{ fontSize: 12 }}>
                    {data?.bg_path.split("/").pop()}
                  </p>
                )}
              </Col>
            </Row>

            <Form.Item>
              <Row gutter={5} style={{ justifyContent: "flex-end" }}>
                <Col>
                  <Button
                    type="danger"
                    shape="round"
                    onClick={() => {
                      form.resetFields();
                      setvisibleAddBenefitModal(false);
                    }}
                    size="large"
                  >
                    Close
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    size="large"
                    loading={loading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}
      </>
    );
  }
);

export default AddEditBenefitModal;
