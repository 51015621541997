import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "../../store";
import { Table, Col, Row, Typography, Select, Input } from "antd";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const Favourite = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.FavouriteReducer);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [selectectedEntityType, setselectectedEntityType] = useState(null);
  const [searchedText, setsearchedText] = useState(null);
  const [orderValue, setorderValue] = useState(null);

  // entity_type:entity_type,
  // q:q,
  // sort:sort,
  // order:order

  useEffect(() => {
    dispatch(ActionCreators.getFavouriteAction(1, 20));
  }, [dispatch]);

  const handlePageChange = (page) => {
    let action = ActionCreators.getFavouriteAction(
      page,
      20,
      selectectedEntityType,
      searchedText,
      null,
      orderValue
    );
    dispatch(action);
    setCurrentPageNumber(page);
  };

  const onChangeEntityType = useCallback(
    (value) => {
      value = value === "ALL" ? null : value;
      setselectectedEntityType(value);
      setCurrentPageNumber(1);
      dispatch(
        ActionCreators.getFavouriteAction(
          1,
          20,
          value ?? null,
          searchedText ?? null,
          null,
          orderValue ?? null
        )
      );
    },
    [searchedText, orderValue, dispatch]
  );

  const onChangeOrderValue = useCallback(
    (value) => {
      setorderValue(value);
      setCurrentPageNumber(1);
      dispatch(
        ActionCreators.getFavouriteAction(
          1,
          20,
          selectectedEntityType ?? null,
          searchedText ?? null,
          null,
          value ?? null
        )
      );
    },
    [selectectedEntityType, searchedText, dispatch]
  );

  const onSearchContent = useCallback(() => {
    let action = ActionCreators.getFavouriteAction(
      1,
      20,
      selectectedEntityType ?? null,
      searchedText ?? null,
      null,
      orderValue ?? null
    );
    dispatch(action);
    setCurrentPageNumber(1);
  }, [selectectedEntityType, searchedText, orderValue, dispatch]);

  const columns = [
    {
      title: "NAME",
      dataIndex: "favourite_by",
      width: 130,
      render: (text) => <>{text.name}</>,
    },
    {
      title: "EMAIL",
      dataIndex: "favourite_by",

      width: 200,
      render: (text) => <>{text.email}</>,
    },
    {
      title: "ENTITY NAME",
      dataIndex: "entity",
      ellipsis: true,
      textWrap: "word-break",
      width: 130,
      render: (text) => <>{text.name}</>,
    },
    {
      title: "ENTITY TYPE",
      dataIndex: "entity_type",
      align: "center",
      ellipsis: true,
      textWrap: "word-break",
      width: 130,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      width: 100,
      align: "center",
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },
  ];

  return (
    <div>
      {/* {data.errMsg ? <p>{data.errMsg}</p> : null} */}
      <Row>
        <Col>
          <Title level={2} style={{ marginBottom: 0, textAlign: "left" }}>
            FAVOURITE
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL FAVOURITE : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: 5, marginBottom: 5 }}>
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Select
            placeholder="Select Entity Type..."
            size="large"
            value={selectectedEntityType}
            onChange={onChangeEntityType}
          >
            <Option value="CONTENT">CONTENT</Option>
            <Option value="COURSE">COURSE</Option>
            <Option value="INSTRUCTOR">INSTRUCTOR</Option>
            <Option value="ALL">ALL</Option>
          </Select>
        </Col>
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Select
            placeholder="Order by."
            size="large"
            value={orderValue}
            onChange={onChangeOrderValue}
          >
            <Option value="1">ASC</Option>
            <Option value="-1">DESC</Option>
          </Select>
        </Col>

        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search by user name or content name..."
            enterButton
            size="large"
            onChange={(e) => setsearchedText(e.target.value)}
            onSearch={onSearchContent}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={data.allFavourite}
        size="middle"
        scroll={{ x: 1, y: window.innerHeight - 300 }}
        loading={data.loading}
        rowKey={(data) => data.id}
        pagination={{
          total: data.count,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
    </div>
  );
};

export default Favourite;
