/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class NotificationApi {
  static async getAllNotification(payload) {
    const response = await http.get("/api/admin/notification", payload);
    return response;
  }

  static async deleteSingleNotification(payload) {
    const response = await http.del(`/api/admin/notification/${payload}`);
    return response;
  }

  static async editNotification(payload, id) {
    const response = await http.put(`/api/admin/notification/${id}`, payload);
    return response;
  }

  static async addNotification(payload) {
    const response = await http.post("/api/admin/notification", payload);
    return response;
  }
}
