import { constant } from "../Constant";

const initialState = {
  loading: true,
  permission: [],
  errMsg: "",
  details: {},
  count: 0,
};

const PermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_PERMISSION_GROUP:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_PERMISSION_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        permission: action.permission,
        errMsg: "",
        count: action.count,
      };
    case constant.GET_PERMISSION_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        id: action.id,
        loading: true,
      };

    case constant.GET_PERMISSION_GROUP_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    case constant.CLEAN_PERMISSION_GROUP:
      return {
        ...state,
        loading: false,
        permission: [],
        errMsg: "",
      };
    default:
      return state;
  }
};

export default PermissionReducer;
