import { put } from "redux-saga/effects";
import { InstructorApi } from "../api";
import { constant } from "../Constant";
// workers
function* getInstructorsAction(param) {
  try {
    const res = yield InstructorApi.getInstructors({
      page: param.page,
      limit: param.limit,
      q: param.q,
      is_active: param.is_active,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_INSTRUCTORS_SUCCESS,
        instructors: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_INSTRUCTORS_FAILED,
        errMsg: "Invalid instructor",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_INSTRUCTORS_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
function* getInstructorDetailsAction(param) {
  try {
    const res = yield InstructorApi.getInstructorDetails(param);
    if (res.status === 200) {
      const payload = {
        type: constant.GET_INSTRUCTOR_DETAILS_SUCCESS,
        details: res.data,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_INSTRUCTORS_FAILED,
        errMsg: "Invalid instructor",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_INSTRUCTORS_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
export { getInstructorsAction, getInstructorDetailsAction };
