import React, { memo, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  Radio,
  Card,
  Spin,
  Select,
} from "antd";
import { UserApi, AppointmentApi } from "../../store/api";
import { useState } from "react";
import amexCard from "./img/amex.png";
import discoverCard from "./img/Discover.png";
import jcbCard from "./img/JCB.png";
import masterCard from "./img/mastercard.png";
import visaCard from "./img/visa.png";
import styles from "./style.module.scss";
import { SQUARE_APP_ID, SQUARE_LOCATION_ID } from "../../store/api/Config";

const { Option } = Select;

const Payment = memo(({ data, collectPayment }) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState("attachedCard");
  const [cardList, setAllCard] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userAttachedCard, setUserAttachedCard] = useState({});

  useEffect(() => {
    form.resetFields();
    setValue("attachedCard");
    fetchCard();
    setAllCard([]);
    setUserAttachedCard({});
  }, [data, form]);

  const fetchCard = async () => {
    setLoading(true);
    let payload = {
      page: 1,
      limit: 20,
      user_id: data.user.id,
    };
    const resCard = await UserApi.getUserCardInfo(payload);
    if (resCard.status === 200) {
      // console.log("all card", resCard.data.results);
      setAllCard(resCard.data.results);
    }
    const response = await AppointmentApi.getAppointmentDetail(data.id);
    if (response.status === 200) {
      // console.log("response.data.user_card_info", response.data.user_card_info);
      setUserAttachedCard(response.data.user_card_info);
    }
    setLoading(false);
  };

  const deletCardHandle = async (userID, cardID) => {
    const res = await UserApi.deleteUserCard(cardID, userID);
    if (res.status === 200 || res.status === 201) {
      fetchCard();
    } else {
      message.error(res.data.detail ?? "Something is wrong!");
    }
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const CashPayment = ({ type }) => {
    useEffect(() => {
      form.resetFields();
    }, [type]);

    const cashHandle = async (item) => {
      if (!item.receipt_number) {
        message.error("please input the receipt number!");
        return;
      } else if (type === "pos/taps" && !item.card_last4) {
        message.error("please input the card last 4 digit!");
        return;
      }
      // else if (type === "pos/taps" && !item.card_brand) {
      //   message.error("please input the card brand!");
      //   return;
      // }
      else {
        if (type === "cash") {
          item.card_last4 = null;
          item.card_brand = null;
        }
        collectPayment(data.id, item);
      }
    };

    return (
      <Form
        form={form}
        initialValues={{
          card_last4: null,
          card_brand: null,
          receipt_number: null,
        }}
        onFinish={cashHandle}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
      >
        <Form.Item
          label="Receipt number"
          name="receipt_number"
          style={{ marginTop: 20 }}
          rules={[
            {
              required: true,
              message: "please input the receipt number!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {type === "pos/taps" && (
          <>
            <Form.Item
              label="Card last 4 digit"
              name="card_last4"
              rules={[
                {
                  required: true,
                  message: "please input the card last 4 digit!",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="Card brand"
              name="card_brand"
              // rules={[
              //   {
              //     required: true,
              //     message: "please input the card brand!",
              //   },
              // ]}
            >
              <Select placeholder="Select card brand">
                <Option value="VISA">VISA</Option>
                <Option value="MASTERCARD">MASTERCARD</Option>
                <Option value="AMERICAN_EXPRESS">AMERICAN EXPRESS</Option>
                <Option value="INTERAC">INTERAC</Option>
                <Option value="DISCOVER">DISCOVER</Option>
                <Option value="JCB">JCB</Option>
                <Option value="EBT">EBT</Option>
              </Select>
            </Form.Item>
          </>
        )}

        <Form.Item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ borderRadius: 10, paddingLeft: 40, paddingRight: 40 }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const cardView = (item, key) => (
    <Col xxl={24} lg={24} md={24} xs={24} key={key}>
      <Card className={styles.cardView}>
        <Row gutter={8} style={{ marginBottom: 20 }}>
          <Col lg={6} xs={6} className={styles.cardInside}>
            <img
              src={
                item.card_brand === "VISA" || item.card_brand === "VISA"
                  ? visaCard
                  : item.card_brand === "MASTER" ||
                    item.card_brand === "MASTERCARD"
                  ? masterCard
                  : item.card_brand === "JCB" || item.card_brand === "JCBCARD"
                  ? jcbCard
                  : item.card_brand === "AMEX" ||
                    item.card_brand === "AMERICAN EXPRESS"
                  ? amexCard
                  : discoverCard
              }
              alt="Card"
              style={{ maxWidth: "70%" }}
            />
          </Col>
          <Col lg={12} xs={10}>
            <p className={styles.text600}>{item.card_brand}</p>
          </Col>
          <Col lg={6} xs={8} className={styles.text600}>
            {"****" + item?.card_number?.slice(-4)}
          </Col>
        </Row>
        <Row gutter={8}>
          <Col lg={6} xs={6} className={styles.cardInside}>
            <div className={styles.cardInsideCh}>
              <button
                onClick={() => deletCardHandle(data.user.id, item.id)}
                className={styles.cardButton}
                style={{ marginTop: 5 }}
              >
                Delete
              </button>
              <button
                onClick={() => collectPayment(data.id, null, item.id)}
                className={styles.cardButton}
                style={{ marginTop: 5 }}
              >
                Select
              </button>
            </div>
          </Col>
          <Col lg={12} xs={10}>
            <p className={styles.textGray}>Name on Card</p>
            <p className={styles.text600}>{item.cardholder_name ?? "N/A"}</p>
          </Col>
          <Col lg={6} xs={8}>
            <p className={styles.textGray}>Expiry Date</p>
            <p className={styles.text600}>
              {item.expire_month + "/" + item.expire_year}
            </p>
          </Col>
        </Row>
      </Card>
    </Col>
  );

  const attachedCard = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 20,
      }}
    >
      {!userAttachedCard && !loading && (
        <p className={styles.noCard} style={{ paddingLeft: 10 }}>
          You don’t have any card! Please use different methods.
        </p>
      )}
      {!loading && userAttachedCard && (
        <div style={{ width: "90%" }}>{cardView(userAttachedCard, null)} </div>
      )}
    </div>
  );

  const NewCard = () => {
    const [card, setcard] = useState();
    const cardRef = useRef();

    useEffect(() => {
      initializeCard();
    }, []);
    const initializeCard = async () => {
      const payments = await window?.Square?.payments(
        SQUARE_APP_ID,
        SQUARE_LOCATION_ID
      );
      const card = await payments?.card();
      await card?.attach("#card");
      setcard(card);
    };

    const onPressSave = async () => {
      const result = await card?.tokenize();
      if (result?.status === "Invalid") {
        if (result?.errors?.[0].message) {
          message.error(result?.errors?.[0].message);
          return;
        } else if (result?.errors?.[1].message) {
          message.error(result?.errors?.[1].message);
          return;
        } else if (result?.errors?.[1].message) {
          message.error(result?.errors?.[1].message);
          return;
        }
      } else {
        createNewCard(result?.token);
      }
    };

    const createNewCard = async (token) => {
      setLoading(true);
      let payload = {
        default: true,
        card_token: token,
        user: data?.user?.id,
      };
      const response = await UserApi.AddUserCard(payload);
      if (response?.status === 201 || response?.status === 200) {
        message.success("success", "Card Successfully added");
        fetchCard();
      } else {
        message.error("error", response?.data?.detail);
        setLoading(false);
      }
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 20,
        }}
      >
        {!loading && !card && (
          <Spin
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 30,
              paddingBottom: 20,
            }}
          />
        )}
        <div style={{ width: "90%" }}>
          {card &&
            cardList &&
            !loading &&
            cardList?.length > 0 &&
            cardList?.map((item, key) => cardView(item, key))}
        </div>

        {!loading && (
          <div
            id="card"
            ref={cardRef}
            style={{ paddingTop: 10, width: "90%" }}
          ></div>
        )}

        {card && !loading && (
          <Button
            type="primary"
            size="large"
            style={{
              borderRadius: 10,
              paddingLeft: 40,
              paddingRight: 40,
            }}
            onClick={() => onPressSave()}
          >
            Add Card
          </Button>
        )}
      </div>
    );
  };
  return (
    <React.Fragment>
      <Radio.Group onChange={onChange} value={value} style={{ marginTop: 20 }}>
        <Radio value={"attachedCard"}>Attached Card</Radio>
        <Radio value={"newCard"}>New Card</Radio>
        <Radio value={"cash"}>Cash</Radio>
        <Radio value={"pos/taps"}>Pos/Taps</Radio>
      </Radio.Group>
      {loading && (
        <Spin
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 30,
            paddingBottom: 20,
          }}
        />
      )}
      {value === "attachedCard" && attachedCard}
      {value === "newCard" && <NewCard />}
      {value === "cash" && <CashPayment type="cash" />}
      {value === "pos/taps" && <CashPayment type="pos/taps" />}
    </React.Fragment>
  );
});

export default Payment;
