import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  message,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserApi } from "../../store/api";

export default function UserProfile() {
  const { id } = useParams();
  const [form] = Form.useForm(null);
  const dateFormat = "YYYY/MM/DD";

  const [loading, setloading] = useState(true);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const [userInfoData, setuserInfoData] = useState({});

  useEffect(() => {
    getDetail();
  }, []);

  useEffect(() => {
    if (userInfoData?.id === undefined) {
      form.resetFields();
    }
  }, [form, userInfoData]);

  const getDetail = async () => {
    setloading(true);
    form.resetFields();
    const response = await UserApi.getUserProfile(id);
    if (response.status === 200) {
      setuserInfoData(response?.data);
      // console.log("USAMA=======", response?.data);
    } else {
      console.log("Error while fetch solodives content list data!");
    }
    setloading(false);
  };

  const onPressUpdate = async () => {
    setloading(true);
    let payload = {
      cliniko_patient_id: userInfoData?.cliniko_patient_id,
      full_name: form.getFieldValue("full_name"),
      date_of_birth: form.getFieldValue("date_of_birth"),
      phone_number: form.getFieldValue("phone_number"),
      pronoun: form.getFieldValue("pronoun"),
      gender: form.getFieldValue("gender"),
      emergency_contact_name: form.getFieldValue("emergency_contact_name"),
      emergency_contact_number: form.getFieldValue("emergency_contact_number"),
      emergency_contact_relation: form.getFieldValue(
        "emergency_contact_relation"
      ),
    };
    // console.log(payload);
    const response = await UserApi.updateUserProfile(userInfoData?.id, payload);
    if (response.status === 200) {
      console.log(response);
      setuserInfoData(response?.data);
      message.success("Successfully updated");
      setloading(false);
    } else {
      message.error("Update Failed");
      setloading(false);
    }
  };

  //   const onChangeDob = (date, dateString) => {
  //     console.log(dateString);
  //     // form.setFieldValue("date_of_birth", dateString);

  //     form.setFieldValue(form, "date_of_birth", dateString);
  //   };

  return (
    <div>
      {loading ? (
        <Spin
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 100,
          }}
        />
      ) : (
        <Card
          title="USER PROFILE"
          style={{
            width: "100%",
          }}
        >
          <Form
            name="basic"
            form={form}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 8,
            }}
            initialValues={{
              email: userInfoData?.user?.email,
              user: userInfoData?.user?.id,
              cliniko_patient_id: userInfoData?.cliniko_patient_id,
              full_name: userInfoData?.user?.name,
              date_of_birth: userInfoData?.date_of_birth ?? null,
              gender: userInfoData?.gender,
              pronoun: userInfoData?.pronoun,
              phone_number: userInfoData?.phone_number,
              emergency_contact_name: userInfoData?.emergency_contact_name,
              emergency_contact_relation:
                userInfoData?.emergency_contact_relation,
            }}
            onFinish={() => {
              onPressUpdate();
            }}
            onFinishFailed={() => {}}
            autoComplete="off"
          >
            <Form.Item label="Email" name="email">
              <Input disabled />
            </Form.Item>
            <Form.Item label="User" name="user">
              <Input disabled />
            </Form.Item>
            {/* <Form.Item label="Patient Id" name="cliniko_patient_id">
              <Input disabled />
            </Form.Item> */}
            <Form.Item
              label="Full Name"
              name="full_name"
              rules={[
                {
                  required: true,
                  message: "Please input full name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="DOB"
              name="date_of_birth"
              rules={[
                {
                  required: true,
                  message: "Please input date of birth!",
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={
                  userInfoData?.date_of_birth
                    ? moment(userInfoData?.date_of_birth)
                    : null
                }
                onChange={(date, dateString) => {
                  form.setFieldValue("date_of_birth", date);
                }}
              />
              <Input style={{ display: "none" }} />
            </Form.Item>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Please select gender!",
                },
              ]}
            >
              <Select>
                <Select.Option value="FEMALE">FEMALE</Select.Option>
                <Select.Option value="MALE">MALE</Select.Option>
                <Select.Option value="OTHERS">OTHERS</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Pronoun" name="pronoun">
              <Input />
            </Form.Item>

            <Form.Item
              label="Phone number"
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: "Please input your Phone number!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Emergency Contact name"
              name="emergency_contact_name"
              //   rules={[
              //     {
              //       required: true,
              //       message: "Please input your Contact name!",
              //     },
              //   ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Emergency Contact number"
              name="emergency_contact_number"
              initialValue={userInfoData?.emergency_contact_number}
              //   rules={[
              //     {
              //       required: true,
              //       message: "Please input your Contact number!",
              //     },
              //   ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Emergency Contact relation"
              name="emergency_contact_relation"
              //   rules={[
              //     {
              //       required: true,
              //       message: "Please input your Contact relation!",
              //     },
              //   ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 8,
              }}
            >
              <Button type="primary" htmlType="submit" block>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
    </div>
  );
}
