import moment from "moment";
import React, { memo } from "react";
import styles from "./ModalScreen.module.scss";

const ModalScreen = memo(({ modalData }) => {
  console.log(modalData);
  const downloadHandle = async () => {
    window.location.replace(modalData?.invoice?.file_path);
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.flex}>
        <div>
          <img
            src="https://divethru.com/wp-content/uploads/2022/11/footer-logo-1.png"
            alt="divethru_logo"
            height={100}
            width={100}
          />
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <h2 className={styles.h2}>INVOICE</h2>
            <p>Date: </p>
            <p>Practitioner:</p>
            <p>Designation:</p>
            <p>Provider Number:</p>
          </div>
          <div style={{ marginLeft: 10 }}>
            <h2 className={styles.h2}>#{modalData?.context?.invoice_id}</h2>
            <p>{moment(modalData?.invoice?.created_at).format("MM/DD/YYYY")}</p>
            <p>{modalData?.context?.instructor_name}</p>
            <p>{modalData?.context?.instructor_designation}</p>
            <p>{modalData?.context?.instructor_id}</p>
          </div>
        </div>
      </div>
      <hr />
      <div className={styles.flex} style={{ width: "100%", paddingTop: 10 }}>
        <div>
          <h5 className={styles.h5}>INVOICE TO:</h5>
          <p className={styles.medium}>{modalData?.context?.client_name}</p>
          <p className={styles.medium} style={{ marginTop: -10 }}>
            {modalData?.context?.city +
              `${modalData?.context?.state !== "" ? ", " : ""}` +
              modalData?.context?.state}
          </p>
          <p className={styles.medium} style={{ marginTop: -10 }}>
            {modalData?.context?.country}
          </p>
        </div>
        <div>
          <h5 className={styles.h5}>CLIENT</h5>
          <p className={styles.medium}>{modalData?.context?.client_name}</p>
        </div>
        <div>
          <h5 className={styles.h5}>PAYMENT DETAILS</h5>

          {modalData?.context?.client_payment_type === "CASH" ? (
            <>
              <p className={styles.medium}>Paid by cash</p>
            </>
          ) : (
            <>
              <p className={styles.medium}>Paid with card ending in</p>
              <p className={styles.medium}>
                ********{modalData?.context?.client_card_number}
              </p>
            </>
          )}
        </div>
      </div>
      <hr style={{ marginBottom: 20 }} />

      <div style={{ display: "flex" }}>
        <div className={styles.left}>
          <h6 className={styles.table}>ITEM</h6>
        </div>
        <div className={styles.right}>
          <h6 className={styles.table}>TYPE</h6>
        </div>
        <div className={styles.right}>
          <h6 className={styles.table}>PRICE</h6>
        </div>
        <div className={styles.right}>
          <h6 className={styles.table}>TOTAL</h6>
        </div>
      </div>
      {modalData?.context?.items &&
        modalData?.context?.items.map((item, index) => (
          <div style={{ display: "flex" }} key={index}>
            <div
              className={styles.left}
              style={{
                backgroundColor: "#1BCDB7",
                paddingLeft: 10,
              }}
            >
              {item?.item}
            </div>
            <div
              className={styles.right}
              style={{ backgroundColor: "#1BCDB7" }}
            >
              {item?.type}
            </div>
            <div
              className={styles.right}
              style={{ backgroundColor: "#1BCDB7" }}
            >
              ${item?.amount}
            </div>
            <div
              className={styles.right}
              style={{ backgroundColor: "#1BCDB7" }}
            >
              ${item?.amount}
            </div>
          </div>
        ))}

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ width: "15%", textAlign: "center" }}>
          <div>Tax:</div>
          <div>Total Amount:</div>
        </div>
        <div style={{ width: "15%", textAlign: "center" }}>
          <div>$0.00</div>
          <div>${modalData?.context?.amount}</div>
        </div>
      </div>

      <hr />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ textAlign: "end" }}>
          <div className={styles.due}> AMOUNT DUE:</div>
        </div>
        <div style={{ width: "15%", textAlign: "center" }}>
          <div className={styles.due}>$0.00</div>
        </div>
      </div>

      <h6 style={{ fontWeight: "bold" }}>
        {modalData?.context?.location_name}
      </h6>
      <p>{modalData?.context?.address}</p>
      <p style={{ marginTop: -10 }}>
        {modalData?.context?.city +
          `${modalData?.context?.state !== "" ? ", " : " "}` +
          modalData?.context?.state}
      </p>
      <p style={{ marginTop: -10 }}>{modalData?.context?.postal_code}</p>
      {/* <p style={{ marginTop: -10 }}>{modalData?.context?.country}</p> */}
      <p style={{ marginTop: -10 }}>{modalData?.context?.phone}</p>

      <p className={styles.download} onClick={() => downloadHandle()}>
        Download
      </p>
    </div>
  );
});

export default ModalScreen;
