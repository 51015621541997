import React, { useState, useEffect } from "react";
import { Col, Row, Space, Table, Typography } from "antd";
import { UserActivityApi } from "../../store/api";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

const { Title } = Typography;

const UserSubmittedFormsDetails = () => {
  const { state } = useLocation();
  const { formId } = state; // Read values passed on state
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  console.log(data);

  useEffect(() => {
    getData();
  }, [formId]);

  const OptionView = useMemo((data) => {
    if (data?.form_options?.length > 0) {
      return data?.form_options.map((res, key) => (
        <div key={key}>
          <p>{key + 1 + ") " + res?.name}</p>
        </div>
      ));
    } else if (data?.form_options?.length === 0 && data?.text) {
      return <Space size="middle">{data?.text}</Space>;
    } else {
      return <Space size="middle">Not Available</Space>;
    }
    console.log("=====", data);
  }, []);

  const getData = async () => {
    setLoader(true);
    try {
      const response = await UserActivityApi.getAllSubmittedFormsDetails(
        formId
      );
      if (response.status === 200) {
        setData([response.data]);
      } else {
        setData(null);
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const answerColumns = [
    {
      title: "Form ID",
      dataIndex: "form_id",
      // align: "center",
      render: (text, record) => (
        <Space size="middle">{text?.name ?? "Not Available"}</Space>
      ),
    },
    {
      title: "Form Option",
      dataIndex: "form_options",
      // align: "center",
      render: (text, data) =>
        data?.form_options?.length > 0 ? (
          data?.form_options.map((res, key) => (
            <div key={key}>
              <p>{key + 1 + ") " + res?.name}</p>
            </div>
          ))
        ) : data?.text !== "" || data?.text !== null ? (
          <Space size="middle">{data?.text}</Space>
        ) : (
          <Space size="middle">Not Available</Space>
        ),
    },
  ];

  const preferenceColumns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 250,
      // align: "center",
      render: (text, record) => (
        <Space size="middle">{text ?? "Not Available"}</Space>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      // align: "center",
      render: (text, record) => (
        <Space size="middle">{text ?? "Not Available"}</Space>
      ),
    },
  ];

  const recommendedInstructors = [
    {
      title: "Name",
      dataIndex: "name",
      // width: 200,
      render: (text, record) => (
        <Space size="middle">{text ?? "Not Available"}</Space>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      // width: 200,
      render: (text, record) => <Space>{text ?? "Not Available"}</Space>,
    },
    {
      title: "Designation",
      dataIndex: "instructor",
      // width: 200,
      render: (text, record) => (
        <Space>{text.designation ?? "Not Available"}</Space>
      ),
    },
    {
      title: "Total Preference Match",
      dataIndex: "total_preference_match",
      // width: 200,
      render: (text, record) => <Space>{text ?? "Not Available"}</Space>,
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            REQUESTED FORM DETAILS
          </Title>
          <p style={{ textAlign: "left", marginBottom: 20 }}>
            USER NAME: {data[0]?.user ?? "Not Available"}
          </p>
        </Col>
      </Row>
      <p>Answer Sheet:</p>
      <Table
        style={{ marginTop: 10 }}
        columns={answerColumns}
        dataSource={data[0]?.answers ?? []}
        scroll={{ x: 1, y: window.innerHeight - 350 }}
        size="middle"
        loading={loader}
        rowKey={(data, index) => index}
        pagination={false}
      />
      <p style={{ marginTop: 30 }}>Preference Options :</p>
      <Table
        style={{ marginTop: 10, marginBottom: 20 }}
        columns={preferenceColumns}
        dataSource={data[0]?.preference_options ?? []}
        scroll={{ x: 1, y: window.innerHeight - 350 }}
        size="middle"
        loading={loader}
        rowKey={(data, index) => index}
        pagination={false}
      />
      <p style={{ marginTop: 30 }}>Recommended Instructors :</p>
      <Table
        style={{ marginTop: 10, marginBottom: 20 }}
        columns={recommendedInstructors}
        dataSource={data[0]?.recommended_instructors ?? []}
        scroll={{ x: 1, y: window.innerHeight - 350 }}
        size="middle"
        loading={loader}
        rowKey={(data, index) => index}
        pagination={false}
      />
    </React.Fragment>
  );
};

export default UserSubmittedFormsDetails;
