import { put } from "redux-saga/effects";
import { QuestionariesApi } from "../api";
import { constant } from "../Constant";
// workers
function* getAllIdentifier(param) {
  try {
    const res = yield QuestionariesApi.getIAlldentifier({
      page: param.page,
      limit: param.limit,
      q: param.q,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_IDENTIFIERS_SUCCESS,
        allIdentifiers: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_IDENTIFIERS_FAILED,
        errMsg: "Invalid number",
      };
      yield put(payload);
    }
  } catch (error) {
    const payload = {
      type: constant.GET_IDENTIFIERS_FAILED,
      errMsg: error.data.detail ?? "error in get identifiers",
    };
    yield put(payload);
  }
}

function* getAllOptions(param) {
  try {
    const res = yield QuestionariesApi.getIAllOptions({
      page: param.page,
      limit: param.limit,
      q: param.q,
      form_identifier: param.form_identifier,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_OPTIONS_SUCCESS,
        allOptions: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_OPTIONS_FAILED,
        errMsg: "Invalid number",
      };
      yield put(payload);
    }
  } catch (error) {
    const payload = {
      type: constant.GET_OPTIONS_FAILED,
      errMsg: error.data.detail ?? "error in get options",
    };
    yield put(payload);
  }
}

function* getAllLocations(param) {
  try {
    const res = yield QuestionariesApi.getIAllLocations({
      page: param.page,
      limit: param.limit,
      q: param.q,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_LOCATION_SUCCESS,
        allLocations: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_LOCATION_FAILED,
        errMsg: "Invalid number",
      };
      yield put(payload);
    }
  } catch (error) {
    const payload = {
      type: constant.GET_LOCATION_FAILED,
      errMsg: error.data.detail ?? "error in get locations",
    };
    yield put(payload);
  }
}

function* getIAllFormGroups(param) {
  try {
    const res = yield QuestionariesApi.getIAllFormGroups({
      page: param.page,
      limit: param.limit,
      q: param.q,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_FORM_GROUP_SUCCESS,
        allFromGroups: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_FORM_GROUP_FAILED,
        errMsg: "Invalid number",
      };
      yield put(payload);
    }
  } catch (error) {
    const payload = {
      type: constant.GET_FORM_GROUP_FAILED,
      errMsg: error.data.detail ?? "error in get form groups",
    };
    yield put(payload);
  }
}

function* getIAllForms(param) {
  try {
    const res = yield QuestionariesApi.getIAllForms({
      page: param.page,
      limit: param.limit,
      form_group: param.form_group,
      q: param.q,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_FORM_SUCCESS,
        allForms: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_FORM_FAILED,
        errMsg: "Invalid number",
      };
      yield put(payload);
    }
  } catch (error) {
    const payload = {
      type: constant.GET_FORM_FAILED,
      errMsg: error.data.detail ?? "error in get forms",
    };
    yield put(payload);
  }
}

function* getIAllInstructorInfo(param) {
  try {
    const res = yield QuestionariesApi.getIAllInstructorInfo({
      page: param.page,
      limit: param.limit,
      q: param.q,
      instructor: param.instructor,
      form_identifier: param.form_identifier,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_INSTRUCTOR_INFO_SUCCESS,
        allInstructorInfo: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_INSTRUCTOR_INFO_FAILED,
        errMsg: "Invalid number",
      };
      yield put(payload);
    }
  } catch (error) {
    const payload = {
      type: constant.GET_INSTRUCTOR_INFO_FAILED,
      errMsg: error.data.detail ?? "error in get instructor info",
    };
    yield put(payload);
  }
}

export {
  getAllIdentifier,
  getAllOptions,
  getAllLocations,
  getIAllFormGroups,
  getIAllForms,
  getIAllInstructorInfo,
};
