import React, { memo, useState, useEffect } from "react";
import { Checkbox, Form } from "antd";

const CheckboxHelper = memo(
  ({
    name,
    label,
    category,
    form,
    required = false,
    requiredStyle,
    margin_top,
    mergeData,
    mainIndex,
    childIndex,
  }) => {
    const customStyles = {
      label: {
        fontFamily: "Archivo",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: 20,
        lineHeight: "22px",
        letterSpacing: "0.05em",
        color: "#000000",
      },
      itemText: {
        fontFamily: "Archivo",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: 20,
        lineHeight: "22px",
        letterSpacing: "0.05em",
        color: "#000000",
        paddingTop: 10,
      },
    };
    // const [val, setVal] = useState();

    useEffect(() => {
      var selectedVal = [];
      if (category?.length > 0) {
        category.map((res) => {
          if (res?.selected) {
            selectedVal.push(res.value);
          }
        });
      }
      form.setFieldValue(name, selectedVal);
      // setVal(selectedVal);
      // console.log("=-=-=-=-", selectedVal);
    }, [category]);

    return (
      <Form.Item
        name={name}
        label={<label style={customStyles.label}>{label}</label>}
        style={{ marginTop: margin_top ?? 40 }}
        rules={[
          {
            required: required,
            message: `This field is required!`,
          },
        ]}
        className={required ? requiredStyle : null}
      >
        <Checkbox.Group
          onChange={async (e) => {
            await category.forEach(
              (o) => (o.selected = e.includes(o.value) ? true : null)
            );
            function clean(obj) {
              for (var propName in obj) {
                if (
                  obj[propName].selected === null ||
                  obj[propName].selected === undefined
                ) {
                  delete obj[propName].selected;
                }
              }
              return obj;
            }
            mergeData("select", clean(category), mainIndex, childIndex);
          }}
        >
          {category.map(
            (item, index) =>
              item.value !== null && (
                <React.Fragment key={index}>
                  <Checkbox value={item.value} style={customStyles.itemText}>
                    {item.value}
                  </Checkbox>
                  <br />
                </React.Fragment>
              )
          )}
        </Checkbox.Group>
      </Form.Item>
    );
  }
);

export default CheckboxHelper;
