import { Button, Col, Form, Input, message, Row, Spin } from "antd";
import React, { memo, useEffect } from "react";

const { TextArea } = Input;

const AddEditSoloDiveOverviewModal = memo(
  ({
    setvisibleAddSoloDiveOverviewModal,
    onSubmitAddSoloDiveOverviewModal,
    data,
    modalLoading,
  }) => {
    const [form] = Form.useForm();

    useEffect(() => {
      form.resetFields();
    }, [form, data]);

    const onSubmit = (value) => {
      // console.log(value);

      if (value.errorFields) {
        message.error("Please fill up required fields");
      } else {
        form.resetFields();
        onSubmitAddSoloDiveOverviewModal(value);
      }
    };

    return (
      <>
        {modalLoading ? (
          <Spin
            size="large"
            style={{ display: "flex", justifyContent: "center" }}
          />
        ) : (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{
              title: data?.title ?? "",
              modified_title: data?.modified_title ?? "",
              description: data?.description ?? "",
            }}
            onFinish={onSubmit}
            onFinishFailed={onSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input title!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Modified Title" name="modified_title">
              <Input />
            </Form.Item>

            <Form.Item label="Description" name="description">
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item>
              <Row gutter={5} style={{ justifyContent: "flex-end" }}>
                <Col>
                  <Button
                    type="danger"
                    shape="round"
                    onClick={() => {
                      form.resetFields();
                      setvisibleAddSoloDiveOverviewModal(false);
                    }}
                    size="large"
                  >
                    Close
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" shape="round" htmlType="submit" size="large">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}
      </>
    );
  }
);

export default AddEditSoloDiveOverviewModal;
