import { constant } from "../Constant";

const initialState = {
  loading: true,
  tags: [],
  count:0,
  errMsg: "",
  details: {},
};

const TagReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_TAGS:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: action.tags,
        count:action.count,
        errMsg: "",
      };
    case constant.GET_TAG_DETAILS:
      return {
        ...state,
        id: action.id,
        loading: true,
      };
    case constant.GET_TAG_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: action.details,
        errMsg: "",
      };
    case constant.GET_TAGS_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    case constant.CLEAN_SUBSCRIBER:
      return {
        ...state,
        loading: false,
        tags: [],
        count:0,
        errMsg: "",
      };
    default:
      return state;
  }
};

export default TagReducer;
