import { put } from "redux-saga/effects";
import { CourseApi } from "../api";
import { constant } from "../Constant";
// workers
function* getCourseAction(param) {
  try {
    const res = yield CourseApi.getAllCourse({
      page: param.page,
      limit: param.limit,
      q: param.q,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_COURSE_SUCCESS,
        course: res.data.results,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_COURSE_FAILED,
        errMsg: "Invalid Course Data",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_COURSE_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
export { getCourseAction };
