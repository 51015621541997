import { Row, Spin, Typography } from "antd";
import React from "react";
const { Text } = Typography;

export default function DeleteItemConfirmationModal({
  modalLoading,
  deletePopupData,
}) {
  return (
    <div>
      {modalLoading ? (
        <Spin
          style={{
            position: "absolute",
            top: "48%",
            left: "48%",
            zIndex: 1,
          }}
          size="large"
        />
      ) : (
        <>
          {deletePopupData?.chapter && (
            <Row>
              <strong level={5}>
                {deletePopupData?.chapter?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.chapter?.message}</Text>
            </Row>
          )}
          {deletePopupData?.course_content && (
            <Row>
              <strong level={5}>
                {deletePopupData?.course_content?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.course_content?.message}</Text>
            </Row>
          )}
          {deletePopupData?.user_chapter && (
            <Row>
              <strong level={5}>
                {deletePopupData?.user_chapter?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.user_chapter?.message}</Text>
            </Row>
          )}
          {deletePopupData?.user_course && (
            <Row>
              <strong level={5}>
                {deletePopupData?.user_course?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.user_course?.message}</Text>
            </Row>
          )}
          {deletePopupData?.user_course_content && (
            <Row>
              <strong level={5}>
                {deletePopupData?.user_course_content?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.user_course_content?.message}</Text>
            </Row>
          )}
          {deletePopupData?.user_course_review && (
            <Row>
              <strong level={5}>
                {deletePopupData?.user_course_review?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.user_course_review?.message}</Text>
            </Row>
          )}
          {deletePopupData?.solo_dive_content && (
            <Row>
              <strong level={5}>
                {deletePopupData?.solo_dive_content?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.solo_dive_content?.message}</Text>
            </Row>
          )}
          {deletePopupData?.user_active_dive && (
            <Row>
              <strong level={5}>
                {deletePopupData?.user_active_dive?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.user_active_dive?.message}</Text>
            </Row>
          )}
          {deletePopupData?.daily_dive && (
            <Row>
              <strong level={5}>
                {deletePopupData?.daily_dive?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.daily_dive?.message}</Text>
            </Row>
          )}

          {deletePopupData?.user_daily_dive_content && (
            <Row>
              <strong level={5}>
                {deletePopupData?.user_daily_dive_content?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.user_daily_dive_content?.message}</Text>
            </Row>
          )}

          {deletePopupData?.user_dive_actives && (
            <Row>
              <strong level={5}>
                {deletePopupData?.user_dive_actives?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.user_dive_actives?.message}</Text>
            </Row>
          )}

          {deletePopupData?.user_dive_recommendation && (
            <Row>
              <strong level={5}>
                {deletePopupData?.user_dive_recommendation?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.user_dive_recommendation?.message}</Text>
            </Row>
          )}

          {deletePopupData?.user_favourite && (
            <Row>
              <strong level={5}>
                {deletePopupData?.user_favourite?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.user_favourite?.message}</Text>
            </Row>
          )}

          {deletePopupData?.user_notification && (
            <Row>
              <strong level={5}>
                {deletePopupData?.user_notification?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.user_notification?.message}</Text>
            </Row>
          )}
          {deletePopupData?.user_reminder && (
            <Row>
              <strong level={5}>
                {deletePopupData?.user_reminder?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.user_reminder?.message}</Text>
            </Row>
          )}

          {deletePopupData?.user_subscription && (
            <Row>
              <strong level={5}>
                {deletePopupData?.user_subscription?.count}&nbsp;{" "}
              </strong>{" "}
              <Text>{deletePopupData?.user_subscription?.message}</Text>
            </Row>
          )}
        </>
      )}
    </div>
  );
}
