import {
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Row,
  Select,
  Spin,
} from "antd";
import React, { memo, useEffect, useState } from "react";
import NetworkClient from "../../store/api/utils/NetworkClient";
import CustomSelect from "../utils/CustomSelect";
import FileUpload from "../utils/FileUpload";

const { TextArea } = Input;

const AddEditCourseModal = memo(
  ({
    setvisibleAddCourseModal,
    onSubmitAddCourseModal,
    loader,
    insrtuctorOptions,
    categoryOptions,
    benefitsOptions,
    data,
  }) => {
    const [form] = Form.useForm();
    const [modalLoading, setLoading] = useState(false);

    //image preview
    const [bgPathImagePreview, setbgPathImagePreview] = useState("");
    const [bgPathImgLoading, setbgPathImgLoading] = useState(false);
    const [disableSubmit, setdisableSubmit] = useState(false);

    useEffect(() => {
      if (data && data?.cover_image) {
        setbgPathImagePreview(data?.cover_image);
      } else {
        setbgPathImagePreview("");
      }
    }, [data]);

    //loader
    useEffect(() => {
      loader ? setLoading(true) : setLoading(false);
    }, [loader]);

    //reset fields
    useEffect(() => {
      form.resetFields();
    }, [form, data]);

    //background image upload
    const imageUpload = async (e) => {
      try {
        setLoading(true);
        setbgPathImgLoading(true);
        const formData = new FormData();
        formData.append("image", e.file);
        const res = await NetworkClient.filePost(
          "/api/admin/upload-image",
          formData
        );
        // console.log("res", res);
        const { data } = res;
        console.log("uploaded image link", data);
        data && setbgPathImagePreview(data);
        setLoading(false);
        setbgPathImgLoading(false);
        return data;
      } catch (error) {
        setdisableSubmit(true);
      }
    };

    return (
      <>
        {modalLoading && (
          <Spin
            style={{
              position: "absolute",
              top: "48%",
              left: "48%",
              zIndex: 1,
            }}
            size="large"
          />
        )}
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onSubmitAddCourseModal}
          onFinishFailed={onSubmitAddCourseModal}
          autoComplete="off"
          initialValues={{
            title: data?.title ?? null,
            instructor: data?.instructor?.id ?? null,
            categories: data?.categoriesFilter ?? [],
            benefits: data?.benefitsFilter ?? [],
            is_premium: data?.is_premium ?? false,
            is_feature: data?.is_feature ?? false,
            in_feature: data?.in_feature ?? false,
            is_published: data?.is_published ?? false,
            cover_image: data?.cover_image ?? null,
            description: data?.description ?? null,
            description1: data?.description1 ?? null,
            currency: data?.amount_money?.currency,
            amount: data?.amount_money?.amount ?? null,
          }}
        >
          <Form.Item
            label="TITLE"
            name="title"
            rules={[
              {
                required: true,
                message: "Please input title!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <CustomSelect
              item={insrtuctorOptions}
              label="INSTRUCTOR"
              name="instructor"
              required={true}
            />
            <CustomSelect
              item={categoryOptions}
              label="CATEGORIES"
              name="categories"
              defaultValue={data?.categoriesFilter}
            />
          </Row>

          <Row gutter={16}>
            <CustomSelect
              item={benefitsOptions}
              label="BENEFITS"
              name="benefits"
              defaultValue={data?.benefitsFilter}
            />

            <Col span={12}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="PRICE"
                    name="amount"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please input amount!",
                    //   },
                    // ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="CURRENCY"
                    name="currency"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select currency!",
                    //   },
                    // ]}
                  >
                    <Select
                      defaultValue={"CAD"}
                      placeholder="Select a option and change input text above"
                      onChange={(data) => {
                        form.setFieldValue("currency", data);
                      }}
                    >
                      <Select.Option value="CAD">CAD</Select.Option>
                      {/* <Select.Option value="USD">USD</Select.Option> */}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={12} sm={12} xs={24}>
              <Form.Item label="ABOUT THIS COURSE" name="description">
                <TextArea rows={3} placeholder="Write course description..." />
              </Form.Item>
            </Col>
            <Col lg={12} sm={12} xs={24}>
              <Form.Item label="WHO THIS IS FOR" name="description1">
                <TextArea rows={3} placeholder="Write the course for whom..." />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col lg={6} sm={6} xs={12}>
              <Form.Item name="is_premium" valuePropName="checked">
                <Checkbox>IS PREMIUM</Checkbox>
              </Form.Item>
            </Col>
            <Col lg={6} sm={6} xs={12}>
              <Form.Item name="is_feature" valuePropName="checked">
                <Checkbox>IS FEATURE</Checkbox>
              </Form.Item>
            </Col>
            <Col lg={6} sm={6} xs={12}>
              <Form.Item name="in_feature" valuePropName="checked">
                <Checkbox>IN FEATURE</Checkbox>
              </Form.Item>
            </Col>
            <Col lg={6} sm={6} xs={12}>
              <Form.Item name="is_published" valuePropName="checked">
                <Checkbox>IS PUBLISHED</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <FileUpload
              label="BACKGROUND IMAGE"
              name="cover_image"
              upload={imageUpload}
              accept=".png, .jpg, .jpeg"
              modalLoading={modalLoading}
              required={true}
            />
            <Col lg={8} sm={8} xs={12}>
              {bgPathImgLoading ? (
                <Spin size="small" />
              ) : (
                <Image width={150} src={bgPathImagePreview} />
              )}
            </Col>
          </Row>

          <Form.Item>
            <Row gutter={5} style={{ justifyContent: "flex-end" }}>
              <Col>
                <Button
                  type="danger"
                  shape="round"
                  onClick={() => {
                    form.resetFields();
                    setvisibleAddCourseModal(false);
                  }}
                  size="large"
                >
                  Close
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={disableSubmit}
                  shape="round"
                  size="large"
                  loading={modalLoading}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </>
    );
  }
);

export default AddEditCourseModal;
