import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Spin,
  Switch,
  Typography,
  Upload,
} from "antd";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { memo, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ActionCreators } from "../../store";
import NetworkClient from "../../store/api/utils/NetworkClient";
const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

const html = "<p>Hey this <strong>editor</strong> rocks 😀</p>";
const contentBlock = htmlToDraft(html);

const AddEditInstructorModal = memo(
  ({
    setvisibleAddInstructorModal,
    onSubmitAddInstructorModal,
    data,
    modalLoading,
    isUpdateModal,
    dispatch,
    locationData,
  }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [providerData, setProviderData] = useState([]);
    const [editorState, seteditorState] = useState(EditorState.createEmpty());
    // const [referenceData, setReferenceData] = useState([]);

    // useEffect(() => {
    //   const pID = form.getFieldValue("cliniko_practitioner_id");
    //   if (providerData?.length > 0 && pID) {
    //     setPractitioner(pID);
    //   }
    // }, [providerData, form]);
    // useEffect(() => {
    // console.log(
    //   "Usama",
    //   draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // );
    // form?.setFieldValue(
    //   "long_description",
    //   draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // );
    // }, [editorState]);

    // useEffect(() => {

    //   return () => {
    //     form.resetFields();
    //   };
    // }, []);

    const onEditorStateChange = (editorState) => {
      seteditorState(editorState);
    };

    useEffect(() => {
      form.resetFields();
      if (data?.instructor?.studio_locations?.length > 0) {
        setLocationData(data?.instructor?.studio_locations);
      }
      // setProviderData([]);
      generateEditor("");
      if (data?.instructor_profile?.long_description) {
        // form?.setFieldValue('long_description',"")
        generateEditor(data?.instructor_profile?.long_description ?? "");
        // const html = data?.instructor_profile?.long_description ?? "";
        // const contentBlock = htmlToDraft(html);
        // if (contentBlock) {
        //   const contentState = ContentState.createFromBlockArray(
        //     contentBlock.contentBlocks
        //   );
        //   const editorState = EditorState.createWithContent(contentState);
        //   seteditorState(editorState);
        //   form?.setFieldValue(
        //     "long_description",
        //     draftToHtml(convertToRaw(editorState.getCurrentContent()))
        //   );
        // }
      }
    }, [form, data]);

    const generateEditor = (param) => {
      const html = param;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        seteditorState(editorState);
        form?.setFieldValue(
          "long_description",
          draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
      }
    };

    const imageUpload = async (e, image_name) => {
      setLoading(true);
      const formData = new FormData();
      formData.append(image_name === "file_path" ? "file" : "image", e.file);
      const url =
        image_name === "file_path"
          ? "/api/admin/upload-file"
          : "/api/admin/upload-image";
      const res = await NetworkClient.filePost(url, formData);
      const { data } = res;
      // console.log("uploaded image link", data);
      if (image_name === "photo_link") {
        form.setFieldsValue({ photo_link: data });
      } else if (image_name === "image_path") {
        form.setFieldsValue({ image_path: data });
      } else if (image_name === "cover_path") {
        form.setFieldsValue({ cover_path: data });
      } else {
        form.setFieldsValue({ file_path: data });
      }
      setLoading(false);
      return data;
    };

    // const syncWithCliniko = async () => {
    //   if (userDeatils?.instructor?.appointment_types?.length === 0) {
    //     message.error("No appoinment types to sync!");
    //     return;
    //   }
    //   const res = await InstructorApi.syncInstructor(data?.id);
    //   if (res.status === 200) {
    //     message.success("Sync with cliniko has been succeess!");
    //   } else {
    //     message.error("Sync with cliniko has not been succeed!");
    //   }
    // };

    const onSubmit = async (value) => {
      if (value.errorFields) {
        message.error("Please fill up required fields");
      } else {
        // if (value.sync_cliniko === true) {
        //   const res = await InstructorApi.syncInstructor(data?.id);
        //   if (res.status !== 200) {
        //     message.error("Sync with cliniko has not been succeed!");
        //   }
        // }
        delete value.appointment_types;
        delete value.sync_cliniko;
        delete value.referenceNumbers;

        // value.reference_numbers = referenceData;
        value.long_description = draftToHtml(
          convertToRaw(editorState.getCurrentContent())
        );
        onSubmitAddInstructorModal(value);
        form.resetFields();
      }
    };

    // const setPractitioner = async (data) => {
    //   const res = providerData.filter((e) => e.id === data);
    //   console.log(">><><><><><><><><>", res);
    //   if (res?.[0]) {
    //     setReferenceData(res?.[0]?.reference_numbers);
    //     form.setFieldsValue({
    //       referenceNumbers: res[0].reference_numbers[0].reference_number,
    //     });
    //   }
    // };

    const setsearchedText = (text) => {
      dispatch(ActionCreators.getLocations(1, 20, text));
    };

    const setLocationData = async (data) => {
      if (data?.length === 0) {
        setProviderData([]);
        form.setFieldsValue({ cliniko_practitioner_id: null });
        form.setFieldsValue({ referenceNumbers: null });
        return;
      }
      setLoading(true);
      const allArr = [];
      for (let i = 0; i < data.length; i++) {
        await locationData.filter((res) => {
          if (res.id === data[i] && res.cliniko_location_id !== null) {
            allArr.push(res.cliniko_location_id);
          }
          return null;
        });
      }
      let params =
        "cliniko_location_ids=" + allArr.join("&cliniko_location_ids=");
      const getData = await NetworkClient.get(
        `api/admin/appointment-management/instructors?${params}`
      );
      setProviderData(getData.data.result ?? []);
      setLoading(false);
    };

    // const content = (
    //   <>
    //     {userDeatils?.instructor?.appointment_types?.length > 0 &&
    //       userDeatils?.instructor?.appointment_types?.map((res, key) => (
    //         <p key={key}>{res.appointment_name}</p>
    //       ))}
    //     {userDeatils?.instructor?.appointment_types?.length === 0 && (
    //       <p>No appointment data found!</p>
    //     )}
    //   </>
    // );
    return (
      <>
        {modalLoading ? (
          <Spin
            size="large"
            style={{ display: "flex", justifyContent: "center" }}
          />
        ) : (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{
              email: data?.email ?? null,
              title: data?.instructor?.title ?? "",
              description: data?.instructor?.description ?? "",
              priority: data?.instructor?.priority ?? null,
              cover_path: data?.instructor?.cover_path ?? "",
              file_path: data?.instructor?.file_path ?? "",
              image_path: data?.instructor?.image_path ?? "",
              designation: data?.instructor?.designation ?? null,
              // long_description: data?.instructor?.long_description ?? null,
              // referenceNumbers: data?.instructor_profile?.referenceNumbers ?? null,
              rate: data?.instructor?.rate ?? null,
              studio_locations: data?.instructor?.studio_locations ?? [],
              is_available: data?.instructor?.is_available ?? false,
              cliniko_practitioner_id:
                data?.instructor?.cliniko_practitioner_id ?? null,
              pronoun: data?.instructor?.pronoun ?? null,
              is_active: data?.is_active ?? true,
              invoice_designation:
                data?.instructor_profile?.invoice_designation ?? null,
            }}
            onFinish={onSubmit}
            onFinishFailed={onSubmit}
            autoComplete="off"
            style={{ position: "relative" }}
          >
            {!isUpdateModal && (
              <>
                <Form.Item
                  label="NAME"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Instructor name!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Row gutter={12}>
                  <Col lg={12} sm={24} xs={24}>
                    <Form.Item label="First Name" name="first_name">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={12} sm={24} xs={24}>
                    <Form.Item label="Last Name" name="last_name">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Form.Item label="Instructor Title" name="title">
              <Input />
            </Form.Item>
            <Row gutter={16}>
              <Col lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12} sm={12} xs={24}>
                <Form.Item label="Studio Locations" name="studio_locations">
                  <Select
                    showSearch
                    placeholder="Select/Search Locations"
                    optionFilterProp="children"
                    onChange={(res) => setLocationData(res)}
                    onSearch={(value) => setsearchedText(value)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    mode={"multiple"}
                  >
                    {locationData?.length > 0 &&
                      locationData.map((e, key) => {
                        if (e?.is_published) {
                          return (
                            <Option key={key} value={e.id}>
                              {e.location_name}
                            </Option>
                          );
                        }
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {!isUpdateModal && (
              <>
                <Row gutter={12}>
                  <Col lg={12} sm={24} xs={24}>
                    <Form.Item label="Membership" name="membership">
                      <Select
                        placeholder="Select membership"
                        onChange={() => {}}
                      >
                        <Option value="FREE">FREE</Option>
                        <Option value="TRIAL">TRIAL</Option>
                        <Option value="UNLOCKED">UNLOCKED</Option>
                        <Option value="MONTHLY">MONTHLY</Option>
                        <Option value="YEARLY">YEARLY</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={12} sm={24} xs={24}>
                    <Form.Item label="Role" name="role">
                      <Select placeholder="Select role" onChange={() => {}}>
                        <Option value="ADMIN" disabled={true}>
                          ADMIN
                        </Option>
                        <Option value="STAFF" disabled={true}>
                          STAFF
                        </Option>
                        <Option value="INSTRUCTOR">INSTRUCTOR</Option>
                        <Option value="BASIC" disabled={true}>
                          BASIC
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Row gutter={16}>
              <Col lg={12} sm={12} xs={24}>
                <Form.Item label="Instructor Designation" name="designation">
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12} sm={12} xs={12}>
                <Form.Item
                  label="Practitioner Name"
                  name="cliniko_practitioner_id"
                >
                  <Select
                    placeholder="Select Practitioner name"
                    // onChange={(e) => setPractitioner(e)}
                    loading={loading}
                  >
                    {providerData?.length > 0 &&
                      providerData.map((res, key) => (
                        <Option value={res.id} key={key}>
                          {res.label}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col lg={12} sm={12} xs={12}>
                <Form.Item name={"pronoun"} label="Pronoun">
                  <Select placeholder="Select Pronoun">
                    <Option value="she/her">she/her</Option>
                    <Option value="he/him">he/him</Option>
                    <Option value="they/them">they/them</Option>
                    <Option value="other">other</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} sm={12} xs={12}>
                <Form.Item
                  label="Invoice Designation"
                  name="invoice_designation"
                >
                  <Input />
                </Form.Item>
              </Col>
              {data?.instructor_profile?.reference_numbers?.length > 0 && (
                <Col lg={12} sm={12} xs={12}>
                  {/* <p>{JSON.stringify(data?.instructor_profile?.reference_numbers)}</p> */}

                  <Form.Item label="Reference Numbers" name="referenceNumbers">
                    <ul>
                      {data?.instructor_profile?.reference_numbers?.map(
                        (item, key) => (
                          <li key={key}>
                            <Typography.Text>
                              {item?.reference_number}
                            </Typography.Text>
                          </li>
                        )
                      )}
                    </ul>
                    {/* <Input disabled /> */}
                  </Form.Item>
                </Col>
              )}
            </Row>

            {/* {!isUpdateModal && (
              <Form.Item label="Bio" name="bio">
                <TextArea rows={3} placeholder={"Write from here..."} />
              </Form.Item>
            )} */}
            <Form.Item label="Instructor Description" name="description">
              <TextArea rows={3} placeholder={"Write from here..."} />
            </Form.Item>
            <Form.Item label="Long Description" name="long_description">
              {/* <TextArea rows={3} placeholder={"Write from here..."} /> */}
              <Editor
                editorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                  options: ["inline", "fontSize", "list", "textAlign"],
                  inline: {
                    options: ["bold", "italic", "underline"],
                  },
                }}
                editorStyle={{
                  border: "1px solid #d9d9d9",
                  // lineHeight: "3px",
                  // height: 80,
                }}
                placeholder={"Write from here..."}
                onEditorStateChange={onEditorStateChange}
              />
            </Form.Item>

            <Row gutter={16}>
              <Col lg={8} sm={12} xs={12}>
                <Form.Item
                  label="IS AVAILABLE"
                  name="is_available"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={data?.instructor?.is_available ?? true}
                    // onChange={(data) => {
                    //   form.setFieldsValue("is_available", data);
                    // }}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} sm={12} xs={12}>
                <Form.Item label="Rate" name="rate">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col lg={8} sm={12} xs={12}>
                <Form.Item label="Priority" name="priority">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              {/* </Row>

            <Row gutter={16}> */}

              <Col lg={8} sm={12} xs={12}>
                <Form.Item
                  label="IS ACTIVE"
                  name="is_active"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={data?.is_active ?? true}
                    // onChange={(data) => {
                    //   form.setFieldsValue("is_active", data);
                    // }}
                  />
                </Form.Item>
              </Col>
              {!isUpdateModal && (
                <Col lg={8} sm={12} xs={12}>
                  <Form.Item
                    label="Photo link"
                    name="photo_link"
                    valuePropName="string"
                    getValueFromEvent={(e) => imageUpload(e, "photo_link")}
                    rules={[
                      {
                        required: true,
                        message: "Please upload icon!",
                      },
                    ]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      multiple={false}
                      maxCount={1}
                      disabled={loading}
                      accept=".png, .jpg, .jpeg"
                    >
                      <Button icon={<UploadOutlined />} shape="round">
                        UPLOAD
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              )}
              <Col lg={8} sm={12} xs={12}>
                <Form.Item
                  label="Image path"
                  name="image_path"
                  valuePropName="string"
                  getValueFromEvent={(e) => imageUpload(e, "image_path")}
                >
                  <Upload
                    beforeUpload={() => false}
                    multiple={false}
                    maxCount={1}
                    disabled={loading}
                    accept=".png, .jpg, .jpeg"
                  >
                    <Button icon={<UploadOutlined />} shape="round">
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>
                <Text>
                  {data?.instructor?.image_path?.substr(
                    data?.instructor?.image_path?.lastIndexOf("/") + 1
                  )}
                </Text>
              </Col>

              <Col lg={8} sm={12} xs={24}>
                <Form.Item
                  label="Cover path"
                  name="cover_path"
                  valuePropName="string"
                  getValueFromEvent={(e) => imageUpload(e, "cover_path")}
                >
                  <Upload
                    beforeUpload={() => false}
                    multiple={false}
                    maxCount={1}
                    disabled={loading}
                    accept=".png, .jpg, .jpeg"
                  >
                    <Button icon={<UploadOutlined />} shape="round">
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>
                <Text>
                  {data?.instructor?.cover_path?.substr(
                    data?.instructor?.cover_path?.lastIndexOf("/") + 1
                  )}
                </Text>
              </Col>
              <Col lg={8} sm={12} xs={12}>
                <Form.Item
                  label="File path"
                  name="file_path"
                  valuePropName="string"
                  getValueFromEvent={(e) => imageUpload(e, "file_path")}
                >
                  <Upload
                    beforeUpload={() => false}
                    multiple={false}
                    maxCount={1}
                    disabled={loading}
                    accept="*"
                  >
                    <Button icon={<UploadOutlined />} shape="round">
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>
                <Text>
                  {data?.instructor?.file_path?.substr(
                    data?.instructor?.file_path?.lastIndexOf("/") + 1
                  )}
                </Text>
              </Col>
            </Row>

            <Form.Item>
              <Row gutter={5} style={{ justifyContent: "flex-end" }}>
                <Col>
                  <Button
                    type="danger"
                    onClick={() => {
                      form.resetFields();
                      setvisibleAddInstructorModal(false);
                    }}
                    size="large"
                    shape="round"
                  >
                    Close
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    shape="round"
                    loading={loading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form.Item>

            {loading && (
              <Spin
                size="large"
                style={{ position: "absolute", top: "48%", left: "48%" }}
              />
            )}
          </Form>
        )}
      </>
    );
  }
);

export default AddEditInstructorModal;
