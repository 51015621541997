/* eslint-disable require-jsdoc */
import { ContentChoices } from "../enums";
import { getNonNaneData } from "../utils";
import http from "./utils/NetworkClient";

export default class ContentApi {
  static async getAllContent(payload) {
    let params = getNonNaneData(payload.params);
    const response = await http.get("/api/admin/content", params);
    return response;
  }
  static async getAllContentList(params) {
    const response = await http.get("/api/admin/content", params);
    return response;
  }
  static async getContentDetails(type, id) {
    const response = await http.get(`/api/admin/${type}/${id}`);
    return response;
  }
  static async deleteSingleContent(id) {
    // const response = await http.del(`/api/admin/${type}/${id}`);
    const response = await http.del(`/api/admin/content/${id}`);
    return response;
  }
  static async deleteSingleContentConfirm(id) {
    // const response = await http.del(`/api/admin/${type}/${id}`);
    const response = await http.del(`/api/admin/content/${id}?delete=true`);
    return response;
  }
  static async addJournal(payload) {
    const response = await http.post(
      `/api/admin/${ContentChoices.journal}`,
      payload
    );
    return response;
  }
  static async updateJournal(payload, id) {
    const response = await http.put(
      `/api/admin/${ContentChoices.journal}/${id}`,
      payload
    );
    return response;
  }
  static async addAudio(payload) {
    const response = await http.post(
      `/api/admin/${ContentChoices.audio}`,
      payload
    );
    return response;
  }
  static async updateAudio(payload, id) {
    const response = await http.put(
      `/api/admin/${ContentChoices.audio}/${id}`,
      payload
    );
    return response;
  }

  static async addVideo(payload) {
    const response = await http.post(
      `/api/admin/${ContentChoices.video}`,
      payload
    );
    return response;
  }
  static async updateVideo(payload, id) {
    const response = await http.put(
      `/api/admin/${ContentChoices.video}/${id}`,
      payload
    );
    return response;
  }
  static async addArticle(payload) {
    const response = await http.post(
      `/api/admin/${ContentChoices.article}`,
      payload
    );
    return response;
  }
  static async updateArticle(payload, id) {
    const response = await http.put(
      `/api/admin/${ContentChoices.article}/${id}`,
      payload
    );
    return response;
  }
}
