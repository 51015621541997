import { put } from "redux-saga/effects";
import { constant } from "../Constant";
import { IntervalApi } from "../api";
// workers
function* getIntervalsAction(param) {
  try {
    const res = yield IntervalApi.getIntervals({
      page: param.page,
      limit: param.limit,
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_INTERVALS_SUCCESS,
        intervals: res.data.results,
        count:res.data.count
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_INTERVALS_FAILED,
        errMsg: "Invalid interval",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_INTERVALS_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
function* getIntervalDetailsAction(param) {
  try {
    const res = yield IntervalApi.getIntervalDetails(param);
    if (res.status === 200) {
      const payload = {
        type: constant.GET_INTERVAL_DETAILS_SUCCESS,
        details: res.data,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_INTERVALS_FAILED,
        errMsg: "Invalid interval",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_INTERVALS_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
export { getIntervalsAction, getIntervalDetailsAction };
