import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Radio,
  Row,
  Spin,
} from "antd";
import parse from "html-react-parser";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useLocation, useNavigate } from "react-router";
import SignatureCanvas from "react-signature-canvas";
import IntakeFormHeader from "../../components/header/IntakeFormHeader";
import CheckboxHelper from "../../components/intakeForm/CheckboxHelper";
import IntakeFormApi from "../../store/api/IntakeFormApi";
import styles from "./IntakeForm.module.scss";

const IntakeForm = () => {
  let signatureRef = useRef();
  const [form] = Form.useForm();
  const navigation = useNavigate();
  const params = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imgExists, setImgExists] = useState(false);

  const payload = data?.cliniko_patient_form_payload?.sections;
  const submitDataArr =
    payload?.length > 0 ? JSON.parse(JSON.stringify(payload)) : [];

  // console.log(">>>>>>>>>>>.", payload);
  // console.log("??????????", submitDataArr);

  //default value set
  const initialValue = () => {
    if (payload?.length > 0) {
      payload.forEach((item, mainIndex) => {
        if (item?.questions?.length > 0) {
          item?.questions?.forEach((res, index) => {
            if (res.type === "text" && res.name.includes("Signature")) {
              if (
                res?.answer === undefined ||
                res?.answer === null ||
                res?.answer === ""
              ) {
                setImgExists(false);
              } else {
                setImgExists(true);
              }
            }
            if (
              (res.type === "text" ||
                res.type !== "date" ||
                res.type === "paragraph") &&
              res?.answer
            ) {
              form.setFieldValue(res.name + mainIndex + index, res.answer);
              if (res.name.includes("Signature") && res?.answer) {
                const signatureName = item?.questions?.[index - 1]?.name;
                form.setFieldValue(
                  signatureName + mainIndex + (index - 1),
                  res.answer
                );
              }
            }
            if (res.type === "date" && res?.answer) {
              form.setFieldValue(
                res.name + mainIndex + index,
                moment(res.answer)
              );
            }
            if (res.type === "radiobuttons") {
              let selectedVal;
              res.answers.forEach((val) => {
                if (val.selected) {
                  selectedVal = val.value;
                }
              });
              form.setFieldValue(res.name + mainIndex + index, selectedVal);
            }
            if (res.type === "checkboxes" && res.answers?.[0]?.value === null) {
              form.setFieldValue(
                res.name + mainIndex + index,
                res.answers?.[0]?.selected ?? undefined
              );
            }
            return;
          });
        }
      });
    }
  };
  useEffect(() => {
    initialValue();
  }, [payload, data, form]);

  //fetach form data
  const fetchData = async () => {
    if (!params?.state?.id) {
      navigation(-1);
    } else {
      const res = await IntakeFormApi.getFormDetails(params?.state?.id);
      if (res.status === 200) {
        setData(res.data);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [navigation, params]);

  //array update based on onchange
  const mergeData = async (type, val, mainArrIndex, childArrIndex) => {
    await submitDataArr.map((item, index) => ({
      ...item,
      questions:
        index === mainArrIndex
          ? item.questions.map((res, key) => ({
              ...res,
              answer:
                key === childArrIndex && type === "answer"
                  ? (res.answer = val)
                  : res.answer ?? null,
              answers:
                key === childArrIndex && type === "select"
                  ? (res.answers = val)
                  : res.answers ?? null,
            }))
          : item.questions,
    }));

    submitDataArr.map((item) => {
      function clean(obj) {
        for (var propName in obj) {
          if (
            obj[propName]?.answer === null ||
            obj[propName]?.answer === undefined ||
            obj[propName]?.answer === ""
          ) {
            delete obj[propName]?.answer;
          }
        }
        return obj;
      }
      return clean(item.questions);
    });
    // console.log("submit data arr: ", submitDataArr);
  };

  // generate file from base64 string
  const base64ToBlob = (dataurl) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const sliceSize = 1024;
    const byteChars = window.atob(arr[1]);
    const byteArrays = [];
    for (
      let offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      let slice = byteChars.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {
      type: mime,
    });
  };

  const submitData = async (e, save = false) => {
    setLoading(true);
    let sigID;
    let sigUrl;
    const image = form.getFieldValue("signatureData");

    if (imgExists && image !== undefined && image !== null && image !== "") {
      await IntakeFormApi.deleteImg(params?.state?.id, params?.state?.user_id);
    }

    if (image === undefined || image === null || image === "") {
      sigID = null;
      sigUrl = null;
    } else {
      const getFilename = (dataUrl) => {
        const arr = dataUrl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        return Math.round(+new Date() / 1000) + "." + mime.split("/").pop();
      };
      // generate file from base64 string
      const blob = base64ToBlob(image);
      blob.name = getFilename(image);
      const data = new FormData();
      data.append("file", blob);
      const uploadSignature = await IntakeFormApi.uploadSignature(
        data,
        params?.state?.user_id
      );
      if (uploadSignature.status === 200 || uploadSignature.status === 201) {
        sigID = uploadSignature.data.id;
        sigUrl = uploadSignature.data.url;
        await mergeData(
          "answer",
          uploadSignature.data.url,
          form.getFieldValue("signatureMainIndex"),
          form.getFieldValue("signatureChildIndex")
        );
      } else {
        message.error(
          "Found an error while signature is uploading into server!"
        );
        setLoading(false);
        return;
      }
    }

    const payload = {
      is_save: save,
      is_submit: save ? false : true,
      attachment_id: sigID ?? null,
      attachment_url: sigUrl ?? null,
      consent_form_content: {
        sections: submitDataArr,
      },
    };
    console.log("submit data>>>>>>", payload);
    const postRes = await IntakeFormApi.updateIntakeForm(
      payload,
      params?.state?.id,
      params?.state?.user_id
    );
    if (postRes.status === 200) {
      message.success(
        `Consent form has been ${save ? "saved" : "submitted"} successfully!`
      );
      if (save) {
        fetchData();
      }
    } else {
      if (postRes.status === 400) {
        message.error(
          postRes?.data?.details ??
            "Something went wrong! Please try again or contact with admin."
        );
      } else {
        message.error(
          "Something went wrong! Please try again or contact with admin."
        );
      }
    }
    !save && navigation(-1);
    setLoading(false);
  };

  return (
    <Card style={{ textAlign: "left" }}>
      {loading && (
        <div className={styles.spin}>
          <Spin />
        </div>
      )}
      <IntakeFormHeader
        downloadHandle={() => null}
        saveHandle={async () => {
          submitData(form, true);
        }}
      />

      <Form
        form={form}
        name="basic"
        layout="vertical"
        autoComplete="off"
        initialValues={{}}
        scrollToFirstError={true}
        onFinish={submitData}
        onFinishFailed={(e) => {
          console.warn(e);
          message.error("Please fill-up all required fields!");
        }}
      >
        <p className={styles.title}>
          {data?.consent_form?.cliniko_patient_form_template_name}
        </p>

        {payload?.length > 0 &&
          payload.map((mainItem, mainIndex) => {
            return (
              <React.Fragment key={mainIndex}>
                <Row>
                  <Col></Col>
                </Row>
                {mainItem?.name && (
                  <div className={styles.subTitle}>{parse(mainItem?.name)}</div>
                )}

                {mainItem?.description && (
                  <div className={styles.text}>
                    {parse(`${mainItem.description}`)}
                  </div>
                )}

                <Row gutter={24} style={{ marginTop: 40 }}>
                  {mainItem?.questions?.length > 0 &&
                    mainItem?.questions.map((item, index) => {
                      // console.log(">>>>>>>>>>>>>>>>", item);
                      const half =
                        item.name === "First Name" || item.name === "Last Name";
                      return (
                        <React.Fragment key={index}>
                          {item.type === "text" &&
                            !item.name.includes("Phone Number") &&
                            !item.name.includes("Signature") && (
                              <Col
                                lg={half ? 10 : 24}
                                md={half ? 10 : 24}
                                sm={24}
                                xs={24}
                              >
                                <Form.Item
                                  name={item.name + mainIndex + index}
                                  label={
                                    <label className={styles.label}>
                                      {item.name}
                                    </label>
                                  }
                                  rules={[
                                    {
                                      required: item.required,
                                      message: "This field is required",
                                    },
                                  ]}
                                  className={styles.requiredFieldRight}
                                >
                                  <Input
                                    className={styles.nameInputStyle}
                                    onChange={(e) =>
                                      mergeData(
                                        "answer",
                                        e.target.value,
                                        mainIndex,
                                        index
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            )}
                          {item.type === "checkboxes" && (
                            <Col lg={24} md={24} sm={24} xs={24}>
                              {item.answers?.[0]?.value === null ||
                              !item.answers ? (
                                <Form.Item
                                  name={item.name + mainIndex + index}
                                  valuePropName="checked"
                                  rules={[
                                    {
                                      validator: (_, value) =>
                                        value
                                          ? Promise.resolve()
                                          : Promise.reject(
                                              new Error(
                                                "Please agree to the terms and conditions!"
                                              )
                                            ),
                                    },
                                  ]}
                                >
                                  <Checkbox
                                    onChange={async (e) => {
                                      form.setFieldValue(
                                        item.name + mainIndex + index,
                                        e.target.checked
                                      );
                                      await item.answers.forEach(
                                        (o) =>
                                          (o.selected = e.target.checked
                                            ? true
                                            : null)
                                      );
                                      function clean(obj) {
                                        for (var propName in obj) {
                                          if (
                                            obj[propName].selected === null ||
                                            obj[propName].selected === undefined
                                          ) {
                                            delete obj[propName].selected;
                                          }
                                        }
                                        return obj;
                                      }

                                      mergeData(
                                        "select",
                                        clean(item?.answers),
                                        mainIndex,
                                        index
                                      );
                                    }}
                                  >
                                    <p className={styles.checkboxText}>
                                      {item.name}
                                      <span style={{ color: "red" }}>*</span>
                                    </p>
                                  </Checkbox>
                                </Form.Item>
                              ) : (
                                <CheckboxHelper
                                  form={form}
                                  name={item.name + mainIndex + index}
                                  label={item.name}
                                  category={item.answers}
                                  required={item.required}
                                  requiredStyle={styles.requiredFieldRight}
                                  margin_top={0}
                                  mergeData={mergeData}
                                  mainIndex={mainIndex}
                                  childIndex={index}
                                />
                              )}
                            </Col>
                          )}

                          {item.type === "radiobuttons" && (
                            <Col lg={24} md={24} sm={24} xs={24}>
                              <Form.Item
                                name={item.name + mainIndex + index}
                                label={
                                  <label className={styles.label}>
                                    {item.name}
                                  </label>
                                }
                                rules={[
                                  {
                                    required: item.required,
                                    message: "This field is required!",
                                  },
                                ]}
                                className={styles.requiredFieldRight}
                              >
                                <Radio.Group
                                  onChange={(e) => {
                                    item.answers.map(
                                      (res) =>
                                        (res.selected =
                                          res.value === e.target.value
                                            ? true
                                            : null)
                                    );
                                    function clean(obj) {
                                      for (var propName in obj) {
                                        if (
                                          obj[propName].selected === null ||
                                          obj[propName].selected === undefined
                                        ) {
                                          delete obj[propName].selected;
                                        }
                                      }
                                      return obj;
                                    }
                                    mergeData(
                                      "select",
                                      clean(item.answers),
                                      mainIndex,
                                      index
                                    );
                                  }}
                                  // defaultValue={"He/Him/His"}
                                >
                                  {item.answers.map((item, index) => (
                                    <div key={index} style={{ marginTop: 10 }}>
                                      <Radio
                                        value={item.value}
                                        checked={index === 2}
                                      >
                                        <p className={styles.radioButton}>
                                          {item.value}
                                        </p>
                                      </Radio>
                                      <br />
                                    </div>
                                  ))}
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          )}

                          {item.type === "text" &&
                            item.name.includes("Phone Number") && (
                              <Col lg={24} md={24} sm={24} xs={24}>
                                <Form.Item
                                  name={item.name + mainIndex + index}
                                  label={
                                    <label
                                      className={styles.label}
                                      style={{ marginBottom: 10 }}
                                    >
                                      {item.name}
                                    </label>
                                  }
                                  rules={[
                                    {
                                      required: item.required,
                                      message: "This field is required!",
                                    },
                                  ]}
                                  className={styles.requiredFieldRight}
                                >
                                  <PhoneInput
                                    placeholder="Enter phone number"
                                    country={"ca"}
                                    inputStyle={{
                                      width: "100%",
                                      height: 55,
                                      borderRadius: 10,
                                      backgroundColor: "#f5f5f5",
                                      borderColor: "#f5f5f5",
                                      fontSize: 20,
                                    }}
                                    buttonStyle={{
                                      borderTopLeftRadius: 10,
                                      borderBottomLeftRadius: 10,
                                    }}
                                    dropdownStyle={{
                                      textAlign: "left",
                                    }}
                                    onChange={(e) =>
                                      mergeData("answer", e, mainIndex, index)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            )}
                          {item.type === "date" && (
                            <Col lg={24} md={24} sm={24} xs={24}>
                              <Form.Item
                                name={item.name + mainIndex + index}
                                label={
                                  <label
                                    className={styles.label}
                                    style={{ marginBottom: 10 }}
                                  >
                                    {item.name}
                                  </label>
                                }
                                rules={[
                                  {
                                    required: item.required,
                                    message: "This field is required!",
                                  },
                                ]}
                                className={styles.requiredFieldRight}
                              >
                                <DatePicker
                                  format={["YYYY-MM-DD", "YY-MM-DD"]}
                                  className={styles.DatePicker}
                                  disabledDate={(date) => {
                                    if (moment(date) < moment().add(0, "day")) {
                                      return false;
                                    }
                                    return true;
                                  }}
                                  onChange={(e, f) =>
                                    mergeData("answer", f, mainIndex, index)
                                  }
                                />
                              </Form.Item>
                            </Col>
                          )}

                          {item.type === "paragraph" && (
                            <Col lg={24} md={24} sm={24} xs={24}>
                              <Form.Item
                                name={item.name + mainIndex + index}
                                label={
                                  <label className={styles.label}>
                                    {item.name}
                                  </label>
                                }
                                rules={[
                                  {
                                    required: item.required,
                                    message: "This field is required!",
                                  },
                                ]}
                                className={styles.requiredFieldRight}
                              >
                                <Input.TextArea
                                  className={styles.nameInputStyle}
                                  rows={4}
                                  onChange={(e) =>
                                    mergeData(
                                      "answer",
                                      e.target.value,
                                      mainIndex,
                                      index
                                    )
                                  }
                                />
                              </Form.Item>
                            </Col>
                          )}

                          {item.type === "signature" && (
                            <Col lg={24} md={24} sm={24} xs={24}>
                              <Form.Item
                                name={item.name + mainIndex + index}
                                label={
                                  <label className={styles.label}>
                                    {item.name}
                                  </label>
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required!",
                                  },
                                ]}
                                className={styles.requiredFieldRight}
                              >
                                <SignatureCanvas
                                  ref={(ref) => {
                                    signatureRef = ref;
                                  }}
                                  penColor="blue"
                                  canvasProps={{ className: styles.sigPad }}
                                  onEnd={async () => {
                                    const image = signatureRef
                                      .getTrimmedCanvas()
                                      .toDataURL("image/png");

                                    form.setFields([
                                      {
                                        name: item.name + mainIndex + index,
                                        validating: true,
                                        errors: [""],
                                      },
                                    ]);

                                    form.setFieldValue(
                                      item.name + mainIndex + index,
                                      image
                                    );

                                    if (
                                      mainItem?.questions[index + 1].type ===
                                      "date"
                                    ) {
                                      form.setFieldsValue({
                                        signatureMainIndex: mainIndex,
                                        signatureChildIndex: index,
                                        signatureData: image,
                                      });
                                    } else {
                                      form.setFieldsValue({
                                        signatureMainIndex: mainIndex,
                                        signatureChildIndex: index + 1,
                                        signatureData: image,
                                      });
                                    }
                                  }}
                                />
                                <span
                                  className={styles.clear}
                                  onClick={() => {
                                    signatureRef.clear();
                                    form.setFieldValue(
                                      item.name + mainIndex + index,
                                      undefined
                                    );
                                    form.setFieldsValue({
                                      signatureMainIndex: undefined,
                                      signatureChildIndex: undefined,
                                      signatureData: undefined,
                                    });
                                    // mergeData(
                                    //   "answer",
                                    //   "",
                                    //   mainIndex,
                                    //   mainItem?.questions[index + 1].type ===
                                    //     "date"
                                    //     ? index
                                    //     : index + 1
                                    // );
                                  }}
                                >
                                  Clear
                                </span>
                              </Form.Item>
                            </Col>
                          )}
                          {item.type === "text" &&
                            item.name.includes("Signature") &&
                            item.answer && (
                              <Col
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                                style={{ paddingBottom: 10 }}
                              >
                                <img
                                  src={item.answer}
                                  alt="client signature"
                                  height={50}
                                  width={200}
                                  onError={async (e) => {
                                    const newImg =
                                      await IntakeFormApi.getSignatureImg(
                                        item.answer
                                      );
                                    e.target.src = newImg?.data?.url;
                                    form.setFieldValue(
                                      mainItem?.questions[index - 1].name +
                                        [index - 1],
                                      newImg?.data?.url
                                    );
                                  }}
                                />
                              </Col>
                            )}
                        </React.Fragment>
                      );
                    })}
                </Row>
              </React.Fragment>
            );
          })}
        {payload?.length > 0 && (
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              shape="round"
              className={styles.submitBtn}
            >
              <p className={styles.submit}>Submit</p>
            </Button>
          </Form.Item>
        )}
      </Form>
    </Card>
  );
};

export default IntakeForm;
