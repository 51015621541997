import React, { memo, useEffect } from "react";
import { Form, Input, Row, Col, Button, Spin, message } from "antd";
const { TextArea } = Input;
const AddEditChapterModal = memo(
  ({ visibleAddChapterModal, onSubmitAddChapterModal, data, modalLoading }) => {
    const [form] = Form.useForm();

    useEffect(() => {
      form.resetFields();
    }, [form, data]);

    const onSubmit = (value) => {
      if (value.errorFields) {
        message.error("Please fill up required fields");
      } else {
        form.resetFields();

        if (value.order) value["order"] = parseInt(value.order);
        else delete value.order;

        onSubmitAddChapterModal(value);
      }
    };

    return (
      <>
        {modalLoading ? (
          <Spin
            size="large"
            style={{ display: "flex", justifyContent: "center" }}
          />
        ) : (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{
              title: data?.title ?? "",
              description: data?.description ?? "",
              order: data?.order ?? null,
            }}
            onFinish={onSubmit}
            onFinishFailed={onSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="TITLE"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input Tag name!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="DESCRIPTION" name="description">
              <TextArea rows={4} placeholder="Write Chapter Description..." />
            </Form.Item>

            <Form.Item label="ORDER" name="order">
              <Input type="number" />
            </Form.Item>

            <Form.Item>
              <Row gutter={10} style={{ justifyContent: "flex-end" }}>
                <Col>
                  <Button
                    type="danger"
                    onClick={() => {
                      form.resetFields();
                      visibleAddChapterModal(false);
                    }}
                    size="large"
                  >
                    Close
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit" size="large">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}
      </>
    );
  }
);

export default AddEditChapterModal;
