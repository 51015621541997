import http from "./utils/NetworkClient";

export default class PermissionApi {
    static async getPermissionGroup(payload) {
        const response = await http.get("/api/admin/permission-group", payload);
        return response;
      }

      static async createPermissionGroup(payload) {
        const response = await http.post("/api/admin/permission-group", payload);
        return response;
      }

      static async getPermissionGroupDetail(id) {
        const response = await http.get("/api/admin/permission-group/"+id);
        return response;
      }

      static async updatePermissionGroup(id,payload) {
        const response = await http.put("/api/admin/permission-group/"+id, payload);
        return response;
      }
      static async addUserFromPermissionGroup(group_id,payload) {
        const response = await http.post("/api/admin/permission-group/"+group_id+'/add-users', payload);
        return response;
      }
      static async deleteUserFromPermissionGroup(group_id,payload) {
        const response = await http.post("/api/admin/permission-group/"+group_id+'/remove-users', payload);
        return response;
      }
}