import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import AddEditSoloDiveOverviewModal from "../../components/modals/AddEditSoloDiveOverviewModal";
import { ActionCreators } from "../../store";
import { SoloDiveOverviewApi } from "../../store/api";

const { Search } = Input;

const SoloDiveOverview = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const { getSoloDiveOverviewAction } = bindActionCreators(
    ActionCreators,
    dispatch
  ); //call action
  const data = useSelector((state) => state.SoloDiveOverviewReducer);
  const [searchedText, setsearchedText] = useState("");

  //modal
  const [visibleAddSoloDiveOverviewModal, setvisibleAddSoloDiveOverviewModal] =
    useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [modalLoading, setmodalLoading] = useState(false);
  const me = useSelector((state) => state.MeReducer?.response);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex((item) => item?.type === "dives");
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  useEffect(() => {
    dispatch(ActionCreators.getSoloDiveOverviewAction(1, 20));
  }, [dispatch]);

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    dispatch(ActionCreators.getSoloDiveOverviewAction(page, 20, searchedText));
  };

  const addSoloDiveOverviewButtonClick = useCallback(() => {
    setvisibleAddSoloDiveOverviewModal(true);
    setUpdateModal(false);
    setEditData({});
  }, []);

  //edit button click
  const editButtonClick = useCallback((param) => {
    setEditData(param);
    setUpdateModal(true);
  }, []);

  // const deletePress = async (id) => {
  //   const res = await SoloDiveOverviewApi.deleteSingleSoloDiveOverview(id);
  //   if (res.status === 200) {
  //     getSoloDiveOverviewAction(currentPageNumber, 20);
  //     message.success(res?.data?.message);
  //   }
  // };

  //Add SoloDiveOverview
  const onSubmitAddSoloDiveOverviewModal = useCallback(
    async (res) => {
      if (!res.errorFields) {
        setmodalLoading(true);
        const values = {
          title: res.title,
          modified_title: res.modified_title ? res.modified_title : "",
          description: res.description ? res.description : "",
        };
        const response = updateModal
          ? await SoloDiveOverviewApi.editSoloDiveOverview(values, editData.id)
          : await SoloDiveOverviewApi.addSoloDiveOverview(values);
        if (response.status === 201 || response.status === 200) {
          getSoloDiveOverviewAction(currentPageNumber, 20);
          setvisibleAddSoloDiveOverviewModal(false);
          setUpdateModal(false);
          message.success(
            `SoloDiveOverview successfully ${updateModal ? "Updated" : "Added"}`
          );
          setmodalLoading(false);
        } else {
          message.error(response?.data?.detail);
          setmodalLoading(false);
        }
      }
    },
    [getSoloDiveOverviewAction, editData, updateModal, currentPageNumber]
  );

  const onSearchSoloDiveOverview = useCallback(
    (q) => {
      // console.log(value)
      let action = ActionCreators.getSoloDiveOverviewAction(1, 20, q);
      dispatch(action);
      setCurrentPageNumber(1);
      setsearchedText(q);
    },
    [dispatch]
  );

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      ellipsis: true,
      textWrap: "word-break",
      width: 130,
    },
    {
      title: "Title",
      dataIndex: "title",
      width: 150,
    },
    {
      title: "Modified Title",
      dataIndex: "modified_title",
      width: 150,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 150,
    },
    {
      title: "Created Time",
      dataIndex: "created_at",
      width: 130,
      render: (text, record) => (
        <Space size="middle">{moment(text).utc().format("MM/DD/YYYY")}</Space>
      ),
    },

    {
      title: "Action",
      dataIndex: "role",
      key: "name",
      align: "center",
      width: 100,
      render: (text, record) => (
        <Space size="middle">
          {showCreateUpdate && (
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={() => editButtonClick(record)}
            >
              Edit
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            SOLO DIVE OVERVIEW
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL SOLO DIVE OVERVIEW : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          {showCreateUpdate && (
            <Button
              icon={<PlusCircleOutlined />}
              size={"large"}
              shape="round"
              type="primary"
              onClick={() => addSoloDiveOverviewButtonClick()}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              ADD SOLO DIVE OVERVIEW
            </Button>
          )}
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-between" }}>
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search here..."
            enterButton
            size="large"
            onSearch={onSearchSoloDiveOverview}
          />
        </Col>
      </Row>
      <Table
        rowKey={(data) => data.id}
        columns={columns}
        dataSource={data.soloDiveOverview}
        scroll={{ x: 1, y: window.innerHeight - 330 }}
        size="middle"
        loading={data.loading}
        pagination={{
          total: data.count,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
      <Modal
        title={
          updateModal ? "UPDATE SOLO DIVE OVERVIEW" : "ADD  SOLO DIVE OVERVIEW"
        }
        open={updateModal ? updateModal : visibleAddSoloDiveOverviewModal}
        footer={null}
        closable={false}
      >
        <AddEditSoloDiveOverviewModal
          setvisibleAddSoloDiveOverviewModal={
            updateModal ? setUpdateModal : setvisibleAddSoloDiveOverviewModal
          }
          onSubmitAddSoloDiveOverviewModal={onSubmitAddSoloDiveOverviewModal}
          data={editData}
          modalLoading={modalLoading}
        />
      </Modal>
    </React.Fragment>
  );
};

export default SoloDiveOverview;
