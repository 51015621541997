import http from "./utils/NetworkClient";

export default class UserActivityApi {
  static async getAllUserCourse(payload) {
    const response = await http.get("/api/admin/user_course", payload);
    return response;
  }
  static async getAllUserChapter(payload) {
    const response = await http.get("/api/admin/user_chapter", payload);
    return response;
  }
  static async getAllRequestedLocations(payload) {
    const response = await http.get("/api/admin/requested-locations", payload);
    return response;
  }
  static async getAllSubmittedForms(payload) {
    const response = await http.get("/api/admin/user_submitted_forms", payload);
    return response;
  }
  static async getAllSubmittedFormsDetails(id) {
    const response = await http.get(`/api/admin/user_submitted_forms/${id}`);
    return response;
  }
}
