import { Col, Input, Row, Select } from "antd";
import React, { memo, useEffect, useState } from "react";
import { TIME_CHOICES } from "../../store/enums";

const { Search } = Input;
const { Option } = Select;

const ContentFilter = memo(({ onSubmit, currentPageNumber }) => {
  const [type, setType] = useState(null);
  const [extended_type, setextended_type] = useState(null);
  const [interval, setinterval] = useState(null);
  const [is_published, setis_published] = useState(null);
  const [is_premium, setis_premium] = useState(null);
  const [is_feature, setis_feature] = useState(null);
  const [query, setQuery] = useState(null);
  const [isFirstTimeLoading, setisFirstTimeLoading] = useState(true);

  useEffect(() => {
    const arr = {
      page: 1,
      limit: 20,
      type: type,
      extended_type: extended_type,
      interval: interval,
      is_published: is_published,
      is_premium: is_premium,
      is_feature: is_feature,
      q: query,
    };
    if (!isFirstTimeLoading) {
      onSubmit(arr);
    } else {
      setisFirstTimeLoading(false);
    }
  }, [
    type,
    extended_type,
    interval,
    is_published,
    is_premium,
    is_feature,
    query,
  ]);

  return (
    <React.Fragment>
      <Row style={{ marginBottom: 10 }} gutter={8}>
        <Col>FILTER: </Col>
        <Col lg={10} md={12} sm={12} xs={24}>
          <Search
            placeholder="Search"
            allowClear
            size="medium"
            onSearch={(value) => setQuery(value)}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }} gutter={16}>
        <Col lg={4} md={4} sm={12} xs={24}>
          <Select
            placeholder="SELECT TYPE"
            optionFilterProp="children"
            onChange={(val) => setType(val)}
            style={{ minWidth: 150 }}
          >
            <Option value={null}>--</Option>
            <Option value="JOURNAL">JOURNAL</Option>
            <Option value="AUDIO">AUDIO</Option>
            <Option value="VIDEO">VIDEO</Option>
            <Option value="ARTICLE">ARTICLE</Option>
          </Select>
        </Col>
        <Col lg={4} md={4} sm={12} xs={24}>
          <Select
            placeholder="EXTENDED TYPE"
            optionFilterProp="children"
            onChange={(val) => setextended_type(val)}
            style={{ minWidth: 150 }}
          >
            <Option value={null}>--</Option>
            <Option value="REGULAR">REGULAR</Option>
            <Option value="DAILY_DIVE">DAILY DIVE</Option>
            <Option value="COURSE">COURSE</Option>
          </Select>
        </Col>
        <Col lg={4} md={4} sm={12} xs={24}>
          <Select
            placeholder="Select Interval"
            optionFilterProp="children"
            onChange={(val) => setinterval(val)}
            style={{ minWidth: 150 }}
          >
            <Option value={null}>--</Option>
            {Object.keys(TIME_CHOICES).map((key) => {
              return (
                <Option key={key} value={key}>
                  {TIME_CHOICES[key]}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col lg={4} md={4} sm={12} xs={24}>
          <Select
            placeholder="IS PUBLISHED"
            optionFilterProp="children"
            onChange={(val) => setis_published(val)}
            style={{ minWidth: 150 }}
          >
            <Option value={null}>--</Option>
            <Option value={true}>TRUE</Option>
            <Option value={false}>FALSE</Option>
          </Select>
        </Col>
        <Col lg={4} md={4} sm={12} xs={24}>
          <Select
            placeholder="IS PREMIUM"
            optionFilterProp="children"
            onChange={(val) => setis_premium(val)}
            style={{ minWidth: 150 }}
          >
            <Option value={null}>--</Option>
            <Option value={true}>TRUE</Option>
            <Option value={false}>FALSE</Option>
          </Select>
        </Col>
        <Col lg={4} md={4} sm={12} xs={24}>
          <Select
            placeholder="IS FEATURE"
            optionFilterProp="children"
            onChange={(val) => setis_feature(val)}
            style={{ minWidth: 150 }}
          >
            <Option value={null}>--</Option>
            <Option value={true}>TRUE</Option>
            <Option value={false}>FALSE</Option>
          </Select>
        </Col>
      </Row>
    </React.Fragment>
  );
});

export default ContentFilter;