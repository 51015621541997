/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class ReportApi {
  static async downloadUserReport(payload) {
    const response = await http.get("/report/user", payload);
    return response;
  }
  static async downloadPremiumContentReport(payload) {
    const response = await http.get("/report/premium-content", payload);
    return response;
  }
  static async downloadUserSubmissionDataPdf(payload) {
    const response = await http.get(
      "/report/user_submitted_form_pdf",
      payload
    );
    return response;
  }

  static async downloadUserSubmissionDataExcel(payload) {
    const response = await http.get(
      "/report/user_submitted_form_excel",
      payload
    );
    return response;
  }
}
