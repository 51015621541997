import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConsentFormApi from "../../store/api/ConsentFormApi";

export default function ConsentForm() {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [patientFormTemplatesList, setpatientFormTemplatesList] = useState([]);
  const [count, setcount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  useEffect(() => {
    fetchPatientFormTemplatesList();
  }, [currentPageNumber]);

  const fetchPatientFormTemplatesList = async () => {
    setloading(true);
    const res = await ConsentFormApi.getPatientFormTemplates(
      currentPageNumber,
      20
    );
    console.log(res);
    if (res.status === 200) {
      setcount(res.data.count);
      setpatientFormTemplatesList(res.data.results);
      setloading(false);
    } else {
      setloading(false);
    }
  };

  const deletePatientFormTemplatesList = async (id) => {
    setloading(true);
    const res = await ConsentFormApi.deletePatientFormTemplate(id);
    console.log(res);
    if (res.status === 200) {
      message.success(res?.data?.message);
      fetchPatientFormTemplatesList();
      setloading(false);
    } else {
      message.error(res?.data?.detail);

      setloading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
  };

  const columns = [
    {
      title: "key Type",
      dataIndex: "key_type",
      ellipsis: true,
      width: 120,
      textWrap: "word-break",
    },
    {
      title: "Form Type",
      dataIndex: "form_type",
      ellipsis: true,
      width: 120,
      textWrap: "word-break",
    },
    {
      title: "Published",
      width: 120,
      align: "center",
      dataIndex: "is_published",
      render: (text) => (
        <>
          {text ? (
            <CheckCircleFilled style={{ color: "#22c55e" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ef4444" }} />
          )}
        </>
      ),
    },
    {
      title: "Cliniko Template Name",
      dataIndex: "cliniko_patient_form_template_name",
      ellipsis: true,
      width: 180,
      textWrap: "word-break",
    },
    {
      title: "Cliniko Template Id",
      dataIndex: "cliniko_patient_form_template_id",
      ellipsis: true,
      width: 180,
      textWrap: "word-break",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      align: "center",
      width: 180,
      render: (text) => (
        <>{moment.utc(text).local().format("YYYY-MM-DD hh:mm A")}</>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      align: "center",
      width: 180,
      render: (text) => (
        <>{moment.utc(text).local().format("YYYY-MM-DD hh:mm A")}</>
      ),
    },
    {
      title: "Action",
      dataIndex: "role",
      align: "center",

      key: "id",
      width: 200,
      render: (text, record) => (
        <Space
          size="middle"
          // style={{ display: "flex", justifyContent: "flex-start" }}
        >
          {/* {showCollectPayment && ( */}
          <Button
            shape="round"
            size="large"
            type="primary"
            onClick={() =>
              navigate("/consent_form/" + record?.id, {
                state: {
                  data: record,
                },
              })
            }
          >
            Edit
          </Button>
          {/* )} */}

          {/* {!record?.cancel && showDelete && ( */}
          <Popconfirm
            title="Are you sure to delete this consent?"
            onConfirm={() => deletePatientFormTemplatesList(record?.id)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" shape="round" size="large">
              Delete
            </Button>
          </Popconfirm>
          {/* )} */}
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Row gutter={16}>
        <Col>
          <Typography.Title
            level={2}
            style={{ textAlign: "left", marginBottom: 0 }}
          >
            Consent Forms
          </Typography.Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL FORMS : <strong>{count}</strong>
          </p>
        </Col>
        <Col span={24}>
          <Button
            icon={<PlusCircleOutlined />}
            size={"large"}
            shape="round"
            type="primary"
            onClick={() => {
              // onClickCreateApplicationType();
              navigate("/consent_form/create");
            }}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 20,
              marginTop: 10,
            }}
          >
            Add Consent Form
          </Button>
        </Col>
        <Col span={24}>
          <Table
            rowKey={(data) => data.id}
            columns={columns}
            dataSource={patientFormTemplatesList}
            size="middle"
            scroll={{ x: 1, y: window.innerHeight - 280 }}
            loading={loading}
            pagination={{
              total: count,
              pageSize: 20,
              showSizeChanger: false,
              onChange: (page) => {
                handlePageChange(page);
              },
            }}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
}
