import { REHYDRATE } from "redux-persist/lib/constants";
import { take, takeLatest } from "redux-saga/effects";
import { constant } from "../Constant";
import { getAppointmentAction } from "./AppointmentSagaAction";
import { getAppointmentTypesAction } from "./AppointmentTypesSagaAction";
import {
  getBenefitDetailsAction,
  getBenefitsAction
} from "./BenefitSagaAction";
import {
  getAllCategoryAction,
  getCategoryDetailsAction
} from "./CategorySagaAction";
import {
  getContentDetailsAction,
  getContentsAction
} from "./ContentSagaAction";
import { getCourseAction } from "./CourseSagaAction";
import {
  getDailyDiveDetailsAction,
  getDailyDivesAction
} from "./DailyDiveSagaAction";
import { getAllFavouriteAction } from "./FavouriteSagaAction";
import {
  getFeelingDetailsAction,
  getFeelingsAction
} from "./FeelingSagaAction";
import {
  getInstructorDetailsAction,
  getInstructorsAction
} from "./InstructorSagaAction";
import {
  getIntervalDetailsAction,
  getIntervalsAction
} from "./IntervalSagaAction";
import { getMeSagaAction } from "./MeSagaAction";
import { getMoodDetailsAction, getMoodsAction } from "./MoodSagaAction";
import { getAllNotificationAction } from "./NotificationSagaAction";
import { getAllOverviewAction } from "./OverviewSagaAction";
import { getPermissionGroupAction } from "./PermissionSagaAction";
import {
  getAllIdentifier, getAllLocations, getAllOptions, getIAllFormGroups,
  getIAllForms,
  getIAllInstructorInfo
} from "./QuestionariesSagaAction";
import {
  getReminderDetailsAction,
  getRemindersAction
} from "./ReminderSagaAction";
import {
  getScalingDetailsAction,
  getScalingsAction
} from "./ScalingSagaAction";
import { getSoloDiveOverviewAction } from "./SoloDiveOverviewSagaAction";
import { getSoloDivesAction } from "./SoloDivesSagaAction";
import { getAllSubscriberAction } from "./SubscribersSagaAction";
import { getTagDetailsAction, getTagsAction } from "./TagSagaAction";
import { userLoginAction, userLogOutAction } from "./UserAuthSagaAction";
import { getAllUserContentAction } from "./UserContentSagaAction";
import { getAllUserCourseContentAction } from "./UserCourseContentSagaAction";
import { getAllUserCourseReviewAction } from "./UserCourseReviewSagaAction";
import { getAllUserAction } from "./UsersSagaAction";

// watchers
function* SagaActions() {
  yield take(REHYDRATE);
  yield takeLatest(constant.USER_LOGIN, userLoginAction);
  yield takeLatest(constant.LOGOUT, userLogOutAction);
  yield takeLatest(constant.GET_USERS, getAllUserAction);
  yield takeLatest(constant.GET_SUBSCRIBERS, getAllSubscriberAction);

  // user content
  yield takeLatest(constant.GET_USER_CONTENT, getAllUserContentAction);
  yield takeLatest(constant.GET_NOTIFICATION, getAllNotificationAction);
  yield takeLatest(constant.GET_FAVOURITE, getAllFavouriteAction);

  // instructor
  yield takeLatest(constant.GET_INSTRUCTORS, getInstructorsAction);
  yield takeLatest(constant.GET_INSTRUCTOR_DETAILS, getInstructorDetailsAction);

  // user course content
  yield takeLatest(
    constant.GET_USER_COURSE_CONTENT,
    getAllUserCourseContentAction
  );

  // mood
  yield takeLatest(constant.GET_CONTENTS, getContentsAction);
  yield takeLatest(constant.GET_CONTENT_DETAILS, getContentDetailsAction);

  // feeling
  yield takeLatest(constant.GET_FEELINGS, getFeelingsAction);
  yield takeLatest(constant.GET_FEELING_DETAILS, getFeelingDetailsAction);

  // tag
  yield takeLatest(constant.GET_TAGS, getTagsAction);
  yield takeLatest(constant.GET_TAG_DETAILS, getTagDetailsAction);

  // overview
  yield takeLatest(constant.GET_OVERVIEW, getAllOverviewAction);

  // permission
  yield takeLatest(constant.GET_PERMISSION_GROUP, getPermissionGroupAction);

  // solo dives
  yield takeLatest(constant.GET_SOLO_DIVES, getSoloDivesAction);

  // user course review
  yield takeLatest(
    constant.GET_USER_COURSE_REVIEW,
    getAllUserCourseReviewAction
  );

  // category
  yield takeLatest(constant.GET_CATEGORIES, getAllCategoryAction);
  yield takeLatest(constant.GET_CATEGORY_DETAILS, getCategoryDetailsAction);

  // interval
  yield takeLatest(constant.GET_INTERVALS, getIntervalsAction);
  yield takeLatest(constant.GET_INTERVAL_DETAILS, getIntervalDetailsAction);

  // benefit
  yield takeLatest(constant.GET_BENEFITS, getBenefitsAction);
  yield takeLatest(constant.GET_BENEFIT_DETAILS, getBenefitDetailsAction);
  // // tag
  // yield takeLatest(constant.GET_TAGS, getTagsAction);
  // yield takeLatest(constant.GET_TAG_DETAILS, getTagDetailsAction);

  // scaling
  yield takeLatest(constant.GET_SCALINGS, getScalingsAction);
  yield takeLatest(constant.GET_SCALING_DETAILS, getScalingDetailsAction);

  yield takeLatest(constant.GET_SOLO_DIVE_OVERVIEW, getSoloDiveOverviewAction);

  // mood
  yield takeLatest(constant.GET_MOODS, getMoodsAction);
  yield takeLatest(constant.GET_MOOD_DETAILS, getMoodDetailsAction);

  // daily dive
  yield takeLatest(constant.GET_DAILYDIVES, getDailyDivesAction);
  yield takeLatest(constant.GET_DAILYDIVE_DETAILS, getDailyDiveDetailsAction);

  // yield takeLatest(constant.GET_DAILYDIVES, getDailyDivesAction);

  // reminder
  yield takeLatest(constant.GET_REMINDERS, getRemindersAction);
  yield takeLatest(constant.GET_REMINDER_DETAILS, getReminderDetailsAction);

  //course
  yield takeLatest(constant.GET_COURSE, getCourseAction);

   //booking management
   yield takeLatest(constant.GET_APPOINTMENT, getAppointmentAction);
   yield takeLatest(constant.GET_APPOINTMENT_TYPES, getAppointmentTypesAction);

  //me
  yield takeLatest(constant.GET_ME, getMeSagaAction);

  //questionaries
  yield takeLatest(constant.GET_IDENTIFIERS, getAllIdentifier);
  yield takeLatest(constant.GET_OPTIONS, getAllOptions);
  yield takeLatest(constant.GET_LOCATION, getAllLocations);
  yield takeLatest(constant.GET_FORM_GROUP, getIAllFormGroups);
  yield takeLatest(constant.GET_FORM, getIAllForms);
  yield takeLatest(constant.GET_INSTRUCTOR_INFO, getIAllInstructorInfo);
}

export default SagaActions;
