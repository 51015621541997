import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  message,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FileUpload from "../../components/utils/FileUpload";
import ConsentFormApi from "../../store/api/ConsentFormApi";
import NetworkClient from "../../store/api/utils/NetworkClient";

export default function AddOrEditConsent() {
  const { id } = useParams();
  const { state } = useLocation();
  const [form] = Form.useForm();
  const [defaultPlatformTemplate, setdefaultPlatformTemplate] = useState();
  const [loading, setloading] = useState(false);
  const [isPublished, setisPublished] = useState(
    state?.data?.is_published ? state?.data?.is_published : false
  );
  const [
    patientFormManagementTemplatesData,
    setpatientFormManagementTemplatesData,
  ] = useState();

  useEffect(() => {
    if (id !== "create" && state?.data?.id) {
      console.log(state);
    }
    fetchPatientFormTemplates();
  }, [id]);

  const fetchPatientFormTemplates = async () => {
    setloading(true);
    const res = await ConsentFormApi.getPatientFormManagementTemplates();
    if (res.status === 200) {
      setpatientFormManagementTemplatesData(res.data.result);
      let temp = res.data.result?.find(
        (data) => data?.id === state?.data?.cliniko_patient_form_template_id
      );
      form.setFieldValue("patient_form_template", temp?.id);
      setdefaultPlatformTemplate(temp);
      setloading(false);
    } else {
      setloading(false);
    }
  };

  const onPressSubmit = async () => {
    if (
      form.getFieldsError().filter((item) => item?.errors?.length > 0).length >
      0
    ) {
      // alert('cannot submit')
      message.error("Please fill up the form");
    } else {
      let { patient_form_template, key_type, form_type, file_path } =
        form.getFieldValue();
      let index = patientFormManagementTemplatesData?.findIndex(
        (d) => d?.id === patient_form_template
      );

      let payload = {
        form_type: form_type,
        is_published: isPublished,
        file_path: file_path,
        key_type: key_type,
        cliniko_patient_form_template_name:
          index > -1 ? patientFormManagementTemplatesData?.[index]?.name : null,
        cliniko_patient_form_template_id: patient_form_template,
      };
      setloading(true);
      let res;
      if (id === "create") {
        res = await ConsentFormApi.createPatientFormTemplate(payload);
      } else {
        res = await ConsentFormApi.updatePatientFormTemplate(id, payload);
      }

      if (res.status === 201 || res?.status === 200) {
        message.success(
          id === "creat" ? "Successfully created." : "Successfully updated."
        );
        if (id === "creat") {
          form.resetFields();
        } else {
          window.history.back();
        }
        setloading(false);
      } else {
        message.error(res?.data?.detail);
        setloading(false);
      }
    }
  };
  const [fileLoading, setfileLoading] = useState(false);
  const fileUpload = async (e) => {
    setfileLoading(true);
    // console.log(e.file);
    try {
      const formData = new FormData();
      formData.append("file", e.file);
      formData.append("path", 'form');
      const res = await NetworkClient.filePost(
        "/api/admin/upload-file",
        formData
      );
      const { data } = res;
      console.log("uploaded file link", data);
      form.setFieldValue("file_path", data);
      setfileLoading(false);
      return data;
    } catch (e) {
      console.log(e);
      setfileLoading(false);
      return false;
    }
  };

  return (
    <Row style={{ justifyContent: "center", placeContent: "center" }}>
      <Col span={10}>
        {loading ? (
          <Spin size="large" />
        ) : (
          <Card>
            <Typography.Title level={3}>
              {id === "create" ? "Add" : "Update"} Form
            </Typography.Title>
            <Divider />
            <Form
              layout="vertical"
              form={form}
              initialValues={{
                // patient_form_template: defaultPlatformTemplate?.id ?? null,
                key_type: state?.data?.key_type ?? null,
                form_type: state?.data?.form_type ?? null,
                file_path: state?.data?.file_path ?? null,
              }}
              onFinish={() => onPressSubmit()}
              onFinishFailed={() => onPressSubmit()}
            >
              <Form.Item
                name="patient_form_template"
                label="Patient Form Template"
                rules={[
                  {
                    required: true,
                    message: "Please select an option!",
                  },
                ]}
              >
                <Select
                  style={{ display: "flex" }}
                  placeholder="Select patient form template"
                  defaultValue={defaultPlatformTemplate?.id}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // onChange={(data) => setselectedPatientFormTemplate(data)}
                >
                  {patientFormManagementTemplatesData?.map((item, key) => (
                    <Select.Option key={key} value={item?.id}>
                      {item?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={"form_type"}
                label="Form Type"
                rules={[
                  {
                    required: true,
                    message: "Please select an option!",
                  },
                ]}
              >
                <Select
                  placeholder="Select form type"
                  // onChange={(data) => setformType(data)}
                >
                  <Select.Option value={"INTAKE"}>Intake Form</Select.Option>
                  <Select.Option value={"CONSENT"}>Consent Form</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name={"key_type"}
                label="Key Type"
                rules={[
                  {
                    required: true,
                    message: "Please select an option!",
                  },
                ]}
              >
                <Select
                  placeholder="Select key type"
                  // onChange={(data) => setkeyType(data)}
                >
                  <Select.Option value={"ADULT"}>Adult</Select.Option>
                  <Select.Option value={"CHILD"}>Child</Select.Option>
                  <Select.Option value={"YOUTH"}>Youth</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name={"file_path"}>
                <FileUpload
                  label="Upload Form"
                  name="file_path"
                  upload={fileUpload}
                  accept=".pdf"
                  modalLoading={fileLoading}
                />
              </Form.Item>

              <p>File Name: - {state?.data?.file_path?.split("/").pop()}</p>
              {/* <p>File Name 2: - {form.getFieldValue("file_path")}</p> */}
              <Form.Item name={"is_published"} style={{ textAlign: "left" }}>
                <Checkbox
                  checked={isPublished}
                  onChange={() => setisPublished(!isPublished)}
                >
                  Is Published?
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  size="large"
                  shape="round"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        )}
      </Col>
    </Row>
  );
}
