/* eslint-disable require-jsdoc */
import http from "./utils/NetworkClient";

export default class UserApi {
  static async getAllUser(payload) {
    const response = await http.get("/api/admin/user", payload);
    return response;
  }

  static async getUserDetail(id) {
    const response = await http.get(`/api/admin/user/${id}`);
    return response;
  }

  static async deleteSingleUser(id, isDelete) {
    const response = await http.del(`/api/admin/user/${id}?delete=${isDelete}`);
    return response;
  }

  static async editUser(payload, id) {
    const response = await http.put(`/api/admin/user/${id}`, payload);
    return response;
  }

  static async addUser(payload) {
    const response = await http.post("/api/admin/user", payload);
    return response;
  }

  static async AddUserCard(payload) {
    const response = await http.post(`/api/admin/user-card-info`, payload);
    return response;
  }
  static async getUserCardInfo(payload) {
    const response = await http.get(`/api/admin/user-card-infos`, payload);
    return response;
  }
  static async deleteUserCard(cardId, userId) {
    const response = await http.put(
      `/api/admin/user-card-info/${cardId}?user_id=${userId}`
    );
    return response;
  }
  static async getUserFamilyMembers(payload) {
    const response = await http.get(`/api/admin/user-relatives`, payload);
    return response;
  }
  static async getUserAssignedInstructors(payload) {
    const response = await http.get(
      `/api/admin/user-instructor-connections`,
      payload
    );
    return response;
  }
  static async getUserConsentForms(payload) {
    const response = await http.get(`/api/admin/user_consent_forms`, payload);
    return response;
  }
  static async getUserPurchasedCourses(payload) {
    const response = await http.get(
      `/api/admin/user/${payload?.user_id}/purchased-courses`,
      payload
    );
    return response;
  }
  static async getUserProfile(id) {
    const response = await http.get(`/api/admin/user-profile/${id}`);
    return response;
  }
  static async updateUserProfile(id, payload) {
    const response = await http.put(`/api/admin/user-profile/${id}`, payload);
    return response;
  }

  static async updateUserAssignedInstructor(id, payload) {
    const response = await http.put(
      `/api/admin/user-instructor-connectivity/${id}`,
      payload
    );
    return response;
  }
}
