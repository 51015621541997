import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "../../store";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography,
  // Popconfirm,
  message,
} from "antd";
import { QuestionariesApi } from "../../store/api";
import { useLocation } from "react-router-dom";
import AddEditOptions from "../../components/modals/AddEditOptions";

const { Title } = Typography;
const { Search } = Input;

const Options = () => {
  const { state } = useLocation();
  const { params } = state; // Read values passed on state
  const dispatch = useDispatch();
  const data = useSelector((state) => state.QuestionariesReducer);
  const [loader, setLoader] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [addOptionModal, setAddOptionModal] = useState(false);
  const [updateOptionModal, setUpdateOptionModal] = useState(false);
  const [editData, setEditData] = useState(false);
  const [modalLoading, setmodalLoading] = useState(false);

  useEffect(() => {
    if (data.loading) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [data]);

  useEffect(() => {
    dispatch(ActionCreators.getOptions(1, 20, null, params.id));
  }, [dispatch, params]);

  const addButtonClick = useCallback(() => {
    setUpdateOptionModal(false);
    setAddOptionModal(true);
    setEditData({});
  }, []);

  //edit button click
  const editButtonClick = useCallback((param) => {
    setEditData(param);
    setAddOptionModal(false);
    setUpdateOptionModal(true);
  }, []);

  // const deletePress = async (id) => {
  //   setLoader(true);
  //   try {
  //     const res = await QuestionariesApi.deleteSingleOption(id);
  //     if (res.status === 200) {
  //       dispatch(
  //         ActionCreators.getOptions(currentPageNumber, 20, null, params.id)
  //       );
  //     }
  //     setLoader(false);
  //   } catch (error) {
  //     setLoader(false);
  //   }
  // };

  const setsearchedText = (text) => {
    setCurrentPageNumber(1);
    dispatch(ActionCreators.getOptions(1, 20, text, params.id));
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    dispatch(ActionCreators.getOptions(page, 20, null, params.id));
  };

  const onSubmitModal = async (res) => {
    setmodalLoading(true);
    if (!res.errorFields) {
      res["form_identifier"] = params.id;
      if (
        res?.amount ||
        res?.desdescription ||
        res?.label ||
        res?.ignore_matching
      ) {
        res["additional_data"] = {
          amount: res?.amount ?? null,
          description: res?.description ?? null,
          label: res?.label ?? null,
          ignore_matching: res?.ignore_matching ?? false,
        };
      }
      delete res?.amount;
      delete res?.description;
      delete res?.label;
      delete res?.ignore_matching;
      const response = updateOptionModal
        ? await QuestionariesApi.updateOption(res, editData.id)
        : await QuestionariesApi.addOption(res);
      if (response.status === 200) {
        dispatch(
          ActionCreators.getOptions(currentPageNumber, 20, null, params.id)
        );
      } else {
        message.error("Failed to add/update Identifier data!");
      }
      setmodalLoading(false);
      setUpdateOptionModal(false);
      setAddOptionModal(false);
    } else {
      setmodalLoading(false);
      setUpdateOptionModal(false);
      setAddOptionModal(false);
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: `id`,
      ellipsis: true,
      textWrap: "word-break",
      // width: 100,
    },
    {
      title: "Name",
      dataIndex: "name",
      dataInkeydex: "name",
      // width: 130,
    },

    {
      title: "DESCRIPTION",
      dataIndex: "additional_data",
      align: "center",
      // width: 100,
      render: (text, record) => (
        <Space size="middle">{text?.description ?? "Not Available"}</Space>
      ),
    },
    {
      title: "AMOUNT",
      dataIndex: "additional_data",
      align: "center",
      // width: 100,
      render: (text, record) => (
        <Space size="middle">{text?.amount ?? "Not Available"}</Space>
      ),
    },

    {
      title: "ACTION",
      dataIndex: "role",
      key: "name",
      // width: 200,
      align: "center",
      render: (text, record) => (
        <Space size="small">
          <Button
            type="primary"
            shape="round"
            onClick={() => editButtonClick(record)}
          >
            EDIT
          </Button>
          {/* <Popconfirm
            title="Are you sure to delete this Identifier?"
            onConfirm={() => deletePress(record.id)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Button shape="round" size="large" type="danger">
              Delete
            </Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 10 }}>
            Identifiers Details
          </Title>
          <p style={{ textAlign: "left" }}>ID: {params.id}</p>
          <p style={{ textAlign: "left" }}>NAME: {params.name}</p>
          <p style={{ textAlign: "left" }}>
            MATCHING MOOD: {params.matching_mood}
          </p>
          <p style={{ textAlign: "left" }}>
            IS PREFERENCE:{" "}
            {params.is_preference ? (
              <CheckCircleFilled
                style={{ color: "#22c55e", display: "inline-flex" }}
              />
            ) : (
              <CloseCircleFilled
                style={{ color: "#ef4444", display: "inline-flex" }}
              />
            )}
          </p>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL OPTIONS : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: 10, marginBottom: 10 }}>
        <Col>
          <Button
            icon={<PlusCircleOutlined />}
            size={"large"}
            shape="round"
            type="primary"
            onClick={() => addButtonClick()}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            ADD OPTIONS
          </Button>
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-between" }}>
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search here..."
            enterButton
            size="large"
            onSearch={(text) => setsearchedText(text)}
          />
        </Col>
      </Row>
      <Table
        style={{ marginTop: 10 }}
        columns={columns}
        dataSource={data.allOptions}
        scroll={{ x: 1, y: window.innerHeight - 350 }}
        size="middle"
        loading={loader}
        rowKey={(data) => data.id}
        pagination={{
          total: data.count,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
      <Modal
        title={updateOptionModal ? "UPDATE OPTION" : "ADD OPTION"}
        open={updateOptionModal ? updateOptionModal : addOptionModal}
        footer={null}
        closable={false}
      >
        <AddEditOptions
          setVisibleModal={
            updateOptionModal ? setUpdateOptionModal : setAddOptionModal
          }
          onSubmitModal={onSubmitModal}
          data={editData}
          modalLoading={modalLoading}
        />
      </Modal>
    </React.Fragment>
  );
};

export default Options;
