import React, { useState, useEffect } from "react";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Col, Input, Row, Space, Table, Typography } from "antd";
import { UserActivityApi } from "../../store/api";

const { Title } = Typography;
const { Search } = Input;

const RequestedLocation = () => {
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (page = 1, q = null) => {
    setLoader(true);
    const values = { page: page, limit: 20, q: q };
    const response = await UserActivityApi.getAllRequestedLocations(values);
    if (response.status === 200) {
      setData(response.data);
    } else {
      setData(null);
    }
    setLoader(false);
  };

  const setsearchedText = (text) => {
    setCurrentPageNumber(1);
    getData(1, text);
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    getData(page, null);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: `id`,
      ellipsis: true,
      textWrap: "word-break",
      //   width: 100,
    },
    {
      title: "NAME",
      dataIndex: "name",
      align: "center",
      render: (text, record) => <Space size="middle">{text}</Space>,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      align: "center",
      render: (text, record) => <Space size="middle">{text}</Space>,
    },
    {
      title: "LOCATION",
      dataIndex: "location",
      align: "center",
      render: (text, record) => <Space size="middle">{text}</Space>,
    },
    {
      title: "IS NOTIFY",
      dataIndex: "is_notify",
      align: "center",
      render: (text, record) =>
        text ? (
          <CheckCircleFilled style={{ color: "#22c55e" }} />
        ) : (
          <CloseCircleFilled style={{ color: "#ef4444" }} />
        ),
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 0 }}>
            Requested Locations
          </Title>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL REQUESTED LOCATIONS : <strong>{data?.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-between" }}>
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search by location"
            enterButton
            size="large"
            onSearch={(text) => setsearchedText(text)}
          />
        </Col>
      </Row>
      <Table
        style={{ marginTop: 10 }}
        columns={columns}
        dataSource={data?.results}
        scroll={{ x: 1, y: window.innerHeight - 180 }}
        size="middle"
        loading={loader}
        rowKey={(data) => data?.id}
        pagination={{
          total: data?.count,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
    </React.Fragment>
  );
};

export default RequestedLocation;
