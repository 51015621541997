import { constant } from "../Constant";

export const loginAction = (email, password) => {
  return {
    type: constant.USER_LOGIN,
    email: email,
    password: password,
  };
};

export const logOutAction = () => {
  return {
    type: constant.LOGOUT,
  };
};

// user
export const getUsersAction = (page, limit, membership, q, is_active, role,sort) => {
  return {
    type: constant.GET_USERS,
    page: page,
    limit: limit,
    membership: membership,
    q: q,
    is_active: is_active,
    role: role,
    sort:sort
  };
};

// subscriber
export const getSubscribersAction = (page, limit, subscription_type, q) => {
  return {
    type: constant.GET_SUBSCRIBERS,
    page: page,
    limit: limit,
    subscription_type: subscription_type,
    q: q,
  };
};

// instructor
export const getInstructorsAction = (page, limit, q, is_active) => {
  return {
    type: constant.GET_INSTRUCTORS,
    page: page,
    limit: limit,
    q: q,
    is_active: is_active,
  };
};
export const getInstructorDetailsAction = (id) => {
  return {
    type: constant.GET_INSTRUCTOR_DETAILS,
    id: id,
  };
};

