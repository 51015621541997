import { put } from "redux-saga/effects";
import { constant } from "../Constant";
import { UserCourseReviewApi } from "../api";
// workers
function* getAllUserCourseReviewAction(param) {
  try {
    const res = yield UserCourseReviewApi.getAllUserCourseReview({
      page: param.page,
      limit: param.limit,
      q: param.q
    });

    if (res.status === 200) {
      const payload = {
        type: constant.GET_USER_COURSE_REVIEW_SUCCESS,
        allUserCourseReview: res.data.results,
        count:res.data.count
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_USER_COURSE_REVIEW_FAILED,
        errMsg: "Invalid UserCourseReview",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_USER_COURSE_REVIEW_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}

export { getAllUserCourseReviewAction };
