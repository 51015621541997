import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ActionCreators } from "../../store";
import { QuestionariesApi } from "../../store/api";

const { Title } = Typography;
const { Search } = Input;

const Form = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { params } = state; // Read values passed on state
  const data = useSelector((state) => state.QuestionariesReducer);
  const [loader, setLoader] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const me = useSelector((state) => state.MeReducer?.response);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex(
        (item) => item?.type === "form-groups"
      );
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  useEffect(() => {
    dispatch(ActionCreators.getFroms(1, 20, params.id));
    dispatch(ActionCreators.getIdentifier(1, 100));
  }, [dispatch, params]);

  // useEffect(() => {
  //   const arr =
  //     data?.allForms?.length > 0 &&
  //     data?.allForms.map((res) => ({
  //       id: res?.id,
  //       name: res?.name,
  //     }));
  //   setFormNext(arr);
  // }, [data?.allForms]);

  const deletePress = async (id) => {
    setLoader(true);
    try {
      const res = await QuestionariesApi.deleteForm(id);
      if (res.status === 200) {
        dispatch(ActionCreators.getFroms(currentPageNumber, 20, params.id));
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const setsearchedText = (text) => {
    setCurrentPageNumber(1);
    dispatch(ActionCreators.getFroms(1, 20, params.id, text));
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    dispatch(ActionCreators.getFroms(page, 20, params.id));
  };

  const addEditButtonClick = async (param) => {
    if (param) {
      setLoader(true);
      const res = await QuestionariesApi.getFormDetails(param.id);
      if (res.status === 200) {
        const values = {
          page: 1,
          limit: 100,
          form_identifier: res.data.form_identifier,
        };
        const response = await QuestionariesApi.getIAllOptions(values);
        if (res.status === 200) {
          const newOptions = [];
          const formDetails = res.data;
          if (formDetails?.question_type === "MULTI_SELECT") {
            formDetails?.form_options.filter(function (entry) {
              return newOptions.push(entry.form_option.id);
            });
          }
          const allOptionsData = response.data.results;
          navigate(`/add-edit-form`, {
            state: {
              formDetails: formDetails,
              allOptionsData: allOptionsData,
              fromGroupId: params.id,
              multiSelectDeafultOptions: newOptions,
              // formNext: formNext,
            },
          });
        } else {
          message.error("Failed to get option data!");
        }
      } else {
        message.error("Failed to get form details data!");
      }
      setLoader(false);
    } else {
      //go to add page
      navigate(`/add-edit-form`, {
        state: {
          formDetails: null,
          allOptionsData: null,
          fromGroupId: params.id,
          // formNext: formNext,
        },
      });
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: `id`,
      ellipsis: true,
      textWrap: "word-break",
      width: 120,
    },
    // {
    //   title: "FORM IDENTIFIER",
    //   dataIndex: "form_identifier",
    //   key: `form_identifier`,
    //   ellipsis: true,
    //   textWrap: "word-break",
    //   width: 100,
    // },
    {
      title: "NAME",
      dataIndex: "name",
      align: "center",
      width: 200,
      render: (text, record) => <Space size="middle">{text}</Space>,
    },

    {
      title: "DESIGN TYPE",
      dataIndex: "design_type",
      align: "center",
      width: 100,
      render: (text, record) => <Space size="middle">{text}</Space>,
    },
    {
      title: "QUESTION TYPE",
      dataIndex: "question_type",
      align: "center",
      width: 100,
      render: (text, record) => <Space size="middle">{text}</Space>,
    },
    {
      title: "PERCENTAGE",
      dataIndex: "percentage",
      align: "center",
      width: 100,
      render: (text, record) => <Space size="middle">{text}</Space>,
    },
    {
      title: "ORDER",
      dataIndex: "order",
      align: "center",
      width: 100,
      render: (text, record) => <Space size="middle">{text}</Space>,
    },
    {
      title: "ACTION",
      dataIndex: "role",
      key: "name",
      width: 200,
      align: "center",
      render: (text, record) => (
        <Space size="small">
          {showCreateUpdate && (
            <Button
              type="primary"
              shape="round"
              onClick={() => addEditButtonClick(record)}
            >
              EDIT
            </Button>
          )}
          {showDelete && (
            <Popconfirm
              title="Are you sure to delete this from?"
              onConfirm={() => deletePress(record.id)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button shape="round" size="large" type="danger">
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      {data.errMsg ? <p>{data.errMsg}</p> : null}
      <Row>
        <Col>
          <Title level={2} style={{ textAlign: "left", marginBottom: 10 }}>
            Form
          </Title>
          <p style={{ textAlign: "left" }}>FROM GROUP ID: {params.id}</p>
          <p style={{ textAlign: "left" }}>NAME: {params.name}</p>
          <p style={{ textAlign: "left" }}>
            PLATFORM TYPE: {params.platform_type}
          </p>
          <p style={{ textAlign: "left" }}>
            IS PUBLISHED:{" "}
            {params.is_published ? (
              <CheckCircleFilled
                style={{ color: "#22c55e", display: "inline-flex" }}
              />
            ) : (
              <CloseCircleFilled
                style={{ color: "#ef4444", display: "inline-flex" }}
              />
            )}
          </p>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            TOTAL FORM : <strong>{data.count}</strong>
          </p>
        </Col>
      </Row>
      <Row style={{ marginBottom: 10, marginTop: 5 }}>
        <Col>
          {showCreateUpdate && (
            <Button
              icon={<PlusCircleOutlined />}
              size={"large"}
              shape="round"
              type="primary"
              onClick={() => addEditButtonClick()}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              ADD FORM
            </Button>
          )}
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-between" }}>
        <Col
          lg={8}
          sm={24}
          xs={24}
          style={{ marginBottom: 5, display: "flex" }}
        >
          <Search
            placeholder="Search here..."
            enterButton
            size="large"
            onSearch={(text) => setsearchedText(text)}
          />
        </Col>
      </Row>
      <Table
        style={{ marginTop: 10 }}
        columns={columns}
        dataSource={data.allForms}
        scroll={{ x: 1, y: window.innerHeight - 350 }}
        size="middle"
        loading={loader}
        rowKey={(data) => data.id}
        pagination={{
          total: data.count,
          pageSize: 20,
          current: currentPageNumber,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
          },
        }}
      />
    </React.Fragment>
  );
};

export default Form;
