import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Switch,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SettingApi } from "../../store/api";
import "./style.css";

export default function Settings() {
  const [data, setdata] = useState({});
  const [loading, setloading] = useState(true);
  const [newVersionWarning, setnewVersionWarning] = useState(false);
  const [isForceUpdate, setisForceUpdate] = useState(false);
  const [form] = Form.useForm();
  const me = useSelector((state) => state.MeReducer?.response);
  const [showCreateUpdate, setshowCreateUpdate] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  useEffect(() => {
    if (me?.role === "OWNER" || me?.role === "ADMIN") {
      setshowCreateUpdate(true);
      setshowDelete(true);
    } else {
      let index = me?.permissions?.findIndex(
        (item) => item?.type === "site_configuration"
      );
      // console.log('usama',me?.permissions);
      if (index > -1) {
        if (me?.permissions?.[index]?.value?.includes("C")) {
          // console.log('usama Can Create or edit');
          setshowCreateUpdate(true);
        }
        if (me?.permissions?.[index]?.value?.includes("D")) {
          // console.log('usama Can Delete');
          setshowDelete(true);
        }
      }
    }
  }, [me]);

  const getSettingData = useCallback(async () => {
    const payload = {
      page: 1,
      limit: 20,
    };
    const response = await SettingApi.getAllSetting(payload);
    if (response.status === 201 || response.status === 200) {
      if (Object.keys(response.data).length > 0) {
        setdata(response.data);
        setnewVersionWarning(response.data.new_version_warning);
        setisForceUpdate(response.data.is_force_upgrade);
      } else {
        console.log("NO DATA FOUND");
      }

      setloading(false);
    } else {
      setloading(false);
    }
  }, []);

  useEffect(() => {
    getSettingData();
    return () => {
      setdata({});
    };
  }, [getSettingData]);

  const onSubmit = useCallback(
    async (value) => {
      try {
        setloading(true);
        let values = {
          site: value.site,
          apple_new_version: value.apple_new_version ?? "",
          android_new_version: value.android_new_version ?? "",
          new_version_warning: newVersionWarning,
          apple_force_upgrade_version: value.apple_force_upgrade_version ?? "",
          android_force_upgrade_version:
            value.android_force_upgrade_version ?? "",
          is_force_upgrade: isForceUpdate,
          new_version_message: value.new_version_message ?? "",
          force_upgrade_message: value.force_upgrade_message ?? "",
        };
        const response = await SettingApi.editSetting(values);
        if (response.status === 201 || response.status === 200) {
          message.success("Successfully updated.");
          getSettingData();
        } else {
          setloading(false);
          message.error("Update unsuccessful");
        }
      } catch (error) {
        setloading(false);
        message.error("Update unsuccessful");
      }
    },
    [isForceUpdate, newVersionWarning, getSettingData]
  );

  return (
    <div className="settingFormContainer">
      <Card
        title="APP FORCE UPDATE SETTINGS"
        style={{
          // display:'flex',
          // flex: 1
          width: 600,
        }}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{
              site: data.site,
              apple_new_version: data.apple_new_version,
              android_new_version: data.android_new_version,
              new_version_warning: data.new_version_warning,
              apple_force_upgrade_version: data.apple_force_upgrade_version,
              android_force_upgrade_version: data.android_force_upgrade_version,
              new_version_message: data.new_version_message,
              force_upgrade: data.force_upgrade,
              force_upgrade_message: data.force_upgrade_message,
            }}
            onFinish={onSubmit}
            onFinishFailed={onSubmit}
            autoComplete="off"
          >
            <Form.Item label="SITE NAME" name="site">
              <Input disabled={true} />
            </Form.Item>

            <Form.Item label="IOS NEW VERSION" name="apple_new_version">
              <Input />
            </Form.Item>
            <Form.Item label="ANDROID NEW VERSION" name="android_new_version">
              <Input />
            </Form.Item>

            <Form.Item
              label="IOS FORCE UPDATE VERSION"
              name="apple_force_upgrade_version"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="ANDROID FORCE UPDATE VERSION"
              name="android_force_upgrade_version"
            >
              <Input />
            </Form.Item>

            <Form.Item label="NEW VERSION MESSAGE" name="new_version_message">
              <Input />
            </Form.Item>

            <Form.Item
              label="FORCE UPGRADE MESSAGE"
              name="force_upgrade_message"
            >
              <Input />
            </Form.Item>
            {showCreateUpdate && (
              <>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="NEW VERSION WARNING"
                      name="new_version_warning"
                      style={{
                        marginBottom: 0,
                      }}
                    ></Form.Item>
                  </Col>

                  <Col>
                    <Switch
                      checked={newVersionWarning}
                      onChange={() => setnewVersionWarning(!newVersionWarning)}
                      style={{ alignContent: "flex-end" }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="IS FORCE UPDATE"
                      name="is_force_upgrade"
                      style={{ marginBottom: 0 }}
                    ></Form.Item>
                  </Col>

                  <Col>
                    <Switch
                      checked={isForceUpdate}
                      onChange={() => setisForceUpdate(!isForceUpdate)}
                      style={{ alignContent: "flex-end" }}
                    />
                  </Col>
                </Row>
              </>
            )}

            <Form.Item>
              <Button
                type="primary"
                shape="round"
                size="large"
                htmlType="submit"
                block
                disabled={!showCreateUpdate}
              >
                UPDATE SETTINGS
              </Button>
            </Form.Item>
          </Form>
        )}
      </Card>
    </div>
  );
}
