import { constant } from "../Constant";

// solo dive overview
export const getSoloDiveOverviewAction = (page, limit,q) => {
  return {
    type: constant.GET_SOLO_DIVE_OVERVIEW,
    page: page,
    limit: limit,
    q:q
  };
};

// solo dive 
export const getSoloDivesAction = (page, limit,q) => {
  return {
    type: constant.GET_SOLO_DIVES,
    page: page,
    limit: limit,
    q:q
  };
};

