import { constant } from "../Constant";

const initialState = {
  loading: true,
  allUserContent: [],
  count:0,
  errMsg: "",
};

const UserContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_USER_CONTENT:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_USER_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        allUserContent: action.allUserContent,
        count:action.count,
        errMsg: "",
      };
    case constant.GET_USER_CONTENT_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    case constant.CLEAN_USER_CONTENT:
      return {
        ...state,
        allUserContent: [],
        count:0,
        errMsg: "",
      };
    default:
      return state;
  }
};

export default UserContentReducer;
