import { put } from "redux-saga/effects";
import { constant } from "../Constant";
import { FeelingApi } from "../api";
// workers
function* getFeelingsAction(param) {
  try {
    const res = yield FeelingApi.getAllFeeling({
      page: param.page,
      limit: param.limit,
      q: param.q
    });
    if (res.status === 200) {
      const payload = {
        type: constant.GET_FEELINGS_SUCCESS,
        feelings: res.data.results,
        count:res.data.count
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_FEELINGS_FAILED,
        errMsg: "Invalid feeling",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_FEELINGS_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
function* getFeelingDetailsAction(param) {
  try {
    const res = yield FeelingApi.getFeelingDetails(param);
    if (res.status === 200) {
      const payload = {
        type: constant.GET_FEELING_DETAILS_SUCCESS,
        details: res.data,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_FEELINGS_FAILED,
        errMsg: "Invalid feeling",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_FEELINGS_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}
export { getFeelingsAction, getFeelingDetailsAction };
